import ContentLayout from 'src/layout/ContentLayout';
import { useParams } from 'react-router';
import { useState } from 'react';
import { useEffect } from 'react';
import BreadCrumbs from 'src/components/BreadCrumbs';
import Steps from 'src/components/steps/Steps';
import { CButton, CCol, CForm, CFormInput, CFormLabel, CModal, CModalBody, CModalHeader, CRow } from '@coreui/react';
import api from 'src/api';
import moment from 'moment';
import TheIcon from 'src/components/TheIcon/TheIcon';
import ReceivePurchase from '../lotes/ReceivePurchase';
import LButton from 'src/components/LButton/LButton';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';
import ConcludeLotProduction from 'src/components/ConcludeLotProduction';
import { Helmet } from 'react-helmet';

export default function ProcessByLot() {
  const [status, setStatus] = useState(null);
  const [lotId, setLotId] = useState();
  const [toProduction, setToProduction] = useState('');
  const [showSend, setShowSend] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [showConclude, setShowConclude] = useState(false);

  const [updateKey, setUpdateKey] = useState(0);

  const [lot, setLot] = useState({
    lot_number: '',
  });

  const [steps, setSteps] = useState([
    {
      step: 1,
      progress: 100,
      text: 'Purchased',
      nextProgress: 0,
    },
    {
      step: 2,
      progress: 0,
      text: 'Received',
      nextProgress: 0,
    },
    {
      step: 3,
      progress: 0,
      text: 'Sent to production',
      nextProgress: 0,
    },
    {
      step: 4,
      progress: 0,
      text: `Production concluded`,
      nextProgress: 0,
    },
  ]);

  const [showReceive, setShowReceive] = useState(false);
  const dispatch = useDispatch();

  const params = useParams();
  useEffect(() => {
    if (params.id) {
      setLotId(params.id);
    }
  }, [params]);

  useEffect(() => {
    const controller = new AbortController();
    if (lotId) {
      api.get(`/lotes/status/${lotId}`, { signal: controller.signal }).then(({ data }) => {
        setLot(data);
      });
    }
    return () => {
      controller.abort();
    };
  }, [lotId, updateKey]);

  useEffect(() => {
    if (lot?.statusSimple?.length > 0) {
      setStatus(lot.statusSimple);
      const purchased = `Purchased ${lot.liters_purchased} liters of ${lot.lot_number} on ${moment(lot.date_purchased).format('MMMM DD yyyy')}`;
      const received = `Received the lot on ${lot.date_received == null ? 'Uknown date' : moment(lot.date_received).format('MMMM DD yyyy')}`;
      const sent = `Sent the lot to production on ${moment(lot.date_sent).format('MMMM DD yyyy')}`;
      const concluded = `Concluded production on ${moment(lot.date_completed).format('MMMM DD yyyy')}`;

      if (lot.statusSimple == 'Purchased') {
        setSteps((prev) => {
          return prev.map((item, i) => {
            if (i == 0) {
              item.progress = 100;
              item.text = purchased;
              item.nextProgress = 10;
            }
            return item;
          });
        });
      } else if (lot.statusSimple == 'Received') {
        setSteps((prev) => {
          return prev.map((item, i) => {
            if (i == 0) {
              item.progress = purchased.progress;
              item.text = purchased;
              item.nextProgress = 100;
            }
            if (i == 1) {
              item.progress = 100;
              item.text = received;
              item.nextProgress = 0;
            }
            return item;
          });
        });
      } else if (lot.statusSimple == 'Sent' || lot.statusSimple == 'PartiallySent') {
        setSteps((prev) => {
          return prev.map((item, i) => {
            if (i == 0) {
              item.progress = purchased.progress;
              item.text = purchased;
              item.nextProgress = 100;
            }
            if (i == 1) {
              item.progress = 100;
              item.text = received;
              item.nextProgress = 100;
            }
            if (i == 2) {
              item.progress = 100;
              if (lot.statusSimple == 'Sent') {
                item.text = sent;
              } else {
                item.text = `Sent a new production round. (${getProductions().liters} Liters)`;
              }

              item.nextProgress = 10;
            }
            if (i == 3) {
              item.text = 'Production Concluded';
              item.progress = 0;
            }
            return item;
          });
        });
      } else {
        setSteps((prev) => {
          return prev.map((item, i) => {
            if (i == 0) {
              item.progress = purchased.progress;
              item.text = purchased;
              item.nextProgress = 100;
            }
            if (i == 1) {
              item.progress = 100;
              item.text = received;
              item.nextProgress = 100;
            }
            if (i == 2) {
              item.progress = 100;
              item.text = sent;
              item.nextProgress = 100;
            }
            if (i == 3) {
              item.progress = 100;
              const av = parseFloat(lot.available_liters);
              if (!isNaN(av) && av > 0) {
                item.text = `Concluded production round on ${moment(lot.date_completed).format('MMMM DD yyyy')}. There are ${
                  lot.available_liters
                } liters to be bottled`;
                item.overrideIcon = 'warning_white';
              } else {
                item.text = concluded;
                item.overrideIcon = null;
              }
              item.nextProgress = 10;
            }
            return item;
          });
        });
      }
    }
  }, [lot, lot.statusSimple]);

  const quickAction = () => {
    switch (status) {
      case 'Purchased':
        setShowReceive(true);
        break;
      case 'Received':
        setShowSend(true);
        break;
      default:
        if (status == 'PartiallySent' || status == 'Sent') {
          setShowConclude(true);
        } else {
          setShowSend(true);
        }
        break;
    }
  };

  const getElements = () => {
    const result = {
      icon: '',
      text: '',
    };
    switch (status) {
      case 'Purchased':
        result.text = 'Receive';
        result.icon = 'receive';
        break;

      case 'Sent':
        result.text = 'Conclude Production';
        result.icon = 'conclude';
      default:
        if (status == 'PartiallySent' || status == 'Sent') {
          result.text = 'Conclude Production';
          result.icon = 'conclude';
        } else {
          result.text = 'Start Production';
          result.icon = 'production';
        }
    }
    return result;
  };

  const send = () => {
    const toProd = parseFloat(toProduction);
    const av = parseFloat(lot.available_liters);

    if (isNaN(av) || av <= 0) {
      return dispatch(setErrors([{ message: 'There are no liters available.' }]));
    }

    if (isNaN(toProd)) {
      return dispatch(setErrors([{ message: 'Enter a numeric value.' }]));
    }

    if (toProd <= 0) {
      return dispatch(setErrors([{ message: 'Enter a value greater than zero' }]));
    }

    if (toProd > av) {
      return dispatch(
        setErrors([
          {
            message: `Choose an amout of liters ` + `less than or equal to the liters available.`,
          },
        ]),
      );
    }
    setIsSending(true);
    api
      .post('/lotes/production/new', {
        lot_number: lot.lot_number,
        liters: toProd,
      })
      .then(() => {
        setIsSending(false);
        setShowSend(false);
        setUpdateKey((v) => v + 1);
        setToProduction('');
      })
      .catch((e) => {
        console.log('Catch!');
        dispatch(setErrors([{ message: e }]));
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  const getProductions = () => {
    if (lot?.productions?.length > 0) {
      return lot.productions[lot.productions.length - 1];
    } else {
      return {};
    }
  };

  return (
    <>
      <Helmet>
        <title>Process - Batch Production - {lot.lot_number || ''}</title>
      </Helmet>
      <ContentLayout title={`Production Process`}>
        <BreadCrumbs
          items={[
            {
              to: '/dashboard/process/lots',
              text: 'Production Process',
              disabled: false,
            },
            {
              to: '#',
              text: lot.lot_number,
              disabled: true,
            },
          ]}
        />

        <h1 className="fw-bold">Lot {lot.lot_number}</h1>
        <p className="fw-bold">{lot.expression}</p>
        <hr />
        <Steps steps={steps} />
        {/*
            <CAlert color="info" className="text-black my-4 d-flex
             justify-center align-items-center" style={{ fontSize: '16px' }}>
                <TheIcon
                size={24}
                name="info_black"
                 />
                 Click on a step to view more information about it.
            </CAlert>
            <h2 className="fs-4 fw-bold mt-4">
                Information
            </h2>
            <p>
                100 liters of B001 -
                Ángel Cruz Calvo TOBALÁ
                were purchased on December 1st of 2022
            </p>
            */}
        {status != null && !(status == 'Completed' || (status == 'PartiallyCompleted' && parseFloat(lot.available_liters) <= 0)) && (
          <>
            <h2 className="fs-4 fw-bold mt-4">Quick Actions</h2>
            <p>Below you can find some quick actions you can perform to update the current progress of the lot that has been selected.</p>
            {status.length > 0 && (
              <CButton onClick={quickAction}>
                <TheIcon name={getElements().icon} /> {getElements().text}
              </CButton>
            )}
          </>
        )}
        <ReceivePurchase
          onReceive={() => {
            setUpdateKey((v) => v + 1);
          }}
          purchase={lot}
          visible={showReceive}
          setReceiveVisible={setShowReceive}
        />

        <ConcludeLotProduction
          concludeVisible={showConclude}
          setConcludeVisible={setShowConclude}
          production={{
            id: getProductions().id,
            lot_number: lot.lot_number,
            liters: getProductions().liters,
            projected_bottles: getProductions().projected_bottles,
            expression: lot.expression,
          }}
          onConclude={() => {
            setUpdateKey((v) => v + 1);
          }}
        />
        <CModal backdrop={`static`} visible={showSend} onClose={() => setShowSend(false)}>
          <CModalHeader>Send to production</CModalHeader>
          <CModalBody>
            <CForm onSubmit={(e) => e.preventDefault()}>
              <div>
                <p>{lot.expression}</p>
              </div>

              <CRow>
                <CCol>
                  <CFormLabel>Liters Available.</CFormLabel>
                  <CFormInput placeholder="Liters" value={lot.available_liters} readOnly />
                </CCol>
                <CCol>
                  <CFormLabel>Liters</CFormLabel>
                  <CFormInput placeholder="Liters" value={toProduction} onChange={(e) => setToProduction(e.target.value)} type="text" />
                </CCol>
              </CRow>
              <LButton className="mt-4" isLoading={isSending} onClick={send}>
                Send to production
              </LButton>
            </CForm>
          </CModalBody>
        </CModal>
      </ContentLayout>
    </>
  );
}
