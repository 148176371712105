import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardFooter,
  CCardTitle,
  CCardHeader,
  CButton,
  CAlert,
} from '@coreui/react';
import { useEffect, useState } from 'react';
import api from 'src/api';
import YesNoModal from 'src/components/YesNoModal';
import { useDispatch } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const MyTastingMenus = () => {
  const [menus, setMenus] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();

    api.get('/menu', { signal: controller.signal }).then(({ data }) => {
      setMenus(data);
    });
    return () => {
      controller.abort();
    };
  }, []);

  const deleteMenu = () => {
    if (deleteId) {
      setIsDeleting(true);
      api
        .delete(`/menu/${deleteId}`)
        .then(() => {
          setMenus((prev) =>
            prev.filter((it) => {
              return it.id != deleteId;
            }),
          );
          setDeleteId(null);
          dispatch(
            setSuccessMessages([
              {
                message: 'The menu has been deleted successfully!',
              },
            ]),
          );
        })
        .catch((e) => {
          dispatch(
            setErrors([
              {
                message: e,
              },
            ]),
          );
        })
        .finally(() => {
          setIsDeleting(false);
          setShowDelete(false);
        });
    }
  };

  return (
    <>
      <CContainer>
        <Helmet>
          <title>My Tasting Menus</title>
        </Helmet>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CCardTitle>Saved Menus</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <div className="mb-3">
                      <CButton
                        color="primary"
                        onClick={() => {
                          navigate('/tools/tasting-menu');
                        }}
                      >
                        New Menu
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  {menus.map((menu, idx) => {
                    return (
                      <CCol xs={12} md={6} xl={4} key={`menu-cd-${idx}`}>
                        <CCard style={{ minHeight: '100%' }}>
                          <CCardHeader>
                            <CCardTitle>{menu.title}</CCardTitle>
                          </CCardHeader>
                          <CCardBody>
                            <p>
                              This menu has the following <b>Expressions:</b>
                            </p>
                            <ul>
                              {menu?.options?.map((item, i) => {
                                return item?.brand?.length > 0 ? (
                                  <li key={`item-ex-${i}`}>{item.brand}</li>
                                ) : null;
                              })}
                            </ul>
                          </CCardBody>
                          <CCardFooter>
                            <CButton
                              className="me-1"
                              onClick={() => {
                                navigate({
                                  pathname: '/tools/tasting-menu',
                                  search: `?id=${menu.id}`,
                                });
                              }}
                            >
                              Edit
                            </CButton>
                            <CButton
                              color="danger"
                              onClick={() => {
                                setShowDelete(true);
                                setDeleteId(menu.id);
                              }}
                            >
                              Delete
                            </CButton>
                          </CCardFooter>
                        </CCard>
                      </CCol>
                    );
                  })}
                </CRow>
                {menus.length <= 0 && (
                  <CRow>
                    <CCol>
                      <CAlert color="warning">No Menus Found</CAlert>
                    </CCol>
                  </CRow>
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <YesNoModal
              visible={showDelete}
              setVisible={setShowDelete}
              title="Delete Tasting Menu"
              description="This action can't be undone."
              isLoading={isDeleting}
              onYes={deleteMenu}
              onNo={() => {
                setShowDelete(false);
              }}
            />
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default MyTastingMenus;
