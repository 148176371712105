const initialState = {
  loggedIn: false,
  token: null,
  role: null,
  refresh_token: null,
  avatar: null,
  username: null,
  sessionId: null,
};

const authReducer = (state = initialState, action) => {
  let stateToUse = initialState;

  if (state && state.loggedIn) {
    stateToUse = state;
  }
  switch (action.type) {
    case 'LOGIN':
      stateToUse.loggedIn = true;
      stateToUse.token = action.payload.token;
      stateToUse.role = action.payload.role;
      stateToUse.refresh_token = action.payload.refresh_token;
      stateToUse.avatar = action.payload.avatar;
      stateToUse.username = action.payload.username;
      stateToUse.sessionId = action.payload.sessionId;
      return { ...stateToUse };
    case 'LOGOUT':
      return {};
    case 'SET_NEW_TOKEN_INFO':
      stateToUse.refresh_token = action.payload.refresh_token;
      stateToUse.token = action.payload.token;
      return { ...stateToUse };
    case 'SET_NEW_AVATAR':
      stateToUse.avatar = action.payload;
      return { ...stateToUse };
    default:
      return stateToUse;
  }
};

export default authReducer;
