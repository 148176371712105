import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CModal,
  CModalHeader,
  CRow,
  CButton,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react';
import LotDetailModal from './lotDetails-edit-modal';
import api from 'src/api';
import MapAndMarker from '../../../components/MapAndMarker';

import { Table, Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import { Helmet } from 'react-helmet';

const LotDetails = () => {
  const [visible, setVisible] = useState(false);
  const [lotDetailsList, setLotDetailsList] = useState([]);
  const [modalLotDetail, setModalLotDetail] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [elevationModalVisible, setElevationModalVisible] = useState(false);
  const [elevation, setElevation] = useState('');

  function fetchLotDetails() {
    api.get('/lotes/lot-details').then(({ data }) => {
      setLotDetailsList(data);
    });
  }

  function fetchClassifications() {
    api.get('/lot-classification').then(({ data }) => {
      setClassifications(data);
    });
  }

  useEffect(() => {
    fetchLotDetails();
    fetchClassifications();
  }, []);

  function adjustModal(lotDetail) {
    setModalLotDetail(lotDetail);
    setVisible(!visible);
    if (lotDetail && lotDetail.lot_extra_field) {
      const ef = lotDetail.lot_extra_field;
      if (ef['ELEVACIÓN']) {
        setElevation(ef['ELEVACIÓN']);
      }
    }
  }

  function LotDetailRow(props) {
    const datePurchased = new Date(props.lotDetail.date_purchased);
    const month = datePurchased.getMonth() + 1;
    const year = datePurchased.getFullYear();
    const dateFormatted = month + '/' + datePurchased.getDate() + '/' + year;
    return (
      <Tr
        style={{ cursor: 'pointer', fontSize: '0.85em' }}
        onClick={(e) => {
          adjustModal(props.lotDetail);
        }}
        key={'ldr' + props.l}
      >
        <Td scope="row">{props.lotDetail.lot_number}</Td>
        <Td>{props.lotDetail.expression}</Td>
        <Td>{dateFormatted}</Td>
      </Tr>
    );
  }

  function LotDetailsList(props) {
    const lots = props.lotDetails;
    let l = -1;
    return lots.map((lotDetail) => {
      l++;
      return <LotDetailRow lotDetail={lotDetail} l={l} key={`lde-${l}`} />;
    });
  }

  function openElevationModal() {
    setElevationModalVisible(true);
  }
  function closeElevationModal() {
    setElevationModalVisible(false);
  }

  function saveElevation() {
    setElevationModalVisible(false);
    setVisible(true);
    const lotExtraField = modalLotDetail.lot_extra_field;

    if (lotExtraField) {
      lotExtraField['ELEVACIÓN'] = elevation;
      setModalLotDetail((prevState) => {
        return {
          ...prevState,
          lot_extra_field: lotExtraField,
        };
      });
    } else {
      alert('Unable to update.');
    }
  }

  return (
    <CContainer>
      <Helmet>
        <title>Lot Details</title>
      </Helmet>
      <CModal visible={visible} onDismiss={() => setVisible(false)} size="lg">
        <CModalHeader>
          <CCol sm={5}>
            <div>
              <strong>LOT DETAILS</strong>
            </div>
            <div>
              {modalLotDetail.expression} | {modalLotDetail.lot_number}
            </div>
          </CCol>
        </CModalHeader>
        <LotDetailModal
          lotDetail={modalLotDetail}
          setVisible={setVisible}
          fetchLotDetails={fetchLotDetails}
          classifications={classifications}
          elevation={elevation}
          setElevation={setElevation}
          openElevationModal={openElevationModal}
          onLotDeleted={(lotId) => {
            setVisible(false);
            setLotDetailsList((prevValue) => {
              return prevValue.filter((item) => {
                return item.lot_id != lotId;
              });
            });
          }}
        />
      </CModal>
      <CRow>
        <CCol>
          <CModal
            visible={elevationModalVisible}
            onClose={() => closeElevationModal(false)}
            fullscreen={true}
          >
            <CModalHeader>
              <CModalTitle>Select a location.</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <MapAndMarker updateElevation={setElevation}></MapAndMarker>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="danger"
                style={{ color: 'white' }}
                onClick={() => closeElevationModal(false)}
              >
                Cancel
              </CButton>
              <CButton
                color="primary"
                style={{ color: 'white' }}
                onClick={() => saveElevation()}
              >
                Save
              </CButton>
            </CModalFooter>
          </CModal>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <CCol sm={5}>
                <strong>LOTES</strong> <small>Lot Details</small>
              </CCol>
            </CCardHeader>
            <CCardBody>
              <Table>
                <Thead>
                  <Tr>
                    <Td scope="col">Lot</Td>
                    <Td scope="col">Expression</Td>
                    <Td scope="col">Date Purchased</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <LotDetailsList lotDetails={lotDetailsList} />
                </Tbody>
              </Table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

LotDetails.defaultProps = {
  lotDetails: [],
};

LotDetails.propTypes = {
  lotDetails: PropTypes.any,
  lotDetailsList: PropTypes.any,
  lotDetail: PropTypes.string,
  l: PropTypes.number,
};

export default LotDetails;
