const sidebarShowReducer = (state = true, action) => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR_SHOW':
      return !state;
      break;
    case 'HIDE_SIDEBAR_SHOW':
      return false;
      break;
    case 'SHOW_SIDEBAR_SHOW':
      return true;
      break;
    default:
      return state;
  }
};
export default sidebarShowReducer;
