import { CCol, CModal, CModalHeader, CModalBody, CButton, CForm, CFormLabel, CFormInput } from '@coreui/react';
import api from 'src/api';
import PropTypes from 'prop-types';

function ReceivePurchase(props) {
  function receive(e) {
    e.preventDefault();
    api
      .post('/lotes/purchases/receive', {
        lot_number: props.purchase.lot_number,
      })
      .then(() => {
        props.onReceive();
      })
      .finally(() => {
        props.setReceiveVisible(false);
      });
  }

  return (
    <CModal
      visible={props.visible}
      onClose={() => {
        props.setReceiveVisible(false);
      }}
      backdrop={`static`}
    >
      <CModalHeader>
        <CCol sm={5}>
          <strong>RECEIVE</strong> <small>Purchase</small>
        </CCol>
      </CModalHeader>
      <CModalBody>
        <CForm style={{ margin: '10px' }} onSubmit={receive}>
          <div className="mb-3">
            <CFormLabel htmlFor="expressionInput">Expression</CFormLabel>
            <CFormInput type="text" id="expressionInput" defaultValue={props.purchase.expression} readOnly size="sm" />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="lotInput">Lot</CFormLabel>
            <CFormInput type="text" id="lotInput" defaultValue={props.purchase.lot_number} readOnly size="sm" />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="litersInput">Liters</CFormLabel>
            <CFormInput type="text" id="litersInput" defaultValue={props.purchase.liters_purchased} readOnly size="sm" />
          </div>
          <CButton type="submit" color="primary">
            Confirm
          </CButton>
          <CButton
            color="danger"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              props.setReceiveVisible(false);
            }}
            style={{ marginLeft: '10px' }}
          >
            Close
          </CButton>
        </CForm>
      </CModalBody>
    </CModal>
  );
}

ReceivePurchase.propTypes = {
  purchase: PropTypes.array,
  visible: PropTypes.bool,
  setReceiveVisible: PropTypes.func,
  onReceive: PropTypes.func,
};

export default ReceivePurchase;
