import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import PropTypes from 'prop-types';

function ContentLayout({ children, title, subtitle }) {
  return (
    <CCard>
      <CCardHeader>
        <strong>{title}</strong> {subtitle && <span>- {subtitle}</span>}
      </CCardHeader>
      <CCardBody>{children}</CCardBody>
    </CCard>
  );
}

ContentLayout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default ContentLayout;
