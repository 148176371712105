import React, { useState, useEffect } from 'react';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormInput,
  CContainer,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CForm,
  CFormLabel,
  CFormSelect,
} from '@coreui/react';

import api from 'src/api';
import ChangePassword from 'src/components/ChangePassword';
import { Helmet } from 'react-helmet';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hasPassword, setHasPassword] = useState(false);

  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [userCreated, setUserCreated] = useState(null);

  useEffect(() => {
    if (users?.length > 0 && userCreated != null) {
      setUserId(userCreated);
      setChangePasswordVisible(true);
    }
  }, [userCreated, users]);

  useEffect(() => {
    if (!changePasswordVisible) {
      setUserCreated(null);
    }
  }, [changePasswordVisible]);

  useEffect(() => {
    const controller1 = new AbortController();
    const controller2 = new AbortController();

    api
      .get('/user', { signal: controller1.signal })
      .then(({ data }) => setUsers(data));

    api
      .get('/user-role', { signal: controller2.signal })
      .then(({ data }) => setUserRoles(data));

    return () => {
      controller1.abort();
      controller2.abort();
    };
  }, []);

  const closeDelete = () => {
    setUserId(null);
    setVisibleDelete(false);
  };

  const confirmDelete = () => {
    api.delete(`/user/${userId}`).then(() => {
      setUsers(users.filter((user) => user.id !== userId));
      closeDelete();
    });
  };

  const rolesForSelect = userRoles.map((ur) => {
    return (
      <option key={`opt-r-${ur.id}`} value={ur.id}>
        {ur.role}
      </option>
    );
  });

  const openEditModal = (id) => {
    if (!editVisible) {
      setUserId(id);
      setEditVisible(true);
      users.forEach((user) => {
        if (user.id === id) {
          setUsername(user.username);
          setEmail(user.email);
          setRoleId(user.role_id);
          setHasPassword(user.has_password == '1');
        }
      });
    }
  };

  const confirmUpdate = () => {
    const data = {
      username,
      email,
      user_role_id: roleId,
      password,
      confirm_password: confirmPassword,
    };

    if (userId) {
      api.put(`/user/${userId}`, data).then(() => {
        setUsers(
          users.map((usr) => {
            if (usr.id === userId) {
              usr.username = username;
              usr.email = email;

              userRoles.forEach((role) => {
                if (parseInt(role.id) === parseInt(roleId)) {
                  usr.role_id = roleId;
                  usr.role_key = role.role_key;
                  usr.role = role.role;
                }
              });
            }
            return usr;
          }),
        );

        setUsername('');
        setEmail('');
        setRoleId('');
        closeEdit();
      });
    } else {
      api.post('/user/sign-up', data).then(({ data: responseData }) => {
        userRoles.forEach((role) => {
          if (
            parseInt(role.id) === parseInt(roleId) ||
            parseInt(responseData.roleId) === parseInt(role.id)
          ) {
            data.role = role.role;
            data.role_key = role.role_key;
            data.role_id = role.id;
          }
        });
        data.id = responseData.id;
        const newUsers = [...users];
        newUsers.push(data);
        setUsers(newUsers);
        closeEdit();
        setUserCreated(responseData.id);
      });
    }
  };

  const closeEdit = () => {
    setUserId(null);
    setEditVisible(false);
    setUsername('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  };

  const askNewUser = () => {
    setUserId(null);
    setEditVisible(true);
  };

  const askChangePassword = () => {
    setEditVisible(false);
    setTimeout(() => {
      setChangePasswordVisible(true);
    }, 300);
  };

  const usersRows = users.map((user) => {
    return (
      <Tr
        key={`user-row-${user.id}`}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          openEditModal(user.id);
        }}
      >
        <Td>{user.username}</Td>
        <Td>{user.email}</Td>
        <Td>{user.role}</Td>
      </Tr>
    );
  });

  return (
    <CContainer>
      <Helmet>
        <title>Users</title>
      </Helmet>

      <CRow>
        <CCol>
          <CButton style={{ marginBottom: '15px' }} onClick={askNewUser}>
            New User
          </CButton>
        </CCol>
      </CRow>

      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <CCol sm={5}>
                <strong>Users</strong> <small>List of users</small>
              </CCol>
            </CCardHeader>
            <CCardBody>
              <div className="table-container">
                <Table>
                  <Thead>
                    <Tr>
                      <Th scope="col">Username</Th>
                      <Th scope="col">Email</Th>
                      <Th scope="col">Role</Th>
                      {/* <Th scope="col">Actions</Th>*/}
                    </Tr>
                  </Thead>
                  <Tbody>{usersRows}</Tbody>
                </Table>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <ChangePassword
            visible={changePasswordVisible}
            closeModal={() => {
              setChangePasswordVisible(false);
              // setEditVisible(true);
            }}
            id={parseInt(userId)}
            hasPassword={hasPassword}
            onPasswordSet={(pUserId) => {
              setHasPassword(true);
              setUserCreated(null);
              if (userId != null) {
                setUsers((prevUsers) => {
                  return prevUsers.map((user) => {
                    if (user.id == pUserId) {
                      user.has_password = 1;
                    }
                    return user;
                  });
                });
                setChangePasswordVisible(false);
              }
            }}
          />
        </CCol>
      </CRow>
      <CRow>
        <CModal className="show" backdrop={true} visible={visibleDelete}>
          <CModalHeader>
            <CModalTitle>Do you want to delete this user?</CModalTitle>
          </CModalHeader>
          <CModalBody>This action can&apos;t be undone.</CModalBody>
          <CModalFooter>
            <CButton color="primary" type="button" onClick={closeDelete}>
              Close
            </CButton>
            <CButton
              color="danger"
              type="button"
              onClick={confirmDelete}
              style={{ color: 'white' }}
            >
              Delete
            </CButton>
          </CModalFooter>
        </CModal>
      </CRow>
      <CRow>
        <CModal className="show" backdrop={true} visible={editVisible}>
          <CModalHeader>
            <CModalTitle>{userId ? 'Update User' : 'Create User'}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CForm>
              <CFormLabel>Username</CFormLabel>
              <CFormInput
                className="mb-3"
                value={username}
                label="Username"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
              ></CFormInput>

              <CFormLabel>Email</CFormLabel>
              <CFormInput
                className="mb-3"
                value={email}
                placeholder="Email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              ></CFormInput>

              <CFormLabel>Role</CFormLabel>
              <CFormSelect
                value={roleId}
                onChange={(e) => setRoleId(e.target.value)}
                aria-label="User Role"
              >
                {rolesForSelect}
              </CFormSelect>

              {userId && (
                <>
                  <CButton
                    className="mt-3"
                    color="primary"
                    variant="ghost"
                    onClick={askChangePassword}
                  >
                    Change Password
                  </CButton>
                </>
              )}
            </CForm>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="danger"
              style={{ color: 'white' }}
              type="button"
              onClick={closeEdit}
            >
              Close
            </CButton>
            <CButton color="primary" type="button" onClick={confirmUpdate}>
              {userId ? 'Update User' : 'Create User'}
            </CButton>
          </CModalFooter>
        </CModal>
      </CRow>
    </CContainer>
  );
};

export default Users;
