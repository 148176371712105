import React from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CForm,
  CFormLabel,
  CFormInput,
  CFormSwitch,
  CButton,
  CContainer,
  CModalTitle,
  CFormTextarea,
} from '@coreui/react';
import {useState, useEffect} from 'react';
import CIcon from '@coreui/icons-react';
import {cilTrash} from '@coreui/icons';
import api from 'src/api';

import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import {Helmet} from 'react-helmet';

const Mezcaleros = () => {
  const [visible, setVisible] = useState(false);
  const [mezcalerosList, setMezcalerosList] = useState([]);
  const [modalMezcalero, setModalMezcalero] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);

  function fetchMezcaleros() {
    api.get('/contacts/mezcaleros').then(({data}) => {
      setMezcalerosList(data);
    });
  }

  useEffect(() => {
    fetchMezcaleros();
  }, []);

  function handleSaveSubmit(e) {
    e.preventDefault();
    const elms = e.target.elements;
    api
        .post('/contacts/mezcaleros/update', {
          maker_name: elms.nameInput.value,
          address: elms.addInput.value,
          city: elms.cityInput.value,
          state: elms.staInput.value,
          zip: elms.zipInput.value,
          phone: elms.phonInput.value,
          email: elms.emailInput.value,
          certified: elms.certInput.checked,
          note: elms.noteInput.value,
          bank_details: elms.bankInput.value,
        })
        .then(() => {
          fetchMezcaleros();
        })
        .finally(() => {
          setVisible(false);
        });
  }

  function deleteMezcaldero(mezcalero) {
    api
        .post('/contacts/mezcaleros/delete', {
          maker_name: mezcalero,
        })
        .then(() => {
          fetchMezcaleros();
        })
        .finally(() => {
          setVisible(false);
        });
  }

  function handleNewSubmit(e) {
    e.preventDefault();
    if (!e.target.elements.nameInput.value) {
      alert('Name must be entered');
    }

    const tempMezcalerosList = mezcalerosList.map((element) => {
      return element.maker_name;
    });

    if (tempMezcalerosList.includes(e.target.elements.nameInput.value)) {
      alert('Mezcalero already exists');
    }

    const elms = e.target.elements;

    api
        .post('/contacts/mezcaleros/new', {
          maker_name: elms.nameInput.value,
          address: elms.addInput.value,
          city: elms.cityInput.value,
          state: elms.staInput.value,
          zip: elms.zipInput.value,
          phone: elms.phonInput.value,
          email: elms.emailInput.value,
          certified: elms.certInput.checked,
          note: elms.noteInput.value,
          bank_details: elms.bankInput.value,
        })
        .then(() => {
          fetchMezcaleros();
        })
        .finally(() => {
          setNewVisible(false);
        });
  }

  function MezcaleroModal(props) {
    return (
      <CModalBody>
        <CModal key="modalConfirmDelete" size="sm" visible={deleteModalVisible}>
          <CModalHeader>
            <CModalTitle>Confirm Delete</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow>
              <CCol style={{marginTop: '20px'}}>
                <CButton
                  color="primary"
                  type="submit"
                  onClick={() => {
                    setDeleteModalVisible(false);
                    deleteMezcaldero(props.mezcalero.maker_name);
                  }}
                >
                  Delete
                </CButton>
                <CButton
                  color="danger"
                  onClick={() => {
                    setDeleteModalVisible(false);
                  }}
                  style={{marginLeft: '10px'}}
                >
                  Close
                </CButton>
              </CCol>
            </CRow>
          </CModalBody>
        </CModal>
        <CForm style={{margin: '10px'}} onSubmit={handleSaveSubmit}>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="nameInput">
                Name
              </CFormLabel>
              <CFormInput
                type="text"
                id="nameInput"
                defaultValue={props.mezcalero.maker_name}
                size="sm"
                readOnly
              />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="emailInput">
                E-mail
              </CFormLabel>
              <CFormInput
                type="text"
                id="emailInput"
                defaultValue={props.mezcalero.email}
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="addInput">
                Address
              </CFormLabel>
              <CFormInput
                type="text"
                id="addInput"
                defaultValue={props.mezcalero.address}
                size="sm"
              />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="cityInput">
                City
              </CFormLabel>
              <CFormInput
                type="text"
                id="cityInput"
                defaultValue={props.mezcalero.city}
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="staInput">
                State
              </CFormLabel>
              <CFormInput
                type="text"
                id="staInput"
                defaultValue={props.mezcalero.state}
                size="sm"
              />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="zipInput">
                Zip
              </CFormLabel>
              <CFormInput
                type="text"
                id="zipInput"
                defaultValue={props.mezcalero.zip}
                size="sm"
              />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="phoInput">
                Phone
              </CFormLabel>
              <CFormInput
                type="text"
                id="phonInput"
                defaultValue={props.mezcalero.phone}
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormSwitch
                label="Certified"
                id="certInput"
                defaultChecked={props.mezcalero.certified}
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="bankInput">
                Bank Details
              </CFormLabel>
              <CFormTextarea
                id="bankInput"
                defaultValue={props.mezcalero.bank_details}
                rows="2"
              />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="noteInput">
                Note
              </CFormLabel>
              <CFormTextarea
                id="noteInput"
                defaultValue={props.mezcalero.note}
                rows="2"
              />
            </CCol>
          </CRow>
          <div style={{marginTop: '5px'}}>
            <CButton
              color="danger"
              className="float-end"
              onClick={() => setDeleteModalVisible(true)}
            >
              <CIcon icon={cilTrash} />
            </CButton>
            <CButton type="submit" color="primary">
              Save
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                setVisible(false);
              }}
              style={{marginLeft: '10px'}}
            >
              Close
            </CButton>
          </div>
        </CForm>
      </CModalBody>
    );
  }

  function MezcaleroNewModal() {
    return (
      <CModalBody>
        <CForm style={{margin: '10px'}} onSubmit={handleNewSubmit}>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="nameInput">
                Name
              </CFormLabel>
              <CFormInput type="text" id="nameInput" size="sm" />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="emailInput">
                E-mail
              </CFormLabel>
              <CFormInput type="text" id="emailInput" size="sm" />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="addInput">
                Address
              </CFormLabel>
              <CFormInput type="text" id="addInput" size="sm" />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="cityInput">
                City
              </CFormLabel>
              <CFormInput type="text" id="cityInput" size="sm" />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="staInput">
                State
              </CFormLabel>
              <CFormInput type="text" id="staInput" size="sm" />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="zipInput">
                Zip
              </CFormLabel>
              <CFormInput type="text" id="zipInput" size="sm" />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="phoInput">
                Phone
              </CFormLabel>
              <CFormInput type="text" id="phonInput" size="sm" />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormSwitch label="Certified" id="certInput" />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="bankInput">
                Bank Details
              </CFormLabel>
              <CFormTextarea id="bankInput" rows="2" />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="noteInput">
                Note
              </CFormLabel>
              <CFormTextarea id="noteInput" rows="2" />
            </CCol>
          </CRow>
          <div style={{marginTop: '5px'}}>
            <CButton type="submit" color="primary">
              Add New
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                setNewVisible(false);
              }}
              style={{marginLeft: '10px'}}
            >
              Close
            </CButton>
          </div>
        </CForm>
      </CModalBody>
    );
  }

  function adjustModal(mezcalero) {
    setModalMezcalero(mezcalero);
    setVisible(!visible);
  }

  function MezcaleroRow(props) {
    return (
      <Tr
        style={{cursor: 'pointer', fontSize: '0.85em'}}
        onClick={(e) => {
          adjustModal(props.mezcalero);
        }}
        key={'mr' + props.m}
      >
        <Td>{props.mezcalero.maker_name}</Td>
      </Tr>
    );
  }

  function MezcalerosList(props) {
    const mez = props.mezcaleros;
    let m = -1;
    const list = mez.map((mezcalero) => {
      m++;
      return <MezcaleroRow mezcalero={mezcalero} m={m} key={`mez-row-` + m} />;
    });
    return list;
  }

  return (
    <CContainer>
      <Helmet>
        <title>Mezcaleros</title>
      </Helmet>
      <CModal
        key="modalNew"
        visible={newVisible}
        onDismiss={() => setNewVisible(false)}
        size="lg"
      >
        <CModalHeader>
          <CCol sm={5}>
            <strong>MEZCALERO</strong> <small>New</small>
          </CCol>
        </CModalHeader>
        <MezcaleroNewModal />
      </CModal>
      <CModal
        key="modalEdit"
        visible={visible}
        onDismiss={() => setVisible(false)}
        size="lg"
      >
        <CModalHeader>
          <CCol sm={5}>
            <strong>MEZCALERO</strong> <small>Details</small>
          </CCol>
        </CModalHeader>
        <MezcaleroModal mezcalero={modalMezcalero} />
      </CModal>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <CCol sm={5}>
                <strong>MEZCALEROS</strong>
              </CCol>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CButton
                    style={{marginBottom: '15px'}}
                    onClick={() => {
                      setNewVisible(true);
                    }}
                  >
                    New Mezcalero
                  </CButton>
                </CCol>
              </CRow>
              <div className="table-container">
                <Table>
                  <Thead>
                    <Tr>
                      <Th scope="col">Name</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <MezcalerosList mezcaleros={mezcalerosList} />
                  </Tbody>
                </Table>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

Mezcaleros.defaultProps = {
  mezcaleros: [],
};

Mezcaleros.propTypes = {
  mezcaleros: PropTypes.any,
  mezcalerosList: PropTypes.any,
  mezcalero: PropTypes.string,
  m: PropTypes.number,
};

export default Mezcaleros;
