export default function useValidator() {
  return {
    validateEmail: (email) => {
      return String(email)
        .toLowerCase()
        .match(
          // eslint-disable-next-line
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    validateURL(urlToValidate) {
      let url;
      try {
        url = new URL(urlToValidate);
      } catch (_) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:';
    },
  };
}
