export default function useCasePackCalculator() {
  const PACK_SIZE = 6;

  const bottlesPerPallet = {
    750: 720,
    1000: 600,
  };

  const howManyCasePacks = (qtyBottles) => {
    return qtyBottles / PACK_SIZE;
  };

  const howManyPallets = (size, qtyBottles) => {
    if (!Object.keys(bottlesPerPallet).includes(size)) {
      throw new Error(
        '[useCasePackCalculator]: ' +
          'The case size is not supported. Please add it in the code.',
      );
    }
    return qtyBottles / bottlesPerPallet[size];
  };

  const howManyBottles = (casePacks) => {
    return parseFloat(casePacks) * PACK_SIZE;
  };

  return {
    howManyCasePacks,
    howManyPallets,
    howManyBottles,
  };
}
