import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCol, CContainer, CRow } from '@coreui/react';
import { useCookies } from 'react-cookie';
import api from 'src/api';
import { login } from 'src/actions';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useValidator from 'src/hooks/useValidator';
import { Helmet } from 'react-helmet';

const Login = () => {
  const [, setCookie] = useCookies(['session_id']);
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const role = useSelector((state) => state.auth.role);
  const navigate = useNavigate();
  const params = useParams();
  const validator = useValidator();
  const [searchParams] = useSearchParams();
  const [callback, setCallback] = useState(null);

  useEffect(() => {
    if (loggedIn && role.length > 0) {
      if (callback) {
        window.location.assign(callback);
      } else {
        if (role === 'CLIENT') {
          navigate('/dashboard/inventory/by-expression');
        } else if (role === 'ARTIST') {
          navigate('/dashboard/art-submissions');
        } else if (role === 'ADMIN' || role === 'STAFF') {
          navigate('/dashboard');
        } else {
          navigate('/dashboard/inventory/by-expression');
        }
      }
    }
  }, [loggedIn, role, callback]);

  useEffect(() => {
    const cb = searchParams.get('callback');
    if (validator.validateURL(cb)) {
      const cbURL = new URL(cb);
      const currURL = new URL(window.location.href);
      if (cbURL.origin == currURL.origin) {
        setCallback(cb);
      }
    }

    return () => {
      setCallback(null);
    };
  }, [useSearchParams, validator]);

  useEffect(() => {
    if (validator.validateURL(searchParams.get('callback'))) {
      setCallback(decodeURIComponent(searchParams.get('callback')));
    }
    const sessionId = params.session_id;
    if (sessionId) {
      const requestJWT = (sessionId) => {
        api
          .post('/sessions/jwt', {
            session_id: sessionId,
          })
          .then(({ data }) => {
            if (data && data.token) {
              dispatch(
                login({
                  token: data.token,
                  role: data.role,
                  refresh_token: data.refresh_token,
                  avatar: data.avatar,
                  username: data.username,
                  sessionId: sessionId,
                }),
              );
            }
          })
          .catch((e) => {
            alert(e);
          });
      };
      setCookie('session_id', sessionId, {
        path: '/',
      });
      requestJWT(sessionId);
    }
  }, [params.session_id, searchParams]);

  return (
    <>
      <Helmet>
        <title>Signing in...</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol>
            <CCard>
              <CCardBody style={{ marginTop: '3rem' }}>
                <h1>Signing on...</h1>
                <p>Please wait...</p>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default Login;
