import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardHeader,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CAlert,
  CFormInput,
} from '@coreui/react';

import LButton from 'src/components/LButton/LButton';
import { cilCloudUpload, cilDrinkAlcohol, cilSave } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

import './tasting-menu-page.css';
import domtoimage from 'dom-to-image';
import { useState, useRef, useEffect } from 'react';
import TastingItem from 'src/components/TastingItem/TastingItem';
import JsPDF from 'jspdf';
import api from 'src/api';

import { useDispatch } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';
import { useNavigate } from 'react-router-dom';

import useQuery from 'src/hooks/useQuery';

import { UploadBoxContext, UploadBoxProvider } from 'react-upload-component';
import { UploadBox } from 'react-upload-component';
import { useContext } from 'react';
import ImageFromFile from 'src/components/ImageFromFile';
import { Helmet } from 'react-helmet';

const TastingMenuPage = () => {
  return (
    <>
      <UploadBoxProvider>
        <Content></Content>
      </UploadBoxProvider>
    </>
  );
};

const Content = () => {
  const wrapperRef = useRef(null);
  const canvasRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [isSavingMenu, setIsSavingMenu] = useState(false);
  const [title, setTitle] = useState('');
  const [editId, setEditId] = useState(null);
  const [img, setImg] = useState(null);

  const [visibleUpload, setVisibleUpload] = useState(false);

  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();
  const { files, setFiles } = useContext(UploadBoxContext);

  const [expressions, setExpressions] = useState([
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ]);
  const [expressionsModal, setExpressionsModal] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    if (query.get('id')) {
      const id = query.get('id');
      if (id && id > 0 && id != editId) {
        api
          .get(`/menu/${id}`, { signal: controller.signal })
          .then(({ data }) => {
            setEditId(id);
            if (data.title) {
              setTitle(data.title);
            }
            if (data.options) {
              setExpressions(data.options);
              setExpressionsModal(data.options);
            }
            if (data.img) {
              setImg(data.img);
            }
          });
      }
    } else {
      setEditId(null);
      setTitle('');
      const newEx = [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {
          id: 5,
        },
      ];
      setExpressions(newEx);
      setExpressionsModal(newEx);
    }
    return () => {
      controller.abort();
    };
  }, [query]);

  useEffect(() => {
    const controller = new AbortController();
    api
      .get('/product/for-menu', { signal: controller.signal })
      .then(({ data }) => {
        setProducts(data);
      });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setExpressionsModal([...expressions]);
  }, [expressions]);

  const savePdf = async () => {
    if (!isSaving && wrapperRef.current) {
      setIsSaving(true);
      const element = wrapperRef.current;
      let scale = 12;
      const windowWidth = window.outerWidth;

      if (windowWidth > 400) {
        scale = 6;
      }
      if (windowWidth > 800) {
        scale = 3;
      }

      if (windowWidth > 1200) {
        scale = 2;
      }
      domtoimage
        .toPng(element, {
          width: element.clientWidth * scale,
          height: element.clientHeight * scale,
          style: {
            transform: 'scale(' + scale + ')',
            transformOrigin: 'top left',
          },
        })
        .then(function(dataUrl) {
          const pdf = new JsPDF('l', 'in', 'a4', true);
          pdf.addImage(dataUrl, 'PNG', 0, 0, 11.7, 8.3);
          pdf.save(`tasting-menu-cc.pdf`);
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const onItemUpdated = (data) => {
    expressionsModal.forEach((ex) => {
      if (ex.id == data.id) {
        if (
          ex.brand != data.brand ||
          ex.type != data.type ||
          ex.age != data.age
        ) {
          setExpressionsModal((prev) => {
            return prev.map((p) => {
              if (p.id == data.id) {
                return {
                  id: ex.id,
                  brand: data.brand,
                  type: data.type,
                  age: data.age,
                };
              } else {
                return p;
              }
            });
          });
        }
      }
    });
  };

  const hasData = (index) => {
    const data = expressionsModal[index];
    return (
      data &&
      (data?.brand?.length > 0 ||
        data?.type?.length > 0 ||
        data?.age?.length > 0)
    );
  };

  const saveMenu = () => {
    if (title.length <= 0) {
      dispatch(
        setErrors([
          {
            message: 'Enter a title for your menu.',
          },
        ]),
      );
    }
    const hasAtLeastOneItem = expressionsModal.some((item) => {
      return item?.brand?.length > 0 || item?.type?.length > 0;
    });
    if (hasAtLeastOneItem) {
      const dataMenu = new FormData();
      dataMenu.append('title', title);
      dataMenu.append('options', JSON.stringify(expressionsModal));

      if (files.length > 0) {
        dataMenu.append('img', files[0]);
      }
      setIsSavingMenu(true);
      if (editId) {
        api
          .put(`/menu/${editId}`, dataMenu)
          .then(({ data }) => {
            dispatch(
              setSuccessMessages([
                {
                  message: 'Your menu has been updated successfully!',
                },
              ]),
            );
            setEditId(data.id);
            setFiles([]);
            if (data.img) {
              setImg(data.img);
            }
          })
          .catch((e) => {
            setErrors([
              {
                message: e,
              },
            ]);
          })
          .finally(() => {
            setIsSavingMenu(false);
          });
      } else {
        api
          .post('/menu', dataMenu)
          .then(({ data }) => {
            dispatch(
              setSuccessMessages([
                {
                  message: 'Your menu has been saved successfully!',
                },
              ]),
            );
            setEditId(data.id);
            setFiles([]);
            if (data.img) {
              setImg(data.img);
            }
          })
          .catch((e) => {
            setErrors([
              {
                message: e,
              },
            ]);
          })
          .finally(() => {
            setIsSavingMenu(false);
          });
      }
    } else {
      dispatch(
        setErrors([
          {
            message: 'Please add at least one expression to your menu',
          },
        ]),
      );
    }
  };

  const onContinueUploadLogo = () => {
    let message = 'Chose an image';
    let valid = true;

    if (files != null && files != undefined) {
      switch (files.length) {
        case 0:
          message = 'Choose an image ';
          valid = false;
          break;
        case 1:
          const file = files[0];
          if (file.type.includes('image')) {
            valid = true;
          } else {
            valid = false;
            message = 'Please choose a valid image.';
          }

          break;
        default:
          message = 'Please choose a maximum of one image.';
          valid = false;
          break;
      }
    } else {
      message = 'Files not received';
      valid = false;
    }

    if (valid) {
      setVisibleUpload(false);
    } else {
      dispatch(setErrors([{ message }]));
    }
  };

  return (
    <>
      <Helmet>
        <title>Tasting Menus</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CCol sm={5}>
                  <strong>Tools</strong> <small>Tasting Menu</small>
                </CCol>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CButton
                      className="me-2 mb-2"
                      onClick={() => {
                        setVisible(true);
                      }}
                    >
                      Choose Expressions
                      <CIcon icon={cilDrinkAlcohol} className="ml-1" />
                    </CButton>
                    <LButton
                      color="success"
                      className="mb-2 me-2"
                      isLoading={isSaving}
                      onClick={savePdf}
                    >
                      PDF
                    </LButton>
                    {editId && (
                      <CButton
                        onClick={() => {
                          navigate('/tools/tasting-menu');
                        }}
                        color="success"
                        variant="outline"
                        className="mb-2 me-2"
                      >
                        New Menu
                      </CButton>
                    )}
                    <CButton
                      className="mb-2 me-2"
                      color="dark"
                      onClick={() => {
                        navigate('/tools/my-tasting-menus');
                      }}
                    >
                      My Saved Menus <CIcon icon={cilSave} className="ml-1" />
                    </CButton>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <div className="my-3">
                      <CButton
                        color="primary"
                        onClick={() => {
                          setVisibleUpload(true);
                        }}
                      >
                        Upload a Logo
                        <CIcon icon={cilCloudUpload} />
                      </CButton>
                      <CModal
                        visible={visibleUpload}
                        onClose={() => {
                          setVisibleUpload(false);
                        }}
                        backdrop={`static`}
                      >
                        <CModalHeader>
                          <CModalTitle>Upload a logo</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                          <UploadBox />
                        </CModalBody>
                        <CModalFooter>
                          <CButton
                            color="danger"
                            onClick={() => {
                              setVisibleUpload(false);
                              setFiles([]);
                            }}
                          >
                            Cancel
                          </CButton>
                          <CButton
                            color="primary"
                            onClick={onContinueUploadLogo}
                          >
                            Continue
                          </CButton>
                        </CModalFooter>
                      </CModal>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div className="tasting-menu-wrapper" ref={wrapperRef}>
                      <CRow>
                        <CCol xs={4}>
                          {hasData(0) && (
                            <TastingItem
                              isDisabled={true}
                              expression={expressionsModal[0]}
                            />
                          )}
                        </CCol>
                        <CCol xs={4}>
                          {hasData(1) && (
                            <TastingItem
                              isDisabled={true}
                              expression={expressionsModal[1]}
                            />
                          )}
                        </CCol>
                        <CCol xs={4}>
                          {hasData(2) && (
                            <TastingItem
                              isDisabled={true}
                              expression={expressionsModal[2]}
                            />
                          )}
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol xs={4}>
                          {hasData(3) && (
                            <TastingItem
                              isDisabled={true}
                              expression={expressionsModal[3]}
                            />
                          )}
                        </CCol>
                        <CCol xs={4}>
                          <div className="flex-center">
                            {files && files?.length > 0 ? (
                              <>
                                <ImageFromFile
                                  file={files[0]}
                                  className="img-center"
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    img == null ?
                                      '/agave_illustration.png' :
                                      process.env.REACT_APP_API_URL +
                                        `/img/menu/${img}`
                                  }
                                  alt="Agave illustration"
                                  className="img-center"
                                />
                              </>
                            )}
                          </div>
                          <div className="agave-center-text">
                            <h1>Degustación</h1>
                          </div>
                        </CCol>
                        <CCol xs={4}>
                          {hasData(4) && (
                            <TastingItem
                              isDisabled={true}
                              expression={expressionsModal[4]}
                            />
                          )}
                        </CCol>
                      </CRow>
                    </div>
                  </CCol>
                </CRow>
                <div className="my-3">
                  <CRow>
                    <CCol xs={12} md={8} lg={10}>
                      <CFormInput
                        placeholder="Title"
                        className="mb-1"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </CCol>
                    <CCol xs={12} md={4} lg={2}>
                      <LButton
                        className="mb-1 w-100"
                        isLoading={isSavingMenu}
                        onClick={saveMenu}
                      >
                        {editId == null ? 'Save Menu' : 'Save changes'}
                      </LButton>
                    </CCol>
                  </CRow>
                </div>
                <CRow>
                  <CCol>
                    <div ref={canvasRef}></div>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CModal
              visible={visible}
              onClose={() => setVisible(false)}
              size="xl"
            >
              <CModalHeader>
                <CModalTitle>Choose Expressions</CModalTitle>
              </CModalHeader>
              <CModalBody>
                <CRow>
                  <CCol>
                    <CAlert color="info">
                      You can double click on the text to replace it.
                    </CAlert>
                  </CCol>
                </CRow>
                <CRow>
                  {expressionsModal.map((expression, idx) => {
                    return (
                      <CCol
                        xs={12}
                        md={6}
                        xl={4}
                        key={`item-exp-tasting-${idx}`}
                      >
                        <TastingItem
                          fixedSize={true}
                          expression={expression}
                          products={products}
                          onItemUpdated={onItemUpdated}
                        />
                      </CCol>
                    );
                  })}
                </CRow>
              </CModalBody>
              <CModalFooter>
                <CButton
                  color="danger"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Close
                </CButton>
                <CButton
                  color="primary"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Continue
                </CButton>
              </CModalFooter>
            </CModal>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default TastingMenuPage;
