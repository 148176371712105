import React from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilClipboard,
  cilContact,
  cilNotes,
  cilDollar,
  cilCart,
  cilUser,
  cilFile,
  cilBook,
  cilLibrary,
  cilApplications,
  cilGroup,
  cilColorPalette,
  cilBarChart,
  cilEuro,
  cilGraph,
  cilCalculator,
  cilGift,
  cilChartPie,
  cilMoney,
  cilChart,
  cilInfo,
  cilHome,
} from '@coreui/icons';

import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react';
import TheIcon from './components/TheIcon/TheIcon';

const _nav = [
  {
    component: CNavItem,
    name: 'Home',
    to: '/dashboard/',
    icon: <CIcon icon={cilHome} customClassName='nav-icon' />,
    allowed: ['ADMIN', 'STAFF'],
  },
  {
    component: CNavItem,
    name: 'By Expression',
    to: '/dashboard/inventory/by-expression',
    icon: <CIcon icon={cilClipboard} customClassName='nav-icon' />,
    allowed: ['CLIENT'],
  },
  {
    component: CNavItem,
    name: 'Art Submissions',
    to: '/dashboard/art-submissions',
    icon: <CIcon icon={cilFile} customClassName='nav-icon' />,
    allowed: ['ARTIST'],
  },
  {
    component: CNavGroup,
    name: 'Inventory',
    icon: <CIcon icon={cilClipboard} customClassName='nav-icon' />,
    allowed: ['ADMIN', 'STAFF'],
    items: [
      {
        component: CNavItem,
        name: 'By Expression',
        to: '/dashboard/inventory/by-expression',
      },
      {
        component: CNavItem,
        name: 'By Lot',
        to: '/dashboard/inventory/by-lot',
      },
      {
        component: CNavItem,
        name: 'A Granel',
        to: '/dashboard/inventory/a-granel',
      },
      {
        component: CNavItem,
        name: 'Physical Count',
        to: '/dashboard/inventory/physical-count',
      },
      {
        component: CNavItem,
        name: 'By Date or Month',
        to: '/dashboard/inventory/by-date',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Lotes',
    allowed: ['ADMIN', 'STAFF'],
    icon: <CIcon icon={cilNotes} customClassName='nav-icon' />,
    items: [
      {
        component: CNavItem,
        name: 'Purchases',
        to: '/dashboard/lotes/purchases',
      },
      {
        component: CNavItem,
        name: 'Production',
        to: '/dashboard/lotes/production',
      },
      {
        component: CNavItem,
        name: 'Lot Details',
        to: '/dashboard/lotes/lot-details',
      },
    ],
  },
  {
    component: CNavTitle,
    name: 'Processes',
    allowed: ['ADMIN', 'STAFF'],
  },
  {
    component: CNavItem,
    name: 'Lotes',
    allowed: ['ADMIN', 'STAFF'],
    icon: <TheIcon name='pallet' />,
    to: '/dashboard/process/lots',
  },
  {
    component: CNavTitle,
    name: 'Sales',
    allowed: ['ADMIN', 'STAFF'],
  },
  {
    component: CNavTitle,
    name: 'Purchases',
    allowed: ['CLIENT'],
  },
  {
    component: CNavItem,
    name: 'POs',
    allowed: ['CLIENT'],
    icon: <CIcon icon={cilCart} customClassName='nav-icon' />,
    to: '/dashboard/cpos/cpos',
  },
  {
    component: CNavItem,
    name: 'CPOs',
    allowed: ['ADMIN', 'STAFF'],
    icon: <CIcon icon={cilCart} customClassName='nav-icon' />,
    to: '/dashboard/cpos/cpos',
  },
  {
    component: CNavItem,
    name: 'External Sales',
    allowed: ['ADMIN', 'STAFF'],
    to: '/dashboard/sales/external-sales',
    icon: <CIcon icon={cilMoney} customClassName='nav-icon' />,
  },
  {
    component: CNavItem,
    name: 'Customer Pricing',
    allowed: ['ADMIN', 'STAFF', 'CLIENT'],
    icon: <CIcon icon={cilDollar} customClassName='nav-icon' />,
    to: '/dashboard/prices/prices',
  },
  {
    component: CNavItem,
    name: 'Pricing Schemes',
    allowed: ['ADMIN', 'STAFF'],
    icon: <CIcon icon={cilCalculator} customClassName='nav-icon' />,
    to: '/dashboard/prices/price-calculator',
  },
  {
    component: CNavItem,
    name: 'Discounts',
    allowed: ['ADMIN', 'STAFF'],
    icon: <CIcon icon={cilChartPie} customClassName='nav-icon' />,
    to: '/dashboard/discounts',
  },

  {
    component: CNavItem,
    name: 'Coupons',
    allowed: ['ADMIN', 'STAFF'],
    icon: <CIcon icon={cilGift} customClassName='nav-icon' />,
    to: '/dashboard/coupons',
  },
  {
    component: CNavTitle,
    name: 'Accounting',
    allowed: ['ADMIN', 'STAFF'],
  },
  {
    component: CNavItem,
    name: 'Quickbooks',
    allowed: ['ADMIN', 'STAFF'],
    to: '/dashboard/quickbooks/home',
    icon: <TheIcon name='quickbooks' />,
  },
  {
    component: CNavTitle,
    name: 'Financial',
    allowed: ['ADMIN'],
  },
  {
    component: CNavItem,
    name: 'Rates',
    allowed: ['ADMIN'],
    to: '/dashboard/financial/rates',
    icon: <CIcon icon={cilEuro} customClassName='nav-icon' />,
  },
  {
    component: CNavItem,
    name: 'Variable Costs',
    allowed: ['ADMIN', 'STAFF'],
    to: '/dashboard/financial/variable-costs',
    icon: <CIcon icon={cilBarChart} customClassName='nav-icon' />,
  },

  {
    component: CNavItem,
    name: 'Price Calculator',
    allowed: ['ADMIN', 'STAFF'],
    to: '/dashboard/financial/pricing-schemes',
    icon: <CIcon icon={cilGraph} customClassName='nav-icon' />,
  },
  {
    component: CNavTitle,
    name: 'PDF',
    allowed: ['ADMIN', 'STAFF'],
  },
  {
    component: CNavItem,
    name: 'Schema Info',
    allowed: ['ADMIN', 'STAFF'],
    to: '/dashboard/schema-info',
    icon: <CIcon icon={cilInfo} customClassName='nav-icon' />,
  },
  {
    component: CNavTitle,
    name: 'REPORTS',
    allowed: ['ADMIN', 'STAFF'],
  },
  {
    component: CNavItem,
    name: 'Cost of Goods',
    allowed: ['ADMIN', 'STAFF'],
    to: '/dashboard/reports/costs',
    icon: <CIcon icon={cilChart} customClassName='nav-icon' />,
  },
  {
    component: CNavItem,
    name: 'Ex Works',
    allowed: ['ADMIN', 'STAFF'],
    to: '/dashboard/reports/ex-works',
    icon: <CIcon icon={cilMoney} customClassName='nav-icon' />,
  },
  {
    component: CNavTitle,
    name: 'Catalogs',
    allowed: ['ADMIN', 'STAFF'],
  },
  {
    component: CNavGroup,
    name: 'Items',
    icon: <CIcon icon={cilApplications} customClassName='nav-icon' />,
    allowed: ['ADMIN', 'STAFF', 'ARTIST'],
    items: [
      {
        component: CNavItem,
        name: 'Expressions',
        to: '/dashboard/expressions',
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        component: CNavItem,
        name: 'Maguey Name',
        to: '/dashboard/maguey',
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        component: CNavItem,
        name: 'Barcodes',
        to: '/dashboard/barcodes',
        allowed: ['ADMIN', 'STAFF', 'ARTIST'],
      },
      {
        component: CNavItem,
        name: 'Miscellaneous',
        to: '/dashboard/miscellaneous',
        allowed: ['ADMIN', 'STAFF'],
      },
    ],
  },

  {
    component: CNavGroup,
    name: 'Contacts',
    allowed: ['ADMIN', 'STAFF'],
    icon: <CIcon icon={cilGroup} customClassName='nav-icon' />,
    to: '',
    items: [
      {
        component: CNavItem,
        name: 'Mezcaleros',
        icon: <CIcon icon={cilContact} customClassName='nav-icon' />,
        to: '/dashboard/mezcaleros',
      },
      {
        component: CNavItem,
        name: 'Importers',
        to: '/dashboard/importers',
      },
    ],
  },
  {
    component: CNavTitle,
    name: 'Art',
    allowed: ['ADMIN', 'STAFF'],
  },
  {
    component: CNavItem,
    name: 'Art Review',
    allowed: ['ADMIN', 'STAFF'],
    icon: <CIcon icon={cilColorPalette} customClassName='nav-icon' />,
    to: 'art-review',
  },
  {
    component: CNavTitle,
    name: 'Users',
    allowed: ['ADMIN', 'STAFF'],
  },
  {
    component: CNavItem,
    name: 'Users',
    allowed: ['ADMIN', 'STAFF'],
    icon: <CIcon icon={cilUser} customClassName='nav-icon' />,
    to: '/dashboard/users',
  },
  {
    component: CNavTitle,
    name: 'Tools',
    allowed: ['CLIENT', 'ADMIN', 'STAFF'],
  },
  {
    component: CNavItem,
    name: 'Shelf Talker',
    to: '/tools/shelf-talker',
    icon: <CIcon icon={cilLibrary} customClassName='nav-icon' />,
    allowed: ['CLIENT', 'ADMIN', 'STAFF'],
  },

  {
    component: CNavItem,
    name: 'Tasting Menu',
    to: '/tools/tasting-menu',
    icon: <CIcon icon={cilBook} customClassName='nav-icon' />,
    allowed: ['CLIENT', 'ADMIN', 'STAFF'],
  },
];

export default _nav;
