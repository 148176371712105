import { useEffect, useState } from 'react';
import { logout } from 'src/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

const Logout = () => {
  const dispatch = useDispatch();

  const [, setCookie] = useCookies(['session_id']);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const [wasDispatched, setWasDispatched] = useState(false);
  const auth = useSelector((s) => s.auth);
  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    setSessionId(auth.sessionId);
    dispatch(logout());
    setCookie('session_id', null, {
      path: '/',
    });
    const timeout = setTimeout(() => {
      setWasDispatched(true);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const to = setTimeout(() => {
      if (!loggedIn && wasDispatched) {
        window.location.replace(`${process.env.REACT_APP_LOGIN_URL_SSO}/sign-out?sessionId=${sessionId}`);
      }
    }, 300);
    return () => {
      clearTimeout(to);
    };
  }, [loggedIn, wasDispatched, sessionId]);

  return (
    <>
      <h1>Logging out...</h1>
    </>
  );
};

export default Logout;
