const initialState = {
  stockInCart: [],
  productionInCart: [],
  onDemand: [], // Bottles ordered on demand
  misc: [],
};

const orderReducer = (state = initialState, action) => {
  if (!Array.isArray(state.onDemand)) {
    state.onDemand = [];
  }
  if (!Array.isArray(state.productionInCart)) {
    state.productionInCart = date.productionInCart;
  }
  if (!Array.isArray(state.stockInCart)) {
    state.stockInCart = date.stockInCart;
  }

  if (!Array.isArray(state.misc)) {
    state.misc = [];
  }

  switch (action.type) {
    case 'SET_STOCK_IN_CART':
      return {
        ...state,
        stockInCart: action.payload,
      };
    case 'SET_PRODUCTION_IN_CART':
      return {
        ...state,
        productionInCart: action.payload,
      };
    case 'SET_ON_DEMAND':
      return {
        ...state,
        onDemand: action.payload || [],
      };
    case 'SET_MISCELLANEOUS':
      return {
        ...state,
        misc: action.payload,
      };
    default:
      return state;
  }
};

export default orderReducer;
