import React from 'react';
import PropTypes from 'prop-types';
import { CCol, CRow, CForm, CFormLabel, CButton } from '@coreui/react';
import { useState } from 'react';
import FilterableSelect from 'src/components/FilterableSelect/FilterableSelect';

const MagueySelectDiv = (props) => {
  const [maguey, setMaguey] = useState('');
  return (
    <CForm
      style={{ margin: '10px' }}
      onSubmit={(e) => {
        e.preventDefault();
        if (maguey) {
          props.setChosenMaguey(maguey);
          props.setMagueysSelectVisible(false);
          props.setNewExpressionFormVisible(true);
        } else {
          // TODO: Replace message with an error modal.
          alert('Choose a maguey first.');
        }
      }}
    >
      <CRow className="mb-2">
        <CFormLabel className="mb-1" htmlFor="makerSelect">
          Select the Common Name for the new Expression:
        </CFormLabel>
        <FilterableSelect
          options={props.magueys}
          value="id"
          text="common_name"
          onChange={(value) => {
            setMaguey(value);
          }}
        ></FilterableSelect>
      </CRow>
      <CRow>
        <CCol>
          <CButton type="submit" color="primary">
            Next
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

MagueySelectDiv.propTypes = {
  setMagueysSelectVisible: PropTypes.any,
  setNewExpressionFormVisible: PropTypes.any,
  chosenMaker: PropTypes.any,
  setChosenMaguey: PropTypes.any,
  magueys: PropTypes.array,
};

export default MagueySelectDiv;
