import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CContainer,
  CButton,
  CFormInput,
  CButtonGroup,
} from '@coreui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import { useState, useEffect, useCallback } from 'react';
import api from 'src/api';

import CIcon from '@coreui/icons-react';
import { cilSave } from '@coreui/icons';
import LButton from 'src/components/LButton/LButton';

import YesNoModal from 'src/components/YesNoModal';
import { useDispatch } from 'react-redux';
import { setSuccessMessages } from 'src/actions';
import { Helmet } from 'react-helmet';

import PropTypes from 'prop-types';

const InventoryCountInput = ({
  lotId,
  bottlesInStock,
  updatedLots,
  setUpdatedLots,
  disabled,
}) => {
  const [bottlesInStockState, setBottlesInStockState] = useState('');
  useEffect(() => {
    setBottlesInStockState(bottlesInStock);
  }, [bottlesInStock]);

  return (
    <>
      <CFormInput
        disabled={disabled}
        size="sm"
        value={bottlesInStockState}
        onChange={(e) => {
          setBottlesInStockState(e.target.value);

          /*
           * The updatedLots array should contain only the
           * items that were modified.
           *
           * So we need to check if we already added an
           * updated item to the array.
           *
           * If we already added it, we only need to map the array
           * of objects and update the object.
           *
           * Else, we need to push the new updated item to the array.
           */
          const exists = updatedLots.some((item) => {
            return item.lot_id === lotId;
          });

          if (exists) {
            // Update the existing item
            setUpdatedLots((prevValue) => {
              return prevValue.map((lot) => {
                if (lot.lot_id === lotId) {
                  lot.bottles_in_stock = e.target.value;
                }
                return lot;
              });
            });
          } else {
            // Push the new value
            setUpdatedLots((prevValue) => {
              return [
                ...prevValue,
                {
                  lot_id: lotId,
                  bottles_in_stock: e.target.value,
                },
              ];
            });
          }
        }}
      ></CFormInput>
    </>
  );
};

InventoryCountInput.propTypes = {
  lotId: PropTypes.any,
  bottlesInStock: PropTypes.number,
  updatedLots: PropTypes.any,
  setUpdatedLots: PropTypes.func,
  disabled: PropTypes.bool,
};

const InventoryByLot = () => {
  const [isAmend, setIsAmend] = useState(false);
  const [lots, setLots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedLots, setUpdatedLots] = useState([]);
  const [visible, setVisible] = useState(false);
  const [availableSoon, setAvailableSoon] = useState({});

  const dispatch = useDispatch();

  function fetchGranelInventory() {
    api.get('/situation/a-granel').then(({data}) => {
      const granelMap = {};
      data.forEach((granel) => {
        granelMap[granel.expression] = Math.trunc(((granel.available_liters - 1) * 0.995) / (granel.ml_btl / 1000))
      })

      setAvailableSoon(granelMap);
    });
  }

  useEffect(() => {
    fetchGranelInventory();
  }, []);

  const fetchInventory = useCallback(() => {
    api.get('/situation/inventory-by-products').then(({ data }) => {
      const fullLots = [];
      data.forEach((product) => {
        if (product && product.lots) {
          product.lots.forEach((lot) => {
            lot.expression = product.expression;
            fullLots.push(lot);
          });
        }
      });
      setLots(fullLots);
      setIsAmend(false);
    });
  }, []);

  useEffect(() => {
    fetchInventory();
  }, []);

  const saveChanges = () => {
    setVisible(false);
    setIsLoading(true);
    api
      .post('/lot/amend-inventory', {
        items: JSON.stringify(updatedLots),
      })
      .then(() => {
        dispatch(
          setSuccessMessages([
            {
              message: 'The Inventory has been updated.',
            },
          ]),
        );
        fetchInventory();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Inventory By Lot</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>INVENTORY</strong> <small>By Lot</small>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CButtonGroup>
                      <CButton
                        size="sm"
                        color="primary"
                        onClick={() => {
                          setIsAmend(!isAmend);
                        }}
                        style={{ marginRight: '0.6rem' }}
                        disabled={isLoading}
                      >
                        {isAmend ? 'Disable Edit Mode' : 'Enable Edit Mode'}
                      </CButton>
                      {isAmend && (
                        <LButton
                          isLoading={isLoading}
                          color="primary"
                          onClick={() => {
                            setVisible(true);
                          }}
                        >
                          Save Changes <CIcon icon={cilSave} />
                        </LButton>
                      )}
                    </CButtonGroup>
                  </CCol>
                </CRow>
                <div
                  className="table-container"
                  style={{ marginTop: '0.3rem' }}
                >
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Expression</Th>
                        <Th>Lot</Th>
                        <Th>Physical</Th>
                        <Th>Available Soon</Th>
                        <Th>Available Now</Th>
                        <Th>In someone&apos;s cart</Th>
                        <Th>In Production</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {/* <ProductsList products={productsList} />*/}

                      {lots.map((lot) => {
                        return (
                          <Tr
                            style={{ fontSize: '0.85em' }}
                            key={`lot-${lot.lot_number}${lot.bottles_in_stock}`}
                          >
                            <Td>{lot.expression}</Td>
                            <Td>{lot.lot_number}</Td>
                            <Td>
                              {isAmend ? (
                                <InventoryCountInput
                                  lotId={lot.lot_id}
                                  bottlesInStock={lot.bottles_in_stock}
                                  updatedLots={updatedLots}
                                  setUpdatedLots={setUpdatedLots}
                                  disabled={isLoading}
                                />
                              ) : (
                                lot.bottles_in_stock
                              )}
                            </Td>
                            <Td color="info" style={{ fontWeight: 'bold' }}>
                              {
                                availableSoon[lot.expression] || 0
                              }
                            </Td>
                            <Td>
                              {lot.bottles_in_stock - lot.in_someones_cart}
                            </Td>
                            <Td>{lot.in_someones_cart}</Td>
                            <Td>
                              {parseInt(
                                lot.bottles_in_production != null ?
                                  lot.bottles_in_production :
                                  0,
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <YesNoModal
              title="Are you sure?"
              description={
                'You will update the inventory permanently and could affect ' +
                'CPOs or other sections that depend on the inventory'
              }
              visible={visible}
              onYes={saveChanges}
              onNo={() => {
                setVisible(falase);
              }}
              setVisible={setVisible}
            />
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default InventoryByLot;
