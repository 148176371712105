import React from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CContainer,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react';
import { useState, useEffect } from 'react';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';

import api from '../../api';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const Barcodes = () => {
  const [visible, setVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const [barcodeList, setBarcodeList] = useState([]);
  const [inUseList, setInUseList] = useState([]);
  const [availableList, setAvailableList] = useState([]);
  const [blockedList, setBlockedList] = useState([]);
  const [modalBarcode, setModalBarcode] = useState([]);
  const [activeKey, setActiveKey] = useState(1);

  const role = useSelector((state) => state.auth.role);

  function fetchSKU() {
    const allArray = [];
    const inUseArray = [];
    const availableArray = [];
    const blockedArray = [];

    api.get('/catalog/items/barcodes-in-use').then(({ data }) => {
      data.forEach((element) => {
        inUseArray.push(element);
        const upcArrayItem = { upc: element.upc };
        allArray.push(upcArrayItem);
      });
      api.get('/catalog/items/barcodes-not-in-use').then(({ data }) => {
        data.forEach((element) => {
          const upcArrayItem = { upc: element.upc };
          allArray.push(upcArrayItem);
          if (element.date_available === null) {
            availableArray.push(element);
          } else {
            blockedArray.push(element);
          }
        });
        setBarcodeList(allArray);
        setInUseList(inUseArray);
        setAvailableList(availableArray);
        setBlockedList(blockedArray);
      });
    });
  }

  useEffect(() => {
    fetchSKU();
  }, []);

  function handleNewSubmit(e) {
    e.preventDefault();
    if (!e.target.elements.packageTypeInput.value) {
      alert('Package Type must be entered');
    }

    if (!e.target.elements.upcAInput.value) {
      alert('UPC-A must be entered');
    }

    if (!e.target.elements.upcInput.value) {
      alert('UPC must be entered');
    }

    const tempbarcodeList = barcodeList.map((element) => {
      return element.upc;
    });

    if (tempbarcodeList.includes(e.target.elements.upcInput.value)) {
      alert('UPC already exists');
    }

    api
      .post('/catalog/items/barcodes/new', {
        upc_a_digit: e.target.elements.upcAInput.value,
        upc: e.target.elements.upcInput.value,
        package_type: e.target.elements.packageTypeInput.value,
      })
      .then(() => {
        fetchSKU();
      })
      .finally(() => {
        setNewVisible(false);
      });
  }

  function unblock(sku) {
    api
      .post('/catalog/items/barcodes/unblock', {
        sku: sku,
      })
      .then(() => {
        fetchSKU();
      });
  }

  function BarcodeNewModal(props) {
    return (
      <CModalBody>
        <CForm style={{ margin: '10px' }} onSubmit={handleNewSubmit}>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="packageTypeInput">
                Package Type
              </CFormLabel>
              <CFormInput type="text" id="packageTypeInput" size="sm" />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="upcAInput">
                UPC A Digit
              </CFormLabel>
              <CFormInput id="upcAInput" size="sm" />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="upcInput">
                UPC
              </CFormLabel>
              <CFormInput type="text" id="upcInput" size="sm" />
            </CCol>
          </CRow>
          <div style={{ marginTop: '5px' }}>
            <CButton type="submit" color="primary">
              Add New
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                setNewVisible(false);
              }}
              style={{ marginLeft: '10px' }}
            >
              Close
            </CButton>
          </div>
        </CForm>
      </CModalBody>
    );
  }

  function BarcodeModal(props) {
    return (
      <CModalBody>
        <CForm style={{ margin: '10px' }}>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="assignedExpInput">
                Assigned Expression
              </CFormLabel>
              <CFormInput type="text" id="assignedExpInput" defaultValue={props.barcode.expression} size="sm" readOnly />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="packageTypeInput">
                Package Type
              </CFormLabel>
              <CFormInput type="text" id="packageTypeInput" defaultValue={props.barcode.package_type} size="sm" readOnly />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="interleavedInput">
                Interleaved 2 of 5
              </CFormLabel>
              <CFormInput id="interleavedInput" defaultValue={props.barcode.interleaved_2_of_5} size="sm" readOnly />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="upcAInput">
                UPC A Digit
              </CFormLabel>
              <CFormInput id="upcAInput" defaultValue={props.barcode.upc_a_digit} size="sm" readOnly />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="upcInput">
                UPC
              </CFormLabel>
              <CFormInput type="text" id="upcInput" defaultValue={props.barcode.upc} size="sm" readOnly />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="cscInput">
                CSC
              </CFormLabel>
              <CFormInput id="cscInput" defaultValue={props.barcode.csc} size="sm" readOnly />
            </CCol>
          </CRow>
          <div style={{ marginTop: '5px' }}>
            <CButton
              color="danger"
              onClick={() => {
                setVisible(false);
              }}
              style={{ marginTop: '10px' }}
            >
              Close
            </CButton>
          </div>
        </CForm>
      </CModalBody>
    );
  }

  function adjustModal(barcode) {
    setModalBarcode(barcode);
    setVisible(!visible);
  }

  function InUseBarcodeRow(props) {
    return (
      <Tr
        style={{ cursor: 'pointer', fontSize: '0.85em' }}
        onClick={(e) => {
          adjustModal(props.barcode);
        }}
        key={'ius' + props.ius}
      >
        <Td scope="row">{props.barcode.sku}</Td>
        <Td>{props.barcode.expression}</Td>
        <Td>
          <CButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              // API Docs: https://github.com/metafloor/bwip-js/wiki/Online-Barcode-API
              window.open('http://bwipjs-api.metafloor.com/?bcid=code128&text=0' + props.barcode.upc + '&scaleX=3&scaleY=2&includetext', '_blank');
            }}
          >
            Download UPC
          </CButton>
        </Td>
        <Td>
          <CButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              // alert('Donwload Barcodes not yet implemented')
              window.open('http://bwipjs-api.metafloor.com/?bcid=code128&text=' + props.barcode.csc + '&scaleX=3&scaleY=2&includetext', '_blank');
            }}
          >
            Download CSC
          </CButton>
        </Td>
      </Tr>
    );
  }

  function InUseBarcodesList(props) {
    const skus = props.barcodes;
    let ius = -1;
    const list = skus.map((barcode) => {
      ius++;
      return <InUseBarcodeRow barcode={barcode} ius={ius} key={`ius-${ius}`} />;
    });
    return list;
  }

  function AvailableBarcodeRow(props) {
    return (
      <Tr style={{ fontSize: '0.85em' }}>
        <Td scope="row">{props.barcode.sku}</Td>
      </Tr>
    );
  }

  function AvailableBarcodesList(props) {
    const skus = props.barcodes;
    let as = -1;
    const list = skus.map((barcode) => {
      as++;
      return <AvailableBarcodeRow barcode={barcode} as={as} key={`as-${as}`} />;
    });
    return list;
  }

  function BlockedBarcodeRow(props) {
    const date = new Date(props.barcode.date_available);
    return (
      <Tr style={{ fontSize: '0.85em' }} key={'bs' + props.as}>
        <Td scope="row">{props.barcode.sku}</Td>
        <Td>{date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()}</Td>
        <Td>
          <CButton
            size="sm"
            onClick={() => {
              unblock(props.barcode.sku);
            }}
          >
            unblock
          </CButton>
        </Td>
      </Tr>
    );
  }

  function BlockedBarcodesList(props) {
    const skus = props.barcodes;
    let bs = -1;
    const list = skus.map((barcode) => {
      bs++;
      return <BlockedBarcodeRow barcode={barcode} bs={bs} key={`bs-${bs}`} />;
    });
    return list;
  }

  return (
    <CContainer>
      <Helmet>
        <title>Barcodes</title>
      </Helmet>
      <CModal key="newModal" visible={newVisible} onDismiss={() => setNewVisible(false)} size="lg">
        <CModalHeader>
          <CCol sm={5}>
            <strong>BARCODE</strong> <small>New</small>
          </CCol>
        </CModalHeader>
        <BarcodeNewModal />
      </CModal>
      <CModal key="barcodeModal" visible={visible} onDismiss={() => setVisible(false)} size="lg">
        <CModalHeader>
          <CCol sm={5}>
            <strong>BARCODE</strong> <small>Details</small>
          </CCol>
        </CModalHeader>
        <BarcodeModal barcode={modalBarcode} />
      </CModal>
      <CCard className="mb-4">
        <CCardHeader>
          <CCol sm={5}>
            <strong>BARCODES</strong> <small>SKU</small>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CNav variant="tabs" role="tablist">
            <CNavItem>
              <CNavLink
                href=""
                active={activeKey === 1}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveKey(1);
                }}
              >
                In Use
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                href=""
                active={activeKey === 2}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveKey(2);
                }}
              >
                Available
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                href=""
                active={activeKey === 3}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveKey(3);
                }}
              >
                Blocked
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
              <CRow>
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardBody>
                      <div className="table-container">
                        <Table>
                          <Thead>
                            <Tr>
                              <Th scope="col">SKU</Th>
                              <Th scope="col">Expression</Th>
                              <Th scope="col">UPC</Th>
                              <Th scope="col">CSC</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <InUseBarcodesList barcodes={inUseList} />
                          </Tbody>
                        </Table>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CTabPane>
            <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 2}>
              <CRow>
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardBody>
                      <CRow>
                        <CCol>
                          {role != 'ARTIST' && (
                            <CButton style={{ marginBottom: '15px' }} onClick={() => setNewVisible(true)}>
                              New Barcode
                            </CButton>
                          )}
                        </CCol>
                      </CRow>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th scope="col">SKU</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <AvailableBarcodesList barcodes={availableList} />
                        </Tbody>
                      </Table>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CTabPane>
            <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 3}>
              <CRow>
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardBody>
                      <div className="table-container">
                        <Table>
                          <Thead>
                            <Tr>
                              <Th scope="col">SKU</Th>
                              <Th scope="col">Blocking Conclusion</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <BlockedBarcodesList barcodes={blockedList} />
                          </Tbody>
                        </Table>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </CContainer>
  );
};

Barcodes.defaultProps = {
  magueys: [],
};

Barcodes.propTypes = {
  barcode: PropTypes.any,
  barcodes: PropTypes.any,
  ius: PropTypes.number,
  as: PropTypes.number,
  bs: PropTypes.number,
};

export default Barcodes;
