import { useEffect, useState, useCallback } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFormLabel,
  CForm,
  CRow,
  CCol,
  CButton,
  CFormCheck,
  CFormInput,
} from '@coreui/react';
import FilterableSelect from 'src/components/FilterableSelect/FilterableSelect';
import { useDispatch } from 'react-redux';
import api from 'src/api';
import useScreenSize from 'src/hooks/useScreenSize';
import TheIcon from 'src/components/TheIcon/TheIcon';
import useMoneyCurrency from 'src/hooks/useMoneyCurrency';
import usePriceUpdater from 'src/hooks/usePriceUpdater';
import { setErrors, setSuccessMessages } from 'src/actions';
import AlphabetSort from 'src/components/AlphabetSort';
import LButton from 'src/components/LButton/LButton';
import useDownload from 'src/hooks/useDownload';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const ProductRowDesktop = ({
  product,
  onChangeFn,
  showOldPrice,
  formattedNumber,
  savePrice,
}) => {
  const [price, setPrice] = useState('');

  const handler = usePriceUpdater({ product, setPrice, onChangeFn });

  return (
    <tr>
      <td>{product.id}</td>
      <td>{product.expression}</td>
      <td>{formattedNumber(product.totalCostsPerUnit)}</td>
      {showOldPrice ? <td>{formattedNumber(product.price)}</td> : null}
      <td>
        <CFormInput
          size='sm'
          placeholder='$0.00'
          value={price}
          onChange={(e) => {
            setPrice(e.target.value);
            handler(e.target.value, product.id);
          }}
          type='number'
        />
      </td>
      <td
        className={`${
          product.category == 'Unassigned' ? 'text-danger fw-bold' : ''
        }`}
      >
        {product.category}
      </td>
      <td>
        <CButton
          color='primary'
          size='sm'
          onClick={() => {
            savePrice(product, price);
          }}
          tabIndex='-1'
        >
          <TheIcon name='save' />
          Save price
        </CButton>
      </td>
    </tr>
  );
};

ProductRowDesktop.propTypes = {
  product: PropTypes.object,
  onChangeFn: PropTypes.func,
  showOldPrice: PropTypes.bool,
  formattedNumber: PropTypes.func,
  savePrice: PropTypes.func,
};

const ProductRowMobile = ({
  product,
  onChangeFn,
  showOldPrice,
  formattedNumber,
  savePrice,
  startFromA,
  toggleAz,
}) => {
  const [price, setPrice] = useState('');

  const handler = usePriceUpdater({ product, setPrice, onChangeFn });

  return (
    <div className='item'>
      <div className='tr'>
        <div className='td'>ID</div>
        <div className='td'>{product.id}</div>
      </div>
      <div className='tr'>
        <div className='td'>Expression</div>
        <div className='td'>{product.expression}</div>
      </div>
      <div className='tr'>
        <div className='td'>C.O.G</div>
        <div className='td'>{formattedNumber(product.totalCostsPerUnit)}</div>
      </div>
      {showOldPrice && (
        <div className='tr'>
          <div className='td'>Current price</div>
          <div className='td'>
            <span>{formattedNumber(product.price, 'USD')}</span>
          </div>
        </div>
      )}
      <div className='tr'>
        <div className='td'>Type</div>
        <div
          className={`td ${
            product.category == 'Unassigned' ? 'text-danger fw-bold' : ''
          }`}
        >
          {product.category}{' '}
          <span onClick={toggleAz}>
            <AlphabetSort startFromA={startFromA} />
          </span>
        </div>
      </div>
      <div className='tr'>
        <div className='td'>New Price</div>
        <div className='td'>
          <CFormInput
            size='sm'
            placeholder='$0.00'
            style={{ textAlign: 'right' }}
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
              handler(e.target.value, product.id);
            }}
          />
        </div>
      </div>
      <div className='tr'>
        <div className='td'>
          <CButton
            color='primary'
            size='sm'
            className='d-block w-100'
            onClick={() => {
              savePrice(product, price);
            }}
            tabIndex='-1'
          >
            <TheIcon name='save' />
            Save price
          </CButton>
        </div>
      </div>
    </div>
  );
};

ProductRowMobile.propTypes = {
  product: PropTypes.object,
  onChangeFn: PropTypes.func,
  showOldPrice: PropTypes.bool,
  formattedNumber: PropTypes.func,
  savePrice: PropTypes.func,
  startFromA: PropTypes.bool,
  toggleAz: PropTypes.func,
};

const PriceCalculator = () => {
  const [products, setProducts] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [results, setResults] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [chosenBuyer, setChosenBuyer] = useState(null);
  const [chosenSchema, setChosenSchema] = useState(-1);
  // const [pricing, setPricing] = useState();
  const [schemaKey, setSchemaKey] = useState(0);
  // const [isUpdating, setIsUpdating] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showOldPrice, setShowOldPrice] = useState(true);
  const [exporting, setExporting] = useState(false);

  const [startFromA, setStartFromA] = useState(null);
  const toggleAz = () => {
    setStartFromA((v) => {
      if (v == null) {
        return true;
      } else if (v === true) {
        return false;
      } else {
        return null;
      }
    });
  };

  const screenSize = useScreenSize();
  const dispatch = useDispatch();
  const money = useMoneyCurrency();
  const downloader = useDownload();

  useEffect(() => {
    const controller = new AbortController();
    const controller2 = new AbortController();

    api.get('/user/buyers', { signal: controller.signal }).then(({ data }) => {
      setBuyers(data);
    });

    api
      .get('/pricing-scheme', { signal: controller2.signal })
      .then(({ data }) => {
        setSchemas(data);
      });

    return () => {
      controller.abort();
      controller2.abort();
    };
  }, []);

  const getComparator = useCallback((startFromA) => {
    const sortById = (a, b) => a.id - b.id;
    const compAZ = (a, b) =>
      a.category.toLowerCase().localeCompare(b.category.toLowerCase());
    const compZA = (a, b) =>
      b.category.toLowerCase().localeCompare(a.category.toLowerCase());

    if (startFromA == null) {
      return sortById;
    } else if (startFromA) {
      return compAZ;
    } else {
      return compZA;
    }
  }, []);

  useEffect(() => {
    if (isNaN(parseInt(chosenBuyer))) {
      setProducts([]);
    } else {
      setSchemaKey((current) => {
        return current + 1;
      });
      setChosenSchema(-1);
      api.get(`/product/for-buyer/${chosenBuyer}`).then(({ data }) => {
        const mapped = data.map((p) => {
          if (p.category == null) {
            p.category = 'Unassigned';
          }
          return p;
        });

        setProducts(mapped.sort(getComparator(startFromA)));

        setResults(
          [...data].map((res) => {
            return {
              id: res.id,
              price: res.price,
            };
          })
        );
      });
    }
  }, [chosenBuyer, startFromA]);

  useEffect(() => {
    if (chosenSchema > 0) {
      api.get(`/pricing-scheme/prices/${chosenSchema}`).then(({ data }) => {
        // setPricing(data);
        setProducts((prevProds) => {
          const mapped = prevProds.map((prod) => {
            data.some((d) => {
              const isD = d.product_id == prod.id;
              if (isD) {
                prod.price_with_schema = d.ex_works;
                prod.totalCostsPerUnit = d.totalCostsPerUnit;
                if (d?.error?.length > 0) {
                  prod.error = d.error;
                }
              }
              return isD;
            });
            return prod;
          });
          return mapped.sort(getComparator(startFromA));
        });
      });
    } else {
      // setPricing([]);
    }
  }, [chosenSchema, startFromA]);

  /*
  const getMarkup = () => {
    if (chosenSchema) {
      let markup = null;
      schemas.some((schema) => {
        if (chosenSchema === schema.id) {
          markup = schema.ex_works;
          return true;
        } else {
          return false;
        }
      });
      return markup;
    } else {
      return null;
    }
  };*/

  useEffect(() => {
    if (screenSize === 'xs' || screenSize === 'sm') {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenSize]);

  const formattedNumber = (cost) => {
    if (isNaN(parseFloat(cost))) {
      return '';
    } else {
      return money(parseFloat(cost), 'USD');
    }
  };

  const savePrice = (product, price) => {
    if (isNaN(parseFloat(price))) {
      dispatch(
        setErrors([
          {
            message: 'Please enter a valid price.',
          },
        ])
      );
    } else {
      console.log('Product Id: ', product, product.id);
      api
        .put(`/buyer-price/price/${chosenBuyer}`, {
          price,
          product_id: product.id,
        })
        .then(() => {
          dispatch(
            setSuccessMessages([
              {
                message:
                  `The price for the ` +
                  product.expression +
                  ` has been updated successfully.`,
              },
            ])
          );
        })
        .catch((e) => {
          dispatch(setErrors([{ message: e }]));
        });
    }
  };

  const saveAllPrices = () => {
    const hasInvalid = results.some((res) => {
      if (!res.final_price) {
        console.log('final -->', res.final_price, res);
      }
      return (
        isNaN(parseFloat(res.final_price)) || parseFloat(res.final_price) <= 0
      );
    });

    if (hasInvalid) {
      dispatch(
        setErrors([
          {
            message:
              'Please fill all the fields with numbers greater than zero.',
          },
        ])
      );
    } else {
      // setIsUpdating(true);
      api
        .put(`/buyer-price/prices/${chosenBuyer}`, {
          prices: JSON.stringify(results),
        })
        .then(() => {
          dispatch(
            setSuccessMessages([
              {
                message: 'The prices have been saved.',
              },
            ])
          );
        })
        .catch((err) => {
          dispatch(
            setErrors({
              message: err,
            })
          );
        })
        .finally(() => {
          // setIsUpdating(false);
        });
    }
  };

  const onChangeFn = (value, productId) => {
    setResults((prev) => {
      return prev.map((result) => {
        if (parseInt(result.id) == parseInt(productId)) {
          result.final_price = value;
        }
        return result;
      });
    });
  };

  const exportToExcel = () => {
    if (
      !isNaN(parseInt(chosenBuyer)) &&
      chosenBuyer > 0 &&
      !isNaN(parseInt(chosenSchema))
    ) {
      setExporting(true);

      const finalResults = results.map((result) => {
        products.some((p) => {
          const isP = p.id == result.id;
          if (isP) {
            result.expression = p.expression;
            result.category = p.category || '';
            result.cog = parseFloat(p.totalCostsPerUnit) || 0;
          }
          return isP;
        });
        return result;
      });

      api
        .post(`/buyer-price/excel/${chosenBuyer}`, {
          data: JSON.stringify(finalResults),
          showOldPrice,
        })
        .then(({ data }) => {
          if (data.success && data?.excel?.length > 0) {
            dispatch(setSuccessMessages([{ message: 'Downloading...' }]));
            downloader.download(
              `${process.env.REACT_APP_API_URL}/${data.excel}`
            );
          } else {
            dispatch(
              setErrors([
                { message: 'Unknown error. The pdf was not returned.' },
              ])
            );
          }
        })
        .catch((e) => {
          dispatch(setErrors([{ message: e }]));
        })
        .finally(() => {
          setExporting(false);
        });
    } else {
      dispatch(
        setErrors([
          {
            message: 'Choose a buyer and a schema first.',
          },
        ])
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Pricing Schemes</title>
      </Helmet>
      <CCard>
        <CCardHeader>
          <b className='fs-6'>Pricing Schemes</b>
        </CCardHeader>
        <CCardBody>
          <CForm
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <CRow>
              <CCol xs={12} md={4}>
                <CFormLabel>Buyer</CFormLabel>
                <FilterableSelect
                  size={null}
                  defaultValue={-1}
                  value='user_id'
                  text='username'
                  options={buyers}
                  onChange={(value) => {
                    setChosenBuyer(value);
                    setChosenSchema(-1);
                  }}
                  placeholder='Choose a buyer'
                />
              </CCol>
              <CCol xs={12} md={4}>
                <CFormLabel className='mt-4 mt-md-0'>
                  Choose a pricing scheme
                </CFormLabel>
                <FilterableSelect
                  size={null}
                  key={`schema-key-${schemaKey}`}
                  defaultValue={chosenSchema}
                  value='id'
                  text='schema_name'
                  options={schemas}
                  onChange={(value) => {
                    if (value !== chosenSchema) {
                      setChosenSchema(value);
                    }
                  }}
                  placeholder='Choose a schema'
                />
              </CCol>
              <CCol xs={12} md={4}>
                <div className='mt-4 mt-md-0'>
                  <CRow>
                    <CCol xs={6}>
                      <div className='label-filler'></div>
                      <LButton
                        color='primary'
                        onClick={exportToExcel}
                        isLoading={exporting}
                      >
                        <img
                          src='/icons/excel.svg'
                          width={20}
                          height={20}
                          className='me-2'
                        />
                        Export
                      </LButton>
                    </CCol>
                    <CCol xs={6}>
                      <div className='vertical-center mt-md-3'>
                        <CFormCheck
                          label='Show old price'
                          checked={showOldPrice}
                          onChange={() => {
                            setShowOldPrice((v) => !v);
                          }}
                        />
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <div className='mt-3'>
                  {isMobile ? (
                    <div className='dynamic-table-mobile'>
                      {products.map((product, index) => {
                        return (
                          <ProductRowMobile
                            toggleAz={toggleAz}
                            startFromA={startFromA}
                            setStartFromA={setStartFromA}
                            formattedNumber={formattedNumber}
                            showOldPrice={showOldPrice}
                            product={product}
                            key={`product-mobile-${index}`}
                            onChangeFn={onChangeFn}
                            savePrice={savePrice}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <table className='dynamic-table-desktop'>
                        <thead className='item item-header'>
                          <tr>
                            <th>Id</th>
                            <th>Expression</th>
                            <th>C.O.G</th>
                            {showOldPrice && <th>Current Price</th>}
                            <th>New Price</th>
                            <th>
                              Type
                              <span className='ms-2' onClick={toggleAz}>
                                <AlphabetSort startFromA={startFromA} />
                              </span>
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.map((product, index) => {
                            return (
                              <ProductRowDesktop
                                formattedNumber={formattedNumber}
                                showOldPrice={showOldPrice}
                                product={product}
                                key={`tr-desktop-${index}`}
                                onChangeFn={onChangeFn}
                                savePrice={savePrice}
                              />
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <div className='mt-3 d-flex w-100'>
                    <div className='d-flex ms-auto'>
                      <CButton
                        color='primary'
                        size='sm'
                        onClick={saveAllPrices}
                      >
                        <TheIcon name='save' />
                        Save All Prices
                      </CButton>
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    </>
  );
};

export default PriceCalculator;
