import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {CButton, CCard, CCardBody, CCardHeader, CCol, CContainer, CModal, CModalHeader, CRow} from '@coreui/react';
import {cilClone} from '@coreui/icons';
import CIcon from '@coreui/icons-react';

import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import ExpressionEditModal from './expression-edit-modal';
import ExpressionNewModal from './expression-new-modal';
import ExpressionCloneModal from './expression-clone-modal';
import api from 'src/api';
import {Helmet} from 'react-helmet';

const Expressions = () => {
  const [visible, setVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [modalExpression, setModalExpression] = useState([]);
  const [cloneVisible, setCloneVisible] = useState(false);
  const [makers, setMakers] = useState();

  function fetchExpressions() {
    api.get('/catalog/items/expressions').then(({data}) => {
      setProductsList(data);
    });
  }

  useEffect(() => {
    fetchExpressions();
    const controller = new AbortController();
    api
        .get('/contacts/mezcaleros', {signal: controller.signal})
        .then(({data}) => {
          setMakers(data);
        });
    return () => {
      controller.abort();
    };
  }, []);

  function adjustModal(expression) {
    setModalExpression(expression);
    setVisible(!visible);
  }

  function ExpressionRow({expression}) {
    return (
      <Tr
        style={{cursor: 'pointer', fontSize: '0.85em'}}
        onClick={() => {
          adjustModal(expression);
        }}
      >
        <Td>
          {expression.product_id}
        </Td>
        <Td>
          {expression.full_expression_name}
        </Td>
        <Td>{expression.sku}</Td>
        <Td>{expression.case_pack}</Td>
        <Td>{expression.ml_btl}</Td>
      </Tr>
    );
  }

  function ExpressionsList({products}) {
    return products.map((expression, idx) => {
      return <ExpressionRow expression={expression} e={idx} key={`e-` + idx} />;
    });
  }

  return (
    <CContainer>
      <Helmet>
        <title>Expressions</title>
      </Helmet>
      <ExpressionCloneModal
        visible={cloneVisible}
        expressions={productsList.map((ex) => {
          return {
            product_id: ex.product_id,
            expression_name: [ex.expression_name, ex.common_name].join(' '),
          };
        })}
        onClone={() => {
          setCloneVisible(false);
          fetchExpressions();
        }}
        makers={makers}
        onCancel={() => {
          setCloneVisible(false);
        }}
      ></ExpressionCloneModal>
      <CModal
        key="modalNew"
        visible={newVisible}
        size="lg"
        onClose={() => setNewVisible(false)}
        backdrop={`static`}
      >
        <CModalHeader>
          <CCol sm={5}>
            <strong>EXPRESSION</strong> <small>New</small>
          </CCol>
        </CModalHeader>
        <ExpressionNewModal
          fetchExpressions={fetchExpressions}
          setNewVisible={setNewVisible}
        />
      </CModal>
      <CModal
        key="modalEdit"
        visible={visible}
        onClose={() => setVisible(false)}
        size="lg"
        backdrop={`static`}
      >
        <CModalHeader>
          <CCol sm={5}>
            <strong>EXPRESSION</strong> <small>Standard Values</small>
          </CCol>
        </CModalHeader>
        <ExpressionEditModal
          expression={modalExpression}
          setVisible={setVisible}
          fetchExpressions={fetchExpressions}
          makers={makers}
        />
      </CModal>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <CCol sm={5}>
                <strong>EXPRESSIONS</strong>
              </CCol>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <div style={{marginBottom: '15px'}}>
                    <CButton onClick={() => setNewVisible(true)}>
                                            New Expression
                    </CButton>
                    <CButton
                      color="primary"
                      style={{marginLeft: '0.5rem'}}
                      onClick={() => {
                        setCloneVisible(true);
                        setVisible(false);
                      }}
                    >
                                            Copy expression{' '}
                      <CIcon icon={cilClone} style={{color: 'white'}} />
                    </CButton>
                  </div>
                </CCol>
              </CRow>
              <div className="table-container">
                <Table>
                  <Thead>
                    <Tr>
                      <Th scope="col">ID</Th>
                      <Th scope="col">Name</Th>
                      <Th scope="col">SKU</Th>
                      <Th scope="col">Case Pack</Th>
                      <Th scope="col">ml/btl</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <ExpressionsList products={productsList} />
                  </Tbody>
                </Table>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

Expressions.defaultProps = {
  expressions: [],
};

Expressions.propTypes = {
  expressions: PropTypes.any,
  products: PropTypes.any,
  expressionsList: PropTypes.any,
  expression: PropTypes.string,
  e: PropTypes.number,
};

export default Expressions;
