const { default: CIcon } = require('@coreui/icons-react');
const { CTableRow, CTableDataCell, CRow, CCol, CFormInput, CButton } = require('@coreui/react');
const { default: YesNoModal } = require('../YesNoModal');
import { cilTrash } from '@coreui/icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from 'src/api';
import useMoneyCurrency from 'src/hooks/useMoneyCurrency';
import { useDispatch } from 'react-redux';
import { setErrors, setMiscellaneous } from 'src/actions';

const MiscellaneousCartRow = ({ item, isEdit }) => {
  const [qty, setQty] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [removing, setRemoving] = useState(false);
  const currency = useSelector((state) => state.currency.currency);
  const dispatch = useDispatch();
  const money = useMoneyCurrency();
  const [timesRun, setTimesRun] = useState(0);
  const { misc } = useSelector((state) => state.order);

  useEffect(() => {
    if (item) {
      setQty(item.qty);
    }
  }, [item]);

  useEffect(() => {
    const to = setTimeout(() => {
      if (timesRun > 0) {
        api
          .put(`/cpo-miscellaneous/${item.misc_id}`, {
            qty,
          })
          .then(() => {
            dispatch(
              setMiscellaneous(
                misc.map((i) => {
                  if (i.misc_id == item.misc_id) {
                    i.qty = qty;
                  }
                  return i;
                }),
              ),
            );
            toast.success('Automatically Saved');
          })
          .catch((e) => {
            dispatch(setErrors([{ message: e }]));
          })
          .finally(() => {});
      }
      setTimesRun((t) => t + 1);
    }, 700);

    return () => {
      clearTimeout(to);
    };
  }, [qty]);

  return (
    <CTableRow>
      <CTableDataCell>{item.name}</CTableDataCell>
      <CTableDataCell>
        <CRow>
          <CCol>
            {isEdit ? (
              <CFormInput
                value={qty}
                onChange={(e) => {
                  setQty(e.target.value);
                }}
                placeholder="Qty"
                readOnly={!isEdit}
                disabled={!isEdit}
              />
            ) : (
              <>{qty}</>
            )}
          </CCol>
          <CCol>
            {isEdit && (
              <CButton
                color="danger"
                onClick={() => {
                  setShowDelete(true);
                }}
              >
                <CIcon icon={cilTrash} />
              </CButton>
            )}
            <YesNoModal
              title="Remove item from cart"
              description={'Permanently remove this item from cart'}
              visible={showDelete}
              setVisible={setShowDelete}
              isLoading={removing}
              onYes={() => {
                if (isEdit) {
                  setRemoving(true);
                  api
                    .delete(`/cpo-miscellaneous/${item.misc_id}`)
                    .then(() => {
                      // update state!
                      dispatch(
                        setMiscellaneous(
                          misc.filter((it) => {
                            return it.misc_id != item.misc_id;
                          }),
                        ),
                      );
                      setShowDelete(false);
                    })
                    .finally(() => {
                      setRemoving(false);
                    });
                }
              }}
            />
          </CCol>
        </CRow>
      </CTableDataCell>
      <CTableDataCell>{money(item.price, currency)}</CTableDataCell>
      <CTableDataCell>{money(item.qty * item.price, currency)}</CTableDataCell>
    </CTableRow>
  );
};

MiscellaneousCartRow.propTypes = {
  item: PropTypes.func,
  isEdit: PropTypes.func,
};

export default MiscellaneousCartRow;
