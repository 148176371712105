import { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const OrderContext = createContext({});

export const OrderProvider = ({ children }) => {
  const [data, setData] = useState({
    stockInCart: undefined,
    productionInCart: [],
    preOrderInCart: [],
    order: {},
    products: [],
  });
  const [socket, setSocket] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isAutoSave, setIsAutoSave] = useState(true);

  const [coupon, setCoupon] = useState(null);
  return (
    <OrderContext.Provider
      value={{
        data,
        setData,
        coupon,
        setCoupon,
        socket,
        setSocket,
        isConnected,
        setIsConnected,
        isAutoSave,
        setIsAutoSave,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

OrderProvider.propTypes = {
  children: PropTypes.any,
};
