import React from 'react';
import {
  CAvatar,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import {cilSettings, cilAccountLogout} from '@coreui/icons';
import CIcon from '@coreui/icons-react';

import {useSelector} from 'react-redux';
import CCAvatar from '../CCAvatar';

const AppHeaderDropdown = () => {
  const avatar = useSelector((state) => state.auth.avatar);
  const username = useSelector((state) => state.auth.username);
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  const getAvatarSrc = () => {
    return `${process.env.REACT_APP_API_URL}/user/avatar/${avatar}`;
  };

  const getFirstLetters = (name) => {
    let letters = '';
    if (typeof name === 'string') {
      name.split(' ').forEach((word) => {
        if (word.length > 0) {
          letters += word.charAt(0).toUpperCase();
        }
      });
    }
    return letters;
  };

  return (
    <>
      {isLoggedIn && (
        <CDropdown variant="nav-item">
          <CDropdownToggle
            placement="bottom-end"
            className="py-0"
            caret={false}
          >
            {avatar === null || !avatar ? (
              <>
                <CAvatar color="primary" textColor="white">
                  {getFirstLetters(username)}
                </CAvatar>
              </>
            ) : (
              <CCAvatar src={getAvatarSrc()} />
            )}
          </CDropdownToggle>
          <CDropdownMenu className="pt-0" placement="bottom-end">
            <CDropdownHeader className="bg-light fw-semibold py-2">
              Account
            </CDropdownHeader>

            <CDropdownItem href="/users/settings">
              <CIcon icon={cilSettings} className="me-2" />
              Settings
            </CDropdownItem>
            <CDropdownItem href="/logout">
              <CIcon icon={cilAccountLogout} className="me-2" />
              Log Out
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      )}
    </>
  );
};

export default AppHeaderDropdown;
