import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './scss/style.scss';
import RequestResponse from './components/RequestResponse';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  const router = createBrowserRouter(routes);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={1}
      />
      <ToastContainer />
      <RequestResponse />
      <RouterProvider router={router} />
    </>
  );
}
