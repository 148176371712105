import { CCard, CCardBody, CCardFooter } from '@coreui/react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import api from 'src/api';
import useMoneyCurrency from 'src/hooks/useMoneyCurrency';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const InventoryValue = () => {
  const [aGranel, setAGranel] = useState(0);
  const [bottled, setBottled] = useState(0);

  const money = useMoneyCurrency();

  useEffect(() => {
    const controller = new AbortController();

    api.get('/product/cost-reports-data', { signal: controller.signal }).then(({ data }) => {
      setAGranel(data.granelTotalCost);
      setBottled(data.bottledTotalCost);
    });

    return () => controller.abort();
  }, []);

  return (
    <section>
      <CCard className="my-3">
        <CCardBody>
          <h2>Inventory Value</h2>
          <Bar
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Inventory Value',
                },
              },
            }}
            data={{
              labels: ['A Granel', 'Bottled'],
              datasets: [
                {
                  label: '(USD)',
                  data: [aGranel, bottled],
                  backgroundColor: ['#28a745', '#007bff'],
                },
              ],
            }}
          />
        </CCardBody>
        <CCardFooter className="flex">
          <span className="float-right">
            <b>Total Value: </b> {money(aGranel + bottled, 'USD')}
          </span>
        </CCardFooter>
      </CCard>
    </section>
  );
};

export default InventoryValue;
