import { CAlert, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'src/api';
import LButton from '../LButton/LButton';

import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { OrderContext } from 'src/context/OrderContext';
import { setErrors, setOnDemand } from 'src/actions';
import useProductCategoryChecker from 'src/hooks/userProductCategoryChecker';

export default function OrderOnDemand({ product }) {
  const [saving, setSaving] = useState(false);
  const [casePacks, setCasePacks] = useState('');
  const [hasOnDemand, setHasOnDemand] = useState(false);
  const onDemand = useSelector((s) => s.order.onDemand);
  const { data } = useContext(OrderContext);
  const dispatch = useDispatch();

  const categoryChecker = useProductCategoryChecker();

  const saveDemand = () => {
    if (!categoryChecker.isElBarroOrTsook(product?.product_name)) {
      toast.error('You can only pre-order products from tso\'ok or el barro.');
      return false;
    }

    if ((isNaN(casePacks) || casePacks == 0) && hasOnDemand) {
      api
        .post(`/cpo/remove-on-demand/${data.id}`, { productId: product.product_id })
        .then(() => {
          dispatch(
            setOnDemand(
              onDemand.filter((item) => {
                return item.product_id != product.product_id;
              }),
            ),
          );
          toast.success('This on demand item has been removed from your pre-orders.');
        })
        .catch((e) => {
          dispatch(setErrors([{ message: e }]));
        });
      return;
    }

    const cp = parseFloat(casePacks);
    if (isNaN(cp)) {
      toast.error('Please enter a number');
    } else if (cp % 1 != 0) {
      toast.error('Please enter whole numbers only. No decimals are allowed here');
    } else {
      setSaving(true);
      if (data.id && product.product_id) {
        api
          .post(`/cpo/on-demand/${data.id}`, {
            qty: parseFloat(casePacks) * 6,
            productId: product.product_id,
          })
          .then(({ data }) => {
            if (data.success) {
              toast.success(`Saved Successfully!`);

              const exists = onDemand.some((item) => item.product_id == product.product_id);

              if (exists) {
                setOnDemand(
                  onDemand.map((item) => {
                    if (item.product_id === product.product_id) {
                      item.bottle_qty = parseFloat(casePacks) * 6;
                    }
                    return item;
                  }),
                );
              } else {
                setOnDemand([
                  ...onDemand,
                  {
                    id: data.id,
                    cpo_id: data.id,
                    lot_id: null,
                    product_id: product.product_id,
                    bottle_qty: parseFloat(casePacks) * 6,
                  },
                ]);
              }
            } else {
              toast.error(`Your request was not successful. Please refresh the page and try again.`);
            }
          })
          .catch((e) => {
            dispatch(setErrors([{ message: e }]));
          })
          .finally(() => {
            setSaving(false);
          });
      } else {
        toast.error('Something went wrong. Please refresh and try again later.');
        setSaving(false);
      }
    }
  };

  useEffect(() => {
    if (Array.isArray(onDemand) && onDemand.length > 0) {
      const item = onDemand.find((item) => item.product_id === product.product_id);
      if (item?.product_id) {
        setHasOnDemand(true);
        setCasePacks(parseFloat(item.bottle_qty) / 6);
      }
    } else {
      setCasePacks('');
    }
  }, [onDemand]);

  return (
    <>
      <div>
        <CRow>
          <CCol>
            <div className="mt-3">
              <CAlert color="info">
                The amount of case packs that you need are going to be produced on demand.
                <br />
                This means that this could take longer time than ordering already made and available bottles.
              </CAlert>
            </div>
          </CCol>
        </CRow>
        <CForm>
          <CRow>
            <CCol>
              <div className="my-3">
                <CFormLabel htmlFor={`product-id-${product?.product_id}`}>Amount of case packs (6 bottles per pack)</CFormLabel>
                <CFormInput
                  value={casePacks}
                  onChange={(e) => setCasePacks(e.target.value)}
                  id={`product-id-${product?.product_id}`}
                  placeholder="Amount of Case Packs"
                ></CFormInput>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              {!isNaN(parseInt(casePacks)) && casePacks > 0 && (
                <CAlert color="primary">
                  By ordering <b>{casePacks} CP</b> you will get <b>{casePacks * 6} bottles.</b>
                </CAlert>
              )}
              {hasOnDemand && (isNaN(parseInt(casePacks)) || casePacks == '' || casePacks == 0) && (
                <CAlert color="danger">Saving changes will remove this item from your order.</CAlert>
              )}
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <LButton isLoading={saving} onClick={saveDemand}>
                {hasOnDemand ? 'Save changes' : 'Order on demand'}
              </LButton>
            </CCol>
          </CRow>
        </CForm>
      </div>
    </>
  );
}

OrderOnDemand.propTypes = {
  product: PropTypes.object,
};
