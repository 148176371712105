// sidebarShow reducer actions.

export const toggleSidebarShow = () => {
  return {
    type: 'TOGGLE_SIDEBAR_SHOW',
  };
};

export const hideSidebarShow = () => {
  return {
    type: 'HIDE_SIDEBAR_SHOW',
  };
};

// Unfoldable reducer actions
export const toggleUnfoldable = () => {
  return {
    type: 'TOGGLE_UNFOLDABLE',
  };
};

// Auth Reducer Actions

export const login = (data) => {
  return {
    type: 'LOGIN',
    payload: data,
  };
};

export const logout = () => {
  return {
    type: 'LOGOUT',
  };
};

export const logoutRoute = () => {
  return {
    type: 'LOGOUT_ROUTE',
  };
};

export const resetState = () => {
  return {
    type: 'RESET_STATE',
  };
};

export const newTokenInfo = (tokenInfo) => {
  return {
    type: 'SET_NEW_TOKEN_INFO',
    payload: tokenInfo,
  };
};

export const setNewAvatar = (avatar) => {
  return {
    type: 'SET_NEW_AVATAR',
    payload: avatar,
  };
};

export const setErrors = (errors) => {
  return {
    type: 'SET_ERRORS',
    payload: errors,
  };
};

export const setSuccessMessages = (messages) => {
  return {
    type: 'SET_SUCCESS_MESSAGES',
    payload: messages,
  };
};

export const setCurrency = (currency) => {
  return {
    type: 'SET_CURRENCY',
    payload: currency,
  };
};

export const resetCurrency = () => {
  return {
    type: 'SET_CURRENCY',
  };
};

/**
 * Actions for the orders
 */

export const setStockInCart = (stockInCart) => {
  return {
    type: 'SET_STOCK_IN_CART',
    payload: stockInCart,
  };
};

export const setProductionInCart = (productionInCart) => {
  return {
    type: 'SET_PRODUCTION_IN_CART',
    payload: productionInCart,
  };
};

export const setOnDemand = (onDemand) => {
  return {
    type: 'SET_ON_DEMAND',
    payload: onDemand,
  };
};

export const setMiscellaneous = (miscellaneous) => {
  return {
    type: 'SET_MISCELLANEOUS',
    payload: miscellaneous,
  };
};
