import useMoneyCurrency from './useMoneyCurrency';
import api from 'src/api';

export default function usePriceCalculator() {
  const money = useMoneyCurrency();

  return {
    getResults: async ({ data, currencies, currency, stockInCart, productionInCart, onDemand, misc }) => {
      const keys = Object.keys(data);
      if (!keys.includes('stockInCart') || !keys.includes('productionInCart') || !keys.includes('coupon')) {
        throw new Error(
          'The object passed to [usePriceCalculator().getResults(data, currencies)] ' +
            'did not include all the required keys (stockInCart, productionInCart, and coupon)',
        );
      }
      if (!Array.isArray(currencies) || currencies.length <= 0) {
        throw new Error('Invalid argument passed to [usePriceCalculator().getResults(data, currencies)] did not include a valid currencies array. ');
      }
      const results = {
        subtotal: 0,
        total: 0,
        discount: 0,
        discountString: '',
      };

      if (Array.isArray(onDemand)) {
        onDemand = onDemand.map((odi) => {
          odi.qty = odi.bottle_qty;
          odi.lot_number = 'IDK'; // IDK --> I don't know. There couuld be no log assigned yet and it doesnt really matter.
          return odi;
        });
      } else {
        onDemand = [];
      }

      const allItemsInCart = [...stockInCart, ...productionInCart, ...onDemand];

      const orderCurrency = currencies.find((c) => {
        return c.currency == currency;
      });

      if (!orderCurrency?.currency) {
        throw new Error('Oh no. The order\'s currency was not found. Please try again later.');
      }

      results.subtotal = allItemsInCart.reduce((prev, currentItem) => {
        if (currentItem.qty > 0) {
          if (isNaN(parseFloat(currentItem.price))) {
            return prev;
          } else {
            return prev + currentItem.qty * currentItem.price;
          }
        } else {
          return prev;
        }
      }, 0);

      if (Array.isArray(misc)) {
        results.subtotal += misc.reduce((prev, currItem) => {
          return prev + currItem.price * currItem.qty;
        }, 0);
      }

      if (data.coupon != null && data.coupon.code) {
        if (data.coupon.discount_percent > 0) {
          results.discountString = `-${data.coupon.discount_percent}% OFF `;
          results.discount = results.subtotal * (data.coupon.discount_percent / 100);
        } else {
          if (data.coupon.currency == currency) {
            // When the couupon and the order's currency is the same we don't need to exchange it.
            results.discount = data.coupon.discount_amount;
          } else {
            const {
              data: { result },
            } = await api.post('/rates/convert-currency', {
              from: data.coupon.currency,
              to: currency,
              amount: data.coupon.discount_amount,
            });
            results.discount = result;
          }
        }

        results.discountString += `${data.coupon.discount_percent > 0 ? ' (' : ''}${money(results.discount * -1, orderCurrency.currency)} OFF${
          data.coupon.discount_percent > 0 ? ')' : ''
        }`;
      }

      if (!isNaN(results.subtotal) && !isNaN(results.discount) && results.subtotal > 0 && results.discount > 0) {
        results.total = results.subtotal - results.discount;
      } else {
        results.total = results.subtotal;
      }

      return results;
    },
  };
}
