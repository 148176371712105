import React from 'react';
import PropTypes from 'prop-types';
import {CModalBody} from '@coreui/react';
import {useState} from 'react';
import MakerSelectDiv from './maker-select-div';
import MagueySelectDiv from './maguey-select-div';
import NewPurchaseFormDiv from './new-purchase-form-div';

const PurchaseNewModal = (props) => {
  const [makersSelectVisible, setMakersSelectVisible] = useState(true);
  const [magueysSelectVisible, setMagueysSelectVisible] = useState(false);
  const [newPurchaseFormVisible, setNewPurchaseFormVisible] = useState(false);
  const [chosenMaker, setChosenMaker] = useState();
  const [chosenMaguey, setChosenMaguey] = useState(false);

  return (
    <CModalBody key="modal1">
      <div id="makerSelectDiv">
        {makersSelectVisible ? (
          <MakerSelectDiv
            setMakersSelectVisible={setMakersSelectVisible}
            setMagueysSelectVisible={setMagueysSelectVisible}
            setChosenMaker={setChosenMaker}
          />
        ) : null}
      </div>
      <div id="magueySelectDiv">
        {magueysSelectVisible ? (
          <MagueySelectDiv
            setMagueysSelectVisible={setMagueysSelectVisible}
            setNewPurchaseFormVisible={setNewPurchaseFormVisible}
            chosenMaker={chosenMaker}
            setChosenMaguey={setChosenMaguey}
          />
        ) : null}
      </div>
      <div id="NewPurchaseFormDiv">
        {newPurchaseFormVisible ? (
          <NewPurchaseFormDiv
            setNewPurchaseFormVisible={setNewPurchaseFormVisible}
            chosenMaker={chosenMaker}
            chosenMaguey={chosenMaguey}
            fetchPurchases={props.fetchPurchases}
            setNewVisible={props.setNewVisible}
          />
        ) : null}
      </div>
    </CModalBody>
  );
};

PurchaseNewModal.propTypes = {
  fetchPurchases: PropTypes.any,
  setNewVisible: PropTypes.any,
};

export default PurchaseNewModal;
