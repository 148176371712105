import React, { useEffect, useState } from 'react';

import { CCard, CCardBody, CCol, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';

import api from 'src/api';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { useNavigate } from 'react-router';

import InventoryValue from 'src/components/Dashboard/InventoryValue';

const Dashboard = () => {
  const [openedOrders, setOpenedOrders] = useState([]);
  useEffect(() => {
    const controller = new AbortController();

    api.get('/cpo', { signal: controller.signal }).then(({ data }) => {
      const openOrders = data.filter(order => order.order_status === "Opened" || order.order_status === "In Preparation");
      setOpenedOrders(openOrders);
    });
    return () => {
      controller.abort();
    };
  }, []);
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Home - Realmezcal</title>
      </Helmet>
      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm={5}>
                  <h4 id="traffic" className="card-title mb-0">
                    Open CPOs
                  </h4>
                </CCol>
                <CCol sm={7} className="d-none d-md-block"></CCol>
              </CRow>
              <CRow>
                <CTable hover style={{ marginTop: '6px' }}>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Order</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Client</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {openedOrders.map((order, i) => {
                      return (
                        <CTableRow style={{ cursor: 'pointer' }} key={`dashboard-${i}`} onClick={() => {
                          navigate('/dashboard/cpos/cpo/' + order.id);
                        }}>
                          <CTableDataCell>{order.id}</CTableDataCell>
                          <CTableDataCell>{order.client_name}</CTableDataCell>
                          <CTableDataCell>{order.order_status}</CTableDataCell>
                          <CTableDataCell>{order.date_created ? moment(order.date_created).format('YYYY-MM-DD') : ''}</CTableDataCell>
                        </CTableRow>
                      );
                    })}
                    {openedOrders.length <= 0 && (
                      <CTableRow>
                        <CTableDataCell colSpan="5">
                          <b>No orders with status &quot;Open&quot; were found.</b>
                        </CTableDataCell>
                      </CTableRow>
                    )}
                  </CTableBody>
                </CTable>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12} md={6}>
          <InventoryValue />
        </CCol>
      </CRow>
      <br></br>
      {/*
      <CCard>
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Last Activities
              </h4>
              {/* <div className="small text-medium-emphasis">January - July 2021</div>* /}
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
              <CButton color="primary" className="float-end" onClick={() => alert('Donwload report not yet implemented')}>
                <CIcon icon={cilCloudDownload} />
              </CButton>
              {/* <CButtonGroup className="float-end me-3">
                {['Day', 'Month', 'Year'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === 'Month'}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>* /}
            </CCol>
          </CRow>
          <CRow>
            <CTable striped small style={{ marginTop: '10px' }}>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">Timestamp</CTableHeaderCell>
                  <CTableHeaderCell scope="col">User</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Activity</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Detail</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                <CTableRow>
                  <CTableHeaderCell scope="col" colSpan="4">
                    No Data available right now.
                  </CTableHeaderCell>
                </CTableRow>
                {false && (
                  /* Hide for the moment. * /
                  <>
                    <CTableRow>
                      <CTableDataCell>09/09/2021 11:30am</CTableDataCell>
                      <CTableDataCell>Alan</CTableDataCell>
                      <CTableDataCell>Lot Received</CTableDataCell>
                      <CTableDataCell>Lot: ACR046</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>09/09/2021 10:00am</CTableDataCell>
                      <CTableDataCell>Alan</CTableDataCell>
                      <CTableDataCell>New Lot Purchase</CTableDataCell>
                      <CTableDataCell>Lot: ACR046</CTableDataCell>
                    </CTableRow>
                  </>
                )}
              </CTableBody>
            </CTable>
          </CRow>
        </CCardBody>
      </CCard>
      */}
    </>
  );
};

export default Dashboard;
