import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterableSelect from 'src/components/FilterableSelect/FilterableSelect';
import { CCol, CRow, CForm, CFormLabel, CButton } from '@coreui/react';
import { useState } from 'react';
import api from 'src/api';

const MakerSelectDiv = (props) => {
  const [makersList, setMakersList] = useState([]);
  const [chosenOption, setChosenOption] = useState('');

  function fetchMakers() {
    api.get('/contacts/mezcaleros').then(({ data }) => {
      setMakersList(data);
    });
  }

  useEffect(() => {
    fetchMakers();
  }, []);

  return (
    <CForm
      style={{ margin: '10px' }}
      onSubmit={(e) => {
        e.preventDefault();
        if (chosenOption) {
          props.setChosenMaker(chosenOption);
          props.setMakersSelectVisible(false);
          props.setMagueysSelectVisible(true);
        } else {
          alert('Please select a maker first.');
        }
      }}
    >
      <CRow className="mb-2">
        <CFormLabel className="mb-1" htmlFor="makerSelect">
          Select the Maker for the new Expression:
        </CFormLabel>
        <FilterableSelect
          options={makersList}
          value="id"
          text="maker_name"
          onChange={(value) => {
            setChosenOption(value);
          }}
        />
      </CRow>
      <CRow>
        <CCol>
          <CButton type="submit" color="primary">
            Next
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

MakerSelectDiv.propTypes = {
  setMakersSelectVisible: PropTypes.any,
  setMagueysSelectVisible: PropTypes.any,
  setChosenMaker: PropTypes.any,
};

export default MakerSelectDiv;
