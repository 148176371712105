import {useState} from 'react';
import PropTypes from 'prop-types';
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CAvatar,
} from '@coreui/react';

import {UploadBox} from 'react-upload-component';
import LButton from '../LButton/LButton';
import {UploadBoxContext} from 'react-upload-component';

import {useContext} from 'react';
import api from 'src/api';
import {useDispatch} from 'react-redux';
import {setNewAvatar} from 'src/actions';
import CCAvatar from '../CCAvatar';

const AvatarUpload = ({avatar, username, setAvatar}) => {
  const {files, setProgress, setIsUploading} = useContext(UploadBoxContext);
  const [uploadAvatarVisible, setUploadAvatarVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const getAvatarSrc = () => {
    return `${process.env.REACT_APP_API_URL}/user/avatar/${avatar}`;
  };

  const getFirstLetters = (name) => {
    let letters = '';
    if (typeof name === 'string') {
      name.split(' ').forEach((word) => {
        if (word.length > 0) {
          letters += word.charAt(0).toUpperCase();
        }
      });
    }
    return letters;
  };

  const uploadAvatar = () => {
    if (files.length <= 0) {
    } else if (files.length === 1) {
      setIsLoading(true);
      const data = new FormData();
      data.append('avatar', files[0]);

      setIsUploading(true);
      const totalSize = files[0].size;
      api
          .patch('/user/avatar', data, {
            onUploadProgress: (progressEvent) => {
              setProgress((progressEvent.loaded * 100) / totalSize);
            },
          })
          .then(({data}) => {
            dispatch(setNewAvatar(data.avatar));
            setAvatar(data.avatar);
            setUploadAvatarVisible(false);
          })
          .finally(() => {
            setIsUploading(false);
            setIsLoading(false);
          });
    } else {
      alert('Please select one image maximum.');
    }
  };

  return (
    <>
      <p>
        <b data-testid="title">Avatar</b>
      </p>
      <div style={{marginBottom: '0.9rem'}}>
        {avatar === null ? (
          <CAvatar data-testid="cc-profile-letter" size="xl" color="primary" textColor="white">
            {getFirstLetters(username)}
          </CAvatar>
        ) : (
          <>
            <CCAvatar src={getAvatarSrc()} size="lg" />
          </>
        )}
      </div>
      <CButton
        color="primary"
        size="sm"
        onClick={() => {
          setUploadAvatarVisible(true);
        }}
      >
        Update Avatar
      </CButton>

      <CModal
        data-testid="upload-modal"
        visible={uploadAvatarVisible}
        onClose={() => setUploadAvatarVisible(false)}
      >
        <CModalHeader onClose={() => setUploadAvatarVisible(false)}>
          <CModalTitle>Upload Avatar</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <UploadBox />
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="danger"
            style={{color: 'white'}}
            onClick={() => setUploadAvatarVisible(false)}
            disabled={isLoading}
          >
            Cancel
          </CButton>

          <LButton
            isLoading={isLoading}
            color="primary"
            onClick={() => {
              uploadAvatar();
            }}
          >
            Save changes
          </LButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

AvatarUpload.defaultProps = {
  avatar: null,
  username: null,
};

AvatarUpload.propTypes = {
  avatar: PropTypes.string,
  username: PropTypes.string,
  setAvatar: PropTypes.func.isRequired,
};

export default AvatarUpload;
