import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CCol, CRow, CForm, CFormLabel, CButton } from '@coreui/react';
import { useState } from 'react';
import api from 'src/api';
import FilterableSelect from 'src/components/FilterableSelect/FilterableSelect';

const MagueySelectDiv = (props) => {
  const [magueysList, setMagueysList] = useState([]);
  const [magueyId, setMagueyId] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    api
      .post(
        '/catalog/items/maguey-name/existent-magueys-for-maker',
        {
          maker_name: props.chosenMaker,
        },
        {
          signal: controller.signal,
        },
      )
      .then(({ data }) => {
        setMagueysList(data);
      });
    return () => {
      controller.abort();
    };
  }, [props.chosenMaker]);

  return (
    <CForm
      style={{ margin: '10px' }}
      onSubmit={(e) => {
        e.preventDefault();
        props.setChosenMaguey(magueyId);
        props.setMagueysSelectVisible(false);
        props.setNewPurchaseFormVisible(true);
      }}
    >
      <CRow className="mb-2">
        <CFormLabel className="mb-1" htmlFor="makerSelect">
          Select the Common Name of the new Purhase:
        </CFormLabel>
        <FilterableSelect
          options={magueysList}
          value="id"
          text="common_name"
          onChange={(magueyId) => {
            setMagueyId(magueyId);
          }}
        ></FilterableSelect>
      </CRow>
      <CRow>
        <CCol>
          <CButton type="submit" color="primary">
            Next
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

MagueySelectDiv.propTypes = {
  setMagueysSelectVisible: PropTypes.any,
  setNewPurchaseFormVisible: PropTypes.any,
  chosenMaker: PropTypes.any,
  setChosenMaguey: PropTypes.any,
};

export default MagueySelectDiv;
