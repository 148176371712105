import { useState } from 'react';
import { CCol, CModal, CModalHeader, CModalBody, CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';

import LButton from './LButton/LButton';
import api from 'src/api';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';

function ConcludeLotProduction(props) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  function conclude(e) {
    e.preventDefault();
    setIsLoading(true);

    api
      .post('/lotes/conclude-production', {
        production_id: e.target.elements.idInput.value,
        received_bottles: e.target.elements.receivedInput.value,
        lot_number: e.target.elements.lotInput.value,
      })
      .then(() => {
        props.onConclude();
      })
      .catch((e) => {
        dispatch(setErrors([{ message: e }]));
      })
      .finally(() => {
        props.setConcludeVisible(false);
        setIsLoading(false);
      });
  }

  return (
    <CModal visible={props.concludeVisible}>
      <CModalHeader>
        <CCol sm={5}>
          <strong>CONCLUDE</strong> <small>Production</small>
        </CCol>
      </CModalHeader>
      <CModalBody>
        <CForm style={{ margin: '10px' }} onSubmit={conclude}>
          <div className="mb-3" hidden>
            <CFormLabel htmlFor="idInput">Id</CFormLabel>
            <CFormInput type="text" id="idInput" defaultValue={props.production.id} disabled size="sm" />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="expressionInput">Expression</CFormLabel>
            <CFormInput type="text" id="expressionInput" defaultValue={props.production.expression} readOnly size="sm" />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="lotInput">Lot</CFormLabel>
            <CFormInput type="text" id="lotInput" defaultValue={props.production.lot_number} readOnly size="sm" />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="litersInput">Liters</CFormLabel>
            <CFormInput type="text" id="litersInput" defaultValue={props.production.liters} readOnly size="sm" />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="projectedInput">Projected Bottles</CFormLabel>
            <CFormInput type="text" id="projectedInput" defaultValue={props.production.projected_bottles} readOnly size="sm" />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="receivedInput">Received Bottles</CFormLabel>
            <CFormInput type="text" id="receivedInput" size="sm" />
          </div>
          <LButton isLoading={isLoading} type="submit" color="primary">
            Confirm
          </LButton>
          <CButton
            disabled={isLoading}
            color="danger"
            onClick={() => {
              props.setConcludeVisible(false);
            }}
            style={{ marginLeft: '10px' }}
          >
            Close
          </CButton>
        </CForm>
      </CModalBody>
    </CModal>
  );
}

ConcludeLotProduction.propTypes = {
  production: PropTypes.object,
  onConclude: PropTypes.func,
  setConcludeVisible: PropTypes.func,
  concludeVisible: PropTypes.bool,
};

export default ConcludeLotProduction;
