import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CCollapse,
  CContainer,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CForm,
  CFormLabel,
  CFormTextarea,
  CFormSwitch,
} from '@coreui/react';
import { useEffect } from 'react';

import { useState } from 'react';
import DatePicker from 'react-datepicker';
import api from 'src/api';
import LButton from 'src/components/LButton/LButton';

import 'react-datepicker/dist/react-datepicker.css';
import ChooseProduct from 'src/components/ChooseProduct/ChooseProduct';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';
import 'src/components/custom-responsive-table.css';
import YesNoModal from 'src/components/YesNoModal';
import { Helmet } from 'react-helmet';

import PropTypes from 'prop-types';

const ExternalItemRow = ({ sell, askDelete }) => {
  const [collapseVisible, setCollapseVisible] = useState(false);

  const itemsTotalPrice = sell.items.reduce((prev, current) => {
    return parseFloat(current.price) + prev;
  }, 0);

  const itemsTotalQty = sell.items.reduce((prev, current) => {
    return parseInt(current.qty) + prev;
  }, 0);

  const getTotalPrice = () => {
    return sell.items.reduce((prev, current) => {
      return parseInt(current.qty) * parseInt(current.price) + prev;
    }, 0);
  };

  const toggleCollapse = () => {
    setCollapseVisible((value) => !value);
  };

  return (
    <>
      <CTableRow style={{ cursor: 'pointer' }}>
        <CTableDataCell onClick={toggleCollapse} width="10%">
          #{sell.id}
        </CTableDataCell>
        <CTableDataCell onClick={toggleCollapse} width="10%">
          {new Date(sell.date).toLocaleDateString()}
        </CTableDataCell>
        <CTableDataCell onClick={toggleCollapse} width="20%">
          {sell.items.length} item(s)
        </CTableDataCell>
        <CTableDataCell onClick={toggleCollapse} width="10%">
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(itemsTotalPrice)}
        </CTableDataCell>
        <CTableDataCell onClick={toggleCollapse} width="10%">
          {itemsTotalQty}
        </CTableDataCell>
        <CTableDataCell onClick={toggleCollapse} width="10%">
          {' '}
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(getTotalPrice(sell.items))}
        </CTableDataCell>
        <CTableDataCell onClick={toggleCollapse} width="20%">
          {sell.notes}
        </CTableDataCell>
        <CTableDataCell width="10%">
          <CButton
            color="danger"
            size="sm"
            className="me-1 mb-1"
            onClick={() => {
              askDelete(sell.id);
            }}
          >
            Delete
          </CButton>
        </CTableDataCell>
      </CTableRow>
      <CTableRow>
        <CTableDataCell
          colSpan={8}
          style={{ padding: 0, margin: 0, border: 0 }}
        >
          <CCollapse visible={collapseVisible}>
            <CTable style={{ width: '100%' }}>
              <CTableBody>
                {sell.items.map((item, idx) => {
                  return (
                    <CTableRow key={`sub-table-items-${idx}`}>
                      <CTableDataCell width="20%" colSpan={3}></CTableDataCell>
                      <CTableDataCell width="20%">
                        {item.expression} <b>({item.lot_number})</b>
                      </CTableDataCell>
                      <CTableDataCell width="10%">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(item.price)}
                      </CTableDataCell>
                      <CTableDataCell width="10%">{item.qty}</CTableDataCell>
                      <CTableDataCell width="10%">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(item.price * item.qty)}
                      </CTableDataCell>
                      <CTableDataCell width="30%"></CTableDataCell>
                    </CTableRow>
                  );
                })}
              </CTableBody>
            </CTable>
          </CCollapse>
        </CTableDataCell>
      </CTableRow>
    </>
  );
};

ExternalItemRow.propTypes = {
  sell: PropTypes.objectOf({
    id: PropTypes.any,
    items: PropTypes.array,
    date: PropTypes.any,
    notes: PropTypes.string,
  }),
  askDelete: PropTypes.func.isRequired,
};

const ExternalSales = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const [dateSold, setDateSold] = useState(new Date());

  const [expressions, setExpressions] = useState([]);
  const [items, setItems] = useState([]);
  const [notes, setNotes] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [updated, setUpdated] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [restoreInventory, setRestoreInventory] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [updateInventory, setUpdateInventory] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const controller2 = new AbortController();

    setLoading(true);
    api
      .get('/sale-external', { signal: controller.signal })
      .then(({ data }) => {
        setSales(data);
      })
      .finally(() => {
        setLoading(false);
      });

    api.get('/product/all', { signal: controller2.signal }).then(({ data }) => {
      setExpressions(data);
    });
    return () => {
      controller.abort();
      controller2.abort();
    };
  }, [updated]);

  const onDateChange = (date) => {
    setDateSold(date);
  };

  const onContinueAddExpression = (data) => {
    let exists = false;

    items.forEach((item) => {
      if (item.id == data.id) {
        exists = true;
      }
    });

    if (exists) {
      // Replace existing's item data with the new data
      setItems((prevIts) => {
        return prevIts.map((it) => {
          if (it.id == data.id) {
            return { ...data };
          } else {
            return it;
          }
        });
      });
    } else {
      // add the new data
      setItems((prevIts) => {
        return [...prevIts, { ...data }];
      });
    }
  };

  const cancel = () => {
    setNotes('');
    setDateSold(new Date());
    setItems([]);
    setVisible(false);
  };

  const addSale = () => {
    if (!moment(dateSold, 'YYYY-MM-DD', true).isValid()) {
      dispatch(
        setErrors([
          {
            message: 'Please enter a valid date',
          },
        ]),
      );
    }

    if (items.length <= 0) {
      dispatch(
        setErrors([
          {
            message: 'Please enter at least one expression',
          },
        ]),
      );
    }

    if (typeof notes != 'string') {
      setNotes('');
    }

    setIsSaving(true);

    api
      .post('/sale-external', {
        date: moment(dateSold).format('YYYY-MM-DD'),
        notes,
        items: JSON.stringify(items),
        updateInventory: updateInventory ? 1 : 0,
      })
      .then(() => {
        setUpdated((v) => !v);
        dispatch(
          setSuccessMessages([
            {
              message: 'The sale has been added.',
            },
          ]),
        );
        cancel();
      })
      .catch((e) => {
        dispatch(setErrors([{ message: e }]));
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onCancelDelete = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const onDelete = () => {
    setIsDeleting(true);
    api
      .delete(`/sale-external/${deleteId}`, {
        params: {
          restore: restoreInventory ? 1 : 0,
        },
      })
      .then(() => {
        setSales((prev) =>
          prev.filter(({ id }) => {
            return id != deleteId;
          }),
        );
        setShowDelete(false);
        setDeleteId(null);
        dispatch(
          setSuccessMessages([
            { message: 'Successfully deleted the transaction.' },
          ]),
        );
      })
      .catch((e) => {
        dispatch(
          setErrors([
            {
              message: e,
            },
          ]),
        );
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>External Sales</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CCardTitle>External Sales</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <div className="mb-3">
                      <CButton
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        Add external sale
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div className="table-container">
                      <CTable bordered>
                        <CTableHead>
                          <CTableRow>
                            <CTableHeaderCell width="10%">ID</CTableHeaderCell>
                            <CTableHeaderCell width="10%">
                              Date
                            </CTableHeaderCell>
                            <CTableHeaderCell width="20%">
                              Product(s)
                            </CTableHeaderCell>
                            <CTableHeaderCell width="10%">
                              Price
                            </CTableHeaderCell>
                            <CTableHeaderCell width="10%">Qty</CTableHeaderCell>
                            <CTableHeaderCell width="10%">
                              Total
                            </CTableHeaderCell>
                            <CTableHeaderCell width="20%">
                              Notes
                            </CTableHeaderCell>
                            <CTableHeaderCell width="10%">
                              Actions
                            </CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          {sales.map((sell, idx) => {
                            return (
                              <ExternalItemRow
                                askDelete={() => {
                                  setDeleteId(sell.id);
                                  setShowDelete(true);
                                }}
                                sell={sell}
                                key={`sell-row-${idx}`}
                              />
                            );
                          })}
                          {sales.length <= 0 && (
                            <>
                              <CTableRow>
                                <CTableDataCell colSpan={8}>
                                  {loading ? (
                                    'Loading...'
                                  ) : (
                                    <b>No external sales found.</b>
                                  )}
                                </CTableDataCell>
                              </CTableRow>
                            </>
                          )}
                        </CTableBody>
                      </CTable>
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CModal
              keyboard={false}
              backdrop="static"
              visible={visible}
              onClose={() => {
                setVisible(false);
              }}
              size="xl"
            >
              <CModalHeader>
                <CModalTitle>Add Sale</CModalTitle>
              </CModalHeader>
              <CModalBody>
                <CForm>
                  <CRow>
                    <CCol>
                      <CFormLabel>
                        When did you sell / give away the product(s)?
                      </CFormLabel>
                      <DatePicker selected={dateSold} onChange={onDateChange} />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div className="my-3">
                        <CFormSwitch
                          label="Do you want to update the inventory count?"
                          value={updateInventory}
                          onChange={() => {
                            setUpdateInventory((v) => !v);
                          }}
                          defaultChecked={updateInventory}
                        />
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CFormLabel className="mt-3">
                        Choose the products that were sold / given as a gift.
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {items.map((item, idx) => {
                          return (
                            <ChooseProduct
                              key={`itm-${idx}`}
                              item={item}
                              expressions={expressions}
                              removeProduct={(item) => {
                                setItems((its) => {
                                  return its.filter((it) => {
                                    return it != item;
                                  });
                                });
                              }}
                              onContinue={onContinueAddExpression}
                            />
                          );
                        })}
                        <ChooseProduct
                          choose={true}
                          expressions={expressions}
                          onContinue={onContinueAddExpression}
                        />
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CFormLabel className="mt-3">Notes</CFormLabel>
                      <CFormTextarea
                        value={notes}
                        onChange={(e) => {
                          setNotes(e.target.value);
                        }}
                        style={{ minHeight: '100px' }}
                      ></CFormTextarea>
                    </CCol>
                  </CRow>
                </CForm>
              </CModalBody>
              <CModalFooter>
                <LButton onClick={addSale} isLoading={isSaving}>
                  Add Sale
                </LButton>
                <CButton color="danger" onClick={cancel}>
                  Cancel
                </CButton>
              </CModalFooter>
            </CModal>
          </CCol>
        </CRow>
        <CRow>
          <YesNoModal
            isLoading={isDeleting}
            visible={showDelete}
            setVisible={setShowDelete}
            title="Delete Item?"
            description={
              <>
                <CFormSwitch
                  label="Restore inventory"
                  value={restoreInventory}
                  onChange={() => {
                    setRestoreInventory((v) => !v);
                  }}
                  defaultChecked={false}
                />
                <p>
                  The <b>inventory</b> count will <b>increase</b> by the amounts
                  specified in the transaction.
                </p>
              </>
            }
            onNo={onCancelDelete}
            onYes={onDelete}
          />
        </CRow>
      </CContainer>
    </>
  );
};

export default ExternalSales;
