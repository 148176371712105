import { useEffect, useState } from 'react';
import { CAlert, CButton, CRow, CCol, CCard, CCardBody } from '@coreui/react';
import TheIcon from 'src/components/TheIcon/TheIcon';
import ContentLayout from 'src/layout/ContentLayout';

import api from 'src/api';
import { useDispatch } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';
import LButton from 'src/components/LButton/LButton';
import { Helmet } from 'react-helmet';

export default function QuickbooksHome() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSynchronizing, setIsSyncronizing] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    api
      .get('/quickbooks/is-authorized', { signal: controller.signal })
      .then(({ data }) => {
        setIsAuthorized(data.isAuthorized);
      })
      .catch((e) => {
        dispatch(setErrors([{ message: e }]));
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      controller.abort();
    };
  }, []);

  const syncInventory = () => {
    setIsSyncronizing(true);
    api
      .post('/quickbooks/sync-inventory')
      .then(() => {
        dispatch(
          setSuccessMessages([
            { message: 'The inventory has been synchronized successfully.' },
          ]),
        );
      })
      .catch((e) => {
        dispatch(setErrors([{ message: e }]));
      })
      .finally(() => {
        setIsSyncronizing(false);
      });
  };

  const logout = () => {
    setIsLoggingOut(true);
    api
      .post('/quickbooks/logout')
      .then(({ data }) => {
        if (data.loggedOut) {
          dispatch(
            setSuccessMessages([
              {
                message: 'You have been logged out of your quickbooks session.',
              },
            ]),
          );
          setIsAuthorized(false);
        } else {
          dispatch(
            setErrors([
              { message: 'Unable to logout. Please refresh and try again.' },
            ]),
          );
        }
      })
      .finally(() => {
        setIsLoggingOut(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Quickbooks</title>
      </Helmet>
      <ContentLayout title={'Quickbooks'}>
        {loading ? (
          <>Loading...</>
        ) : (
          <>
            {isAuthorized ? (
              <>
                <CRow>
                  <CCol>
                    <CAlert
                      color="info"
                      className="d-inline-flex align-items-center"
                    >
                      <TheIcon name="warning" />
                      You are logged in as
                    </CAlert>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs={12} md={4} xl={3}>
                    <CCard className="h-100">
                      <CCardBody>
                        <div className="h-100 d-flex flex-column">
                          <h2 className="fs-3 fw-bold">Sync Inventory</h2>
                          <p>
                            If the quickbooks inventory is not up to date with
                            quickbooks you can sync it. The system will try to
                            automatically do it if a session is available.
                          </p>
                          <LButton
                            onClick={syncInventory}
                            className="mt-auto"
                            isLoading={isSynchronizing}
                          >
                            <TheIcon name="sync" />
                            Sync Inventory
                          </LButton>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CCol>
                  <CCol xs={12} md={4} xl={3}>
                    <CCard className="h-100">
                      <CCardBody>
                        <div className="h-100 d-flex flex-column">
                          <h2 className="fs-3 fw-bold">Log Out</h2>
                          <p>
                            Your session is securely stored in an encrypted file
                            and it will be used to sync the inventory. If you no
                            longer want your session to be stored in the server
                            you can logout and all the information related to
                            your account will be deleted.
                          </p>
                          <LButton onClick={logout} isLoading={isLoggingOut}>
                            <TheIcon name="logout" />
                            Log out
                          </LButton>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </>
            ) : (
              <>
                <CRow>
                  <CCol>
                    <CAlert
                      color="warning"
                      className="d-inline-flex align-items-center"
                    >
                      <TheIcon name="warning" />
                      In order to authorize quickbooks to interact with this
                      sytem you must login with an account.
                    </CAlert>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CButton
                      href={`${process.env.REACT_APP_API_URL}/quickbooks/token`}
                      className=""
                    >
                      <TheIcon name="quickbooks" />
                      Login with quickbooks
                    </CButton>
                  </CCol>
                </CRow>
              </>
            )}
          </>
        )}
      </ContentLayout>
    </>
  );
}
