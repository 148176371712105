import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CFormInput,
} from '@coreui/react';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';

import { useState, useCallback, useEffect } from 'react';
import api from 'src/api';
import LButton from 'src/components/LButton/LButton';
import { useDispatch } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';
import YesNoModal from 'src/components/YesNoModal';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const ExpressionRow = ({ expression, setExpressions }) => {
  const [showInput, setShowInput] = useState(false);
  const [disc, setDisc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [removing, setIsRemoving] = useState(false);

  useEffect(() => {
    if (parseFloat(expression.discount) > 0) {
      setDisc(expression.discount);
    }
  }, [expression]);

  const save = () => {
    if (disc === '' || disc === null || disc === undefined) {
      dispatch(
        setErrors([
          {
            message: 'The discount can\'t be empty.',
          },
        ]),
      );
    } else if (isNaN(parseFloat(disc))) {
      dispatch(
        setErrors([
          {
            message: 'Entery numbers only please.',
          },
        ]),
      );
    } else if (parseFloat(disc) < 0) {
      dispatch(
        setErrors([
          {
            message: 'The discount must be a positive number.',
          },
        ]),
      );
    } else {
      setIsLoading(true);
      api
        .patch(`/product/discount/${expression.product_id}`, {
          discount: disc,
        })
        .then(() => {
          setShowInput(false);
          dispatch(
            setSuccessMessages([
              { message: 'The discount has been applied successfully.' },
            ]),
          );
          setExpressions((prevVal) => {
            return prevVal.map((ex) => {
              if (ex.product_id === expression.product_id) {
                ex.discount = disc;
              }
              return ex;
            });
          });
        })
        .catch((e) => {
          dispatch(
            setErrors([
              {
                message:
                  e?.data?.errors || 'Sorry we are unable to set a discount.',
              },
            ]),
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Tr>
        <Td scope="col">
          <YesNoModal
            title="Remove discount"
            description={
              'Are you sure that you want to remove the discount for this item?'
            }
            isLoading={removing}
            visible={show}
            setVisible={setShow}
            onYes={() => {
              setIsRemoving(true);
              api
                .patch(`/product/discount/${expression.product_id}`, {
                  discount: 0,
                })
                .then(() => {
                  setShow(false);
                  dispatch(
                    setSuccessMessages([
                      {
                        message: 'The discount has been removed..',
                      },
                    ]),
                  );
                  setExpressions((prevVal) => {
                    return prevVal.map((ex) => {
                      if (ex.product_id === expression.product_id) {
                        ex.discount = 0;
                      }
                      return ex;
                    });
                  });
                })
                .catch(() => {
                  dispatch(
                    setErrors([
                      {
                        message:
                          e?.data?.errors ||
                          'Sorry we are unable to remove the discount.',
                      },
                    ]),
                  );
                })
                .finally(() => {
                  setIsRemoving(false);
                });
            }}
            onNo={() => {
              setShow(false);
            }}
          />
          {expression.expression_name} {expression.common_name}
        </Td>
        <Td scope="col">{expression.sku}</Td>
        <Td scope="col">
          {showInput ? (
            <>
              <CFormInput
                type="number"
                size="sm"
                value={disc}
                onChange={(e) => {
                  setDisc(e.target.value);
                }}
              />
            </>
          ) : (
            <>{expression.discount}%</>
          )}
        </Td>
        <Td scope="col">
          {showInput ? (
            <>
              <LButton
                color="primary"
                style={{ color: 'white', marginRight: '0.3rem' }}
                size="sm"
                isLoading={isLoading}
                onClick={save}
              >
                Save
              </LButton>
              <CButton
                color="danger"
                style={{ color: 'white' }}
                size="sm"
                onClick={() => {
                  setShowInput(false);
                }}
                disabled={isLoading}
              >
                Cancel
              </CButton>
            </>
          ) : (
            <>
              {parseFloat(expression.discount) > 0 ? (
                <>
                  <LButton
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setShowInput(true);
                    }}
                    style={{ marginRight: '0.3rem' }}
                  >
                    Update
                  </LButton>
                  <LButton
                    color="danger"
                    style={{ color: 'white' }}
                    size="sm"
                    disabled={removing}
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Remove Discount
                  </LButton>
                </>
              ) : (
                <>
                  <CButton
                    color="success"
                    style={{ color: 'white' }}
                    size="sm"
                    onClick={() => {
                      setShowInput(true);
                    }}
                  >
                    Add Discount
                  </CButton>
                </>
              )}
            </>
          )}
        </Td>
      </Tr>
    </>
  );
};

ExpressionRow.propTypes = {
  expression: PropTypes.object,
  setExpressions: PropTypes.func,
};

const Discounts = () => {
  const [expressions, setExpressions] = useState([]);

  const fetchExpressions = useCallback(() => {
    api.get('/catalog/items/expressions').then(({ data }) => {
      setExpressions(data);
    });
  }, []);

  useEffect(() => {
    fetchExpressions();
  }, []);

  return (
    <>
      <Helmet>
        <title>Discounts</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <CCol sm={5}>
                  <strong>Discounts</strong>
                </CCol>
              </CCardHeader>
              <CCardBody>
                <div>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th scope="col">Name</Th>
                        <Th scope="col">SKU</Th>
                        <Th scope="col">Discount</Th>
                        <Th scope="col">Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {expressions.map((expression, idx) => (
                        <ExpressionRow
                          expression={expression}
                          setExpressions={setExpressions}
                          key={`exp-${idx}`}
                        />
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default Discounts;
