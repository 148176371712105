import { useState } from 'react';
import { CButton, CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCheckCircle } from '@coreui/icons';
import { useSelector } from 'react-redux';

function PalletSituation() {
  const [situationVisible, setSituationVisible] = useState(false);

  const [fullPallets750, setFullPallets750] = useState(0);
  const [toRemoveBottles750, setToRemoveBottles750] = useState(0);
  const [toAddBottles750, setToAddBottles750] = useState(0);

  const [fullPallets1000, setFullPallets1000] = useState(0);
  const [toRemoveBottles1000, setToRemoveBottles1000] = useState(0);
  const [toAddBottles1000, setToAddBottles1000] = useState(0);
  const { stockInCart, productionInCart } = useSelector((state) => state.order);

  const countBySize = (items) => {
    let size750 = 0;
    let size1000 = 0;

    if (!Array.isArray(items)) {
      throw new Error('The items must be an array.');
    }

    items.forEach((item) => {
      if (item.ml_btl == '750') {
        size750 += item.qty;
      } else {
        size1000 += item.qty;
      }
    });
    return {
      size750,
      size1000,
    };
  };

  const getBottleCount = () => {
    let bottleCount750 = 0;
    let bottleCount1000 = 0;

    const allItems = [];
    if (Array.isArray(stockInCart)) {
      allItems.push(...stockInCart);
    }
    if (Array.isArray(productionInCart)) {
      allItems.push(...productionInCart);
    }

    const allItemsCount = countBySize(allItems);
    bottleCount750 += allItemsCount.size750;
    bottleCount1000 += allItemsCount.size1000;

    return {
      bottleCount750,
      bottleCount1000,
    };
  };

  const getSituation = (bottleCount, maxBottlesPerPallet) => {
    const full = Math.trunc(bottleCount / maxBottlesPerPallet);
    const remaining = bottleCount % maxBottlesPerPallet; // Reminder
    const toAdd = maxBottlesPerPallet - remaining;
    return {
      full,
      remaining,
      toAdd,
    };
  };

  return (
    <>
      <CButton
        type="button"
        onClick={() => {
          const { bottleCount750, bottleCount1000 } = getBottleCount();
          const { full: full750, remaining: rem750, toAdd: toAddBottles750 } = getSituation(bottleCount750, 720);
          const { full: full1000, remaining: rem1000, toAdd: toAddBottles1000 } = getSituation(bottleCount1000, 600);

          setFullPallets750(full750);
          setToAddBottles750(toAddBottles750 / 6);
          setToRemoveBottles750(rem750 / 6);
          setFullPallets1000(full1000);
          setToAddBottles1000(toAddBottles1000 / 6);
          setToRemoveBottles1000(rem1000 / 6);
          // setTotal750(bottleCount750);
          // setTotal1000(bottleCount1000);
          setSituationVisible(true);
        }}
      >
        Show Pallet Situation
      </CButton>
      <CModal
        backdrop={`static`}
        visible={situationVisible}
        onClose={() => {
          setSituationVisible(false);
        }}
      >
        <CModalHeader>
          <CModalTitle>Pallet situation</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <h4>
            <b>750ml</b>
          </h4>
          <p>
            You have <span data-testid="fullPallets750">{fullPallets750.toFixed(1)}</span> full pallet
            {fullPallets750 > 1 || fullPallets750 === 0 ? 's' : ''}
            {toRemoveBottles750 > 0 && (
              <span>
                {' '}
                plus{' '}
                <b>
                  <span data-testid="extraBottles750">{toRemoveBottles750.toFixed(1)}</span> CP
                </b>
              </span>
            )}
            .{fullPallets750 > 0 && toRemoveBottles750 === 0 && <CIcon icon={cilCheckCircle} style={{ color: 'green', marginLeft: '0.3rem' }}></CIcon>}
          </p>
          <p>
            {toRemoveBottles750 > 0 && (
              <span>
                {' '}
                Either remove{' '}
                <b>
                  <span data-testid="toRemove750">{toRemoveBottles750.toFixed(1)}</span> CP
                </b>{' '}
                or add{' '}
                <b>
                  <span data-testid="toAdd750">{toAddBottles750.toFixed(1)}</span>
                </b>{' '}
                CP to have full pallets only.
              </span>
            )}
          </p>
          <hr />
          <h4>
            <b>1000ml</b>:
          </h4>
          <p>
            You have <span data-testid="fullPallets1000">{fullPallets1000.toFixed(1)}</span> full pallet
            {fullPallets1000 > 1 || fullPallets1000 === 0 ? 's' : ''}
            {toRemoveBottles1000.toFixed(1) > 0 && (
              <span>
                {' '}
                plus{' '}
                <b>
                  <span data-testid="extraBottles1000">{toRemoveBottles1000.toFixed(1)}</span> CP
                </b>
              </span>
            )}
            .{fullPallets1000 > 0 && toRemoveBottles1000 === 0 && <CIcon icon={cilCheckCircle} style={{ color: 'green', marginLeft: '0.3rem' }}></CIcon>}
          </p>
          <p>
            {toRemoveBottles1000 > 0 && (
              <span>
                Either remove{' '}
                <b>
                  <span data-testid="toRemoveBottles1000">{toRemoveBottles1000.toFixed(1)}</span>
                </b>{' '}
                CP or add{' '}
                <b>
                  <span data-testid="toAdd1000">{toAddBottles1000.toFixed(1)}</span>
                </b>{' '}
                CP to have full pallets only.
              </span>
            )}
          </p>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="primary"
            onClick={() => {
              setSituationVisible(false);
            }}
          >
            Ok
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default PalletSituation;
