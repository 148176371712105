import React from 'react';

import PropTypes from 'prop-types';

import { Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';

function FinishedCPORow(props) {
  const dateCreated = new Date(props.cpo.date_created);
  let month = dateCreated.getMonth() + 1;
  let year = dateCreated.getFullYear();
  const dateCreatedFormatted = month + '/' + dateCreated.getDate() + '/' + year;

  const dateShipped = new Date(props.cpo.date_shipped);
  month = dateShipped.getMonth() + 1;
  year = dateShipped.getFullYear();

  const dateShippedFormatted = month + '/' + dateShipped.getDate() + '/' + year;

  const getTotalAfterCoupon = (order) => {
    let amount = 0;
    if (order.discount_amount > 0) {
      amount = order.discount_amount;
    } else if (order.discount_percent > 0) {
      amount = order.total * (order.discount_percent / 100);
    }
    return order.total - amount;
  };

  return (
    <Tr
      style={{ cursor: 'pointer', fontSize: '0.85em' }}
      onClick={() => {
        props.adjustCPOViewModal(props.cpo);
      }}
      key={props.fc}
    >
      <Th scope="row">{props.cpo.id}</Th>
      <Td>{props.cpo.client_name}</Td>
      <Td>{props.cpo.order_status}</Td>
      <Td>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: props.cpo.cpo_currency || 'USD',
        }).format(getTotalAfterCoupon(props.cpo))}
      </Td>
      <Td>{dateCreatedFormatted}</Td>
      <Td>{dateShippedFormatted}</Td>
    </Tr>
  );
}

FinishedCPORow.propTypes = {
  cpo: PropTypes.any,
  adjustCPOViewModal: PropTypes.func.isRequired,
  fc: PropTypes.any,
};

export default FinishedCPORow;
