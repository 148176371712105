export default function useMoneyCurrency() {
  return (amount, currency, fractionDigits =2)=>{
    amount = parseFloat(amount);
    // if (amount % 1 === 0){
    //     // We are working with a whole number (No decimal)
    //     fractionDigits = 0
    // }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: fractionDigits,
    }).format(amount);
  };
}
