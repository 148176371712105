import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useValidator from '../../../hooks/useValidator';

const DefaultPage = () => {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const role = useSelector((state) => state.auth.role);
  const navigate = useNavigate();
  const validator = useValidator();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (loggedIn) {
      if (role === 'CLIENT') {
        navigate('/dashboard/inventory/by-expression');
      } else if (role === 'ARTIST') {
        navigate('/art-submissions');
      } else if (role === 'ADMIN' || role === 'STAFF') {
        navigate('/dashboard');
      } else {
        navigate('/dashboard/inventory/by-expression');
      }
    } else {
      let callback = searchParams.get('callback');

      if (!validator.validateURL(callback)) {
        callback = ''; // No callback when its invalid.
      }
      /**
       * Redirect the not logged in user to the Sign On Screen
       * and pass the callback where the user will go after signing on again.
       */
      window.location.replace(process.env.REACT_APP_LOGIN_URL_SSO + `/sign-out/?callback=${encodeURIComponent(callback)}`);
    }
  }, [loggedIn, searchParams]);

  return (
    <>
      <div>
        <p style={{ textAlign: 'center', padding: '1rem' }}></p>
      </div>
    </>
  );
};

export default DefaultPage;
