import {
  CCol,
  CContainer,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';

import api from 'src/api';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { UploadBox, UploadBoxProvider, UploadBoxContext } from 'react-upload-component';
import LButton from '../../components/LButton/LButton';

/**
 * This is the main content but we have to make it a
 * child because we need to be able to access the data from UploadBoxPovider
 * @return {React.ReactElement}
 */
const Content = () => {
  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);
  const [expression, setExpression] = useState('');
  const [details, setDetails] = useState({});
  const [artRequestId, setArtRequestId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { files } = useContext(UploadBoxContext);

  const fetchItems = useCallback(() => {
    const controller = new AbortController();
    api.get('/art-request', { signal: controller.signal }).then(({ data }) => {
      setItems(data);
    });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const setProductDetails = (id) => {
    items.some((item) => {
      const isItem = item.product_id === id;
      if (isItem) {
        setExpression(item.computed_expression);
        setDetails(item.standard_extra_fields || {});
      }
      return isItem;
    });
  };

  const submitFiles = async () => {
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach((file) => {
        formData.append('art', file);
      });
      setIsLoading(true);
      api
        .post('/art-submission/' + artRequestId, formData)
        .then(() => {
          setSubmitVisible(false);
          fetchItems();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      alert('Select at least one file.');
    }
  };

  return (
    <CContainer>
      <CRow>
        <CCol>
          <CCard className="mb-4">
            <CCardHeader>
              <CCol sm={5}>
                <strong>SALES</strong> <small>CPOs</small>
              </CCol>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <h4 style={{ padding: '0.5rem' }}>Pending Submissions</h4>
                <div className="table-container">
                  <Table>
                    <Thead>
                      <Tr>
                        <Th scope="col">Expresion Name</Th>
                        <Th scope="col">Submissions</Th>
                        <Th scope="col">Status of last submission</Th>
                        <Th scope="col">Commentaries</Th>
                        <Th scope="col">Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {items.map((item, idx) => {
                        // const extra = item.standard_extra_fields;
                        return (
                          <Tr key={`item-${idx}`}>
                            <Th>{item.computed_expression}</Th>
                            <Th>
                              You have sent {item.amount_of_submissions} submission
                              {`${item.amount_of_submissions > 1 ? 's' : ''}`}
                            </Th>
                            <Th>
                              {item.approved === null ? (
                                <span> {parseInt(item.amount_of_submissions) > 0 ? 'Pending...' : ''}</span>
                              ) : parseInt(item.approved) === 1 ? (
                                <span style={{ color: 'green' }}>Approved</span>
                              ) : (
                                <span style={{ color: 'red' }}>Disapproved</span>
                              )}
                            </Th>
                            <Th>
                              <p>{item.comentaries}</p>
                            </Th>
                            <Td>
                              <CButton
                                color="success"
                                style={{
                                  marginTop: '1rem',
                                  marginRight: '1rem',
                                  color: 'white',
                                }}
                                onClick={() => {
                                  setProductDetails(item.product_id);
                                  setVisible(true);
                                }}
                              >
                                Details
                              </CButton>
                              {parseInt(item.approved) === 1 ? (
                                ''
                              ) : (
                                <CButton
                                  onClick={() => {
                                    setProductDetails(item.product_id);
                                    setSubmitVisible(true);
                                    setArtRequestId(item.art_request_id);
                                  }}
                                  style={{ marginTop: '1rem' }}
                                  disabled={item.approved === null && parseInt(item.amount_of_submissions) > 0}
                                >
                                  Submit
                                </CButton>
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </div>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CModal visible={visible} onClose={() => setVisible(false)} size="xl">
            <CModalHeader onClose={() => setVisible(false)}>
              <CModalTitle>Expression Details:</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <h4 style={{ padding: '0.5rem' }}>{expression}</h4>
              <div className="table-container">
                <Table>
                  <Thead>
                    <Tr>
                      <Th scope="col">Item</Th>
                      <Th scope="col">Value</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Th scope="row">Classe</Th>
                      <Td> {details.CLASSE} </Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Finca</Th>
                      <Td>{details.FINCA}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Cosecha:</Th>
                      <Td colSpan="2">{details.COSECHA}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Tierra:</Th>
                      <Td colSpan="2">{details.TIERRA}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Pueblo:</Th>
                      <Td colSpan="2">{details.PUEBLO}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Horno:</Th>
                      <Td colSpan="2">{details.HORNO}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Leña:</Th>
                      <Td colSpan="2">{details.LEÑA}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Duración de Horneado:</Th>
                      <Td colSpan="2">{details.DURACIÓN}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Molino:</Th>
                      <Td colSpan="2">{details.MOLINO}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Fermentación:</Th>
                      <Td colSpan="2">{details.FERMENTACIÓN}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Fuente de agua:</Th>
                      <Td colSpan="2">{details.FUENTE}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Destilación:</Th>
                      <Td colSpan="2">{details.DESTILADOR}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Ajustes:</Th>
                      <Td colSpan="2">{details.AJUSTES}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton color="danger" style={{ color: '#FFF' }} onClick={() => setVisible(false)}>
                Close
              </CButton>
            </CModalFooter>
          </CModal>
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <CModal visible={submitVisible} onClose={() => setSubmitVisible(false)} size="xl">
            <CModalHeader onClose={() => setSubmitVisible(false)}>
              <CModalTitle>Submit:</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <h4>{expression}</h4>
              <UploadBox />
            </CModalBody>
            <CModalFooter>
              <CButton
                color="danger"
                style={{ color: '#FFF', marginRight: '1rem' }}
                onClick={() => setSubmitVisible(false)}
              >
                Cancel
              </CButton>
              <LButton isLoading={isLoading} color="primary" style={{}} onClick={submitFiles}>
                Submit
              </LButton>
            </CModalFooter>
          </CModal>
        </CCol>
      </CRow>
    </CContainer>
  );
};

// This is the page that gets rendered.
const ArtSubmissions = () => {
  return (
    <>
      <UploadBoxProvider>
        <Content />
      </UploadBoxProvider>
    </>
  );
};

export default ArtSubmissions;
