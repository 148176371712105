import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  CContainer,
  CRow,
  CCol,
  CModal,
  CModalHeader,
  CModalTitle,
  CForm,
  CFormLabel,
  CFormInput,
  CModalBody,
  CModalFooter,
  CButton,
} from '@coreui/react';

import api from 'src/api';
import {setSuccessMessages} from 'src/actions';
import {useDispatch} from 'react-redux';

const ChangePassword = (props) => {
  useEffect(()=>{
    if (!props.visible) {
      setCurrentPwd('');
      setPwd('');
      setConfirmPwd('');
    }
  }, [props.visible]);

  const [currentPwd, setCurrentPwd] = useState('');
  const [pwd, setPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');

  const dispatch = useDispatch();

  const updatePwd = () => {
    let patchRoute = '/user/password';

    if (props.id) {
      // To update other user's password.
      patchRoute += `/${props.id}`;
    }

    api
        .patch(patchRoute, {
          old_password: currentPwd,
          password: pwd,
          confirm_password: confirmPwd,
        })
        .then(() => {
          dispatch(setSuccessMessages([
            {
              message: 'The password has been updated.',
            },
          ]));
          props.closeModal();
          setCurrentPwd('');
          setPwd('');
          setConfirmPwd('');
          props.onPasswordSet(props.id || null);
        }).catch(()=>{});
  };

  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CModal visible={props.visible}>
              <CModalHeader>
                <CModalTitle>{props.hasPassword ? 'Change password' : 'Set new password'}</CModalTitle>
              </CModalHeader>
              <CModalBody>
                <CForm>
                  {props.hasPassword ? (<div className="mb-3">
                    <CFormLabel>Current password</CFormLabel>
                    <CFormInput
                      placeholder="Current password"
                      type="password"
                      onChange={(e) => {
                        setCurrentPwd(e.target.value);
                      }}
                    ></CFormInput>
                  </div>) : null}
                  <CRow>
                    <CCol xs={12} sm={12} md={6} className="mb-3">
                      <CFormLabel>New Password</CFormLabel>
                      <CFormInput
                        placeholder="New password"
                        type="password"
                        onChange={(e) => {
                          setPwd(e.target.value);
                        }}
                      ></CFormInput>
                    </CCol>
                    <CCol xs={12} sm={12} md={6} className="mb-3">
                      <CFormLabel>Confirm your new password</CFormLabel>
                      <CFormInput
                        placeholder="Confirm your new password"
                        type="password"
                        onChange={(e) => {
                          setConfirmPwd(e.target.value);
                        }}
                      ></CFormInput>
                    </CCol>
                  </CRow>
                </CForm>
              </CModalBody>
              <CModalFooter>
                <CButton
                  color="danger"
                  style={{color: 'white'}}
                  onClick={() => {
                    props.closeModal();
                  }}
                >
                  Cancel
                </CButton>
                <CButton
                  color="primary"
                  onClick={() => {
                    updatePwd();
                  }}
                >
                  Update
                </CButton>
              </CModalFooter>
            </CModal>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

ChangePassword.defaultTypes = {
  hasPassword: true,
  onPasswordSet: ()=>console.log('Password set'),
};

ChangePassword.propTypes = {
  id: PropTypes.number,
  visible: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  hasPassword: PropTypes.bool,
  onPasswordSet: PropTypes.func,
};

export default ChangePassword;
