import {useState, useEffect} from 'react';
import {debounce} from 'lodash';

export default function useWindowSize() {
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  const [windowSize, setWindowSize] = useState(getSize);
  useEffect(() => {
    // Debounce to avoid the function fire multiple times
    const handleResizeDebounced = debounce(function handleResize() {
      setWindowSize(getSize());
    }, 500);
    window.addEventListener('resize', handleResizeDebounced);
    return () => window.removeEventListener('resize', handleResizeDebounced);
  }, []);
  return windowSize;
}
