import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CCol,
  CRow,
  CForm,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CButton,
  CFormTextarea,
} from '@coreui/react';
import { useState } from 'react';
import api from 'src/api';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';

const NewPurchaseFormDiv = (props) => {
  const dispatch = useDispatch();
  // const [productDetails, setProductDetails] = useState([]);
  const [standardExtraFields, setStandardExtraFields] = useState([]);
  const [computedExpression, setComputedExpression] = useState('');
  const [productId, setProductId] = useState('');

  useEffect(() => {
    function fetchSelectedProduct(makerId, commonNameId) {
      api
        .get(`/product/details/by-mc/${makerId}/${commonNameId}`)
        .then(({ data }) => {
          if (data && data.length) {
            setComputedExpression(data[0].full_expression_name);
            setProductId(data[0].product_id);
            // setProductDetails(data[0]);
            if (data.length && data[0] && data[0].standard_extra_fields) {
              setStandardExtraFields(data[0].standard_extra_fields);
            }
          } else {
            dispatch(
              setErrors([
                {
                  message: 'No details found for that product.',
                },
              ]),
            );
          }
        });
    }

    fetchSelectedProduct(props.chosenMaker, props.chosenMaguey);
  }, [props.chosenMaker, props.chosenMaguey]);

  function handleNewSubmit(e) {
    e.preventDefault();
    if (!e.target.elements.lotNumberInput.value) {
      alert('Lot Number must be inserted');
      return;
    }

    if (!e.target.elements.litersInput) {
      alert('Liters must be inserted');
      return;
    }

    if (!e.target.elements.priceInput.value) {
      alert('Price per Liter must be inserted');
      return;
    }

    const extraFields = {
      GRADOS: e.target.elements.abvInput.value,
      FINCA: e.target.elements.fincaInput.value,
      ELEVACIÓN: e.target.elements.eleInput.value,
      COSECHA: e.target.elements.cosInput.value,
      TIERRA: e.target.elements.tierraInput.value,
      PUEBLO: e.target.elements.pueInput.value,
      HORNO: e.target.elements.hornoInput.value,
      LEÑA: e.target.elements.lenaInput.value,
      DURACIÓN: e.target.elements.durInput.value,
      MOLINO: e.target.elements.molInput.value,
      FERMENTACIÓN: e.target.elements.ferInput.value,
      TIPO: e.target.elements.tinaInput.value,
      FUENTE: e.target.elements.aguaInput.value,
      DESTILADOR: e.target.elements.desInput.value,
      NUMERO: e.target.elements.numInput.value,
      CLASSE: e.target.elements.classeInput.value,
      AJUSTES: e.target.elements.ajuInput.value,
      NOTAS: e.target.elements.notasInput.value,
    };

    api
      .post(`/lotes/purchases/new/${productId}`, {
        lot_number: e.target.elements.lotNumberInput.value,
        liters_purchased: e.target.elements.litersInput.value,
        price_per_liter: e.target.elements.priceInput.value,
        classificacion: e.target.elements.classificacionSelect.value,
        date_purchased: new Date(),
        lot_extra_field: extraFields,
      })
      .then(() => {
        props.fetchPurchases();
      })
      .finally(() => {
        props.setNewVisible(false);
      });
  }

  return (
    <CForm style={{ margin: '10px' }} onSubmit={handleNewSubmit}>
      <CRow className="mb-2">
        <CCol xs={8}>
          <CFormLabel className="mb-1" htmlFor="expressionInput">
            Expression Name
          </CFormLabel>
          <CFormInput
            type="text"
            id="expressionInput"
            value={computedExpression}
            size="sm"
            readOnly
          />
        </CCol>
      </CRow>
      <CRow className="mb-2">
        <CCol>
          <CFormLabel
            className="mb-1"
            htmlFor="lotNumberInput"
            style={{ color: 'blue' }}
          >
            Lot Number
          </CFormLabel>
          <CFormInput type="text" id="lotNumberInput" />
        </CCol>
        <CCol>
          <CFormLabel
            className="mb-1"
            htmlFor="litersInput"
            style={{ color: 'blue' }}
          >
            Liters
          </CFormLabel>
          <CFormInput type="text" id="litersInput" />
        </CCol>
        <CCol>
          <CFormLabel
            className="mb-1"
            htmlFor="priceInput"
            style={{ color: 'blue' }}
          >
            MXP$/Ltr
          </CFormLabel>
          <CFormInput type="text" id="priceInput" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="classificacionInput">
            Classificacíon
          </CFormLabel>
          <CFormSelect id="classificacionSelect" size="sm">
            <option value="Artesanal">Artesanal</option>
            <option value="Ancestral">Ancestral</option>
            <option value="Hybrido Destilado de Agave">
              Hybrido Destilado de Agave
            </option>
            <option value="obre Destilado de Agave">
              Cobre Destilado de Agave
            </option>
            <option value="Barro Destilado de Agave">
              Barro Destilado de Agave
            </option>
          </CFormSelect>
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="abvInput">
            ABV
          </CFormLabel>
          <CFormInput
            type="text"
            id="abvInput"
            defaultValue={standardExtraFields['GRADOS']}
            size="sm"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="fincaInput">
            Finca
          </CFormLabel>
          <CFormInput
            type="text"
            id="fincaInput"
            defaultValue={standardExtraFields['FINCA']}
            size="sm"
          />
        </CCol>
        <CCol>
          <CFormLabel className="mb-2" htmlFor="eleInput">
            Elevación
          </CFormLabel>
          <CFormInput
            type="text"
            id="eleInput"
            defaultValue={standardExtraFields['ELEVACIÓN']}
            size="sm"
          />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="cosInput">
            Cosecha
          </CFormLabel>
          <CFormInput
            type="text"
            id="cosInput"
            defaultValue={standardExtraFields['COSECHA']}
            size="sm"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="tierraInput">
            Tierra
          </CFormLabel>
          <CFormInput
            type="text"
            id="tierraInput"
            defaultValue={standardExtraFields['TIERRA']}
            size="sm"
          />
        </CCol>
        <CCol>
          <CFormLabel className="mb-2" htmlFor="pueInput">
            Pueblo
          </CFormLabel>
          <CFormInput
            type="text"
            id="pueInput"
            defaultValue={standardExtraFields['PUEBLO']}
            size="sm"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="hornoInput">
            Horno
          </CFormLabel>
          <CFormInput
            type="text"
            id="hornoInput"
            defaultValue={standardExtraFields['HORNO']}
            size="sm"
          />
        </CCol>
        <CCol>
          <CFormLabel className="mb-2" htmlFor="lenaInput">
            Leña
          </CFormLabel>
          <CFormInput
            type="text"
            id="lenaInput"
            defaultValue={standardExtraFields['LEÑA']}
            size="sm"
          />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="durInput">
            Duración de Horneado
          </CFormLabel>
          <CFormInput
            type="text"
            id="durInput"
            defaultValue={standardExtraFields['DURACIÓN']}
            size="sm"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="molInput">
            Molino
          </CFormLabel>
          <CFormInput
            type="text"
            id="molInput"
            defaultValue={standardExtraFields['MOLINO']}
            size="sm"
          />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="ferInput">
            Fermentación
          </CFormLabel>
          <CFormInput
            type="text"
            id="ferInput"
            defaultValue={standardExtraFields['FERMENTACIÓN']}
            size="sm"
          />
        </CCol>
        <CCol>
          <CFormLabel className="mb-2" htmlFor="tinaInput">
            Tipo de Tina
          </CFormLabel>
          <CFormInput
            type="text"
            id="tinaInput"
            defaultValue={standardExtraFields['TIPO']}
            size="sm"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="aguaInput">
            Fuente de Agua
          </CFormLabel>
          <CFormInput
            type="text"
            id="aguaInput"
            defaultValue={standardExtraFields['FUENTE']}
            size="sm"
          />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="desInput">
            Tipo de Destilador
          </CFormLabel>
          <CFormInput
            type="text"
            id="desInput"
            defaultValue={standardExtraFields['DESTILADOR']}
            size="sm"
          />
        </CCol>
        <CCol>
          <CFormLabel className="mb-2" htmlFor="numInput">
            Numero de Destilaciones
          </CFormLabel>
          <CFormInput
            type="text"
            id="numInput"
            defaultValue={standardExtraFields['NUMERO']}
            size="sm"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="classeInput">
            Classe
          </CFormLabel>
          <CFormInput
            type="text"
            id="classeInput"
            defaultValue={standardExtraFields['CLASSE']}
            size="sm"
          />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="ajuInput">
            Ajustes
          </CFormLabel>
          <CFormInput
            type="text"
            id="ajuInput"
            defaultValue={standardExtraFields['AJUSTES']}
            size="sm"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="notasInput">
            Notas
          </CFormLabel>
          <CFormTextarea
            id="notasInput"
            defaultValue={standardExtraFields['NOTAS']}
            rows="2"
          />
        </CCol>
      </CRow>
      <div style={{ marginTop: '5px' }}>
        <CButton type="submit" color="primary">
          Add New
        </CButton>
        <CButton
          color="danger"
          onClick={() => {
            props.setNewVisible(false);
          }}
          style={{ marginLeft: '10px' }}
        >
          Close
        </CButton>
      </div>
    </CForm>
  );
};

NewPurchaseFormDiv.propTypes = {
  chosenMaker: PropTypes.any,
  chosenMaguey: PropTypes.any,
  fetchPurchases: PropTypes.any,
  setNewVisible: PropTypes.any,
  setNewPurchaseFormVisible: PropTypes.any,
};

export default NewPurchaseFormDiv;
