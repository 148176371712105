import { useEffect, useState } from 'react';

import { CRow, CCol, CCard, CCardHeader, CCardBody, CListGroup, CListGroupItem, CButton } from '@coreui/react';
import api from 'src/api';
import Loading from 'src/components/Loading';
import CIcon from '@coreui/icons-react';
import { cilShieldAlt } from '@coreui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

function Security({ setVisiblePwdModal }) {
  const [isLoading, setIsLoading] = useState(true);
  const [info, setInfo] = useState({
    sessions: 1,
    lastPasswordChange: new Date(),
    lastLogin: new Date(),
    devices: 1,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);
    api
      .get('/user/security-info')
      .then(({ data }) => {
        setInfo(data);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <CRow>
        <CCol md={12}>
          <CCard className="my-3">
            <CCardHeader>
              <h4>
                <CIcon icon={cilShieldAlt} color="success" size="lg" /> Account Security
              </h4>
            </CCardHeader>
            <CCardBody>
              {isLoading ? (
                <Loading />
              ) : (
                <CListGroup>
                  <CListGroupItem className="d-flex justify-content-between align-items-center;">
                    You have {info.sessions} sessions opened.
                    <CButton
                      style={{ minWidth: '100px' }}
                      color="primary"
                      variant="outline"
                      onClick={() => {
                        navigate('/users/sessions-review');
                      }}
                    >
                      Review
                    </CButton>
                  </CListGroupItem>
                  <CListGroupItem className="d-flex justify-content-between align-items-center;">
                    Your password was last changed on {moment(info.lastPasswordChange).format('ddd DD-MMM-YYYY')} at{' '}
                    {moment(info.lastPasswordChange).format('hh:mm A')}
                    <CButton
                      style={{ minWidth: '100px' }}
                      color="primary"
                      variant="outline"
                      onClick={() => {
                        setVisiblePwdModal(true);
                      }}
                    >
                      Change
                    </CButton>
                  </CListGroupItem>
                  <CListGroupItem className="d-flex justify-content-between align-items-center;">
                    Login History
                    <CButton
                      style={{ minWidth: '100px' }}
                      color="primary"
                      variant="outline"
                      onClick={() => {
                        navigate('/users/login-history');
                      }}
                    >
                      Review
                    </CButton>
                  </CListGroupItem>
                </CListGroup>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
}

Security.propTypes = {
  setVisiblePwdModal: PropTypes.func.isRequired,
};

export default Security;
