import { combineReducers } from 'redux';
import sidebarShow from './sidebarShowReducer';
import unfoldable from './unfoldableReducer';
import authReducer from './authReducer';
import errorsReducer from './errorsReducer';
import successMessagesReducer from './successMessagesReducer';
import currencyReducer from './currencyReducer';
import orderReducer from './orderReducer';
import logoutRReducer from './logoutRReducer';

const combinedReducer = combineReducers({
  sidebarShow,
  unfoldable,
  auth: authReducer,
  errorsReducer,
  successMessagesReducer,
  currency: currencyReducer,
  order: orderReducer,
  loggedOut: logoutRReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
