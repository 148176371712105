import { matchRoutes, useLocation } from 'react-router-dom';
import routes from '../routes';
import { useMemo } from 'react';

const useCurrentPath = () => {
  /*
        Some routes are nested (Check App.js), an exaple is /dashboard, which has children from routes (depends on ALLOWED property).
        We need to get a full list of all routes to be able to use matchRoutes and get accurate results.
        If you ever add routes that have children please update this code. (Please keep in mind that routes can or cannot be
        protected according to the role of a user.)
        We are also using useMemo hooks because we only need to calculate the value once.
    */

  const pureRoutes = useMemo(() => {
    const cleanRoutes = [];
    routes.map((route) => {
      if (Array.isArray(route.children) && route.children.length > 0) {
        route.children.forEach((child) => {
          cleanRoutes.push({
            path: `${route.path}${route.path === '/' || route.path === '*' ? '' : '/'}${child.path}`,
            allowed: child.allowed || [],
          });
        });
      } else {
        cleanRoutes.push({
          path: route.path,
          allowed: route.allowed || [],
        });
      }
    });

    return cleanRoutes;
  });

  const location = useLocation();
  const result = matchRoutes(pureRoutes, location);
  /**
   * matchRoutes can return more than one route if we are inside a child route.
   * An example would be in the case we have a dashboard page and a sub-page inside the dashboard.
   * /dashboard/  (Route #1 - The main dashboard)
   * /dashboard/inventory-by-expression (Route #2)
   *
   * In this case we want to get the last item in the array (If you are having troubles with redirection you
   * may want to check the order of the routes in routes.js)
   */
  if (result.length > 0) {
    return result[0].route;
  } else {
    // No route fond
    return '';
  }
};

export default useCurrentPath;
