import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {CModalBody} from '@coreui/react';
import {useState} from 'react';
import MakerSelectDiv from './maker-select-div';
import MagueySelectDiv from './maguey-select-div';
import NewExpressionFormDiv from './new-expression-form-div';
import api from 'src/api';

const ExpressionNewModal = (props) => {
  const [makersSelectVisible, setMakersSelectVisible] = useState(true);
  const [magueysSelectVisible, setMagueysSelectVisible] = useState(false);
  const [newExpressionFormVisible, setNewExpressionFormVisible] =
    useState(false);
  const [chosenMaker, setChosenMaker] = useState();
  const [chosenMaguey, setChosenMaguey] = useState(false);

  const [magueys, setMagueys] = useState();

  useEffect(() => {
    const controller = new AbortController();

    api
        .get('/catalog/items/maguey-name', {signal: controller.signal})
        .then(({data}) => {
          setMagueys(data);
        });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <CModalBody key="modal1">
      <div id="makerSelectDiv">
        {makersSelectVisible ? (
          <MakerSelectDiv
            setMakersSelectVisible={setMakersSelectVisible}
            setMagueysSelectVisible={setMagueysSelectVisible}
            setChosenMaker={setChosenMaker}
          />
        ) : null}
      </div>
      <div id="magueySelectDiv">
        {magueysSelectVisible ? (
          <MagueySelectDiv
            setMagueysSelectVisible={setMagueysSelectVisible}
            setNewExpressionFormVisible={setNewExpressionFormVisible}
            chosenMaker={chosenMaker}
            setChosenMaguey={setChosenMaguey}
            magueys={magueys}
          />
        ) : null}
      </div>
      <div id="newExpressionFormDiv">
        {newExpressionFormVisible ? (
          <NewExpressionFormDiv
            setNewExpressionFormVisible={setNewExpressionFormVisible}
            chosenMaker={chosenMaker}
            chosenMaguey={chosenMaguey}
            fetchExpressions={props.fetchExpressions}
            setNewVisible={props.setNewVisible}
          />
        ) : null}
      </div>
      {/* <div
        id="newExpressionFormDiv"
        style={{ display: newExpressionFormVisible ? 'block' : 'none' }}
      >
        <NewExpressionFormVisible />
      </div>*/}
    </CModalBody>
  );
};

ExpressionNewModal.propTypes = {
  fetchExpressions: PropTypes.any,
  setNewVisible: PropTypes.any,
};

export default ExpressionNewModal;
