import PropTypes from 'prop-types';


const TheIcon = ({name, size, className}) => {
  return (<img src={`/icons/${name}.svg`} width={size} height={size} className={className} data-testid="the-icon" />);
};

TheIcon.defaultProps = {
  size: 20,
  className: 'me-2',
};

TheIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default TheIcon;
