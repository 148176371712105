import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import '../scss/dynamic-table.scss';

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(null);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (window.innerWidth < 576) {
        setScreenSize('xs');
      } else if (window.innerWidth < 768) {
        setScreenSize('sm');
      } else if (window.innerWidth < 992) {
        setScreenSize('md');
      } else if (window.innerWidth < 1200) {
        setScreenSize('lg');
      } else if (window.innerWidth < 1400) {
        setScreenSize('xl');
      } else {
        setScreenSize('xxl');
      }
    };
    updateSize(); // update for the first time
    window.addEventListener('resize', debounce(updateSize, 400));
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return screenSize;
};

export default useScreenSize;
