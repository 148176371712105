import React from 'react';
import {useDispatch} from 'react-redux';
import {CContainer, CHeader, CHeaderNav, CHeaderToggler} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {cilMenu} from '@coreui/icons';

import {AppHeaderDropdown} from './header/index';
import {toggleSidebarShow} from '../actions';

const AppHeader = () => {
  const dispatch = useDispatch();

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={() => dispatch(toggleSidebarShow())}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
