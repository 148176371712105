import React from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CModalHeader,
  CNav,
  CNavItem,
  CNavLink,
  CButton,
  CContainer,
} from '@coreui/react';
import { useState, useEffect } from 'react';
import ModalPurchaseDetails from './purchase-details-modal';
import PurchaseNewModal from './purchase-new-modal';
import api from 'src/api';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import ReceivePurchase from '../ReceivePurchase';
import { Helmet } from 'react-helmet';

const Purchases = () => {
  const [activeKey, setActiveKey] = useState(1);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [openPurchasesList, setOpenPurchasesList] = useState([]);
  const [closedPurchasesList, setClosedPurchasesList] = useState([]);
  const [detailPurchase, setDetailPurchase] = useState([]);
  const [newVisible, setNewVisible] = useState(false);
  const [receivePurchase, setReceivePurchase] = useState([]);
  const [receiveVisible, setReceiveVisible] = useState(false);
  function fetchPurchases() {
    let openPurchases = [];
    let closedPurchases = [];

    api.get('/lotes/lot-details').then(({ data }) => {
      openPurchases = data.filter((item) => {
        return item.date_received == null;
      });
      closedPurchases = data.filter((item) => {
        return item.date_received != null;
      });
      setOpenPurchasesList(openPurchases);
      setClosedPurchasesList(closedPurchases);
    });
  }

  useEffect(() => {
    fetchPurchases();
  }, []);

  function adjustReceiveModal(purchase) {
    setReceivePurchase(purchase);
    setReceiveVisible(true);
  }

  function adjustViewModal(purchase) {
    setDetailPurchase(purchase);
    setDetailsVisible(true);
  }

  function ClosedPurchaseRow(props) {
    const datePurchased = new Date(props.purchase.date_purchased);
    let month = datePurchased.getMonth() + 1;
    let year = datePurchased.getFullYear();
    const datePurchasedFormatted =
      month + '/' + datePurchased.getDate() + '/' + year;

    const dateReceived = new Date(props.purchase.date_received);
    month = dateReceived.getMonth() + 1;
    year = dateReceived.getFullYear();
    const dateReceivedFormatted =
      month + '/' + dateReceived.getDate() + '/' + year;

    return (
      <Tr
        style={{ cursor: 'pointer', fontSize: '0.85em' }}
        onClick={() => {
          adjustViewModal(props.purchase);
        }}
        key={props.cp}
      >
        <Th scope="row">{props.purchase.lot_number}</Th>
        <Td>{props.purchase.expression}</Td>
        <Td>{props.purchase.liters}</Td>
        <Td>{datePurchasedFormatted}</Td>
        <Td>{dateReceivedFormatted}</Td>
      </Tr>
    );
  }

  function ClosedPurchasesList(props) {
    const purs = props.closedPurchases;
    let cp = -1;
    const list = purs.map((purchase) => {
      cp++;
      return (
        <ClosedPurchaseRow purchase={purchase} cp={cp} key={`pu-row-${cp}`} />
      );
    });
    return list;
  }

  function OpenPurchaseRow(props) {
    const datePurchased = new Date(props.purchase.date_purchased);
    const month = datePurchased.getMonth() + 1;
    const year = datePurchased.getFullYear();
    const datePurchasedFormatted =
      month + '/' + datePurchased.getDate() + '/' + year;
    return (
      <Tr
        style={{ cursor: 'pointer', fontSize: '0.85em' }}
        onClick={(e) => {
          adjustViewModal(props.purchase);
        }}
        key={props.cp}
      >
        <Th scope="row">{props.purchase.lot_number}</Th>
        <Td>{props.purchase.expression}</Td>
        <Td>{props.purchase.liters}</Td>
        <Td>{datePurchasedFormatted}</Td>
        <Td>
          <CButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              adjustReceiveModal(props.purchase);
            }}
          >
            Receive
          </CButton>
        </Td>
      </Tr>
    );
  }

  function OpenPurchasesList(props) {
    const purs = props.openPurchases;
    let op = -1;
    const list = purs.map((purchase) => {
      op++;
      return <OpenPurchaseRow purchase={purchase} op={op} key={`opr-${op}`} />;
    });
    return list;
  }

  return (
    <CContainer>
      <Helmet>
        <title>Purchases</title>
      </Helmet>
      {/* <ModalReceive purchase={receivePurchase} />*/}
      <ReceivePurchase
        onReceive={fetchPurchases}
        purchase={receivePurchase}
        visible={receiveVisible}
        setReceiveVisible={setReceiveVisible}
      />

      <CModal
        key="newModal"
        visible={newVisible}
        onClose={() => setNewVisible(false)}
        size="lg"
        backdrop={`static`}
      >
        <CModalHeader>
          <CCol sm={5}>
            <strong>PURCHASE</strong> <small>New</small>
          </CCol>
        </CModalHeader>
        <PurchaseNewModal
          setNewVisible={setNewVisible}
          fetchPurchases={fetchPurchases}
        />
      </CModal>
      <CModal
        key="viewDetailsModal"
        visible={detailsVisible}
        size="lg"
        backdrop={`static`}
        onClose={() => {
          setDetailsVisible(false);
        }}
      >
        <CModalHeader>
          <CCol sm={5}>
            <strong>PURCHASE</strong> <small>Info</small>
          </CCol>
        </CModalHeader>
        <ModalPurchaseDetails
          setVisible={setDetailsVisible}
          purchase={detailPurchase}
          onFinish={() => {
            fetchPurchases();
          }}
        />
      </CModal>
      <CCard className="mb-4">
        <CCardHeader>
          <CCol sm={5}>
            <strong>LOTES</strong> <small>Purchases</small>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol>
              <CButton
                style={{ marginBottom: '15px' }}
                onClick={() => {
                  setNewVisible(true);
                }}
              >
                New Purchase
              </CButton>
            </CCol>
          </CRow>
          <CNav variant="tabs" role="tablist">
            <CNavItem>
              <CNavLink
                href=""
                active={activeKey === 1}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveKey(1);
                }}
              >
                Open
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                href=""
                active={activeKey === 2}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveKey(2);
                }}
              >
                Received
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane
              role="tabpanel"
              aria-labelledby="home-tab"
              visible={activeKey === 1}
            >
              <CRow>
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardBody>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th scope="col">Lot</Th>
                            <Th scope="col">Expression</Th>
                            <Th scope="col">Liters</Th>
                            <Th scope="col">Date Purchased</Th>
                            <Th scope="col">Receive</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <OpenPurchasesList
                            openPurchases={openPurchasesList}
                          />
                        </Tbody>
                      </Table>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="home-tab"
              visible={activeKey === 2}
            >
              <CRow>
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardBody>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th scope="col">Lot</Th>
                            <Th scope="col">Expression</Th>
                            <Th scope="col">Liters</Th>
                            <Th scope="col">Date Purchased</Th>
                            <Th scope="col">Data Received</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <ClosedPurchasesList
                            closedPurchases={closedPurchasesList}
                          />
                        </Tbody>
                      </Table>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </CContainer>
  );
};

Purchases.defaultProps = {
  expressions: [],
};

Purchases.propTypes = {
  purchase: PropTypes.any,
  op: PropTypes.number,
  cp: PropTypes.number,
  closedPurchases: PropTypes.any,
  openPurchases: PropTypes.any,
};

export default Purchases;
