import React from 'react';
import PropTypes from 'prop-types';
import { CCol, CRow, CModal, CModalHeader, CModalBody, CForm, CFormLabel, CFormInput, CFormCheck, CButton, CModalTitle, CFormTextarea } from '@coreui/react';
import { useState, useEffect, useCallback } from 'react';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import api from '../../api';
import FilterableSelect from 'src/components/FilterableSelect/FilterableSelect';

const ExpressionEditModal = (props) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [expressionName, setExpressionName] = useState('');
  const [magueys, setMagueys] = useState([]);
  const [chosenMaguey, setChosenMaguey] = useState(null);
  const [sku, setSKU] = useState('');
  const [abv, setABV] = useState('');
  const [mlBtl, setMlBtl] = useState('');
  const [elevation, setEleveation] = useState('');
  const [finca, setFinca] = useState('');
  const [cosecha, setCosecha] = useState('');
  const [tierra, setTierra] = useState('');
  const [pueblo, setPueblo] = useState('');
  const [horno, setHorno] = useState('');
  const [lenia, setLenia] = useState('');
  const [duracionHorneado, setDuracionHorneado] = useState('');
  const [molino, setMolino] = useState('');
  const [fermentacion, setFermentacion] = useState('');
  const [tipoDeTina, setTipoDeTina] = useState('');
  const [fuenteAgua, setFuenteAgua] = useState('');
  const [tipoDestilador, setTipoDestilador] = useState('');
  const [numeroDeDestil, setNumeroDeDestil] = useState('');
  const [classe, setClasse] = useState('');
  const [ajustes, setAjustes] = useState('');
  const [notas, setNotas] = useState('');
  const [productType, setProductType] = useState('');
  // const [makerName, setMakerName] = useState('');
  const [blockSKU, setBlockSKU] = useState(false);
  const [expressionDescription, setExpressionDescription] = useState('');
  const [makerId, setMakerId] = useState(null);
  const [suggestedName, setSuggestedName] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    api.get('/catalog/items/maguey-name', { signal: controller.signal }).then(({ data }) => {
      setMagueys(data);
    });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (chosenMaguey != null && makerId != null) {
      let name = '';

      if (Array.isArray(props.makers)) {
        const maker = props.makers.filter((maker) => maker.id === makerId);
        if (maker.length > 0) {
          name += `${maker[0].maker_name} `;
        }
      }

      const maguey = magueys.filter((maguey) => {
        return maguey.id === chosenMaguey;
      });

      if (maguey.length > 0) {
        name += `${maguey[0].common_name}`;
      }

      setSuggestedName(name);
    }

    return () => {
      setSuggestedName('');
    };
  }, [chosenMaguey, makerId]);

  useEffect(() => {
    const ex = props.expression;
    setExpressionName(ex.full_expression_name || '');
    setSKU(ex.sku);
    setMlBtl(ex.ml_btl);
    setProductType(ex.product_type);
    setChosenMaguey(ex.common_name_id);
    // setMakerName(ex.maker_name);
    setMakerId(ex.maker_id);
    if (typeof ex.standard_extra_fields) {
      const st = ex.standard_extra_fields;
      setABV(st.GRADOS);
      setEleveation(st.ELEVACIÓN);
      setFinca(st.FINCA);
      setCosecha(st.COSECHA);
      setTierra(st.TIERRA);
      setPueblo(st.PUEBLO);
      setHorno(st.HORNO);
      setLenia(st.LEÑA);
      setDuracionHorneado(st.DURACIÓN);
      setMolino(st.MOLINO);
      setFermentacion(st.FERMENTACIÓN);
      setTipoDeTina(st.TIPO);
      setFuenteAgua(st.FUENTE);
      setTipoDestilador(st.DESTILADOR);
      setNumeroDeDestil(st.NUMERO);
      setClasse(st.CLASSE);
      setAjustes(st.AJUSTES);
      setNotas(st.NOTAS);
    }
    setExpressionDescription(ex.description || '');
  }, [props.expression]);

  const deleteExpression = useCallback((productId, blockSku) => {
    api
      .delete(`/product/${productId}/${blockSku}`)
      .then(() => {
        props.fetchExpressions();
      })
      .catch((e) => {
        console.log('Failed to delete', e);
      })
      .finally(() => {
        props.setVisible(false);
      });
  }, []);

  function handleSaveSubmit(e) {
    e.preventDefault();
    const extraFields = {
      GRADOS: abv,
      FINCA: finca,
      ELEVACIÓN: elevation,
      COSECHA: cosecha,
      TIERRA: tierra,
      PUEBLO: pueblo,
      HORNO: horno,
      LEÑA: lenia,
      DURACIÓN: duracionHorneado, // Duración de horneado
      MOLINO: molino,
      FERMENTACIÓN: fermentacion,
      TIPO: tipoDeTina, // Tipo de tina
      FUENTE: fuenteAgua, // Fuente de agua
      DESTILADOR: tipoDestilador, // Tipo de destilador
      NUMERO: numeroDeDestil, // Número de destilaciones
      CLASSE: classe,
      AJUSTES: ajustes,
      NOTAS: notas,
    };

    api
      .put(`/product/${props.expression.product_id}`, {
        maker_id: makerId,
        expression_name: expressionName,
        product_type: productType,
        ml_btl: mlBtl,
        standard_extra_fields: JSON.stringify(extraFields),
        common_name_id: chosenMaguey,
        description: expressionDescription,
      })
      .then(() => {
        props.fetchExpressions();
      })
      .finally(() => {
        props.setVisible(false);
      });
  }

  return (
    <CModalBody key="modal1">
      <CModal key="modalConfirmDelete" size="sm" visible={deleteModalVisible} backdrop={`static`}>
        <CModalHeader>
          <CModalTitle>Confirm Delete</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm
            style={{ margin: '10px' }}
            onSubmit={() => {
              deleteExpression(props.expression.product_id, blockSKU);
              setDeleteModalVisible(false);
            }}
          >
            <CRow>
              <CFormCheck
                type="radio"
                name="flexRadio"
                value={true}
                label="Block SKU"
                onChange={() => {
                  setBlockSKU(true);
                }}
              />
              <CFormCheck
                type="radio"
                name="flexRadio"
                label="Free SKU"
                value={false}
                onChange={() => {
                  setBlockSKU(false);
                }}
                defaultChecked
              />
            </CRow>
            <CRow>
              <CCol style={{ marginTop: '20px' }}>
                <CButton color="primary" type="submit">
                  Delete
                </CButton>
                <CButton
                  color="danger"
                  onClick={() => {
                    setDeleteModalVisible(false);
                  }}
                  style={{ marginLeft: '10px', color: 'white' }}
                >
                  Close
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </CModalBody>
      </CModal>
      <CForm style={{ margin: '10px' }} onSubmit={handleSaveSubmit}>
        <CRow className="mb-2">
          <CCol xs={12} md={4}>
            <CFormLabel className="mb-1" htmlFor="expressionInput">
              Maker
            </CFormLabel>
            <FilterableSelect
              defaultValue={makerId}
              options={props.makers}
              value="id"
              text="maker_name"
              onChange={(v) => {
                setMakerId(v);
              }}
            />
          </CCol>
          <CCol xs={12} md={4}>
            <CFormLabel className="mb-1" htmlFor="expressionInput">
              Common Name
            </CFormLabel>
            <FilterableSelect
              defaultValue={props.expression.maguey_name_id}
              options={magueys}
              value="id"
              text="common_name"
              onChange={(value) => {
                setChosenMaguey(value);
              }}
            ></FilterableSelect>
          </CCol>
          <CCol xs={12} md={4}>
            <CFormLabel className="mb-1" htmlFor="skuInput">
              SKU
            </CFormLabel>
            <CFormInput
              type="text"
              id="skuInput"
              value={sku}
              onChange={(e) => {
                setSKU(e.target.value);
              }}
              size="sm"
              readOnly
            />
          </CCol>
        </CRow>
        <CCol xs={12} className="mb-2">
          <CFormLabel className="mb-1" htmlFor="expressionInput">
            Expression Name
          </CFormLabel>
          <br />
          <CFormInput
            type="text"
            size="sm"
            value={expressionName}
            onChange={(e) => {
              setExpressionName(e.target.value);
            }}
          />
          {suggestedName.length > 0 && suggestedName !== expressionName && (
            <>
              <span style={{ fontSize: '12px' }} className={`flex-center-horizontal`}>
                <span>Suggested Name:</span>
                <CButton
                  color="link"
                  onClick={() => {
                    setExpressionName(suggestedName);
                  }}
                >
                  {suggestedName}
                </CButton>
              </span>
            </>
          )}
        </CCol>
        <CRow className="mb-2">
          <CCol>
            <CFormLabel className="mb-1" htmlFor="abvInput">
              Type
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={productType}
              onChange={(e) => {
                setProductType(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="abvInput">
              ABV
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={abv}
              onChange={(e) => {
                setABV(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="mlBtlInput">
              ml/btl
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={mlBtl}
              onChange={(e) => {
                setMlBtl(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="abvInput">
              Case Pack
            </CFormLabel>
            <CFormInput type="text" defaultValue={props.expression.case_pack} size="sm" readOnly />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="fincaInput">
              Finca
            </CFormLabel>
            <CFormInput
              type="text"
              value={finca}
              onChange={(e) => {
                setFinca(e.target.value);
              }}
              size="sm"
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-2" htmlFor="eleInput">
              Elevación
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={elevation}
              onChange={(e) => {
                setEleveation(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="cosInput">
              Cosecha
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={cosecha}
              onChange={(e) => {
                setCosecha(e.target.value);
              }}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="tierraInput">
              Tierra
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={tierra}
              onChange={(e) => {
                setTierra(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-2" htmlFor="pueInput">
              Pueblo
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={pueblo}
              onChange={(e) => {
                setPueblo(e.target.value);
              }}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="hornoInput">
              Horno
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={horno}
              onChange={(e) => {
                setHorno(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-2" htmlFor="lenaInput">
              Leña
            </CFormLabel>
            <CFormInput
              type="text"
              value={lenia}
              onChange={(e) => {
                setLenia(e.target.value);
              }}
              size="sm"
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="durInput">
              Duración de Horneado
            </CFormLabel>
            <CFormInput
              type="text"
              value={duracionHorneado}
              onChange={(e) => {
                setDuracionHorneado(e.target.value);
              }}
              size="sm"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="molInput">
              Molino
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={molino}
              onChange={(e) => {
                setMolino(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="ferInput">
              Fermentación
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={fermentacion}
              onChange={(e) => {
                setFermentacion(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-2" htmlFor="tinaInput">
              Tipo de Tina
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={tipoDeTina}
              onChange={(e) => {
                setTipoDeTina(e.target.value);
              }}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="aguaInput">
              Fuente de Agua
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={fuenteAgua}
              onChange={(e) => {
                setFuenteAgua(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="desInput">
              Tipo de Destilador
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={tipoDestilador}
              onChange={(e) => {
                setTipoDestilador(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-2" htmlFor="numInput">
              Número de Destilaciones
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={numeroDeDestil}
              onChange={(e) => {
                setNumeroDeDestil(e.target.value);
              }}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="classeInput">
              Classe
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={classe}
              onChange={(e) => {
                setClasse(e.target.value);
              }}
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="ajuInput">
              Ajustes
            </CFormLabel>
            <CFormInput
              type="text"
              size="sm"
              value={ajustes}
              onChange={(e) => {
                setAjustes(e.target.value);
              }}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="notasInput">
              Notas
            </CFormLabel>
            <CFormTextarea
              rows="2"
              value={notas}
              onChange={(e) => {
                setNotas(e.target.value);
              }}
            ></CFormTextarea>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="descriptionInput">
              Description
            </CFormLabel>
            <CFormTextarea
              rows="2"
              value={expressionDescription}
              onChange={(e) => {
                setExpressionDescription(e.target.value);
              }}
            ></CFormTextarea>
          </CCol>
        </CRow>
        <div style={{ marginTop: '10px' }}>
          <CButton color="danger" className="float-end" onClick={() => setDeleteModalVisible(true)}>
            <CIcon icon={cilTrash} style={{ color: 'white' }} />
          </CButton>
          <CButton type="submit" color="primary">
            Save
          </CButton>
          <CButton
            color="danger"
            onClick={() => {
              props.setVisible(false);
            }}
            style={{ marginLeft: '10px', color: 'white' }}
          >
            Close
          </CButton>
        </div>
      </CForm>
    </CModalBody>
  );
};

ExpressionEditModal.propTypes = {
  setVisible: PropTypes.any,
  expression: PropTypes.any,
  fetchExpressions: PropTypes.any,
  makers: PropTypes.array,
};

export default ExpressionEditModal;
