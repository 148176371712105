import React, { useEffect, useRef, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCardTitle, CCol, CContainer, CFormInput, CFormLabel, CFormSelect, CRow, CSpinner, CTooltip } from '@coreui/react';
import api from '../../api';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import './exworks.css';
import useMoneyCurrency from '../../hooks/useMoneyCurrency';
import LButton from '../../components/LButton/LButton';
import { setErrors, setSuccessMessages } from '../../actions';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import useDownload from '../../hooks/useDownload';
import { cilBellExclamation, cilCheckCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { Helmet } from 'react-helmet';

function ExWorksBuyer() {
  const [buyers, setBuyers] = useState([]);
  const [buyer, setBuyer] = useState(0);
  const [exWorksData, setExWorksData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [grandTotalProfit, setGrandTotalProfit] = useState(0);
  const [totalBottles, setTotalBottles] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [averageCost, setAverageCost] = useState(0);
  const [averageSellPrice, setAverageSellPrice] = useState(0);
  const [averageMarkupMarkup, setAverageMarkupMarkup] = useState(0);
  const [averageMargin, setAverageMargin] = useState(0);
  const [averageProfit, setAverageProfit] = useState(0);
  // const [averageBottles, setAverageBottles] = useState(0);
  const [minMarkup, setMinMarkup] = useState(0);
  const [minMarkupInput, setMinMarkupInput] = useState(0);
  const [isLoadingMinMarkup, setIsLoadingMinMarkup] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);
  const reportRef = useRef(null);
  const [currency] = useState('USD');

  const money = useMoneyCurrency();
  const dispatch = useDispatch();
  const downloader = useDownload();

  useEffect(() => {
    const controller = new AbortController();
    const c2 = new AbortController();

    api.get('./user/buyers', { signal: controller.signal }).then(({ data }) => {
      setBuyers(data);
    });

    setIsLoadingMinMarkup(true);
    api
      .get('/settings/minimum-ex-works-markup', { signal: controller.signal })
      .then(({ data }) => {
        setMinMarkup(data.min_markup);
        setMinMarkupInput(data.min_markup);
      })
      .finally(() => {
        setIsLoadingMinMarkup(false);
      });

    return () => {
      controller.abort();
      c2.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (buyer == 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
      // Get ex-works by buyer.
      // api.get('/product/')
      api
        .get(`/product/ex-works-reports-data/${buyer}`, {
          signal: controller.signal,
        })
        .then(({ data }) => {
          if (data.exWorksData) {
            setExWorksData(data.exWorksData);
          }
          if (data.grandTotalProfit) {
            setGrandTotalProfit(data.grandTotalProfit);
          }
          if (data.totalBottles) {
            setTotalBottles(data.totalBottles);
          }
          if (data.totalSellPrice) {
            setTotalPrice(data.totalSellPrice);
          }
          if (data.totalCost) {
            setTotalCost(data.totalCost);
          }
          if (data.averageCost) {
            setAverageCost(data.averageCost);
          }

          if (data.averageSellPrice) {
            setAverageSellPrice(data.averageSellPrice);
          }

          if (data.averageMarkup) {
            setAverageMarkupMarkup(data.averageMarkup);
          }

          if (data.averageMargin) {
            setAverageMargin(data.averageMargin);
          }
          if (data.averageProfit) {
            setAverageProfit(data.averageProfit);
          }
          /*
          if (data.averageBottles) {
            setAverageBottles(data.averageBottles);
          }*/
          setIsLoading(false);
        });
    }
    return () => {
      setTotalBottles(0);
      setGrandTotalProfit(0);
      setExWorksData([]);
      controller.abort();
    };
  }, [buyer]);

  const downloadReport = async () => {
    setIsDownloading(true);
    api
      .get(`/reports/ex-works/${buyer}`)
      .then(({ data }) => {
        if (data.pdf) {
          downloader.download(`${process.env.REACT_APP_API_URL}/${data.pdf}`, `ex-works-${buyerName()}-${moment().format('YYYY-MM-DD')}.pdf`);
        } else {
          dispatch(setErrors([{ message: 'Could not download report' }]));
        }
      })
      .catch((e) => {
        setErrors([{ message: e }]);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const buyerName = () => {
    let buyerName = '';
    buyers.some((b) => {
      const isBuyer = b.user_id == buyer;
      if (isBuyer) {
        buyerName = b.username;
      }
      return isBuyer;
    });
    return buyerName;
  };

  const saveMinMarkup = () => {
    if (isNaN(parseFloat(minMarkup))) {
      dispatch(setErrors([{ message: 'The value must be a number.' }]));
    } else if (minMarkup < 0) {
      dispatch(setErrors([{ message: 'The value must be a positive number.' }]));
    } else {
      setIsLoadingMinMarkup(true);
      api
        .put('/settings/minimum-ex-works-markup', {
          value: minMarkupInput,
        })
        .then(() => {
          setMinMarkup(minMarkupInput);
          dispatch(
            setSuccessMessages([
              {
                message: 'The minimum markup value has been updated.',
              },
            ]),
          );
        })
        .catch(() => {
          dispatch(
            setErrors([
              {
                message: 'Unable to update the value: ' + e.message,
              },
            ]),
          );
        })
        .finally(() => {
          setIsLoadingMinMarkup(false);
        });
    }
  };

  return (
    <CContainer>
      <Helmet>
        <title>Ex Works Settings</title>
      </Helmet>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <CCardTitle>Ex Works</CCardTitle>
            </CCardHeader>

            <CCardBody>
              <div className="my-3">
                <CRow>
                  <CCol xs={12} md={6}>
                    <CFormLabel>Buyer</CFormLabel>
                    <CFormSelect
                      value={buyer}
                      onChange={(e) => {
                        setBuyer(e.target.value);
                      }}
                    >
                      <option value={0}>Choose a buyer</option>
                      {buyers.map((buyer, index) => {
                        return (
                          <option key={index} value={buyer.user_id}>
                            {buyer.username}
                          </option>
                        );
                      })}
                    </CFormSelect>
                  </CCol>
                  <CCol xs={12} md={6}>
                    <CRow>
                      <CCol xs={8} md={6}>
                        <CFormLabel>Alert if markup is lower than</CFormLabel>
                        <CFormInput
                          disabled={isLoadingMinMarkup}
                          type="number"
                          placeholder={'Example: 1.2'}
                          value={minMarkupInput}
                          onChange={(e) => {
                            setMinMarkupInput(e.target.value);
                          }}
                        />
                      </CCol>
                      <CCol xs={4} md={6}>
                        <CFormLabel>&nbsp;</CFormLabel>
                        <div>
                          <LButton isLoading={isLoadingMinMarkup} onClick={saveMinMarkup}>
                            Save
                          </LButton>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </div>
              <CRow>
                <CCol>
                  {isLoading ? (
                    <>
                      <CSpinner></CSpinner>
                    </>
                  ) : buyer == 0 ? (
                    <></>
                  ) : (
                    <>
                      {exWorksData.length > 0 ? (
                        <div ref={reportRef}>
                          <div className="my-3">
                            <h1>Ex Works - {buyerName()}</h1>
                            <div className="my-3">
                              <LButton isLoading={isDownloading} onClick={downloadReport}>
                                Download Report
                              </LButton>
                            </div>
                            <p>
                              <b>Date: </b>
                              {moment().format('YYYY-MM-DD')}
                            </p>
                          </div>
                          <Table className={`ex-works-table`}>
                            <Thead>
                              <Tr>
                                <Th>SKU</Th>
                                <Th>Expression</Th>
                                <Th>Lot Number</Th>
                                <Th>Cost</Th>
                                <Th>Ex Works Price</Th>
                                <Th>Markup</Th>
                                <Th>Margin</Th>
                                <Th>Profit</Th>
                                <Th>Bottles</Th>
                                <Th>Total Cost</Th>
                                <Th>Total Sell Price (Ex works)</Th>
                                <Th>Total Profit</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr className="average">
                                <Td>
                                  <b>Average</b>
                                </Td>
                                <Td>
                                  <b>-</b>
                                </Td>
                                <Td>
                                  <b>-</b>
                                </Td>
                                <Td>
                                  <b>{money(averageCost, currency)}</b>
                                </Td>
                                <Td>
                                  <b>{money(averageSellPrice, currency)}</b>
                                </Td>
                                <Td>
                                  <b>{averageMarkupMarkup.toFixed(2)}</b>
                                </Td>
                                <Td>
                                  <b>{averageMargin.toFixed(2)}%</b>
                                </Td>
                                <Td>
                                  <b>{money(averageProfit, currency)}</b>
                                </Td>
                                <Td>
                                  <b>-</b>
                                </Td>
                                <Td>
                                  <b>-</b>
                                </Td>
                                <Td>
                                  <b>-</b>
                                </Td>
                                <Td>
                                  <b>-</b>
                                </Td>
                              </Tr>
                              {exWorksData.map((item, index) => {
                                return (
                                  <Tr key={`ex-works-row-${index}`}>
                                    <Td>{item.sku}</Td>
                                    <Td>{item.expression}</Td>
                                    <Td>{item.lot_number}</Td>
                                    <Td>{money(item.cost, currency)}</Td>
                                    <Td>{item.markup === null ? <span style={{ color: 'red' }}>Price Not Assigned</span> : money(item.price, currency)}</Td>
                                    <Td>
                                      {item.markup === null ? (
                                        <>-</>
                                      ) : (
                                        <>
                                          <span className={`me-1 ${item.markup < minMarkup ? ' text-danger fw-bold' : ''}`}>{item.markup.toFixed(2)}</span>
                                          {item.markup < minMarkup ? (
                                            <CTooltip content={'The markup is less than ' + 'the minimum value set.'}>
                                              <CIcon icon={cilBellExclamation} color="red" />
                                            </CTooltip>
                                          ) : (
                                            <CIcon icon={cilCheckCircle} color="success" />
                                          )}
                                        </>
                                      )}
                                    </Td>
                                    <Td>
                                      {item.margin == null ? '' : <span className={`text-${item.margin > 0 ? '' : 'danger'}`}>{item.margin.toFixed(2)}%</span>}
                                    </Td>
                                    <Td>
                                      {item.profit == null ? (
                                        ''
                                      ) : (
                                        <>
                                          <span className={`text-${item.profit > 0 ? '' : 'danger'}`}>{money(item.profit, currency)}</span>
                                        </>
                                      )}
                                    </Td>
                                    <Td>{item.bottles}</Td>
                                    <Td>{money(item.totalCost, currency)}</Td>
                                    <Td>{money(item.totalSellPrice, currency)}</Td>
                                    <Td>
                                      {item.totalProfit == null ? (
                                        ''
                                      ) : (
                                        <span className={`text-${item.totalProfit > 0 ? '' : 'danger'}`}>{money(item.totalProfit, currency)}</span>
                                      )}
                                    </Td>
                                  </Tr>
                                );
                              })}
                              <Tr>
                                <Td colSpan={8}>
                                  <b>Total</b>
                                </Td>
                                <Td>
                                  <b>{totalBottles}</b>
                                </Td>
                                <Td>
                                  <b>{money(totalCost, currency)}</b>
                                </Td>
                                <Td>
                                  <b>{money(totalPrice, currency)}</b>
                                </Td>
                                <Td>
                                  <b>{money(grandTotalProfit, currency)}</b>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      ) : (
                        <>No Data Found for buyer</>
                      )}
                    </>
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
}

export default ExWorksBuyer;
