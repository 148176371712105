import { CAlert, CButton, CCol, CListGroup, CListGroupItem, CRow } from '@coreui/react';
import { useEffect, useState } from 'react';
import api from 'src/api';
import ContentLayout from 'src/layout/ContentLayout';
// import Bowser from 'bowser';
import CIcon from '@coreui/icons-react';
import { cilCheckCircle } from '@coreui/icons';
import YesNoModal from 'src/components/YesNoModal';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import BreadCrumbs from 'src/components/BreadCrumbs';
import { Helmet } from 'react-helmet';

export default function SessionsReview() {
  const [sessions, setSessions] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [removing, setRemoving] = useState(false);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    // setLoadingBrowserInfo(true);
    // const browser = Bowser.getParser(window.navigator.userAgent);
    // setBrowserName(browser.getBrowserName());
    // setBrowserVersion(browser.getBrowserVersion());
    // setOsName(browser.getOSName());
    // setOsVersion(browser.getOSVersion());
    // setPlatformType(browser.getPlatformType());
    // setLoadingBrowserInfo(false);

    const controller = new AbortController();

    api.get('/user/sessions').then(({ data }) => {
      setSessions(data);
    });

    return () => {
      controller.abort();
    };
  }, []);

  const closeSessionConfirm = (sessionId) => {
    setSessionId(sessionId);
    setConfirm(true);
  };

  const closeSession = () => {
    setRemoving(true);

    api
      .delete(`/sessions/${sessionId}`)
      .then(() => {
        setSessions((prev) => prev.filter((sess) => sess.session_id != sessionId));
        toast.success('The session has been removed. It could take up to 5 minutes for the session to completely close.');
      })
      .finally(() => {
        setRemoving(false);
        setConfirm(false);
        setSessionId(null);
      });
  };

  return (
    <>
      <Helmet>
        <title>Sessions Review</title>
      </Helmet>
      <ContentLayout title="Sessions review">
        <CRow>
          <CCol>
            <BreadCrumbs
              items={[
                {
                  to: '/users/settings',
                  text: 'Settings',
                  disabled: false,
                },
                {
                  to: '/dashboard/cpos/cpos',
                  text: 'Sessions Review',
                  disabled: true,
                },
              ]}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CAlert color="info">
              These are all sessions that were opened using your credentials.
              <br />
              If you don&apos;t recognize a session you can close it. And in this case we advise you to change your password.
            </CAlert>
          </CCol>
        </CRow>

        <CListGroup>
          {sessions.map((session, idx) => {
            return (
              <CListGroupItem key={`c-list-group-item-${idx}`}>
                <div>
                  <p>
                    <b>Session&apos;s Info: </b>
                  </p>
                  <p>
                    {session.ip_address} - {session.browser_name} / {session.os_name} / {session.platform_type}
                  </p>
                  {auth.sessionId == session?.session_id ? (
                    <p>
                      <span className={`${auth.sessionId == session?.session_id ? 'text-success' : ''}`}>
                        <CIcon icon={cilCheckCircle} color="success" style={{ marginRight: '0.25rem' }} />
                        This is your current session.
                      </span>
                    </p>
                  ) : (
                    <CButton
                      style={{ minWidth: '100px' }}
                      color="primary"
                      variant="outline"
                      onClick={() => {
                        closeSessionConfirm(session.session_id);
                      }}
                    >
                      Close this session
                    </CButton>
                  )}
                </div>
              </CListGroupItem>
            );
          })}
        </CListGroup>
      </ContentLayout>
      <YesNoModal
        visible={confirm}
        setVisible={setConfirm}
        onNo={() => {
          console.log('No');
          setConfirm(false);
          setSessionId(null);
        }}
        title="Are you sure you want to close this session?"
        description="The session will be closed but can be opened again using the password."
        isLoading={removing}
        onYes={closeSession}
      />
    </>
  );
}
