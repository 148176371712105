import React from 'react';
import { useSelector } from 'react-redux';

import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react';

import { AppSidebarNav } from './AppSidebarNav';

import mainLogo from 'src/assets/brand/cuenta-logo.png';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import 'src/components/simplebar.css';

// sidebar nav config
import navigation from '../_nav';

const AppSidebar = () => {
  const sidebarShow = useSelector((state) => state.sidebarShow);

  const role = useSelector((state) => state.auth.role);

  let filteredItems = navigation.filter((item) => {
    return !item.allowed || item.allowed.includes(role);
  });

  filteredItems = filteredItems.map((item) => {
    if (Array.isArray(item.items)) {
      console.log('item');

      item.items = item.items.filter((subItem) => {
        return !subItem.allowed || subItem.allowed.includes(role);
      });
    }

    return item;
  });

  return (
    <CSidebar position="fixed" visible={sidebarShow}>
      <CSidebarBrand className="d-none d-md-flex" to="/" style={{ marginBottom: '10px' }}>
        <div>
          <img src={mainLogo} style={{ maxWidth: '100%', maxHeight: '100%', padding: '1rem' }} alt="Main Logo" />
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        Sidebar is here:
        <SimpleBar>
          <AppSidebarNav items={filteredItems} />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
