import React, { useEffect } from 'react';
import { AppSidebar, AppHeader } from '../components/index';
import { hideSidebarShow } from 'src/actions';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';
import useCurrentPath from 'src/hooks/useCurrentPath';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';

import useWindowSize from 'src/hooks/useWindowSize';
import OnLogout from 'src/components/OnLogout/OnLogout';

const DefaultLayout = () => {
  const dispatch = useDispatch();
  const currentPath = useCurrentPath();
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const windowSize = useWindowSize();
  useEffect(() => {
    const timeout = setTimeout(() => {
      const width = window.innerWidth;
      if (width < 766) {
        dispatch(hideSidebarShow());
      }
    }, 150);
    return () => {
      clearTimeout(timeout);
    };
  }, [windowSize]);

  useEffect(() => {
    if (currentPath.allowed.length > 0) {
      if (authState.loggedIn) {
        const role = authState.role;
        if (!currentPath.allowed.includes(role)) {
          navigate('/not-allowed');
        }
      } else {
        navigate({
          pathname: '/',
          search: createSearchParams({
            callback: window.location.href,
          }).toString(),
        });
      }
    }
  }, [currentPath, authState]);

  return (
    <div>
      <OnLogout />
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default DefaultLayout;
