const initialState = {
  currency: 'USD',
  updated: false,
};

const errorsReducer = (state = initialState, action) => {
  const stateToUse = state.updated ? state : initialState;

  switch (action.type) {
    case 'SET_CURRENCY':
      stateToUse.currency = action.payload.currency;
      stateToUse.updated = true;
      return { ...stateToUse };
    case 'RESET_CURRENCY':
      stateToUse.currency = 'USD';
      stateToUse.updated = true;
      return { ...stateToUse };
    default:
      return stateToUse;
  }
};

export default errorsReducer;
