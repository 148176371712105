export default function useDownload() {
  return {
    download: (uri, name) => {
      const link = document.createElement('a');
      link.setAttribute('data-testid', 'downloaderLink');
      link.download = name;
      link.href = uri;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  };
}
