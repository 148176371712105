import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function BreadCrumbs({ items }) {
  return (
    <div className="mb-4">
      {items.map((item, key) => (
        <div className="d-inline-flex" key={`breadcrumb-${key}`}>
          <Link to={item.disabled ? '#' : item.to} style={{ color: `${item.disabled ? '#8A93A2' : ''}` }}>
            {item.text}
          </Link>
          {items.length > 0 && key < items.length - 1 && (
            <>
              <span style={{ color: '#8A93A2' }} className="mx-2">
                /
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

BreadCrumbs.propTypes = {
  items: PropTypes.array,
};

export default BreadCrumbs;
