import { cilCloudDownload, cilReload } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CAlert, CButton, CCard, CCardBody, CCardHeader, CCardTitle, CCol, CContainer, CForm, CFormCheck, CFormLabel, CFormSelect, CRow } from '@coreui/react';

import { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import LButton from 'src/components/LButton/LButton';

import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';

import { Table, Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import YesNoModal from 'src/components/YesNoModal';
import api from 'src/api';
import useSort from 'src/hooks/useSort';
import { Helmet } from 'react-helmet';

const socket = io(process.env.REACT_APP_SOCKET_URL);

const Importers = () => {
  const [isConnecting, setIsConnecting] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [isScraping, setIsScraping] = useState(false);
  const [description, setDescription] = useState('');
  const [lastItemScraped, setLastItemScraped] = useState({});
  const [showCancel, setShowCancel] = useState(false);
  const [finished, setFinished] = useState(false);
  const [errored, setErrored] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  /**
   * contactName,
   * companyName,
   * email,
   * phone,
   * website,
   * country,
   * city,
   * state, c
   * hosenCountry
   */

  const [contactName, setContactName] = useState(true);
  const [companyName, setCompanyName] = useState(true);
  const [email, setEmail] = useState(true);
  const [phone, setPhone] = useState(true);
  const [website, setWebsite] = useState(true);
  const [country, setCountry] = useState(true);
  const [city, setCity] = useState(true);
  const [state, setState] = useState(true);
  const [chosenCountry, setChosenCountry] = useState(0);
  const [countries, setCountries] = useState([]);
  const [distributorTypes, setDistributorTypes] = useState([]);
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);

  const dispatch = useDispatch();
  const sort = useSort();

  useEffect(() => {
    const controller = new AbortController();
    const controller2 = new AbortController();

    if (socket.connected) {
      setIsConnected(true);
      setIsConnecting(false);
    }

    socket.on('connect', () => {
      setIsConnected(true);
      setIsScraping(false);
      setIsConnecting(false);

      socket.emit('join', 'importers-scraper');
      socket.on('scraping-status', (data) => {
        setIsScraping(data.isScraping);
        setDescription(data.description || '');
        setLastItemScraped(data.lastItemScraped || {});
        setFinished(data.finished || false);
        setErrored(data.errored || false);
      });
    });
    socket.on('disconnect', (reason) => {
      setIsConnected(false);
      setIsScraping(false);
      if (reason === 'io server disconnect') {
        /**
         * the disconnection was initiated by the server,
         * you need to reconnect manually
         */
        socket.connect();
      }
    });

    api
      .get('/distributors/types')
      .then(({ data }) => {
        setDistributorTypes(
          data.map((dist) => {
            if (dist.type == 'Spirit Distributor') {
              dist.isChecked = true;
            } else {
              dist.isChecked = false;
            }
            return dist;
          }),
        );
      })
      .catch((e) => {
        dispatch(setErrors([{ message: e }]));
      });

    api
      .get('/distributors/countries')
      .then(({ data }) => {
        data = data.sort();
        setCountries(data.sort(sort.aphabetAZ('country')));
      })
      .catch((e) => dispatch(setErrors([{ message: e }])));

    return () => {
      if (socket != null) {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('importers-scraper');
        socket.off('scraping-status');
      }
      setRequestSent(false);
      setIsScraping(false);
      controller.abort();
      controller2.abort();
    };
  }, []);

  const scrapeAgain = useCallback(() => {
    setRequestSent(true);
    if (isConnected && socket != null) {
      socket.emit('scrape-importers');
    } else {
      dispatch(
        setErrors([
          {
            message: 'There was an error while trying to connect.',
          },
        ]),
      );
    }
  }, [isConnected, socket]);

  const askCancel = () => {
    setShowCancel(true);
  };

  const confirmCancel = () => {
    setRequestSent(false);
    if (isConnected && socket != null) {
      socket.emit('scrape-importers-cancel');
      setShowCancel(false);
    } else {
      dispatch(setErrors([{ message: 'Could not cancel.' }]));
    }
  };

  const downloadCSV = () => {
    let valid = true;

    const fields = [];

    if (companyName) {
      fields.push('Company Name');
    }
    if (contactName) {
      fields.push('Name');
    }
    if (email) {
      fields.push('Email');
    }
    if (phone) {
      fields.push('Phone');
    }
    if (website) {
      fields.push('Website');
    }
    if (country) {
      fields.push('Country');
    }
    if (city) {
      fields.push('City');
    }
    if (state) {
      fields.push('State');
    }

    if (fields.length <= 0) {
      dispatch(setErrors([{ message: 'Choose at least one field.' }]));
      valid = false;
    }

    const includeTypes = [];

    distributorTypes.forEach((type) => {
      if (type.isChecked) {
        includeTypes.push(type.type);
      }
    });
    if (includeTypes.length <= 0) {
      dispatch(setErrors([{ message: 'Choose at least one "distributor" type' }]));
      valid = false;
    }

    const settings = {
      fields: fields.join(','),
      types: includeTypes.join(','),
      country: chosenCountry,
    };

    if (valid) {
      setIsDownloadingCSV(true);
      api
        .post('/distributors/csv', settings)
        .then(({ data }) => {
          if (data.name) {
            downloadURI(`${process.env.REACT_APP_API_URL}/${data.name}`, data.name);
          } else {
            dispatch(setErrors([{ message: 'Unable to download a file.' }]));
          }
        })
        .catch((e) => {
          dispatch(setErrors([{ message: e }]));
        })
        .finally(() => {
          setIsDownloadingCSV(false);
        });
    }
  };

  function downloadURI(uri, name) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <CContainer>
        <Helmet>
          <title>Importers</title>
        </Helmet>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CCardTitle>Spirit Distrubutors</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <YesNoModal
                  visible={showCancel}
                  setVisible={setShowCancel}
                  title="Cancel"
                  description={'If you want to scrape again ' + 'you\'ll have to start from zero.'}
                  onYes={confirmCancel}
                  onNo={() => {
                    setShowCancel(false);
                  }}
                />

                {isConnected && !isConnecting ? (
                  <>
                    <CRow>
                      <CCol>
                        <div className="mb-3">
                          <LButton disabled={requestSent || isScraping} isLoading={isScraping || requestSent} onClick={scrapeAgain} className="me-1 mb-1">
                            {isScraping ? (
                              'Scraping...'
                            ) : (
                              <>
                                Scrape Again <CIcon icon={cilReload} />
                              </>
                            )}
                          </LButton>
                          <CButton color="danger" className="me-1 mb-1" disabled={!isScraping} onClick={askCancel}>
                            Cancel
                          </CButton>
                        </div>
                      </CCol>
                    </CRow>
                    {!isScraping && (
                      <>
                        <CRow>
                          <CCol>
                            <hr />
                            <h3>CSV Settigns</h3>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <CForm className="my-3">
                              <CRow>
                                <CCol>
                                  <CFormLabel>By Country</CFormLabel>
                                  <CFormSelect
                                    size="sm"
                                    className="mb-3"
                                    onChange={(e) => {
                                      setChosenCountry(e.target.value);
                                    }}
                                  >
                                    <option value={0}>All Countries</option>
                                    {countries.map((country, idx) => {
                                      return (
                                        <option key={`country-cfs-${idx}`} value={country.country}>
                                          {country.country}
                                        </option>
                                      );
                                    })}
                                  </CFormSelect>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol>
                                  <CFormLabel>
                                    <b>Include types:</b>
                                  </CFormLabel>
                                  {distributorTypes.map((type, idx) => {
                                    return (
                                      <CFormCheck
                                        label={`${type.type}`}
                                        value={type.id}
                                        key={`type-checkbox-${idx}`}
                                        checked={type.isChecked}
                                        onChange={(e) => {
                                          setDistributorTypes((prev) => {
                                            return prev.map((t) => {
                                              if (t.id == type.id) {
                                                t.isChecked = !t.isChecked;
                                              }
                                              return t;
                                            });
                                          });
                                        }}
                                      />
                                    );
                                  })}
                                </CCol>
                                <CCol>
                                  <CFormLabel>
                                    <b>Fields:</b>
                                  </CFormLabel>
                                  <CFormCheck
                                    label="Company Name"
                                    checked={companyName}
                                    onChange={() => {
                                      setCompanyName((p) => !p);
                                    }}
                                  />
                                  <CFormCheck
                                    label="Contact Name"
                                    checked={contactName}
                                    onChange={() => {
                                      setContactName((p) => !p);
                                    }}
                                  />
                                  <CFormCheck
                                    label="Email"
                                    checked={email}
                                    onChange={() => {
                                      setEmail((p) => !p);
                                    }}
                                  />
                                  <CFormCheck
                                    label="Phone Number"
                                    checked={phone}
                                    onChange={() => {
                                      setPhone((p) => !p);
                                    }}
                                  />
                                  <CFormCheck
                                    label="Website"
                                    checked={website}
                                    onChange={() => {
                                      setWebsite((p) => !p);
                                    }}
                                  />
                                  <CFormCheck
                                    label="Country"
                                    checked={country}
                                    onChange={() => {
                                      setCountry((p) => !p);
                                    }}
                                  />
                                  <CFormCheck
                                    label="City"
                                    checked={city}
                                    onChange={() => {
                                      setCity((p) => !p);
                                    }}
                                  />
                                  <CFormCheck
                                    label="State"
                                    checked={state}
                                    onChange={() => {
                                      setState((p) => !p);
                                    }}
                                  />
                                </CCol>
                              </CRow>
                            </CForm>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <LButton color="dark" onClick={downloadCSV} isLoading={isDownloadingCSV}>
                              Download CSV <CIcon icon={cilCloudDownload} />
                            </LButton>
                          </CCol>
                        </CRow>
                      </>
                    )}
                    <CRow>
                      <CCol>
                        {errored && (
                          <>
                            <h1 className="text-danger">Error</h1>

                            <CAlert color="danger">{description}</CAlert>
                          </>
                        )}
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        {finished && (
                          <>
                            <h1 className="text-success">Success!</h1>
                            <CAlert color="success">Scraping process completed!</CAlert>
                          </>
                        )}
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        {isScraping && (
                          <div className="mb-3">
                            <h2 className="fw-bold">Status:</h2>
                            <CAlert color="info">{description}</CAlert>
                            {lastItemScraped && lastItemScraped?.companyName && (
                              <>
                                <h3>Last Item Scraped:</h3>
                                <Table>
                                  <Thead>
                                    <Tr>
                                      <Td>Company Name</Td>
                                      <Td>Contact Name</Td>
                                      <Td>Phone</Td>
                                      <Td>General Email</Td>
                                      <Td>Website</Td>
                                      <Td>Country</Td>
                                      <Td>City</Td>
                                      <Td>State</Td>
                                      <Td>Type(s)</Td>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    <Tr>
                                      <Td>{lastItemScraped.companyName || ''}</Td>
                                      <Td>{lastItemScraped.name || ''}</Td>
                                      <Td>{lastItemScraped.phone || ''}</Td>
                                      <Td>{lastItemScraped.general_email || ''}</Td>
                                      <Td>{lastItemScraped.website || ''}</Td>
                                      <Td>{lastItemScraped.country || ''}</Td>
                                      <Td>{lastItemScraped.city || ''}</Td>
                                      <Td>{lastItemScraped.state || ''}</Td>
                                      <Td>
                                        {lastItemScraped?.types && lastItemScraped?.types?.length > 0 && (
                                          <>
                                            {lastItemScraped.types.map((type, idx) => {
                                              return (
                                                <p
                                                  key={`ky-p-type-${idx}`}
                                                  style={{
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  {type}
                                                </p>
                                              );
                                            })}
                                          </>
                                        )}
                                      </Td>
                                    </Tr>
                                  </Tbody>
                                </Table>
                              </>
                            )}
                          </div>
                        )}
                      </CCol>
                    </CRow>
                  </>
                ) : isConnecting ? (
                  <>
                    <CRow>
                      <CCol>
                        <h1 className="text-success">Connecting...</h1>
                      </CCol>
                    </CRow>
                  </>
                ) : (
                  <>
                    <CRow>
                      <CCol>
                        <h1 className="text-danger">Disconnected.</h1>
                        <p>The connection to the server has been lost.</p>
                        <p>This could be because of one of the resons below:</p>
                        <ul>
                          <li>The server is down</li>
                          <li>The server is being restarted</li>
                          <li>Your internet connection is not working</li>
                        </ul>
                      </CCol>
                    </CRow>
                  </>
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default Importers;
