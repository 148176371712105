import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CButton,
  CModalBody,
  CForm,
  CRow,
  CCol,
  CFormLabel,
} from '@coreui/react';
import FilterableSelect from 'src/components/FilterableSelect/FilterableSelect';
import PropTypes from 'prop-types';
import { useState } from 'react';
import api from 'src/api';

const ExpressionCloneModal = ({
  visible,
  expressions,
  makers,
  onClone,
  onCancel,
}) => {
  const [expressionId, setExpressionId] = useState();
  const [makerId, setMakerId] = useState();

  const cloneExpression = () => {
    api
      .post(`/product/clone/${expressionId}`, {
        maker_id: makerId,
      })
      .then(() => {
        onClone();
      });
  };

  return (
    <CModal
      className="show d-block position-fixed"
      backdrop={`static`}
      keyboard={false}
      portal={false}
      visible={visible}
      onClose={() => {
        onCancel();
      }}
    >
      <CModalHeader>
        <CModalTitle>Copy Expression</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm>
          <CRow>
            <CCol>
              <CFormLabel>Expression</CFormLabel>
              <div style={{ marginBottom: '1rem' }}>
                <FilterableSelect
                  size={null}
                  text="expression_name"
                  value="product_id"
                  options={expressions}
                  placeholder="Choose an expression"
                  onChange={(value) => {
                    setExpressionId(value);
                  }}
                ></FilterableSelect>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CFormLabel>Maker</CFormLabel>
              <FilterableSelect
                size={null}
                text="maker_name"
                value="id"
                options={makers}
                placeholder="Choose a maker"
                onChange={(value) => {
                  setMakerId(value);
                }}
              ></FilterableSelect>
            </CCol>
          </CRow>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton color="danger" style={{ color: 'white' }} onClick={onCancel}>
          Cancel
        </CButton>
        <CButton color="primary" onClick={cloneExpression}>
          Clone
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

ExpressionCloneModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  expressionId: PropTypes.number,
  expressions: PropTypes.array,
  makers: PropTypes.array,
  onClone: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ExpressionCloneModal;
