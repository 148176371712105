import { CAlert, CCol, CListGroup, CListGroupItem, CRow } from '@coreui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import api from 'src/api';
import BreadCrumbs from 'src/components/BreadCrumbs';
import Loading from 'src/components/Loading';
import ContentLayout from 'src/layout/ContentLayout';
export default function LoginHistory() {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();

    setLoading(true);
    api
      .get('/sessions/history')
      .then(({ data }) => {
        setHistory(data);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Login History</title>
      </Helmet>
      <ContentLayout title="Login History">
        <CRow>
          <CCol>
            <BreadCrumbs
              items={[
                {
                  to: '/users/settings',
                  text: 'Settings',
                  disabled: false,
                },
                {
                  to: '/dashboard/cpos/cpos',
                  text: 'Login History',
                  disabled: true,
                },
              ]}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CAlert color="info">This is the login history of the last 30 days.</CAlert>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            {loading ? (
              <Loading />
            ) : (
              <>
                {history.length > 0 ? (
                  <CListGroup>
                    {history.map((login, idx) => {
                      return (
                        <CListGroupItem key={`clogin-item-${idx}`}>
                          <div>
                            <p>
                              <b>Login Information</b>
                            </p>
                            <p>
                              {login.ip_address} - {login.browser_name} / {login.os_name} / {login.platform_type}
                            </p>
                            <p>
                              Logged In at {moment(login.createdAt).format('hh:mm A')} on {moment(login.createdAt).format('ddd DD MMM YYYY')}
                            </p>
                          </div>
                        </CListGroupItem>
                      );
                    })}
                  </CListGroup>
                ) : (
                  <>
                    <CAlert color="warning">There was no login history. Try to check later.</CAlert>
                  </>
                )}
              </>
            )}
          </CCol>
        </CRow>
      </ContentLayout>
    </>
  );
}
