import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CFormSelect,
  CRow,
  CForm,
  CAlert,
  CFormLabel,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableRow,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableBody,
  CFormSwitch,
  CSpinner,
} from '@coreui/react';
import { useEffect, useState } from 'react';
import api from 'src/api';
import LButton from 'src/components/LButton/LButton';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const FilterableRow = ({ product }) => {
  const [checked, setChecked] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (product?.show_in_pdf == '1') {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [product]);

  const save = () => {
    setSaving(true);
    api
      .patch('/product/show-in-pdf/' + product.id, {
        show: checked ? '0' : '1', // Save the opposite value
      })
      .then(() => {
        setChecked((v) => !v);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <CTableRow>
      <CTableDataCell>{product.full_expression_name}</CTableDataCell>
      <CTableDataCell>{saving ? <CSpinner /> : <CFormSwitch checked={checked} onChange={save}></CFormSwitch>}</CTableDataCell>
    </CTableRow>
  );
};

FilterableRow.propTypes = {
  product: PropTypes.object,
};

const SchemaInfo = () => {
  const [schemas, setSchemas] = useState([]);
  const [chosenSchema, setChosenSchema] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [buyers, setBuyers] = useState([]);
  const [buyer, setBuyer] = useState(0);

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState('USD');

  const [showListModal, setShowListModal] = useState(false);
  const [filterable, setFilterable] = useState([]);
  const [filterableCount, setFilterableCount] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const controller2 = new AbortController();
    const controller3 = new AbortController();

    api.get('/pricing-scheme', { signal: controller.signal }).then(({ data }) => {
      setSchemas(data);
    });

    api.get('/user/buyers', { signal: controller2.signal }).then(({ data }) => {
      setBuyers(data);
    });

    api.get('/rates/today', { signal: controller3.signal }).then(({ data }) => {
      setCurrencies(data);
    });

    return () => {
      controller.abort();
      controller2.abort();
      controller3.abort();
    };
  }, []);

  useEffect(() => {
    const controller4 = new AbortController();

    api.get('/product/filterable', { signal: controller4.signal }).then(({ data }) => {
      setFilterable(data);
    });

    return () => {
      controller4.abort();
    };
  }, [filterableCount]);

  const onSchemaChosen = () => {
    if (buyer) {
      setIsLoading(true);
      api
        .post(`/pricing-scheme/pdf-buyer/${buyer}`, {
          currency,
        })
        .then(({ data }) => {
          if (data.pdf) {
            downloadURI(`${process.env.REACT_APP_API_URL}/${data.pdf}`);
          }
        })
        .catch((e) => {
          dispatch(setErrors([{ message: e }]));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      dispatch(setErrors([{ message: 'Choose a user' }]));
    }
  };

  function downloadURI(uri, name) {
    const link = document.createElement('a');

    link.target = '_blank';
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
  }

  return (
    <>
      <Helmet>
        <title>Schema Info</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CCardTitle>Schema Info (PDF)</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CForm>
                      <CRow>
                        <CCol>
                          <CAlert color="info">This section is tentative until the details of the pricing calculator are worked out.</CAlert>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <div className="my-3">
                            <CFormLabel>Get Prices From Buyer</CFormLabel>

                            <CFormSelect value={buyer} onChange={(e) => setBuyer(e.target.value)}>
                              <option value="0">Choose a buyer</option>
                              {buyers.map((buyer, idx) => {
                                return (
                                  <option key={`buyer-option-${idx}`} value={buyer.user_id}>
                                    {buyer.username}
                                  </option>
                                );
                              })}
                            </CFormSelect>
                          </div>
                        </CCol>
                        <CCol>
                          <div className="my-3">
                            <CFormLabel>Currency</CFormLabel>
                            <CFormSelect
                              value={currency}
                              onChange={(e) => {
                                setCurrency(e.target.value);
                              }}
                            >
                              {currencies.map((currency) => {
                                return (
                                  <option value={currency.currency} key={`currency-${currency.currency}`}>
                                    {currency.currency}
                                  </option>
                                );
                              })}
                            </CFormSelect>
                          </div>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <CFormSelect
                            disabled={true}
                            value={chosenSchema}
                            onChange={(e) => {
                              setChosenSchema(e.target.value);
                            }}
                          >
                            <option value="0">Choose a schema</option>
                            {schemas.map((schema, idx) => {
                              return (
                                <option key={`schema-${idx}`} value={schema.id}>
                                  {schema.schema_name}
                                </option>
                              );
                            })}
                          </CFormSelect>
                        </CCol>
                        <CCol>
                          <LButton isLoading={isLoading} onClick={onSchemaChosen}>
                            Download
                          </LButton>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <div className="my-3">
                            <CButton onClick={() => setShowListModal(true)}>Update Hiden Products</CButton>
                            <CModal
                              size="lg"
                              visible={showListModal}
                              backdrop={`static`}
                              onClose={() => {
                                setShowListModal(false);
                                setFilterableCount((c) => c + 1);
                              }}
                            >
                              <CModalHeader>
                                <CModalTitle>Update Hidden List</CModalTitle>
                              </CModalHeader>
                              <CModalBody>
                                <CTable>
                                  <CTableHead>
                                    <CTableRow>
                                      <CTableHeaderCell>Product</CTableHeaderCell>
                                      <CTableHeaderCell>Show In PDF</CTableHeaderCell>
                                    </CTableRow>
                                  </CTableHead>
                                  <CTableBody>
                                    {filterable.map((product, index) => (
                                      <FilterableRow key={`product-${index}`} product={product} />
                                    ))}
                                  </CTableBody>
                                </CTable>
                              </CModalBody>
                            </CModal>
                          </div>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default SchemaInfo;
