import {
  CCol,
  CContainer,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormTextarea,
  CFormLabel,
} from '@coreui/react';

import { useState, useEffect, useCallback } from 'react';
import api from 'src/api';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';

import { cilFolder, cilArrowCircleLeft, cilCheckCircle, cilXCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import './art.css';
import { useSelector } from 'react-redux';

import LButton from 'src/components/LButton/LButton';
import { Helmet } from 'react-helmet';

const ArtReview = () => {
  const role = useSelector((state) => state.auth.role);

  const [items, setItems] = useState([]);
  const [submissionsVisible, setSubmissionsVisible] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [artItems, setArtItems] = useState([]);
  const [commentaries, setCommentaries] = useState('');
  const [expression, setExpression] = useState('');
  const [details, setDetails] = useState({});
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFolderReviewed, setIsFolderReviewed] = useState(false);
  const [wasApproved, setWasApproved] = useState(false);

  const fetchRequests = useCallback(() => {
    const controller = new AbortController();
    api.get('/art-request', { signal: controller.signal }).then(({ data }) => {
      setItems(data);
    });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  useEffect(() => {
    if (selectedFolder !== null) {
      submissions.some((submission) => {
        if (submission.id === selectedFolder) {
          setCommentaries(submission.commentaries || '');
          setWasApproved(submission.approved === 1);
          setIsFolderReviewed(submission.approved !== null);
          return true;
        } else {
          return false;
        }
      });
    } else {
      setIsFolderReviewed(false);
    }
  }, [selectedFolder, submissions]);

  const showSubmissions = (item) => {
    setSubmissionsVisible(true);
    api.get(`/art-submission/${item.art_request_id}`).then(({ data }) => {
      setSubmissions(data);
    });
  };

  const showSubmissionItems = (item) => {
    setSelectedFolder(item.id);
    api.get(`/art-submission-item/${item.id}`).then(({ data }) => {
      setArtItems(data);
    });
  };

  const review = (approve) => {
    setIsLoading(true);
    api
      .post(`/art-submission/review/${selectedFolder}`, {
        approve,
        commentaries: commentaries,
      })
      .then(() => {
        setSubmissionsVisible(false);
        setSelectedFolder(false);
        setArtItems([]);
        fetchRequests();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setProductDetails = (id) => {
    items.some((item) => {
      const isItem = item.product_id === id;
      if (isItem) {
        setExpression(item.computed_expression);
        setDetails(item.standard_extra_fields);
      }
      return isItem;
    });
  };

  return (
    <>
      <Helmet>
        <title>Art Review</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol>
            <CCard className="mb-4">
              <CCardHeader>
                <CCol sm={5}>
                  <strong>Art</strong> <small>Review</small>
                </CCol>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <div className="table-container">
                    <Table>
                      <Thead>
                        <Tr>
                          <Th scope="col">Expresion Name</Th>
                          <Th scope="col">Submissions</Th>
                          <Th scope="col">Status of last submission</Th>
                          <Th scope="col">Commentaries</Th>
                          <Th scope="col">Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {items.map((item, idx) => {
                          // const extra = item.standard_extra_fields;
                          return (
                            <Tr key={`item-${idx}`}>
                              <Th>{item.computed_expression}</Th>
                              <Th>
                                You have received {item.amount_of_submissions} submission
                                {`${item.amount_of_submissions > 1 ? 's' : ''}`}
                                <CButton
                                  style={{ marginLeft: '0.3rem' }}
                                  onClick={() => {
                                    showSubmissions(item);
                                  }}
                                >
                                  View
                                </CButton>
                              </Th>
                              <Th>
                                {item.approved === null ? (
                                  <>Pending for review...</>
                                ) : parseInt(item.approved) === 1 ? (
                                  <span style={{ color: 'green' }}>Approved</span>
                                ) : (
                                  <span style={{ color: 'red' }}>Disapproved</span>
                                )}
                              </Th>
                              <Th>
                                <p>{item.comentaries}</p>
                              </Th>
                              <Td>
                                <CButton
                                  color="success"
                                  style={{
                                    marginRight: '1rem',
                                    marginBottom: '1rem',
                                    color: 'white',
                                  }}
                                  onClick={() => {
                                    setProductDetails(item.product_id);
                                    setVisible(true);
                                  }}
                                >
                                  Details
                                </CButton>
                                {parseInt(item.approved) === 1 ?
                                  '' :
                                  role === 'ARTIST' && (
                                      <CButton
                                        onClick={() => {
                                          setSubmitVisible(true);
                                          setArtRequestId(item.art_request_id);
                                        }}
                                        style={{
                                          marginBottom: '1rem',
                                          marginTop: '1rem',
                                        }}
                                      >
                                        Submit
                                      </CButton>
                                    )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CModal visible={visible} onClose={() => setVisible(false)} size="xl">
              <CModalHeader onClose={() => setVisible(false)}>
                <CModalTitle>Expression Details:</CModalTitle>
              </CModalHeader>
              <CModalBody>
                <h4 style={{ padding: '0.5rem' }}>{expression}</h4>
                <Table>
                  <Thead>
                    <Tr>
                      <Th scope="col">Item</Th>
                      <Th scope="col">Value</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Th scope="row">Classe</Th>
                      <Td> {details.CLASSE} </Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Finca</Th>
                      <Td>{details.FINCA}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Cosecha:</Th>
                      <Td colSpan="2">{details.COSECHA}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Tierra:</Th>
                      <Td colSpan="2">{details.TIERRA}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Pueblo:</Th>
                      <Td colSpan="2">{details.PUEBLO}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Horno:</Th>
                      <Td colSpan="2">{details.HORNO}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Leña:</Th>
                      <Td colSpan="2">{details.LEÑA}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Duración de Horneado:</Th>
                      <Td colSpan="2">{details.DURACIÓN}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Molino:</Th>
                      <Td colSpan="2">{details.MOLINO}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Fermentación:</Th>
                      <Td colSpan="2">{details.FERMENTACIÓN}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Fuente de agua:</Th>
                      <Td colSpan="2">{details.FUENTE}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Destilación:</Th>
                      <Td colSpan="2">{details.DESTILADOR}</Td>
                    </Tr>
                    <Tr>
                      <Th scope="row">Ajustes:</Th>
                      <Td colSpan="2">{details.AJUSTES}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </CModalBody>
              <CModalFooter>
                <CButton color="danger" style={{ color: '#FFF' }} onClick={() => setVisible(false)}>
                  Close
                </CButton>
              </CModalFooter>
            </CModal>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CModal visible={submissionsVisible} onClose={() => setSubmissionsVisible(false)} size="xl">
              <CModalHeader onClose={() => setSubmissionsVisible(false)}>
                <CModalTitle>Submissions:</CModalTitle>
              </CModalHeader>
              <CModalBody>
                <div
                  className="selected-folder"
                  onClick={() => {
                    setSelectedFolder(null);
                  }}
                >
                  {selectedFolder === null ? (
                    'No submission selected'
                  ) : (
                    <span>
                      {' '}
                      Go Back <CIcon style={{ marginLeft: '0.6rem' }} icon={cilArrowCircleLeft} size="lg" />
                    </span>
                  )}
                </div>
                {submissions.length <= 0 && <h5 style={{ color: 'red' }}>No submissions found.</h5>}
                <div className="folders">
                  {selectedFolder === null &&
                    submissions.map((s, i) => {
                      return (
                        <div
                          key={`submission-${i}`}
                          onClick={() => {
                            showSubmissionItems(s);
                          }}
                        >
                          <span>#{i + 1}</span>
                          <div>
                            <CIcon icon={cilFolder} size="lg" />
                            <div>{new Date(s.submission_date).toISOString().substring(0, 10)}</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {selectedFolder !== null && (
                  <>
                    <div className="art-items">
                      {artItems.length === 0 && (
                        <>
                          <h3 style={{ color: 'red' }}>This submission is empty</h3>
                        </>
                      )}
                      {artItems.map((art, idx) => {
                        return (
                          <div key={`art-item-${idx}`}>
                            <img src={`${process.env.REACT_APP_API_URL}/art-submission-item/art/${art.art_url}`} />
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      <CFormLabel>Feedback:</CFormLabel>
                      <CFormTextarea
                        value={commentaries}
                        onChange={(e) => {
                          setCommentaries(e.target.value);
                        }}
                        placeholder="Write a message for the artist"
                        disabled={isFolderReviewed}
                      ></CFormTextarea>
                      <br />

                      {isFolderReviewed ? (
                        <>
                          {wasApproved ? (
                            <>
                              <CButton color="success" style={{ color: 'white' }}>
                                This art was Approved <CIcon style={{ marginLeft: '0.3rem' }} icon={cilCheckCircle} />
                              </CButton>
                            </>
                          ) : (
                            <>
                              <CButton color="danger" style={{ color: 'white' }}>
                                This art was Rejected <CIcon style={{ marginLeft: '0.3rem' }} icon={cilXCircle} />
                              </CButton>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <LButton
                            isLoading={isLoading}
                            color="primary"
                            style={{ marginRight: '1rem' }}
                            onClick={() => {
                              review(true);
                            }}
                          >
                            Approve
                          </LButton>

                          <LButton
                            isLoading={isLoading}
                            color="danger"
                            style={{ color: 'white' }}
                            onClick={() => {
                              review(false);
                            }}
                          >
                            Reject
                          </LButton>
                        </>
                      )}
                    </div>
                  </>
                )}
              </CModalBody>
              <CModalFooter>
                <CButton
                  color="danger"
                  style={{ color: '#FFF', marginRight: '1rem' }}
                  onClick={() => setSubmissionsVisible(false)}
                >
                  Close
                </CButton>
              </CModalFooter>
            </CModal>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default ArtReview;
