import { createContext, useState, useRef } from 'react';

export const ShelfTalkerContext = createContext({});
import domtoimage from 'dom-to-image';
import JsPDF from 'jspdf';

import api from 'src/api';
import { useDispatch } from 'react-redux';
import { setSuccessMessages } from 'src/actions';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

export const ShelfTalkerProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [titleSingleLine1, setSingleLine1] = useState('');
  const [subtitle1, setSubtitle1] = useState('');
  const [subtitle2, setSubtitle2] = useState('');
  const [subtitle3, setSubtitle3] = useState('');

  const [titleLine1, setTitleLine1] = useState('');
  const [titleLine2, setTitleLine2] = useState('');
  const [isMultiline, setIsMultiLine] = useState(true);
  const [textSize, setTextSize] = useState(14);
  const [isImage, setIsImage] = useState(true);
  const [src, setSrc] = useState(null);
  const [descriptions, setDescriptions] = useState([]);
  const shelfTakerRef = useRef(null);
  const [description, setDescription] = useState(
    'Cuentacuentos means "Storytellers".\n We chose the name because every bottle tells a story, every mezcal drinker brings a story.\nBe part of the story.',
  );
  const [background, setBackground] = useState('#FFF');
  const [color, setColor] = useState('#000000');
  const types = { CUENTACUENTOS: 1, TSOOK: 2 };
  const [expressionType, setExpressionType] = useState(types.CUENTACUENTOS);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);

  const [files, setFiles] = useState(null);

  const printShelfTalker = () => {
    if (shelfTakerRef.current) {
      setIsLoading(true);
      domtoimage
        .toPng(shelfTakerRef.current)
        .then(function(dataUrl) {
          const imgData = dataUrl;

          const imgWidth = 3;
          const imgHeight = 5;

          const doc = new JsPDF({
            orientation: 'p',
            unit: 'in',
            format: [8, 11],
          });

          const x = 0.6;
          let y = 0.3;

          const x2 = 4.2;

          // First Row
          doc.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight); // x,y (10,y)
          doc.addImage(imgData, 'PNG', x2, y, imgWidth, imgHeight);

          // Second Row
          y = y + imgHeight + 0.3;

          doc.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
          doc.addImage(imgData, 'PNG', x2, y, imgWidth, imgHeight);

          doc.save('shelf-talker.pdf');
        })
        .catch(function(error) {
          alert('oops, something went wrong!');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      alert('The shelf talker container was not found.');
    }
  };

  const saveShelfTaker = () => {
    setIsSaving(true);

    const data = new FormData();

    data.append('title', title);
    data.append('title_line_1', titleLine1);
    data.append('title_line_2', titleLine2);
    data.append('title_single_line_1', titleSingleLine1);
    data.append('title_single_font_size', textSize);
    data.append('sub_line1', subtitle1);
    data.append('sub_line2', subtitle2);
    data.append('sub_line3', subtitle3);
    data.append('description', description);
    data.append('has_image', isImage ? 1 : 0);
    data.append('image', src === null ? (expressionType === types.CUENTACUENTOS ? 'cuentacuentos' : 'tsok') : 'cuentacuentos');
    data.append('background', background);
    data.append('is_multiline', isMultiline ? 1 : 0);
    if (files !== null && files?.length > 0) {
      data.append('custom_image', files[0]);
    }

    data.append('text_color', color || '#000000');

    if (isEdit) {
      api
        .put(`/shelf-talker/${editId}`, data)
        .then(() => {
          setVisible(false);
          dispatch(
            setSuccessMessages([
              {
                message: 'Your shelf talker has been updated.',
              },
            ]),
          );
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      api
        .post('/shelf-talker', data)
        .then(({ data }) => {
          setVisible(false);
          navigate({
            pathname: '/tools/shelf-talker',
            search: `?id=${data.id}`,
          });
          dispatch(
            setSuccessMessages([
              {
                message: 'Your shelf talker has been saved.',
              },
            ]),
          );
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  return (
    <ShelfTalkerContext.Provider
      value={{
        isSaving,
        setIsSaving,
        titleSingleLine1,
        setTitleLine1,
        subtitle1,
        setSubtitle1,
        subtitle2,
        setSubtitle2,
        subtitle3,
        setSubtitle3,
        titleLine1,
        titleLine2,
        setTitleLine2,
        isMultiline,
        setIsMultiLine,
        textSize,
        setTextSize,
        isImage,
        setIsImage,
        src,
        setSrc,
        descriptions,
        setDescriptions,
        shelfTakerRef,
        description,
        setDescription,
        background,
        setBackground,
        types,
        expressionType,
        setExpressionType,
        saveShelfTaker,
        isLoading,
        setIsLoading,
        printShelfTalker,
        visible,
        setVisible,
        setSingleLine1,
        title,
        setTitle,
        isEdit,
        setIsEdit,
        editId,
        setEditId,
        files,
        setFiles,
        color,
        setColor,
      }}
    >
      {children}
    </ShelfTalkerContext.Provider>
  );
};

ShelfTalkerProvider.propTypes = {
  children: PropTypes.any,
};
