import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CCol,
  CRow,
  CModalBody,
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CFormTextarea,
  CFormSelect,
} from '@coreui/react';

import api from '../../../api';

import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import { useDispatch } from 'react-redux';
import { setSuccessMessages, setErrors } from 'src/actions';

import YesNoModal from 'src/components/YesNoModal';

const LotDetailModal = (props) => {
  const isEditable = props.isEditable;
  const [classificationId, setClassificationId] = useState();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lotId, setLotId] = useState(null);

  const dispatch = useDispatch();

  const deleteLot = () => {
    if (lotId != null) {
      setIsLoading(true);
      api
        .delete('/lot/' + lotId)
        .then(() => {
          dispatch(
            setSuccessMessages([
              {
                message: 'The lot has been deleted successfully.',
              },
            ]),
          );
          props.onLotDeleted(lotId);
          setLotId(null);
        })
        .catch((e) => {
          dispatch(
            setErrors({
              message:
                e?.data?.errors || 'Sorry we are unable to delete this lot.',
            }),
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  function handleSaveSubmit(e) {
    e.preventDefault();
    const extraFields = {
      COMMUN: e.target.elements.nombreCommunInput.value,
      SCIENTIFICO: e.target.elements.nombreScienInput.value,
      GRADOS: e.target.elements.gradosInput.value,
      FINCA: e.target.elements.fincaInput.value,
      ELEVACIÓN: e.target.elements.elevacionInput.value,
      COSECHA: e.target.elements.cosechaInput.value,
      TIERRA: e.target.elements.tierraInput.value,
      PUEBLO: e.target.elements.puebloInput.value,
      HORNO: e.target.elements.hornoInput.value,
      LEÑA: e.target.elements.lenaInput.value,
      DURACIÓN: e.target.elements.duracionInput.value,
      MOLINO: e.target.elements.molinoInput.value,
      FERMENTACIÓN: e.target.elements.fermentacionInput.value,
      TIPO: e.target.elements.tinaInput.value,
      FUENTE: e.target.elements.fuenteInput.value,
      DESTILADOR: e.target.elements.destiladorInput.value,
      NUMERO: e.target.elements.destilacionesInput.value,
      CLASSE: e.target.elements.classeInput.value,
      AJUSTES: e.target.elements.ajustesInput.value,
      NOTAS: e.target.elements.notasInput.value,
    };

    api
      .post('/lotes/lot-details/update', {
        lot_number: props.lotDetail.lot_number,
        lot_extra_fields: extraFields,
        classification_id:
          classificationId || props.lotDetail.classification_id,
      })
      .then(() => {
        props.fetchLotDetails();
      })
      .finally(() => {
        props.setVisible(false);
      });
  }

  const ClassificationsOptions = ({ classifications }) => {
    if (classifications) {
      return classifications.map((c, idx) => {
        return (
          <option value={c.id} key={`option-${idx}`}>
            {c.classification}
          </option>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <>
      <CModalBody>
        <CForm style={{ margin: '5px' }} onSubmit={handleSaveSubmit}>
          <CRow className="mb-2">
            <CCol xs={6} md={4}>
              <CFormLabel className="mb-1" htmlFor="nombreCommunInput">
                Nombre Común
              </CFormLabel>
              <CFormInput
                type="text"
                id="nombreCommunInput"
                defaultValue={props.lotDetail['common_name']}
                size="sm"
                readOnly
              />
            </CCol>
            <CCol xs={6} md={4}>
              <CFormLabel className="mb-1" htmlFor="nombreScienInput">
                Nombre Scientifico
              </CFormLabel>
              <CFormInput
                type="text"
                id="nombreScienInput"
                defaultValue={props.lotDetail.lot_extra_field['SCIENTIFICO']}
                size="sm"
                readOnly
              />
            </CCol>
            <CCol xs={12} md={4}>
              <CFormLabel className="mb-1" htmlFor="maestroInput">
                Maestro Mezcaleo
              </CFormLabel>
              <CFormInput
                type="text"
                id="maestroInput"
                defaultValue={props.lotDetail['maker_name']}
                size="sm"
                readOnly
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol xs={6}>
              <CFormLabel className="mb-1">Classificación</CFormLabel>
              <CFormSelect
                size="sm"
                readOnly={!isEditable}
                value={classificationId}
                defaultValue={props.lotDetail.classification_id}
                onChange={(e) => {
                  if (e.target.value) {
                    setClassificationId(e.target.value);
                  }
                }}
              >
                <option>Choose an option</option>
                <ClassificationsOptions
                  classifications={props.classifications}
                ></ClassificationsOptions>
              </CFormSelect>
            </CCol>
            <CCol xs={6}>
              <CFormLabel className="mb-1" htmlFor="gradosInput">
                Grados
              </CFormLabel>
              <CFormInput
                type="text"
                id="gradosInput"
                defaultValue={props.lotDetail.lot_extra_field['GRADOS']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol xs={12} md={6}>
              <CFormLabel className="mb-1" htmlFor="fincaInput">
                Finca
              </CFormLabel>
              <CFormInput
                type="text"
                id="fincaInput"
                defaultValue={props.lotDetail.lot_extra_field['FINCA']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol xs={12} md={6}>
              <CFormLabel className="mb-1" htmlFor="elevacionInput">
                Elevación
              </CFormLabel>
              <CRow>
                <CCol>
                  <CFormInput
                    type="text"
                    id="elevacionInput"
                    value={props.elevation}
                    onChange={(e) => {
                      props.setElevation(e.target.value);
                    }}
                    size="sm"
                    readOnly={!isEditable}
                  />
                </CCol>
                <CCol>
                  <CButton
                    color="primary"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      props.openElevationModal();
                    }}
                  >
                    Select
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol xs={6} md={4}>
              <CFormLabel className="mb-1" htmlFor="cosechaInput">
                Cosecha
              </CFormLabel>
              <CFormInput
                type="text"
                id="cosechaInput"
                defaultValue={props.lotDetail.lot_extra_field['COSECHA']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol xs={6} md={4}>
              <CFormLabel className="mb-1" htmlFor="tierraInput">
                Tierra
              </CFormLabel>
              <CFormInput
                type="text"
                id="tierraInput"
                defaultValue={props.lotDetail.lot_extra_field['TIERRA']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol xs={12} md={4}>
              <CFormLabel className="mb-1" htmlFor="puebloInput">
                Pueblo
              </CFormLabel>
              <CFormInput
                type="text"
                id="puebloInput"
                defaultValue={props.lotDetail.lot_extra_field['[PUEBLO]']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol xs={6} md={4}>
              <CFormLabel className="mb-1" htmlFor="hornoInput">
                Horno
              </CFormLabel>
              <CFormInput
                type="text"
                id="hornoInput"
                defaultValue={props.lotDetail.lot_extra_field['HORNO']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol xs={6} md={4}>
              <CFormLabel className="mb-1" htmlFor="lenaInput">
                Leña
              </CFormLabel>
              <CFormInput
                type="text"
                id="lenaInput"
                defaultValue={props.lotDetail.lot_extra_field['LEÑA']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol xs={12} md={4}>
              <CFormLabel className="mb-1" htmlFor="duracionInput">
                Duración de Horneado
              </CFormLabel>
              <CFormInput
                type="text"
                id="duracionInput"
                defaultValue={props.lotDetail.lot_extra_field['DURACIÓN']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol xs={6} md={4}>
              <CFormLabel className="mb-1" htmlFor="molinoInput">
                Molino
              </CFormLabel>
              <CFormInput
                type="text"
                id="molinoInput"
                defaultValue={props.lotDetail.lot_extra_field[' MOLINO']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol xs={6} md={4}>
              <CFormLabel className="mb-1" htmlFor="fermentacionInput">
                Fermentación
              </CFormLabel>
              <CFormInput
                type="text"
                id="fermentacionInput"
                defaultValue={props.lotDetail.lot_extra_field['FERMANTACIÓN']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol xs={12} md={4}>
              <CFormLabel className="mb-1" htmlFor="tinaInput">
                Tipo de Tina
              </CFormLabel>
              <CFormInput
                type="text"
                id="tinaInput"
                defaultValue={props.lotDetail.lot_extra_field['TIPO']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
          </CRow>

          <CRow className="mb-2">
            <CCol xs={6}>
              <CFormLabel className="mb-1" htmlFor="fuenteInput">
                Fuente de Agua
              </CFormLabel>
              <CFormInput
                type="text"
                id="fuenteInput"
                defaultValue={props.lotDetail.lot_extra_field['FUENTE']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel className="mb-1" htmlFor="destiladorInput">
                Tipo de Destilador
              </CFormLabel>
              <CFormInput
                type="text"
                id="destiladorInput"
                defaultValue={props.lotDetail.lot_extra_field['DESTILADOR']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol xs={12}>
              <CFormLabel className="mb-1" htmlFor="destilacionesInput">
                Numero de Destilaciones
              </CFormLabel>
              <CFormInput
                type="text"
                id="destilacionesInput"
                defaultValue={props.lotDetail.lot_extra_field['NUMERO']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
          </CRow>

          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="classeInput">
                Clase
              </CFormLabel>
              <CFormInput
                type="text"
                id="classeInput"
                defaultValue={props.lotDetail.lot_extra_field['CLASSE']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="ajustesInput">
                Ajustes
              </CFormLabel>
              <CFormInput
                type="text"
                id="ajustesInput"
                defaultValue={props.lotDetail.lot_extra_field['AJUSTES']}
                size="sm"
                readOnly={!isEditable}
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="notasInput">
                Notas
              </CFormLabel>
              <CFormTextarea
                id="notasInput"
                size="sm"
                readOnly={!isEditable}
                defaultValue={props.lotDetail.lot_extra_field['NOTAS']}
              />
            </CCol>
          </CRow>
          <div style={{ marginTop: '10px' }}>
            {isEditable && (
              <CButton type="submit" color="primary">
                Save
              </CButton>
            )}
            <CButton
              type="button"
              color="danger"
              onClick={(e) => {
                e.preventDefault();
                props.setVisible(false);
              }}
              style={{ marginLeft: '10px', color: 'white' }}
            >
              Close
            </CButton>
            <CButton
              color="danger"
              style={{ color: 'white' }}
              className="float-end"
              onClick={(e) => {
                e.preventDefault();
                setLotId(props.lotDetail.lot_id);
                setDeleteVisible(true);
              }}
              type="button"
            >
              <CIcon icon={cilTrash} style={{ color: 'white' }} />
            </CButton>
          </div>
        </CForm>
      </CModalBody>
      <YesNoModal
        title="Delete Lot"
        description={
          <>
            <b style={{ color: 'red' }}>WARNING:</b>
            <p>You cannot undo this action. </p>
            <p>Lots in production will be deleted.</p>
            <p>
              By Deleting the Lot you will also affect Purchases and other data
              linked to this lot.
            </p>
          </>
        }
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        onYes={() => {
          deleteLot();
        }}
        onNo={() => {
          setDeleteVisible(false);
        }}
        isLoading={isLoading}
      />
    </>
  );
};

LotDetailModal.defaultProps = {
  isEditable: true,
};

LotDetailModal.propTypes = {
  elevation: PropTypes.any,
  setElevation: PropTypes.func,
  lotDetail: PropTypes.any,
  setVisible: PropTypes.any,
  fetchLotDetails: PropTypes.any,
  isEditable: PropTypes.bool,
  classifications: PropTypes.array,
  openElevationModal: PropTypes.func,
  onLotDeleted: PropTypes.func,
};

export default LotDetailModal;
