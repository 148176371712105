import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CRow } from '@coreui/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { setErrors } from 'src/actions';
import api from 'src/api';
import LButton from '../LButton/LButton';
import { useDispatch } from 'react-redux';

const NewMiscelaneous = ({ show, setShow, onComplete, isEdit, editId, item }) => {
  const types = ['SERVICE', 'MISCELLANEOUS'];
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [type, setType] = useState('miscellaneous');
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (item?.id) {
      setName(item.name);
      setType(item.type.toLowerCase());
      setPrice(item.price);
    }
  }, [item]);

  const save = () => {
    if (!saving) {
      if (name.length <= 0) {
        return toast.error('Enter an item name');
      }

      if (price.length <= 0) {
        return toast.error('Enter a price');
      } else if (isNaN(parseFloat(price))) {
        return toast.error('Enter a valid price');
      } else if (parseFloat(price) <= 0) {
        return toast.error('The price can\'t be lower or equal to zero');
      }
      setSaving(true);

      let promise;

      if (isEdit) {
        promise = api.put(`/miscellaneous/${item.id}`, {
          name,
          price,
          type,
        });
      } else {
        promise = api.post('/miscellaneous', {
          name,
          price,
          type,
        });
      }

      promise
        .then(() => {
          toast.success('The item has been ' + isEdit ? 'updated' : 'created');
          onComplete();
        })
        .catch((e) => {
          dispatch(setErrors([{ message: e }]));
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const cancel = () => {
    setName('');
    setPrice('');
    setType('miscellaneous');
    setShow(false);
  };

  return (
    <>
      <CModal visible={show} onClose={cancel} backdrop={`static`}>
        <CModalHeader>{isEdit ? 'Update Item' : 'New Item'}</CModalHeader>
        <CModalBody>
          <CRow className="mb-3">
            <CCol>
              <CFormLabel htmlFor="itemName">Item Name</CFormLabel>
              <CFormInput
                type="text"
                id="itemName"
                value={name}
                placeholder="Item Name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </CCol>
            <CCol>
              <CFormLabel htmlFor="priceInput">Price (USD)</CFormLabel>
              <CFormInput
                type="text"
                id="priceInput"
                value={price}
                placeholder="0.00"
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CFormSelect className="text-capitalize" value={type} onChange={(e) => setType(e.target.value)}>
                {types.map((type, idx) => {
                  return (
                    <option value={type.toLowerCase()} key={`type-${idx}`}>
                      {type.toLowerCase()}
                    </option>
                  );
                })}
              </CFormSelect>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <LButton isLoading={saving} onClick={save}>
            {isEdit ? 'Update' : 'Add New'} Item
          </LButton>
          <CButton color="danger" onClick={cancel}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

NewMiscelaneous.propTypes = {
  show: PropTypes.bool,
  isEdit: PropTypes.bool,
  setShow: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  editId: PropTypes.number,
  item: PropTypes.object,
};

export default NewMiscelaneous;
