import { useRef, useState, useEffect, useCallback } from 'react';
import api from 'src/api';

import domtoimage from 'dom-to-image';
import JsPDF from 'jspdf';

import PropTypes from 'prop-types';

function PalletsBoxes({ cpoId, onFinish, downloadPdf }) {
  const canvasContainer = useRef(null);

  const [pallets750, setPallets750] = useState(null);
  const [pallets1000, setPallets1000] = useState(null);
  const [fillCounter, setFillCounter] = useState(0);

  const [mix750, setMix750] = useState(null);
  const [mix1000, setMix1000] = useState(null);

  const [, setPdfInstance] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    if (cpoId) {
      api.get('/reports/pallet-items/' + cpoId, { signal: controller.signal }).then(({ data }) => {
        setPallets750(data.box_pallets_750);
        setPallets1000(data.box_pallets_1000);
        setMix750(data.detached750 || []);
        setMix1000(data.detached1000 || []);
      });
    }

    return () => {
      controller.abort();
      if (canvasContainer.current) {
        canvasContainer.current.textContent = '';
      }
    };
  }, [cpoId]);

  function draw750Boxes(itemsP750) {
    if (itemsP750 !== null) {
      itemsP750.forEach((items, i) => {
        const perLevel = 30;
        const simplifiedItems = simplify(items);
        const layers = chunkArrayInGroups(simplifiedItems, perLevel);
        let isVertical = true;

        layers.forEach((itemsOfLayer, layer) => {
          const { canvas, ctx } = addCanvas(`Pallet # ${i + 1} <small> - Layer: ${layer + 1}</small> -  CPO: ${cpoId}`, '100cm x 120cm');

          fillCanvasWithBoxes(canvas, ctx, itemsOfLayer, {
            initialOrientation: isVertical ? 'VERTICAL' : 'HORIZONTAL',
          });
          isVertical = !isVertical;
        });
        const spacer = document.createElement('div');
        spacer.style.height = '30px';
        canvasContainer.current.appendChild(spacer);
      });

      const spacerDash = document.createElement('div');
      spacerDash.style.border = '3px dashed #eee';
      canvasContainer.current.appendChild(spacerDash);

      if (canvasContainer.current) {
        setFillCounter(fillCounter + 1);
      }
    }
  }

  const draw1000Boxes = (itemsP1000) => {
    if (itemsP1000 !== null) {
      itemsP1000.forEach((items, i) => {
        const perLevel = 20;
        const simplifiedItems = simplify(items);
        const layers = chunkArrayInGroups(simplifiedItems, perLevel);
        let isVertical = true;
        layers.forEach((itemsOfLayer, layer) => {
          const { canvas, ctx } = addCanvas(`Pallet # ${i + 1 + pallets750.length} <small> - Layer: ${layer + 1}</small>  -  CPO: ${cpoId}`, '120cm x 100cm');
          fillCanvasWithBoxes(canvas, ctx, itemsOfLayer, {
            initialOrientation: isVertical ? 'VERTICAL' : 'HORIZONTAL',
            boxDimensions: [18, 26],
            pWidth: 120,
            pHeight: 100,
          });
          isVertical = !isVertical;
        });

        const spacer = document.createElement('div');
        spacer.style.height = '30px';
        canvasContainer.current.appendChild(spacer);
      });
      if (canvasContainer.current) {
        setFillCounter(fillCounter + 1);
      }
    }
  };

  function draw750BoxesGrouped(itemsP750) {
    if (itemsP750 !== null) {
      const perLevel = 30;
      const layers = chunkArrayInGroups(itemsP750, perLevel);
      let isVertical = true;

      layers.forEach((itemsOfLayer, layer) => {
        const { canvas, ctx } = addCanvas(`Cajas Mixtas 750ml -  CPO: ${cpoId}`, '100cm x 120cm');
        fillCanvasWithBoxes(canvas, ctx, itemsOfLayer, {
          initialOrientation: isVertical ? 'VERTICAL' : 'HORIZONTAL',
        });
        isVertical = !isVertical;
      });
      const spacer = document.createElement('div');
      spacer.style.height = '30px';
      canvasContainer.current.appendChild(spacer);
      const spacerDash = document.createElement('div');
      spacerDash.style.border = '3px dashed #eee';
      canvasContainer.current.appendChild(spacerDash);
      if (canvasContainer.current) {
        setFillCounter(fillCounter + 1);
      }
    }
  }
  const draw1000BoxesGrouped = (itemsP1000) => {
    if (itemsP1000 !== null) {
      const perLevel = 20;
      const layers = chunkArrayInGroups(itemsP1000, perLevel);
      let isVertical = true;
      layers.forEach((itemsOfLayer, layer) => {
        const { canvas, ctx } = addCanvas(`Cajas Mixtas 1L -  CPO: ${cpoId}`, '120cm x 100cm');
        fillCanvasWithBoxes(canvas, ctx, itemsOfLayer, {
          initialOrientation: isVertical ? 'VERTICAL' : 'HORIZONTAL',
          boxDimensions: [18, 26],
          pWidth: 120,
          pHeight: 100,
        });
        isVertical = !isVertical;
      });

      const spacer = document.createElement('div');
      spacer.style.height = '30px';
      canvasContainer.current.appendChild(spacer);

      if (canvasContainer.current) {
        setFillCounter(fillCounter + 1);
      }
    }
  };

  useEffect(() => {
    draw750Boxes(pallets750);
  }, [pallets750, canvasContainer]);

  useEffect(() => {
    draw1000Boxes(pallets1000);
  }, [pallets1000, canvasContainer]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (canvasContainer.current) {
        let totalImagesAdded = 0;
        let pdf;
        if (downloadPdf) {
          pdf = new JsPDF('p', 'mm', 'a4', true);
          setPdfInstance(pdf);
        }

        const pages = canvasContainer.current.querySelectorAll('.page');
        const addPages = () => {
          return new Promise(async (resolve) => {
            let counter = 0;
            let counter2 = 0;
            let yInc = 0;
            for (let i = 0; i < pages.length; i++) {
              const page = pages[i];
              const scale = 3;
              const dataUrl = await domtoimage.toPng(page, {
                width: page.clientWidth * scale,
                height: page.clientHeight * scale,
                style: {
                  transform: 'scale(' + scale + ')',
                  transformOrigin: 'top left',
                },
              });
              const htmlImage = new Image();
              htmlImage.src = dataUrl;

              if (i + 1 < pages.length) {
                // pdf.addPage()
              }
              if (counter === 4) {
                if (downloadPdf) {
                  pdf.addPage();
                }
                counter = 0;
              }
              if (counter2 === 2) {
                counter2 = 0;
                if (yInc > 0) {
                  yInc = 0;
                } else {
                  yInc += 120;
                }
              }
              if (downloadPdf) {
                totalImagesAdded++;
                pdf.addImage(htmlImage, 100 * counter2 + 10, yInc + 10, 100 * 2, 50 * 2);
              }
              counter++;
              counter2++;
            }
            resolve();
          });
        };

        addPages().then(() => {
          if (downloadPdf && totalImagesAdded > 0) {
            pdf.save(`pallet-map-${cpoId}.pdf`);
            pdf = null;
          }
          onFinish();
        });
      }
    }, 600);

    if (fillCounter > 10) {
      interval();
    }

    return () => {
      clearTimeout(interval);
    };
  }, [fillCounter, canvasContainer]);

  const simplifyMixed = (items) => {
    const newItems = [];
    items.forEach((item) => {
      if (item?.dettached > 0) {
        for (let i = 0; i < item.dettached; i++) {
          newItems.push({ lot_number: item.lot_number });
        }
      }
    });
    return newItems;
  };

  function groupedMixed(items) {
    const newItems = simplifyMixed(items);
    const bottlesPerGroup = 6;
    let counter = 0;
    const grouped = [];
    let bottles = [];
    newItems.forEach((item, i) => {
      bottles.push({ lot_number: item.lot_number });
      counter++;
      if (counter === bottlesPerGroup || newItems.length - 1 === i) {
        counter = 0;
        grouped.push({ bottles: bottles });
        bottles = [];
      }
    });
    return grouped;
  }

  useEffect(() => {
    if (mix750 != null) {
      const grouped = groupedMixed(mix750);
      draw750BoxesGrouped(grouped);
    }
  }, [mix750]);

  useEffect(() => {
    if (mix1000 != null) {
      const grouped = groupedMixed(mix1000);
      draw1000BoxesGrouped(grouped);
    }
  }, [mix1000]);

  function chunkArrayInGroups(arr, size) {
    const myArray = [];
    for (let i = 0; i < arr.length; i += size) {
      myArray.push(arr.slice(i, i + size));
    }
    return myArray;
  }

  const addCanvas = useCallback(
    (title, subtitle) => {
      const container = document.createElement('div');
      container.classList.add('page');
      const titleH1 = document.createElement('h2');
      titleH1.style.color = '#000000';
      titleH1.style.fontWeight = 'bold';

      const st = document.createElement('p');
      st.innerHTML = subtitle;

      const canvas = document.createElement('canvas');
      canvas.style.backgroundColor = '#fff';
      canvas.style.border = '1px solid #ccc';

      titleH1.innerHTML = title;

      canvasContainer.current.appendChild(container);

      container.appendChild(titleH1);
      container.appendChild(st);
      container.appendChild(canvas);

      const ctx = canvas.getContext('2d');

      return { canvas, ctx };
    },
    [canvasContainer],
  );

  function simplify(items) {
    const simplified = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      for (let j = 0; j < item.boxes; j++) {
        simplified.push({ lot: item.lot_number });
      }
    }
    return simplified;
  }

  function fillCanvasWithBoxes(canvas, ctx, items, settingsOverrides) {
    const settings = {
      scale: 4,
      pWidth: 100, // Pallet width
      pHeight: 120, // Pallet height
      boxDimensions: [16, 24],
      initialOrientation: 'HORIZONTAL',
    };

    settingsOverrides = settingsOverrides || {};
    // override default settings
    Object.keys(settingsOverrides).forEach((key) => {
      settings[key] = settingsOverrides[key];
    });

    canvas.width = settings.pWidth * settings.scale;
    canvas.height = settings.pHeight * settings.scale;

    const verticalCond = settings.boxDimensions[0] >= settings.boxDimensions[1];

    const vertical = {
      height: (verticalCond ? settings.boxDimensions[0] : settings.boxDimensions[1]) * settings.scale,
      width: (verticalCond ? settings.boxDimensions[1] : settings.boxDimensions[0]) * settings.scale,
    };

    const horizontal = {
      width: vertical.height,
      height: vertical.width,
    };

    const positions = {
      HORIZONTAL: Math.floor((settings.pWidth * settings.scale) / horizontal.width),
      // We can place X boxes every row.
      VERTICAL: Math.floor((settings.pWidth * settings.scale) / vertical.width),
    };

    /**
     * Calculate the amount of free space that we have in a row after placing
     *  the maximum amount of boxes then use that value to get the value
     *  of the gap that will put the same amount of space between boxes.
     */
    const verticalGap = (settings.pWidth * settings.scale - positions.VERTICAL * vertical.width) / (positions.VERTICAL - 1);
    const horizontalGap = (settings.pWidth * settings.scale - positions.HORIZONTAL * horizontal.width) / (positions.HORIZONTAL - 1);

    let currentPosition;

    if (settings.initialOrientation === 'HORIZONTAL') {
      currentPosition = positions.HORIZONTAL;
    } else {
      currentPosition = positions.VERTICAL;
    }

    let currentBox = 0;
    let yIncrement = 0;

    items.forEach((item) => {
      /**
       * If the position's value is equal to the current box
       * (Eg: VERTICAL: 6 and current_box+1 = 6)
       * and the position is VERTICAL then we need to swap and
       *  fill horizontal boxes.
       */
      const swapToHorizontal = positions.VERTICAL === currentBox && currentPosition === positions.VERTICAL;
      const swapToVertical = positions.HORIZONTAL === currentBox && currentPosition === positions.HORIZONTAL;

      if (swapToHorizontal) {
        currentPosition = positions.HORIZONTAL;
        currentBox = 0;
      }
      if (swapToVertical) {
        currentPosition = positions.VERTICAL;
        currentBox = 0;
      }
      let x;
      let y;
      if (swapToHorizontal) {
        yIncrement += vertical.height;
      }
      if (swapToVertical) {
        yIncrement += horizontal.height;
      }
      // const color = '#D2B9B1';
      let _width;
      let _height;

      if (positions.VERTICAL === currentPosition) {
        x = currentBox * vertical.width + currentBox * verticalGap;
        y = yIncrement;
        _width = vertical.width;
        _height = vertical.height;
      } else {
        x = currentBox * horizontal.width + currentBox * horizontalGap;
        y = yIncrement;
        _width = horizontal.width;
        _height = horizontal.height;
      }

      if (item?.bottles?.length > 0) {
        drawWithBottles(ctx, x, y, _width, _height, item, yIncrement, 'black');
      } else {
        drawBoxWText(ctx, x, y, _width, _height, item.lot, yIncrement, 'orange');
      }
      currentBox++;
    });
  }

  function drawEmptyBox(ctx, x, y, _width, _height, color) {
    drawSquare(ctx, x, y, _width, _height, color);
  }

  function drawWithBottles(ctx, x, y, _width, _height, item, yIncrement, color) {
    const bottlesPerRow = 3;
    const bottleWidth = Math.floor(_width / bottlesPerRow);
    const bottleHeight = _height / 2;

    const halfOfX = x + _width / 2;
    const increment = _height / 6.3;
    let counter = increment;

    drawEmptyBox(ctx, x, y, _width, _height, color);

    item.bottles.forEach((bottle, i) => {
      // let _x;
      // let _y = y + i * 2;
      let a;
      if (i >= bottlesPerRow) {
        a = i - bottlesPerRow;
      } else {
        a = i;
      }
      const _x = x + a * bottleWidth;
      // _y = y + a * bottleHeight;

      if (i === bottlesPerRow) {
        y += bottleHeight;
        // y_inc = bottle_height
      }
      drawEmptyBox(ctx, _x, y, bottleWidth, bottleHeight, '#ccc');
      ctx.font = '10px helvetica';
      ctx.textAlign = 'center';
      ctx.fillStyle = 'black';
      /**
       * ctx.fillText(bottle.lot_number,
       *  _x + (bottle_width/2) , y +bottle_height/2)
       */

      // const x_text = x * 3;
      ctx.fillText(bottle.lot_number, halfOfX, counter);
      ctx.closePath();
      counter += increment;
    });
  }

  function drawBoxWText(ctx, x, y, _width, _height, text, yIncrement, color) {
    drawEmptyBox(ctx, x, y, _width, _height, color);
    drawText(ctx, text, x + _width / 2, yIncrement + _height / 2);
  }

  function drawSquare(ctx, x, y, dimA, dimB, color) {
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.rect(x, y, dimA, dimB);
    ctx.stroke();
    ctx.fillStyle = color;
    ctx.closePath();
  }

  function drawText(ctx, text, x, y) {
    ctx.beginPath();
    ctx.font = '10.2px Arial';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.fillText(text, x, y);
    ctx.stroke();
    ctx.closePath();
  }

  return (
    <>
      <div className="canvasContainer">
        <div ref={canvasContainer}></div>
      </div>
    </>
  );
}

PalletsBoxes.defaultProps = {
  onFinish: () => {
    // console.warn("The onFinish Fn is not defined")
  },
  downloadPdf: true,
};

PalletsBoxes.propTypes = {
  cpoId: PropTypes.any,
  onFinish: PropTypes.func,
  downloadPdf: PropTypes.bool,
};

export default PalletsBoxes;
