import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CFormInput,
  CFormLabel,
  CButton,
} from '@coreui/react';

import LButton from 'src/components/LButton/LButton';

import { useSelector } from 'react-redux';
import {
  ShelfTalkerProvider,
  ShelfTalkerContext,
} from '../../context/ShelfTalkerContext';

import { useContext, useEffect } from 'react';
import ShelfTalker from 'src/components/ShelfTalker/ShelfTalker';

import api from 'src/api';

import YesNoModal from 'src/components/YesNoModal';
import useQuery from 'src/hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const Content = (props) => {
  const query = useQuery();

  // const [talker, setTalker] = useState({});
  const loggedIn = useSelector((state) => {
    return state.auth.loggedIn;
  });

  const {
    saveShelfTaker,
    isLoading,
    printShelfTalker,
    isSaving,
    visible,
    setVisible,
    title,
    setTitle,
    setBackground,
    setIsMultiLine,
    setIsImage,
    setSubtitle1,
    setSubtitle2,
    setSubtitle3,
    setTitleLine1,
    setTitleLine2,
    setDescription,
    isEdit,
    setIsEdit,
    setTextSize,
    setSingleLine1,
    types,
    setExpressionType,
    setEditId,
    setSrc,
    setColor,
  } = useContext(ShelfTalkerContext);

  const navigate = useNavigate();

  useEffect(() => {
    const id = query.get('id');
    if (id) {
      setIsEdit(true);
      setEditId(id);
      api
        .get(`/shelf-talker/${id}`)
        .then(({ data }) => {
          // setTalker(data);

          setTitleLine1(data.title_line_1);
          setTitleLine2(data.title_line_2);
          setSingleLine1(data.title_single_line_1);
          setTextSize(data.title_single_font_size);

          setTitle(data.title);
          setBackground(data.background);
          setIsMultiLine(data.is_multiline);
          setIsImage(data.has_image);
          setSubtitle1(data.sub_line1);
          setSubtitle2(data.sub_line2);
          setSubtitle3(data.sub_line3);

          setDescription(data.description);
          setIsImage(data.has_image);
          if (data.image === 'cuentacuentos') {
            setExpressionType(types.CUENTACUENTOS);
          } else if (data.image === 'tsok') {
            setExpressionType(types.TSOOK);
          }
          if (data.custom_img) {
            setSrc(
              process.env.REACT_APP_API_URL +
                `/shelf-talker/img/${data.custom_img}`,
            );
          }
          if (data.text_color) {
            setColor(data.text_color);
          }
        })
        .catch((e) => {
          alert(e);
        });
    }
  }, [query]);

  return (
    <CContainer>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <CCol sm={5}>
                <strong>Tools</strong> <small>Shelf Talker</small>
              </CCol>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs={12} md={8}>
                  <ShelfTalker />
                </CCol>
                <CCol xs={12} md={4} style={{ paddingLeft: '1rem' }}>
                  <h1>{isEdit ? 'Edit Shelf Talker' : 'New Shelf Talker'}</h1>
                  <hr />
                  {props.loggedIn && (
                    <div className="mb-3">
                      <CFormLabel>
                        Set a title for your shelf Talker so that you can
                        identify it later.
                      </CFormLabel>
                      <CFormInput
                        placeholder="Title"
                        value={title || ''}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                  )}

                  {props.loggedIn ? (
                    <LButton
                      color="success"
                      style={{ marginRight: '0.3rem' }}
                      onClick={() => {
                        setVisible(true);
                      }}
                      isLoading={isSaving}
                      className="mb-3"
                    >
                      {isEdit ? 'Save Changes' : 'Save Shelf Talker'}
                    </LButton>
                  ) : (
                    <LButton
                      color="success"
                      style={{ marginRight: '0.3rem' }}
                      onClick={() => {
                        navigate('/sign-up');
                      }}
                      className="mb-3"
                    >
                      CREATE ACCOUNT to Save
                    </LButton>
                  )}

                  <LButton
                    isLoading={isLoading}
                    color="primary"
                    onClick={printShelfTalker}
                    className="mb-3"
                  >
                    Download PDF.
                  </LButton>
                  {loggedIn && (
                    <CButton
                      color="dark"
                      className="mb-3"
                      onClick={() => {
                        navigate('/dashboard/tools/my-shelf-talkers');
                      }}
                    >
                      Saved Shelf Talkers
                    </CButton>
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <YesNoModal
            title={isEdit ? 'Save Changes' : 'Save Shelf Talker'}
            description={
              <>
                <p>
                  {isEdit ? 'Save your Shelf Talker' : 'Save your shelf talker'}
                </p>
              </>
            }
            visible={visible}
            setVisible={setVisible}
            onNo={() => {}}
            onYes={saveShelfTaker}
            isLoading={isSaving}
          />
        </CCol>
      </CRow>
    </CContainer>
  );
};

Content.propTypes = {
  loggedIn: PropTypes.bool,
};

const ShelfTalkerPage = () => {
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  return (
    <ShelfTalkerProvider>
      <Content loggedIn={loggedIn} />
    </ShelfTalkerProvider>
  );
};

export default ShelfTalkerPage;
