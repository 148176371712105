import { useState } from 'react';
import LButton from '../LButton/LButton';
import TheIcon from '../TheIcon/TheIcon';
import api from 'src/api';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';
import useDownload from 'src/hooks/useDownload';
import PropTypes from 'prop-types';

function DownloadExcel({ cpoId }) {
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const dispatch = useDispatch();
  const downloader = useDownload();

  const downloadExcel = () => {
    setDownloadingExcel(true);
    api
      .post(`/cpo/excel/${cpoId}`)
      .then(({ data }) => {
        if (data.success && data.excel) {
          downloader.download(`${process.env.REACT_APP_API_URL}/${data.excel}`);
        } else {
          dispatch(setErrors([{ message: 'The server didn\'t return the excel file. Contact the admin.' }]));
        }
      })
      .catch((err) => {
        dispatch(setErrors([{ message: err }]));
      })
      .finally(() => {
        setDownloadingExcel(false);
      });
  };

  return (
    <>
      <LButton color="success" isLoading={downloadingExcel} onClick={downloadExcel}>
        <TheIcon name="excel" size={20} /> Excel
      </LButton>
    </>
  );
}

DownloadExcel.propTypes = {
  cpoId: PropTypes.any,
};

export default DownloadExcel;
