import { CButton, CCol, CFormInput, CFormLabel, CModal, CModalBody, CModalHeader, CRow } from '@coreui/react';
import { useEffect, useState } from 'react';
import api from 'src/api';
import FilterableSelect from '../FilterableSelect/FilterableSelect';
import { useDispatch, useSelector } from 'react-redux';
import { setErrors, setMiscellaneous } from 'src/actions';
import LButton from '../LButton/LButton';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

const AddMiscCart = ({ cpoId }) => {
  const [showAddMisc, setShowAddMisc] = useState(false);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState('');
  const [adding, setAdding] = useState(false);
  const [qty, setQty] = useState('');
  const { misc } = useSelector((state) => state.order);
  const currency = useSelector((state) => state.currency.currency);
  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    api
      .get('/miscellaneous', {
        signal: controller.signal,
        params: {
          currency,
        },
      })
      .then(({ data }) => {
        setItems(
          data.map((it) => {
            it.composedName = `${it.name} - ${it.type}`;
            return it;
          }),
        );
      })
      .catch((e) => {
        dispatch(setErrors([{ message: e }]));
      });
  }, [currency]);

  const addItem = () => {
    if (!item) {
      return toast.error('Please choose an item');
    }
    if (qty.length <= 0) {
      return toast.error('Please enter the quantity of items sold.');
    } else if (isNaN(parseFloat(qty))) {
      return toast.error('Please choose a valid quantity');
    } else if (parseFloat(qty) <= 0) {
      return toast.error('Please choose a quantity greater than zero');
    }
    setAdding(true);
    api
      .post(`/cpo-miscellaneous/${cpoId}`, {
        item,
        qty,
      })
      .then(({ data }) => {
        dispatch(setMiscellaneous([...misc, data]));
        toast.success('Added! (must add to the store)');
        setQty('');
        setItem('');
        setShowAddMisc(false);
      })
      .catch((e) => {
        dispatch(setErrors([{ message: e }]));
      })
      .finally(() => {
        setAdding(false);
      });
  };

  return (
    <>
      <CButton
        color="info"
        onClick={() => {
          setShowAddMisc(true);
        }}
      >
        Add Miscellaneous / Service
      </CButton>
      <CModal
        visible={showAddMisc}
        onClose={() => {
          setShowAddMisc(false);
        }}
        backdrop={'static'}
      >
        <CModalHeader>Add Item</CModalHeader>
        <CModalBody>
          <CRow>
            <CCol>
              <CFormLabel>Search Item</CFormLabel>
              <FilterableSelect
                options={items}
                text={'composedName'}
                value={'id'}
                defaultValue={item}
                onChange={(val) => {
                  setItem(val);
                }}
              ></FilterableSelect>
            </CCol>
          </CRow>
          <CRow className="my-3">
            <CCol>
              <CFormLabel>Quantity</CFormLabel>
              <CFormInput
                placeholder="Qty"
                value={qty}
                onChange={(e) => {
                  setQty(e.target.value);
                }}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <LButton isLoading={adding} onClick={addItem}>
                Add Item
              </LButton>
              <CButton
                color="danger"
                className="ms-1"
                onClick={() => {
                  setShowAddMisc(false);
                }}
              >
                Cancel
              </CButton>
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
    </>
  );
};

AddMiscCart.propTypes = {
  cpoId: PropTypes.number.isRequired,
};

export default AddMiscCart;
