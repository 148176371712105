import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CCol, CRow, CForm, CFormLabel, CButton } from '@coreui/react';
import { useState } from 'react';
import api from 'src/api';
import FilterableSelect from 'src/components/FilterableSelect/FilterableSelect';

const MakerSelectDiv = (props) => {
  const [makersList, setMakersList] = useState([]);
  const [makerId, setMakerId] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    api.get('/contacts/mezcaleros', { signal: controller.signal }).then(({ data }) => {
      setMakersList(data);
    });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <CForm
      style={{ margin: '10px' }}
      onSubmit={(e) => {
        e.preventDefault();
        props.setChosenMaker(makerId);
        props.setMakersSelectVisible(false);
        props.setMagueysSelectVisible(true);
      }}
    >
      <CRow className="mb-2">
        <CFormLabel className="mb-1" htmlFor="makerSelect">
          Select the Maker of the new Purchase:
        </CFormLabel>
        <FilterableSelect
          options={makersList}
          value="id"
          text="maker_name"
          onChange={(makerId) => {
            setMakerId(makerId);
          }}
        ></FilterableSelect>
      </CRow>
      <CRow>
        <CCol>
          <CButton type="submit" color="primary">
            Next
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

MakerSelectDiv.propTypes = {
  makersApiUrl: PropTypes.any,
  setMakersSelectVisible: PropTypes.any,
  setMagueysSelectVisible: PropTypes.any,
  setChosenMaker: PropTypes.any,
};

export default MakerSelectDiv;
