import TheIcon from './TheIcon/TheIcon';
import PropTypes from 'prop-types';

const AlphabetSort = ({startFromA}) => {
  return (<><TheIcon name={startFromA === null ? 'sort_az_none' : startFromA ? 'sort_az' : 'sort_za'} className="cursor-pointer" /></>);
};

AlphabetSort.propTypes = {
  startFromA: PropTypes.bool,
};


export default AlphabetSort;
