import React, {useState} from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CContainer,
} from '@coreui/react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import api from 'src/api';
import {Helmet} from 'react-helmet';


const Rates = () => {
  const [startDate, setStartDate] = useState();

  const [rates, setRates] = useState([]);

  const onDateChange = (date) => {
    setStartDate(date);

    api
        .get('/rates/by-date', {
          params: {
            date: new Date(date).toISOString().slice(0, 10),
          },
        })
        .then(({data}) => {
          if (data.rates) {
            setRates(data.rates);
          }
        });
  };

  const tableRows = rates.map((item) => {
    return (
      <Tr key={`tr-${item}`}>
        <Td>{item.currency}</Td>
        <Td>{item.rate}</Td>
      </Tr>
    );
  });
  return (
    <CContainer>
      <Helmet>
        <title>
          Rates
        </title>
      </Helmet>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>FINANCIAL</strong> <small>Rates</small>
            </CCardHeader>
            <CCardBody>
              <DatePicker selected={startDate} onChange={onDateChange} />
              <hr />
              {startDate && (
                <div className="table-container">
                  <Table>
                    <Thead>
                      <Tr>
                        <Th scope="col">Currency</Th>
                        <Th scope="col">Rate</Th>
                      </Tr>
                    </Thead>
                    <Tbody>{tableRows}</Tbody>
                  </Table>
                </div>
              )}
              {startDate && !rates.length && (
                <p>No data available for the selected date.</p>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default Rates;
