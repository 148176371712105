import React, { useState, useEffect, useCallback } from 'react';
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardTitle,
  CButton,
  CListGroup,
  CListGroupItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CForm,
  CFormLabel,
  CFormInput,
  CAlert,
  CFormTextarea,
} from '@coreui/react';

import api from 'src/api';
import ChangePassword from 'src/components/ChangePassword';

import { useDispatch, useSelector } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';

import { UploadBoxProvider } from 'react-upload-component';
import AvatarUpload from 'src/components/AvatarUpload/AvatarUpload';
import FilterableSelect from 'src/components/FilterableSelect/FilterableSelect';
import LButton from 'src/components/LButton/LButton';
import useValidator from '../../hooks/useValidator';
import { Helmet } from 'react-helmet';

import Security from './Security';

const Settings = () => {
  const [username, setUsername] = useState('');
  const [visiblePwdModal, setVisiblePwdModal] = useState(false);
  const [visibleEmailModal, setVisibleEmailModal] = useState(false);
  const [emailField, setEmailField] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState('');
  const [visibleCurrencyModal, setVisibleCurrencyModal] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [savingCurrency, setSavingCurrency] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [emails, setEmails] = useState('');
  const [isSavingEmails, setIsSavingEmails] = useState(false);

  const dispatch = useDispatch();
  const validator = useValidator();
  const role = useSelector((state) => state.auth.role);

  const isBuyer = role === 'CLIENT';

  const fetchCurrencies = useCallback(() => {
    api.get('/rates/today').then(({ data }) => {
      setCurrencies(data);
    });
  }, []);

  const fetchMyInfo = () => {
    api.get('/user/info').then(({ data }) => {
      setUsername(data.username);
      setEmail(data.email);
      setEmailField(data.email);
      setAvatar(data.avatar || null);
      setDefaultCurrency(data.default_currency);
      setEmails(data.notification_emails);
    });
  };

  const updateEmail = () => {
    api
      .patch('/user/email', {
        email: emailField,
      })
      .then(() => {
        // setSuccess([
        //   {
        //     message: 'The email has been updated.',
        //   },
        // ])
        dispatch(
          setSuccessMessages([
            {
              message: 'The email has been updated.',
            },
          ]),
        );
        setEmail(emailField);
        setVisibleEmailModal(false);
      });
  };

  useEffect(() => {
    fetchMyInfo();
    fetchCurrencies();
  }, []);

  const updateDefaultCurrency = () => {
    if (selectedCurrency) {
      setSavingCurrency(true);
      api
        .patch('/user/default-currency', {
          currency: selectedCurrency,
        })
        .then(() => {
          setDefaultCurrency(selectedCurrency);
          setSelectedCurrency(null);
          dispatch(
            setSuccessMessages([
              {
                message: 'Your default currency has been updated.',
              },
            ]),
          );
          setVisibleCurrencyModal(false);
        })
        .catch((e) => {
          dispatch(
            setErrors([
              {
                message: e?.data?.errors || 'Sorry we were unable to update your default currency.',
              },
            ]),
          );
        })
        .finally(() => {
          setSavingCurrency(false);
        });
    }
  };

  const saveEmails = () => {
    // Validate emails.
    let allEmails = emails.split(',');
    allEmails = allEmails.map((email) => {
      return email.trim();
    });

    allEmails = allEmails.filter((email) => {
      return email.length > 0;
    });

    if (allEmails.length > 0) {
      let invalidEmail = '';

      const hasInvalid = allEmails.some((email) => {
        const isValid = validator.validateEmail(email);
        if (!isValid) {
          invalidEmail = email;
        }
        return !isValid;
      });

      if (hasInvalid) {
        dispatch(
          setErrors([
            {
              message: `There is an invalid email please check it: ${invalidEmail}`,
            },
          ]),
        );
      } else {
        const uniqueEmails = new Set(allEmails);
        setIsSavingEmails(true);

        api
          .patch('/user/notification-emails', {
            emails: uniqueEmails.join(', '),
          })
          .then(() => {
            dispatch(
              setSuccessMessages([
                {
                  message: `The email addresses have been saved successfully.`,
                },
              ]),
            );
            setEmails(uniqueEmails.join(', '));
          })
          .catch((e) => {
            setErrors([
              {
                message: e,
              },
            ]);
          })
          .finally(() => {
            setIsSavingEmails(false);
          });
      }
    } else {
      dispatch(
        setErrors([
          {
            message: `Please enter at least one email address.`,
          },
        ]),
      );
    }
  };

  return (
    <CContainer>
      <Helmet>
        <title>Account Settings</title>
      </Helmet>
      <CRow>
        <CCol>
          <h1>Account Settings - {username}</h1>
        </CCol>
      </CRow>
      <Security setVisiblePwdModal={setVisiblePwdModal} />
      <CRow>
        <CCol md={6} xl={5}>
          <CCard>
            <CCardBody>
              <CCardTitle>Your Information</CCardTitle>
              <CListGroup>
                <CListGroupItem>
                  <UploadBoxProvider>
                    <AvatarUpload username={username} avatar={avatar} setAvatar={setAvatar} />
                  </UploadBoxProvider>
                </CListGroupItem>
                <CListGroupItem className="d-flex justify-content-between align-items-center;" style={{ flexWrap: 'wrap' }}>
                  <b>Password</b>
                  <span>***************</span>
                  <span
                    className="link"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisiblePwdModal(true);
                    }}
                  >
                    Change password
                  </span>
                </CListGroupItem>
              </CListGroup>
              <CListGroup>
                <CListGroupItem className="d-flex justify-content-between align-items-center;" style={{ flexWrap: 'wrap' }}>
                  <b>Email:</b>
                  <span>{email}</span>
                  <span
                    className="link"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisibleEmailModal(true);
                    }}
                  >
                    Change email
                  </span>
                </CListGroupItem>
              </CListGroup>
              <CListGroup>
                <CListGroupItem className="d-flex justify-content-between align-items-center;" style={{ flexWrap: 'wrap' }}>
                  <b>Currency:</b>
                  <span>{defaultCurrency}</span>
                  <span
                    className="link"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisibleCurrencyModal(true);
                    }}
                  >
                    Change currency
                  </span>
                </CListGroupItem>
              </CListGroup>
            </CCardBody>
          </CCard>
        </CCol>
        {isBuyer && (
          <CCol md={6} xl={5}>
            <CCard className="h-100">
              <CCardBody>
                <CCardTitle>Notifications</CCardTitle>
                <CAlert color={'primary'}>You will receive notifications about your orders to these emails.</CAlert>
                <CForm>
                  <CFormLabel>Enter the emails separated by a comma.</CFormLabel>
                  <CFormTextarea
                    placeholder={'Emails separated by a comma.'}
                    value={emails}
                    onChange={(e) => {
                      setEmails(e.target.value);
                    }}
                  ></CFormTextarea>
                  <LButton color={'primary'} className={'my-3'} isLoading={isSavingEmails} onClick={saveEmails}>
                    Save Emails
                  </LButton>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        )}
      </CRow>
      <CRow>
        <CCol>
          <ChangePassword
            visible={visiblePwdModal}
            closeModal={() => {
              setVisiblePwdModal(false);
            }}
            hasPassword={true}
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CModal visible={visibleEmailModal}>
            <CModalHeader>
              <CModalTitle>Change email</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>Email address</CFormLabel>
                  <CFormInput
                    placeholder="Email"
                    type="email"
                    value={emailField}
                    onChange={(e) => {
                      setEmailField(e.target.value);
                    }}
                  ></CFormInput>
                </div>
              </CForm>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="danger"
                style={{ color: 'white' }}
                onClick={() => {
                  setVisibleEmailModal(false);
                }}
              >
                Cancel
              </CButton>
              <CButton
                color="primary"
                onClick={() => {
                  updateEmail();
                }}
              >
                Update
              </CButton>
            </CModalFooter>
          </CModal>

          <CModal visible={visibleCurrencyModal}>
            <CModalHeader>
              <CModalTitle>Change currency</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CAlert color="info">
                This currency will be applied to your new purchase orders, invoices, and other sections of the site.
                <br />
                You can still view your purchase your orders in a different currency.
              </CAlert>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>Currency</CFormLabel>
                  <FilterableSelect
                    placeholder="Currency"
                    options={currencies}
                    text="currency"
                    value="currency"
                    defaultValue={defaultCurrency}
                    onChange={(newCurrency) => {
                      setSelectedCurrency(newCurrency);
                    }}
                  />
                </div>
              </CForm>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="danger"
                onClick={() => {
                  setVisibleCurrencyModal(false);
                }}
                disabled={savingCurrency}
              >
                Cancel
              </CButton>
              <LButton color="primary" onClick={updateDefaultCurrency} isLoading={savingCurrency}>
                Save
              </LButton>
            </CModalFooter>
          </CModal>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default Settings;
