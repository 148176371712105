import React from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CContainer,
  CFormTextarea,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react';
import { useState, useEffect } from 'react';
import api from 'src/api';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import ConcludeLotProduction from 'src/components/ConcludeLotProduction';
import { Helmet } from 'react-helmet';

const Production = () => {
  const [visible, setVisible] = useState(false);
  const [concludeVisible, setConcludeVisible] = useState(false);
  const [inProductionList, setInProductionList] = useState([]);
  const [doneProductionList, setDoneProductionList] = useState([]);
  const [concludeProduction, setConcludeProduction] = useState([]);
  const [activeKey, setActiveKey] = useState(1);

  function fetchInProduction() {
    let inProduction = [];
    let doneProduction = [];

    api.get('/lotes/in-production').then(({ data }) => {
      inProduction = data.filter((item) => {
        return item.date_completed == null;
      });
      doneProduction = data.filter((item) => {
        return item.date_completed != null;
      });
      setInProductionList(inProduction);
      setDoneProductionList(doneProduction);
    });
  }

  useEffect(() => {
    fetchInProduction();
  }, []);

  function adjustModalConclude(production) {
    setConcludeProduction(production);
    setConcludeVisible(true);
  }

  function InProductionRow(props) {
    const dateSent = new Date(props.production.date_sent);
    const month = dateSent.getMonth() + 1;
    const year = dateSent.getFullYear();
    const dateSentFormatted = month + '/' + dateSent.getDate() + '/' + year;
    const dueDate = new Date(props.production.date_sent);
    dueDate.setDate(
      dueDate.getDate() + props.production.estimated_days_to_completion,
    );
    const dueMonth = dueDate.getMonth() + 1;
    const dueYear = dueDate.getFullYear();
    const dueDateFormatted = dueMonth + '/' + dueDate.getDate() + '/' + dueYear;
    let dueDateColor = 'black';
    const today = new Date();
    if (today > dueDate) {
      dueDateColor = 'red';
    }
    return (
      <Tr key={props.l}>
        <Th scope="row">{props.production.lot_number}</Th>
        <Td>{props.production.expression}</Td>
        <Td>{props.production.liters}</Td>
        <Td>{props.production.projected_bottles}</Td>
        <Td>{dateSentFormatted}</Td>
        <Td style={{ color: dueDateColor }}>{dueDateFormatted}</Td>
        <Td>
          <CButton
            size="sm"
            onClick={() => {
              adjustModalConclude(props.production);
            }}
          >
            Conclude
          </CButton>
        </Td>
      </Tr>
    );
  }

  function InProductionList(props) {
    const inProduction = props.inProduction;
    let l = -1;
    const list = inProduction.map((production) => {
      l++;
      return (
        <InProductionRow production={production} l={l++} key={`pr-${l}`} />
      );
    });
    return list;
  }

  function DoneProductionRow(props) {
    const dateSent = new Date(props.production.date_sent);
    const month = dateSent.getMonth() + 1;
    const year = dateSent.getFullYear();
    const dateSentFormatted = month + '/' + dateSent.getDate() + '/' + year;
    const dateReceived = new Date(props.production.date_completed);
    const receivedMonth = dateReceived.getMonth() + 1;
    const receivedYear = dateReceived.getFullYear();
    const receivedDateFormatted =
      receivedMonth + '/' + dateReceived.getDate() + '/' + receivedYear;
    return (
      <Tr key={props.l}>
        <Th scope="row">{props.production.lot_number}</Th>
        <Td>{props.production.expression}</Td>
        <Td>{props.production.liters}</Td>
        <Td>{props.production.projected_bottles}</Td>
        <Td>{props.production.bottles_received}</Td>
        <Td>{dateSentFormatted}</Td>
        <Td>{receivedDateFormatted}</Td>
      </Tr>
    );
  }

  function DoneProductionList(props) {
    const doneProduction = props.doneProduction;
    let l = -1;
    const list = doneProduction.map((production) => {
      l++;
      return (
        <DoneProductionRow production={production} l={l++} key={`dpr-${l}`} />
      );
    });
    return list;
  }

  return (
    <CContainer>
      <Helmet>
        <title>Production</title>
      </Helmet>
      {/* <ModalConclude production={concludeProduction} />*/}
      <ConcludeLotProduction
        onConclude={() => {
          fetchInProduction();
        }}
        production={concludeProduction}
        concludeVisible={concludeVisible}
        setConcludeVisible={setConcludeVisible}
      />
      <CModal visible={visible} onDismiss={() => setVisible(false)}>
        <CModalHeader>
          <CCol sm={5}>
            <strong>PRODUCTION</strong> <small>Info</small>
          </CCol>
        </CModalHeader>
        <CModalBody>
          <CForm style={{ margin: '10px' }}>
            <div className="mb-3">
              <CFormLabel htmlFor="expressionInput">Expression</CFormLabel>
              <CFormInput
                type="text"
                id="expressionInput"
                defaultValue="Ángel Cruz Calvo TOBALÁ S"
                readOnly
              />
            </div>
            <div className="mb-3">
              <CFormLabel htmlFor="lotInput">Lot</CFormLabel>
              <CFormInput
                type="text"
                id="lotInput"
                defaultValue="CC046"
                readOnly
              />
            </div>
            <div className="mb-3">
              <CFormLabel htmlFor="lotInput">Classification</CFormLabel>
              <CFormInput type="text" id="lotInput" defaultValue="Artesenal" />
            </div>
            <div className="mb-3">
              <CFormLabel htmlFor="abvInput">ABV</CFormLabel>
              <CFormInput type="text" id="abvInput" defaultValue="46.5" />
            </div>
            <div className="mb-3">
              <CFormLabel htmlFor="litersInput">Liters</CFormLabel>
              <CFormInput type="text" id="litersInput" defaultValue="300" />
            </div>
            <div className="mb-3">
              <CFormLabel htmlFor="mxpLtrInput">MXP$/LTR</CFormLabel>
              <CFormInput type="text" id="mxpLtrInput" defaultValue="$300.00" />
            </div>
            <div className="mb-3">
              <CFormLabel htmlFor="extInput">EXT</CFormLabel>
              <CFormInput
                type="text"
                id="extInput"
                defaultValue="$9000.00"
                readOnly
              />
            </div>
            <div className="mb-3">
              <CFormLabel htmlFor="projectedInput">
                Projected Bottles
              </CFormLabel>
              <CFormInput
                type="text"
                id="projectedInput"
                defaultValue="400"
                readOnly
              />
            </div>
            <div className="mb-3">
              <CFormLabel htmlFor="notesInput">Notes</CFormLabel>
              <CFormTextarea id="notesInput" />
            </div>
            <CButton type="submit" color="primary">
              Save
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                setVisible(false);
              }}
              style={{ marginLeft: '10px' }}
            >
              Close
            </CButton>
          </CForm>
        </CModalBody>
      </CModal>
      <CCard className="mb-4">
        <CCardHeader>
          <CCol sm={5}>
            <strong>LOTES</strong> <small>Production</small>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CNav variant="tabs" role="tablist">
            <CNavItem>
              <CNavLink
                href=""
                active={activeKey === 1}
                onClick={(e) => {
                  e.preventDefault(9);
                  setActiveKey(1);
                }}
              >
                In Production
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                href=""
                active={activeKey === 2}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveKey(2);
                }}
              >
                Finished
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane
              role="tabpanel"
              aria-labelledby="home-tab"
              visible={activeKey === 1}
            >
              <CRow>
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardBody>
                      <div className="table-container">
                        <Table>
                          <Thead>
                            <Tr>
                              <Th scope="col">Lot</Th>
                              <Th scope="col">Expression</Th>
                              <Th scope="col">Liters</Th>
                              <Th scope="col">Projected Btls</Th>
                              <Th scope="col">Date Sent</Th>
                              <Th scope="col">Estimated Due Date</Th>
                              <Th scope="col">Conclude</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <InProductionList inProduction={inProductionList} />
                          </Tbody>
                        </Table>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="home-tab"
              visible={activeKey === 2}
            >
              <CRow>
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardBody>
                      <div className="table-container">
                        <Table>
                          <Thead>
                            <Tr>
                              <Th scope="col">Lot</Th>
                              <Th scope="col">Expression</Th>
                              <Th scope="col">Liters</Th>
                              <Th scope="col">Projected Btls</Th>
                              <Th scope="col">Received Btls</Th>
                              <Th scope="col">Date Sent</Th>
                              <Th scope="col">Concluded</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <DoneProductionList
                              doneProduction={doneProductionList}
                            />
                          </Tbody>
                        </Table>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </CContainer>
  );
};

Production.propTypes = {
  production: PropTypes.any,
  l: PropTypes.any,
  inProduction: PropTypes.any,
  doneProduction: PropTypes.any,
};

export default Production;
