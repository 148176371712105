import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCardHeader,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CCardTitle,
} from '@coreui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import LButton from 'src/components/LButton/LButton';

import { useEffect, useState } from 'react';
import api from 'src/api';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';
import { Helmet } from 'react-helmet';

const Coupons = () => {
  const [showCreateModal, setShowCreateModal] = useState();
  const [isCreating, setIsCreating] = useState();
  const [coupons, setCoupons] = useState([]);

  const [code, setCode] = useState('');
  const [couponType, setCouponType] = useState('percentage');
  const [validDate, setValidDate] = useState();
  const [discount, setDiscount] = useState(''); // Discount Amount

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState('USD');
  const [defaultCurrency, setDefaultCurrency] = useState('USD');
  const [minimum, setMinimum] = useState('');

  const [editId, setEditId] = useState();

  const dispatch = useDispatch();

  const onDateChange = (date) => {
    setValidDate(date);
  };

  const createCoupon = () => {
    let message = '';
    let valid = true;

    const discountNumber = parseFloat(discount);

    if (code.length <= 0) {
      message = 'Please enter the coupon code';
      valid = false;
    }
    if (!validDate) {
      message = 'Please enter a date';
      valid = false;
    }
    if (isNaN(discountNumber) || discountNumber <= 0) {
      message = 'Please enter a discount greater than 0';
      valid = false;
    }

    if (isNaN(minimum) || minimum < 0) {
      message = 'Please enter a valid amount for the minimum purchase field.';
      valid = false;
    }

    if (!currency) {
      message = 'Please choose a currency';
      valid = false;
    }

    if (valid) {
      setIsCreating(true);

      const data = {
        code: code,
        discount: discountNumber,
        coupon_type: couponType,
        expires_at: new Date(validDate).toISOString().substring(0, 10),
        currency,
        minimum,
      };

      if (editId) {
        api
          .put(`/coupon/${editId}`, data)
          .then(() => {
            dispatch(
              setSuccessMessages([
                {
                  message: 'The coupon has been updated successfully.',
                },
              ]),
            );
            setShowCreateModal(false);
            fetchCoupons();
            setCode('');
            setDiscount('');
          })
          .catch((e) => {
            dispatch(setErrors([{ message: e }]));
          })
          .finally(() => {
            setIsCreating(false);
          });
      } else {
        api
          .post('/coupon', data)
          .then(() => {
            dispatch(setSuccessMessages([{ message: 'The coupon was created successfully.' }]));
            setShowCreateModal(false);
            fetchCoupons();
            setCode('');
            setDiscount('');
          })
          .catch((e) => {
            dispatch(setErrors([{ message: e }]));
          })
          .finally(() => {
            setIsCreating(false);
          });
      }
    } else {
      dispatch(setErrors([{ message }]));
    }
  };

  const fetchCoupons = useCallback(() => {
    api.get('/coupon').then(({ data }) => {
      setCoupons(data);
    });
  }, []);

  useEffect(() => {
    fetchCoupons();
    const controller = new AbortController();
    api.get('/rates/today', { signal: controller.signal }).then(({ data }) => {
      setCurrencies(data);
    });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!showCreateModal) {
      setEditId(null);
    }
  }, [showCreateModal]);

  useEffect(() => {
    if (editId == null) {
      setCode('');
      setCurrency('USD');
      setDefaultCurrency('USD');
      setMinimum('');
      setDiscount('');
    }
  }, [editId]);

  const openEditModalCoupon = (coupon) => {
    setEditId(coupon.id);
    setShowCreateModal(true);
    setCode(coupon.code);
    setDefaultCurrency(coupon.currency);
    setCurrency(coupon.currency);
    setCouponType(coupon.discount_percent > 0 ? 'percentage' : 'value');
    setDiscount(coupon.discount_percent > 0 ? coupon.discount_percent : coupon.discount_amount);
    setMinimum(coupon.minimum_purchase);
    setValidDate(new Date(coupon.expiresAt));
  };

  return (
    <>
      <Helmet>
        <title>Coupons</title>
      </Helmet>
      <CCard>
        <CCardHeader>
          <CCardTitle>Coupons</CCardTitle>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol>
              <div style={{ marginBottom: '0.3rem', marginTop: '0.3rem' }}>
                <CButton
                  size="sm"
                  onClick={() => {
                    setShowCreateModal(true);
                  }}
                >
                  New Coupon
                </CButton>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <div className="table-container with-margin">
                <Table>
                  <Thead>
                    <Tr>
                      <Th>CODE</Th>
                      <Th>Discount</Th>
                      <Th>Currency</Th>
                      <Th>Minimum Purchase</Th>
                      <Th>Created At</Th>
                      <th>Expiry Date</th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {coupons.map((coupon, idx) => {
                      return (
                        <Tr
                          key={`disocunt-${idx}`}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            openEditModalCoupon(coupon);
                          }}
                        >
                          <Td>{coupon.code}</Td>
                          <Td>
                            {coupon.discount_percent > 0 ?
                              `${coupon.discount_percent}%` :
                              `${new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: coupon.currency || 'USD',
                                }).format(coupon.discount_amount)}`}
                          </Td>
                          <Td>{coupon.currency}</Td>
                          <Td>{`${new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: coupon.currency || 'USD',
                          }).format(coupon.minimum_purchase)}`}</Td>
                          <Td>{new Date(coupon.createdAt).toLocaleDateString()}</Td>
                          <Td>{new Date(coupon.expiresAt).toLocaleDateString()}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CModal visible={showCreateModal} onClose={() => setShowCreateModal(false)} backdrop={`static`} size={`lg`}>
                <CModalHeader onClose={() => setShowCreateModal(false)}>
                  <CModalTitle>New Coupon</CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <CForm>
                    <CRow>
                      <CCol>
                        <div className="my-1">
                          <CFormLabel>Currency</CFormLabel>
                          <CFormSelect
                            disabled={editId != null}
                            defaultValue={defaultCurrency}
                            vale={currency}
                            onChange={(e) => {
                              setCurrency(e.target.value);
                            }}
                          >
                            {currencies.map((currency, idx) => {
                              return (
                                <option key={`currency-${idx}`} value={`${currency.currency}`}>
                                  {currency.currency}
                                </option>
                              );
                            })}
                          </CFormSelect>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs={12} md={6}>
                        <CFormLabel>Code</CFormLabel>
                        <CFormInput
                          style={{ textTransform: 'uppercase' }}
                          size="sm"
                          placeholder="Coupon code"
                          value={code}
                          onChange={(e) => {
                            setCode(e.target.value);
                          }}
                          disabled={editId != null}
                        />
                      </CCol>
                      <CCol xs={12} md={6}>
                        <CFormLabel>Discount</CFormLabel>
                        <CFormSelect
                          size="sm"
                          value={couponType}
                          onChange={(e) => {
                            setCouponType(e.target.value);
                          }}
                          disabled={editId != null}
                        >
                          <option value="percentage">Percentage</option>
                          <option value="value">Fixed Amount</option>
                        </CFormSelect>
                      </CCol>
                    </CRow>
                    <CRow style={{ marginTop: '0.3rem' }}>
                      <CCol xs={12} md={6}>
                        <CFormLabel>{couponType === 'percentage' ? 'Discount (%)' : 'Amount'}</CFormLabel>
                        <CFormInput
                          value={discount}
                          onChange={(e) => {
                            setDiscount(e.target.value);
                          }}
                          placeholder={couponType === 'percentage' ? 'Discount (%)' : 'Amount'}
                          size="sm"
                          type="number"
                          disabled={editId != null}
                        ></CFormInput>
                      </CCol>
                      <CCol xs={12} md={6}>
                        <CFormLabel>Expiry Date</CFormLabel>
                        <DatePicker selected={validDate} onChange={onDateChange} />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <div className="mt-1">
                          <CFormLabel>Minimum purchase</CFormLabel>
                          <CFormInput
                            placeholder="Enter an amount in the selected currency"
                            type="number"
                            value={minimum}
                            onChange={(e) => {
                              setMinimum(e.target.value);
                            }}
                          />
                        </div>
                      </CCol>
                    </CRow>
                  </CForm>
                </CModalBody>
                <CModalFooter>
                  <CButton color="danger" onClick={() => setShowCreateModal(false)} disabled={isCreating}>
                    Close
                  </CButton>
                  <LButton isLoading={isCreating} color="primary" onClick={createCoupon}>
                    {editId == null ? 'Create Coupon' : 'Save changes'}
                  </LButton>
                </CModalFooter>
              </CModal>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};
export default Coupons;
