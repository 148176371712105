import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CFormInput,
  CRow,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CForm,
  CFormLabel,
} from '@coreui/react';
import { useState, useEffect } from 'react';
import api from 'src/api';
import LButton from 'src/components/LButton/LButton';
import { useDispatch } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';
import YesNoModal from 'src/components/YesNoModal';
import { Helmet } from 'react-helmet';
import './variableCosts.css';

const PricingSchemes = () => {
  const [visible, setVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [createSchema, setCreateSchema] = useState(null);
  const [schemes, setSchemes] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [variableCostData, setVariableCostData] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    api
      .get('/pricing-scheme', { signal: controller.signal })
      .then(({ data }) => {
        setSchemes(data);
      })
      .catch(() => {});

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    api
      .get('/variable-costs', { signal: controller.signal })
      .then(({ data }) => {
        if (data?.id) {
          setVariableCostData(data);
        }
      });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (createSchema) {
      recalculateSchema(createSchema);
    }
  }, [
    variableCostData,
    createSchema?.pesos_litre_row?.second,
    createSchema?.works_row?.first,
    createSchema?.profit_row?.first,
    createSchema?.retailer_row?.first,
    createSchema?.consumer_row?.first,
  ]);

  const recalculateSchema = (schema) => {
    const updatedSchema = { ...schema };
    calculatePesoDollarRow(updatedSchema);
    calculateFetRow(updatedSchema);
    calculateVariableRow(updatedSchema);
    calculateCogRow(updatedSchema);
    calculateWorksRow(updatedSchema);
    calculateImporterRow(updatedSchema);
    calculateProfitRow(updatedSchema);
    calculateRetailerRow(
      updatedSchema,
      updatedSchema.schema_name === 'Kastara'
    );
    calculateConsumerRow(updatedSchema);
    setCreateSchema(updatedSchema);
  };

  const calculatePesoDollarRow = (sc) => {
    if (!sc) return;

    if (!sc.pesos_dollar_row) {
      sc.pesos_dollar_row = {};
    }

    const first = variableCostData?.peso_to_usd;
    const second = (
      (sc?.pesos_litre_row?.second / variableCostData?.peso_to_usd) *
      variableCostData?.co_row?.second
    )?.toFixed(2);
    const fourth = (second * 6)?.toFixed(2);

    sc.pesos_dollar_row = { first, second, third: -1, fourth };

    return sc.pesos_dollar_row;
  };

  const calculateFetRow = (sc) => {
    if (!sc) return;

    const second = variableCostData?.ttb_row?.third;

    sc.fet_row = { first: -1, second, third: -1, fourth: sc.fet_row?.fourth };

    return sc.fet_row;
  };

  const calculateVariableRow = (sc) => {
    if (!sc) return;

    if (!sc.variable_row) {
      sc.variable_row = {};
    }

    const second = (
      parseFloat(variableCostData?.bottles_row?.third) +
      parseFloat(variableCostData?.cork_row?.third) +
      parseFloat(variableCostData?.testing_row?.third) +
      parseFloat(variableCostData?.label_btl_row?.third) +
      parseFloat(variableCostData?.label_tag_row?.third) +
      parseFloat(variableCostData?.bottling_row?.third) +
      parseFloat(variableCostData?.cardboard_row?.third)
    )?.toFixed(2);
    const fourth = (second * 6)?.toFixed(2);

    sc.variable_row = { first: -1, second, third: -1, fourth };

    return sc.variable_row;
  };

  const calculateCogRow = (sc) => {
    if (!sc) return;

    if (!sc.cog_row) {
      sc.cog_row = {};
    }

    const second = (
      parseFloat(sc?.pesos_dollar_row?.second) +
      parseFloat(sc?.fet_row?.second) +
      parseFloat(sc?.variable_row?.second)
    )?.toFixed(2);
    const fourth = (second * 6)?.toFixed(2);

    sc.cog_row = { first: -1, second, third: -1, fourth };

    return sc.cog_row;
  };

  const calculateWorksRow = (sc) => {
    if (!sc) return;

    const second = (
      parseFloat(sc?.cog_row?.second) * parseFloat(sc?.works_row?.first)
    )?.toFixed(2);
    const third = (
      (parseFloat(second) - parseFloat(sc?.cog_row?.second)) /
      parseFloat(second)
    )?.toFixed(4);
    const fourth = (parseFloat(second) * 6)?.toFixed(2);

    sc.works_row = { first: sc.works_row?.first, second, third, fourth };

    return sc.works_row;
  };

  const calculateImporterRow = (sc) => {
    if (!sc) return;

    if (!sc.importer_row) {
      sc.importer_row = {};
    }

    const second = (
      parseFloat(sc?.works_row?.second) +
      (parseFloat(variableCostData?.freight_row?.third) +
        parseFloat(variableCostData?.warehousing_row?.third) +
        parseFloat(variableCostData?.ttb_row?.third) +
        parseFloat(variableCostData?.co_row?.third)) -
      parseFloat(variableCostData?.ttb_row?.third)
    )?.toFixed(2);
    const fourth = (parseFloat(second) * 6)?.toFixed(2);

    sc.importer_row = { first: -1, second, third: -1, fourth };

    return sc.importer_row;
  };

  const calculateProfitRow = (sc) => {
    if (!sc) return;

    const second = (
      parseFloat(sc?.profit_row?.first) * parseFloat(sc?.importer_row?.second)
    )?.toFixed(2);
    const third = (
      (parseFloat(second) - parseFloat(sc?.importer_row?.second)) /
      parseFloat(second)
    )?.toFixed(4);
    const fourth = (parseFloat(second) * 6)?.toFixed(2);

    sc.profit_row = { first: sc.profit_row?.first, second, third, fourth };

    return sc.profit_row;
  };

  const calculateRetailerRow = (sc, isKastara) => {
    if (!sc) return;

    let second;
    let third;
    let fourth;

    if (isKastara) {
      second = (
        sc?.retailer_row?.first * parseFloat(sc?.profit_row?.second)
      )?.toFixed(2);
      third = (
        (parseFloat(second) - parseFloat(sc?.profit_row?.second)) /
        parseFloat(second)
      )?.toFixed(4);
      fourth = (parseFloat(second) * 12)?.toFixed(2);
    } else {
      second = (
        sc?.retailer_row?.first * parseFloat(sc?.importer_row?.second)
      )?.toFixed(2);
      third = (
        (parseFloat(second) - parseFloat(sc?.importer_row?.second)) /
        parseFloat(second)
      )?.toFixed(4);
      fourth = (parseFloat(second) * 6)?.toFixed(2);
    }

    sc.retailer_row = { first: sc.retailer_row?.first, second, third, fourth };

    return sc.retailer_row;
  };

  const calculateConsumerRow = (sc) => {
    if (!sc) return;

    const second = (
      sc?.consumer_row?.first * parseFloat(sc?.retailer_row?.second)
    )?.toFixed(2);
    const third = (
      (parseFloat(second) - parseFloat(sc?.retailer_row?.second)) /
      parseFloat(second)
    )?.toFixed(4);
    const fourth = (parseFloat(second) * 6)?.toFixed(2);

    sc.consumer_row = { first: sc.consumer_row?.first, second, third, fourth };

    return sc.consumer_row;
  };

  const saveScheme = () => {
    let valid = true;

    if (
      !createSchema ||
      !createSchema.schema_name ||
      createSchema.schema_name.length <= 0
    ) {
      valid = false;
      dispatch(
        setErrors([
          {
            message: 'Please enter the calculator name',
          },
        ])
      );
    } else if (isNaN(createSchema.retailer_row.first)) {
      valid = false;
      dispatch(
        setErrors([
          {
            message: 'Please enter a number for the price to retailer',
          },
        ])
      );
    } else if (isNaN(createSchema.consumer_row.first)) {
      valid = false;
      dispatch(
        setErrors([
          {
            message: 'Please enter a number for the price to consumer',
          },
        ])
      );
    }
    if (valid) {
      setIsSaving(true);

      const ensureRowStructure = (row) => {
        return {
          first: isNaN(row?.first) ? -1 : row.first,
          second: isNaN(row?.second) ? -1 : row.second,
          third: isNaN(row?.third) ? -1 : row.third,
          fourth: isNaN(row?.fourth) ? -1 : row.fourth,
        };
      };

      const data = {
        schema_name: createSchema.schema_name,
        ex_works: parseFloat(createSchema.works_row.first),
        price_retailer: parseFloat(createSchema.retailer_row.first),
        price_consumer: parseFloat(createSchema.consumer_row.first),
        pesos_litre_row: ensureRowStructure(createSchema.pesos_litre_row),
        fet_row: ensureRowStructure(createSchema.fet_row),
        works_row: ensureRowStructure(createSchema.works_row),
        profit_row: ensureRowStructure(createSchema.profit_row),
        retailer_row: ensureRowStructure(createSchema.retailer_row),
        consumer_row: ensureRowStructure(createSchema.consumer_row),
      };

      if (editId == null) {
        api
          .post('/pricing-scheme', data)
          .then(({ data }) => {
            setSchemes((prevValue) => {
              prevValue.push({
                id: data.id,
                schema_name: createSchema.schema_name,
                ex_works: createSchema.works_row.first,
                price_retailer: createSchema.retailer_row.first,
                price_consumer: createSchema.consumer_row.first,
                pesos_litre_row: createSchema.pesos_litre_row,
                fet_row: createSchema.fet_row,
                works_row: createSchema.works_row,
                profit_row: createSchema.profit_row,
                retailer_row: createSchema.retailer_row,
                consumer_row: createSchema.consumer_row,
              });
              return prevValue;
            });
            dispatch(
              setSuccessMessages([
                {
                  message: 'Saved!',
                },
              ])
            );

            cancel();
          })
          .catch((e) => {
            dispatch(
              setErrors({
                message: e,
              })
            );
          })
          .finally(() => {
            setIsSaving(false);
            setCreateSchema(null);
            setVisible(false);
          });
      } else {
        api
          .put(`/pricing-scheme/${editId}`, data)
          .then(() => {
            setSchemes((prev) => {
              return prev.map((sc) => {
                if (sc.id === editId) {
                  sc.schema_name = createSchema.schema_name;
                  sc.ex_works = createSchema.works_row.first;
                  sc.price_retailer = createSchema.retailer_row.first;
                  sc.price_consumer = createSchema.consumer_row.first;
                  sc.pesos_litre_row = createSchema.pesos_litre_row;
                  sc.fet_row = createSchema.fet_row;
                  sc.works_row = createSchema.works_row;
                  sc.profit_row = createSchema.profit_row;
                  sc.retailer_row = createSchema.retailer_row;
                  sc.consumer_row = createSchema.consumer_row;
                }
                return sc;
              });
            });
            cancel();
          })
          .catch((e) => {
            dispatch(
              setErrors({
                message: e,
              })
            );
          })
          .finally(() => {
            setIsSaving(false);
            setCreateSchema(null);
            setVisible(false);
          });
      }
    }
  };

  const askDelete = (id) => {
    setDeleteId(id);
    setShowDelete(true);
  };

  const deleteScheme = () => {
    if (deleteId) {
      setIsDeleting(true);
      api
        .delete(`/pricing-scheme/${deleteId}`)
        .then(() => {
          setSchemes((prev) => {
            return prev.filter((sc) => sc.id != deleteId);
          });
          dispatch(
            setSuccessMessages([
              {
                message: 'The calculator has been deleted',
              },
            ])
          );
        })
        .catch((e) => {
          setErrors([
            {
              message: e,
            },
          ]);
        })
        .finally(() => {
          setIsDeleting(false);
          setShowDelete(false);
        });
    }
  };

  const handleChange = (field, value) => {
    setCreateSchema((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <>
      <Helmet>
        <title>Price Calculator</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CCardTitle>Price Calculator</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <div className='mb-5'>
                  <CRow>
                    <CCol>
                      <CButton
                        onClick={() => {
                          setCreateSchema({
                            schema_name: '',
                            ex_works: 0,
                            price_retailer: 0,
                            price_consumer: 0,
                            pesos_litre_row: {},
                            fet_row: {},
                            works_row: {},
                            profit_row: {},
                            retailer_row: {},
                            consumer_row: {},
                          });
                          setVisible(true);
                        }}
                      >
                        Add New
                      </CButton>
                    </CCol>
                  </CRow>
                  <CModal
                    visible={visible}
                    onClose={() => setVisible(false)}
                    size='lg'
                  >
                    <CModalHeader>
                      <CModalTitle>
                        {editId == null ? 'New Calculator' : 'Edit Calculator'}
                      </CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                      <CForm>
                        <CRow>
                          <CCol>
                            <CFormLabel htmlFor='schema_name'>
                              Calculator Name
                            </CFormLabel>
                            <CFormInput
                              id='schema_name'
                              placeholder='Eg: USA'
                              type='text'
                              value={createSchema?.schema_name}
                              onChange={(e) =>
                                handleChange('schema_name', e.target.value)
                              }
                            />
                          </CCol>
                        </CRow>
                        {createSchema && (
                          <table className='styled-table'>
                            <thead>
                              <tr>
                                <th>Average Case</th>
                                <th></th>
                                <th>Bottle</th>
                                <th></th>
                                <th>6 CP</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Pesos/litre</td>
                                <td></td>
                                <td
                                  style={{
                                    backgroundColor: '#a0e7e5',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  MX$
                                  <CFormInput
                                    type='number'
                                    value={
                                      createSchema?.pesos_litre_row?.second
                                    }
                                    onChange={(e) =>
                                      handleChange('pesos_litre_row', {
                                        ...createSchema.pesos_litre_row,
                                        second: e.target.value,
                                      })
                                    }
                                    style={{ marginLeft: '5px' }}
                                  />
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Peso per Dollar</td>
                                <td style={{ backgroundColor: '#a0e7e5' }}>
                                  US$
                                  {calculatePesoDollarRow(createSchema)?.first}
                                </td>
                                <td>
                                  US$
                                  {calculatePesoDollarRow(createSchema)?.second}
                                </td>
                                <td></td>
                                <td>
                                  US$
                                  {calculatePesoDollarRow(createSchema)?.fourth}
                                </td>
                              </tr>
                              {createSchema.schema_name !== 'Kastara' && (
                                <tr>
                                  <td>FET Origen</td>
                                  <td></td>
                                  <td>
                                    US${calculateFetRow(createSchema)?.second}
                                  </td>
                                  <td></td>
                                  <td
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    US$
                                    <CFormInput
                                      type='number'
                                      value={createSchema?.fet_row?.fourth}
                                      onChange={(e) =>
                                        handleChange('fet_row', {
                                          ...createSchema.fet_row,
                                          fourth: e.target.value,
                                        })
                                      }
                                      style={{ marginLeft: '5px' }}
                                    />
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td>Variable Cost per Bottle</td>
                                <td></td>
                                <td>
                                  US$
                                  {calculateVariableRow(createSchema)?.second}
                                </td>
                                <td></td>
                                <td>
                                  US$
                                  {calculateVariableRow(createSchema)?.fourth}
                                </td>
                              </tr>
                              <tr>
                                <td className='section-subtotal'>COG</td>
                                <td className='section-subtotal'>#X Markup</td>
                                <td className='section-subtotal'>
                                  US$
                                  {calculateCogRow(createSchema)?.second}
                                </td>
                                <td className='section-subtotal'>
                                  Margin as %
                                </td>
                                <td className='section-subtotal'>
                                  US$
                                  {calculateCogRow(createSchema)?.fourth}
                                </td>
                              </tr>
                              <tr>
                                <td>EX WORKS</td>
                                <td style={{ backgroundColor: '#a0e7e5' }}>
                                  <CFormInput
                                    type='number'
                                    value={createSchema?.works_row?.first}
                                    onChange={(e) =>
                                      handleChange('works_row', {
                                        ...createSchema.works_row,
                                        first: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                                <td style={{ backgroundColor: '#c5f45e' }}>
                                  US${calculateWorksRow(createSchema)?.second}
                                </td>
                                <td>
                                  {(
                                    calculateWorksRow(createSchema)?.third * 100
                                  )?.toFixed(2)}
                                  %
                                </td>
                                <td>
                                  US${calculateWorksRow(createSchema)?.fourth}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  W Importer Cost (Shown D10) - D5 b/c Origen
                                  pays FET
                                </td>
                                <td className='section-subtotal'>FOB</td>
                                <td style={{ backgroundColor: '#f4d35e' }}>
                                  US$
                                  {calculateImporterRow(createSchema)?.second}
                                </td>
                                <td></td>
                                <td>
                                  US$
                                  {calculateImporterRow(createSchema)?.fourth}
                                </td>
                              </tr>
                              {createSchema.schema_name === 'Kastara' && (
                                <tr>
                                  <td>Kastara Profit</td>
                                  <td style={{ backgroundColor: '#a0e7e5' }}>
                                    <CFormInput
                                      type='number'
                                      value={createSchema?.profit_row?.first}
                                      onChange={(e) =>
                                        handleChange('profit_row', {
                                          ...createSchema.profit_row,
                                          first: e.target.value,
                                        })
                                      }
                                    />
                                  </td>
                                  <td style={{ backgroundColor: '#f4d35e' }}>
                                    US$
                                    {calculateProfitRow(createSchema)?.second}
                                  </td>
                                  <td>
                                    {(
                                      calculateProfitRow(createSchema)?.third *
                                      100
                                    )?.toFixed(2)}
                                    %
                                  </td>
                                  <td>
                                    US$
                                    {calculateProfitRow(createSchema)?.fourth}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td>Price to Retailer</td>
                                <td style={{ backgroundColor: '#a0e7e5' }}>
                                  <CFormInput
                                    type='number'
                                    value={createSchema?.retailer_row?.first}
                                    onChange={(e) =>
                                      handleChange('retailer_row', {
                                        ...createSchema.retailer_row,
                                        first: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                                <td style={{ backgroundColor: '#f4d35e' }}>
                                  US$
                                  {
                                    calculateRetailerRow(
                                      createSchema,
                                      createSchema.schema_name === 'Kastara'
                                    )?.second
                                  }
                                </td>
                                <td>
                                  {(
                                    calculateRetailerRow(
                                      createSchema,
                                      createSchema.schema_name === 'Kastara'
                                    )?.third * 100
                                  )?.toFixed(2)}
                                  %
                                </td>
                                <td>
                                  US$
                                  {
                                    calculateRetailerRow(
                                      createSchema,
                                      createSchema.schema_name === 'Kastara'
                                    )?.fourth
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Price to Consumer</td>
                                <td style={{ backgroundColor: '#a0e7e5' }}>
                                  <CFormInput
                                    type='number'
                                    value={createSchema?.consumer_row?.first}
                                    onChange={(e) =>
                                      handleChange('consumer_row', {
                                        ...createSchema.consumer_row,
                                        first: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                                <td style={{ backgroundColor: '#f4635e' }}>
                                  US$
                                  {calculateConsumerRow(createSchema)?.second}
                                </td>
                                <td>
                                  {(
                                    calculateConsumerRow(createSchema)?.third *
                                    100
                                  )?.toFixed(2)}
                                  %
                                </td>
                                <td>
                                  US$
                                  {calculateConsumerRow(createSchema)?.fourth}
                                </td>
                              </tr>
                              <tr>
                                <td className='section-subtotal'>
                                  Margin/Btl in $
                                </td>
                                <td className='section-subtotal'>
                                  US$
                                  {(
                                    parseFloat(
                                      createSchema?.works_row?.second
                                    ) -
                                    parseFloat(createSchema?.cog_row?.second)
                                  )?.toFixed(2)}
                                </td>
                                <td></td>
                                <td>
                                  {(
                                    ((parseFloat(
                                      createSchema?.works_row?.second
                                    ) -
                                      parseFloat(
                                        createSchema?.cog_row?.second
                                      )) /
                                      parseFloat(
                                        createSchema?.works_row?.second
                                      )) *
                                    100
                                  )?.toFixed(2)}
                                  %
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td className='section-subtotal'>Markup</td>
                                <td className='section-subtotal'>
                                  {(
                                    (parseFloat(
                                      createSchema?.works_row?.second
                                    ) /
                                      parseFloat(
                                        createSchema?.cog_row?.second
                                      )) *
                                    100
                                  )?.toFixed(2)}
                                  %
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td className='section-subtotal'>Btl/Pallet</td>
                                <td className='section-subtotal'>
                                  {variableCostData?.freight_row?.second}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style={{ backgroundColor: '#c5f45e' }}>
                                  Profit per pallet
                                </td>
                                <td style={{ backgroundColor: '#c5f45e' }}>
                                  US$
                                  {(
                                    (parseFloat(
                                      createSchema?.works_row?.second
                                    ) -
                                      parseFloat(
                                        createSchema?.cog_row?.second
                                      )) *
                                    variableCostData?.freight_row?.second
                                  )?.toFixed(2)}
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </CForm>
                    </CModalBody>
                    <CModalFooter>
                      <CButton
                        color='danger'
                        onClick={() => {
                          setEditId(null);
                          setCreateSchema(null);
                          setVisible(false);
                        }}
                        disabled={isSaving}
                      >
                        Cancel
                      </CButton>
                      <LButton isLoading={isSaving} onClick={saveScheme}>
                        {editId == null ? 'Create' : 'Save changes'}
                      </LButton>
                    </CModalFooter>
                  </CModal>
                  <YesNoModal
                    visible={showDelete}
                    setVisible={setShowDelete}
                    title='Delete Pricing Scheme?'
                    description="You can't undo this."
                    isLoading={isDeleting}
                    onYes={deleteScheme}
                    onNo={() => {
                      setDeleteId(null);
                      setShowDelete(false);
                    }}
                  />
                </div>
                {schemes.map((sc) => (
                  <div key={`sch-${sc.id}`} className='mb-5'>
                    <CRow className='mb-3'>
                      <CCol>
                        <CFormInput value={sc.schema_name} readOnly />
                      </CCol>
                      <CCol className='d-flex justify-content-end'>
                        <LButton
                          size='sm'
                          className='me-2'
                          isLoading={isSaving}
                          onClick={() => {
                            setEditId(sc.id);
                            setCreateSchema(sc);
                            setVisible(true);
                          }}
                        >
                          Edit
                        </LButton>
                        <CButton
                          size='sm'
                          color='danger'
                          onClick={() => askDelete(sc.id)}
                        >
                          Delete
                        </CButton>
                      </CCol>
                    </CRow>
                    <table className='styled-table'>
                      <thead>
                        <tr>
                          <th>Average Case</th>
                          <th></th>
                          <th>Bottle</th>
                          <th></th>
                          <th>6 CP</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pesos/litre</td>
                          <td></td>
                          <td
                            style={{
                              backgroundColor: '#a0e7e5',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            MX${sc?.pesos_litre_row?.second}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Peso per Dollar</td>
                          <td style={{ backgroundColor: '#a0e7e5' }}>
                            US${calculatePesoDollarRow(sc)?.first}
                          </td>
                          <td>
                            US$
                            {calculatePesoDollarRow(sc)?.second}
                          </td>
                          <td></td>
                          <td>US${calculatePesoDollarRow(sc)?.fourth}</td>
                        </tr>
                        {sc.schema_name !== 'Kastara' && (
                          <tr>
                            <td>FET Origen</td>
                            <td></td>
                            <td>US${calculateFetRow(sc)?.second}</td>
                            <td></td>
                            <td
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              US${sc?.fet_row?.fourth}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>Variable Cost per Bottle</td>
                          <td></td>
                          <td>
                            US$
                            {calculateVariableRow(sc)?.second}
                          </td>
                          <td></td>
                          <td>
                            US$
                            {calculateVariableRow(sc)?.fourth}
                          </td>
                        </tr>
                        <tr>
                          <td className='section-subtotal'>COG</td>
                          <td className='section-subtotal'>#X Markup</td>
                          <td className='section-subtotal'>
                            US$
                            {calculateCogRow(sc)?.second}
                          </td>
                          <td className='section-subtotal'>Margin as %</td>
                          <td className='section-subtotal'>
                            US$
                            {calculateCogRow(sc)?.fourth}
                          </td>
                        </tr>
                        <tr>
                          <td>EX WORKS</td>
                          <td style={{ backgroundColor: '#a0e7e5' }}>
                            {sc?.works_row?.first}
                          </td>
                          <td style={{ backgroundColor: '#c5f45e' }}>
                            US${calculateWorksRow(sc)?.second}
                          </td>
                          <td>
                            {(calculateWorksRow(sc)?.third * 100)?.toFixed(2)}%
                          </td>
                          <td>US${calculateWorksRow(sc)?.fourth}</td>
                        </tr>
                        <tr>
                          <td>
                            W Importer Cost (Shown D10) - D5 b/c Origen pays FET
                          </td>
                          <td className='section-subtotal'>FOB</td>
                          <td style={{ backgroundColor: '#f4d35e' }}>
                            US${calculateImporterRow(sc)?.second}
                          </td>
                          <td></td>
                          <td>US${calculateImporterRow(sc)?.fourth}</td>
                        </tr>
                        {sc.schema_name === 'Kastara' && (
                          <tr>
                            <td>Kastara Profit</td>
                            <td style={{ backgroundColor: '#a0e7e5' }}>
                              {sc?.profit_row?.first}
                            </td>
                            <td style={{ backgroundColor: '#f4d35e' }}>
                              US${calculateProfitRow(sc)?.second}
                            </td>
                            <td>
                              {(calculateProfitRow(sc)?.third * 100)?.toFixed(
                                2
                              )}
                              %
                            </td>
                            <td>US${calculateProfitRow(sc)?.fourth}</td>
                          </tr>
                        )}
                        <tr>
                          <td>Price to Retailer</td>
                          <td style={{ backgroundColor: '#a0e7e5' }}>
                            {sc?.retailer_row?.first}
                          </td>
                          <td style={{ backgroundColor: '#f4d35e' }}>
                            US$
                            {
                              calculateRetailerRow(
                                sc,
                                sc.schema_name === 'Kastara'
                              )?.second
                            }
                          </td>
                          <td>
                            {(
                              calculateRetailerRow(
                                sc,
                                sc.schema_name === 'Kastara'
                              )?.third * 100
                            )?.toFixed(2)}
                            %
                          </td>
                          <td>
                            US$
                            {
                              calculateRetailerRow(
                                sc,
                                sc.schema_name === 'Kastara'
                              )?.fourth
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Price to Consumer</td>
                          <td style={{ backgroundColor: '#a0e7e5' }}>
                            {sc?.consumer_row?.first}
                          </td>
                          <td style={{ backgroundColor: '#f4635e' }}>
                            US${calculateConsumerRow(sc)?.second}
                          </td>
                          <td>
                            {(calculateConsumerRow(sc)?.third * 100)?.toFixed(
                              2
                            )}
                            %
                          </td>
                          <td>US${calculateConsumerRow(sc)?.fourth}</td>
                        </tr>
                        <tr>
                          <td className='section-subtotal'>Margin/Btl in $</td>
                          <td className='section-subtotal'>
                            US$
                            {(
                              parseFloat(sc?.works_row?.second) -
                              parseFloat(sc?.cog_row?.second)
                            )?.toFixed(2)}
                          </td>
                          <td></td>
                          <td>
                            {(
                              ((parseFloat(sc?.works_row?.second) -
                                parseFloat(sc?.cog_row?.second)) /
                                parseFloat(sc?.works_row?.second)) *
                              100
                            )?.toFixed(2)}
                            %
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className='section-subtotal'>Markup</td>
                          <td className='section-subtotal'>
                            {(
                              (parseFloat(sc?.works_row?.second) /
                                parseFloat(sc?.cog_row?.second)) *
                              100
                            )?.toFixed(2)}
                            %
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className='section-subtotal'>Btl/Pallet</td>
                          <td className='section-subtotal'>
                            {variableCostData?.freight_row?.second}
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ backgroundColor: '#c5f45e' }}>
                            Profit per pallet
                          </td>
                          <td style={{ backgroundColor: '#c5f45e' }}>
                            US$
                            {(
                              (parseFloat(sc?.works_row?.second) -
                                parseFloat(sc?.cog_row?.second)) *
                              variableCostData?.freight_row?.second
                            )?.toFixed(2)}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                    <hr style={{ marginTop: '50px', opacity: '0.75' }} />
                  </div>
                ))}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default PricingSchemes;
