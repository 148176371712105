export default function useSort() {
  return {
    byNumericAsc: (property) => {
      return (a, b) => a[property] - b[property];
    },
    aphabetAZ: (property) => {
      return (a, b) => a[property].toLowerCase().localeCompare(b[property].toLowerCase());
    },
    alphabetZA: (property) => {
      return (a, b) => b[property].toLowerCase().localeCompare(a[property].toLowerCase());
    },
  };
}
