import React, { useRef, useEffect, useState } from 'react';

import { CFormInput, CButton, CFormLabel } from '@coreui/react';

import { Wrapper } from '@googlemaps/react-wrapper';
import { createCustomEqual } from 'fast-equals';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';

import PropTypes from 'prop-types';

const render = (status) => {
  return <h1>{status}</h1>;
};

const Map = ({ onClick, onIdle, children, style, onMapLoad, ...options }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
      onMapLoad();
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (isLatLngLiteral(a) || a instanceof google.maps.LatLng || isLatLngLiteral(b) || b instanceof google.maps.LatLng) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  }

  // TODO extend to other types

  // use fast-equals for other objects
  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const MapAndMarker = ({ updateElevation }) => {
  const [clicks, setClicks] = useState([]);
  const [zoom, setZoom] = useState(12); // initial zoom
  const [elevator, setElevator] = useState(false);
  const [elevation, setElevation] = useState('');

  useEffect(() => {
    updateElevation(elevation);
  }, [elevation]);

  // Mitla, Oaxaca.
  const [center, setCenter] = useState({
    lat: 16.915421521443978,
    lng: -96.3007879717867,
  });

  const onClick = (e) => {
    setClicks([e.latLng]);
    if (elevator) {
      elevator
        .getElevationForLocations({
          locations: [e.latLng],
        })
        .then(({ results }) => {
          if (results && results.length > 0) {
            setElevation(results[0].elevation);
          }
        });
    }
  };

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  const getClick = () => {
    if (clicks && clicks.length) {
      return clicks[0];
    } else {
      return false;
    }
  };

  const getLat = () => {
    const i = getClick();
    if (i) {
      return i.lat();
    } else {
      return '';
    }
  };

  const getLng = () => {
    const i = getClick();
    if (i) {
      return i.lng();
    } else {
      return '';
    }
  };

  const clearData = () => {
    setClicks([]);
    setElevation('');
  };

  const form = (
    <div
      style={{
        padding: '1rem',
        flexBasis: '250px',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <label htmlFor="zoom">Zoom</label>
      <CFormInput value={zoom} onChange={(event) => setZoom(Number(event.target.value))}></CFormInput>

      <br />
      <h4>Marker&apos;s Coordinates</h4>
      <CFormInput placeholder="Lat" value={getLat()} readOnly></CFormInput>
      <br />
      <CFormInput placeholder="Lng" value={getLng()} readOnly></CFormInput>
      <br />
      <hr />
      <CFormLabel>Elevation</CFormLabel>
      <CFormInput placeholder="Elevation" value={elevation} readOnly={true}></CFormInput>
      <br />
      <CButton onClick={() => clearData()}>Clear</CButton>
    </div>
  );

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <Wrapper apiKey={process.env.REACT_APP_MAPS_KEY} render={render}>
        <Map
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          mapTypeId="terrain"
          style={{ flexGrow: '1', height: '100%' }}
          onMapLoad={() => {
            const elv = new window.google.maps.ElevationService();
            setElevator(elv);
          }}
        >
          {clicks.map((latLng, i) => (
            <Marker key={i} position={latLng} />
          ))}
        </Map>
      </Wrapper>
      {form}
    </div>
  );
};

MapAndMarker.propTypes = {
  updateElevation: PropTypes.func.isRequired,
};
Map.propTypes = {
  onClick: PropTypes.func,
  onIdle: PropTypes.func,
  children: PropTypes.any,
  style: PropTypes.string,
  onMapLoad: PropTypes.func,
};

export default MapAndMarker;
