import { useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

export default function usePriceUpdater({ product, setPrice, onChangeFn }) {
  const handler = useCallback(
    debounce((finalPrice, productId) => {
      onChangeFn(finalPrice, productId);
    }, 600),
    [],
  );

  useEffect(() => {
    if (!isNaN(parseFloat(product.price_with_schema))) {
      const finalPrice = parseFloat(product.price_with_schema).toFixed(2);
      setPrice(finalPrice);
      handler(finalPrice, product.id);
    }
  }, [product.price_with_schema, product.price]);

  return handler;
}
