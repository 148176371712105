export default function useProductCategoryChecker() {
  const getLower = (name) => {
    if (typeof name !== 'string') {
      return '';
    } else {
      return name.toLowerCase();
    }
  };

  const isElBarro = (productName) => {
    const name = getLower(productName);
    return name.includes('el barro') || name.includes('barro');
  };

  const isTsook = (productName) => {
    const name = getLower(productName);
    return name.includes('tso\'ok') || name.includes('ok rum') || name.includes('tsook');
  };

  const isElBarroOrTsook = (productName) => {
    return isElBarro(productName) || isTsook(productName);
  };

  return {
    isElBarro,
    isTsook,
    isElBarroOrTsook,
  };
}
