import React from 'react';

const HomeClients = () => {
  return (
    <>
      <h1>Clients page</h1>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, itaque.</p>
    </>
  );
};

export default HomeClients;
