import ContentLayout from 'src/layout/ContentLayout';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import api from 'src/api';
import Loading from 'src/components/Loading';
import { useDispatch } from 'react-redux';
import { setErrors, setMiscellaneous, setOnDemand, setSuccessMessages } from 'src/actions';
import {
  CAlert,
  CTableBody,
  CButton,
  CTable,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput,
  CFormLabel,
  CCol,
  CRow,
  CTableDataCell,
} from '@coreui/react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import BreadCrumbs from 'src/components/BreadCrumbs';
import '../../scss/cpo-detail.scss';
import TheIcon from 'src/components/TheIcon/TheIcon';
import { setCurrency } from 'src/actions';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import LButton from 'src/components/LButton/LButton';
import useWindowSize from 'src/hooks/useWindowSize';
import OrderRow from 'src/components/OrderRow/OrderRow';
import { CBadge } from '@coreui/react';
import { OrderContext } from 'src/context/OrderContext';
import useMoneyCurrency from 'src/hooks/useMoneyCurrency';
import YesNoModal from 'src/components/YesNoModal';
import usePriceCalculator from 'src/hooks/usePriceCalculator';
import PalletSituation from 'src/components/PalletSituation/PalletSituation';
import DownloadExcel from 'src/components/DownloadExcel/DownloadExcel';
import DownloadPdfFiles from 'src/components/DownloadPdfFiles/DownloadPdfFiles';
import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_SOCKET_URL);
import { setStockInCart, setProductionInCart } from 'src/actions';
import AddMiscCart from 'src/components/Miscellaneous/AddMiscCart';
import MiscellaneousCartRow from 'src/components/Miscellaneous/MiscellaneousCartRow';

export default function CpoDetail() {
  const { data, setData, isConnected, setIsConnected, setSocket } = useContext(OrderContext);
  const { stockInCart, productionInCart, onDemand, misc } = useSelector((state) => state.order);

  const params = useParams();
  const dispatch = useDispatch();
  const money = useMoneyCurrency();
  const windowSize = useWindowSize();

  const [isLoading, setIsLoading] = useState(true);
  const [wasFound, setWasFound] = useState(true);
  const role = useSelector((state) => state.auth.role);
  const [isClient, setIsClient] = useState(true);
  const [currencies, setCurrencies] = useState([]);
  const navigate = useNavigate();
  const currency = useSelector((state) => state.currency.currency);
  const currencyUpdated = useSelector((state) => state.currency.updated);
  const [clientOrderNumber, setClientOrderNumber] = useState('');
  const [isAdminOrStaff, setIsAdminOrStaff] = useState(false);
  const [showRemoveCoupon, setShowRemoveCoupon] = useState(false);
  const [removingCoupon, setRemovingCoupon] = useState(false);
  const [hasCoupon, setHasCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [isApplying, setIsApplying] = useState(false);
  const [couponPercent, setCouponPercent] = useState(null);
  const [couponAmount, setCouponAmount] = useState(null);
  const [couponCurrency, setCouponCurrency] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const priceCalculator = usePriceCalculator();
  const [priceResults, setPriceResults] = useState({
    subtotal: 0,
    total: 0,
    discount: 0,
    discountString: '0%',
  });
  const [visible, setVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleChange, setVisibleChange] = useState(false);
  const [reFetchCounter, setRefetchCounter] = useState(0);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isPatchingStatus, setIsPatchingStatus] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (typeof role == 'string') {
      setIsAdminOrStaff(role === 'ADMIN' || role === 'STAFF');
    }
    return () => {
      setIsAdminOrStaff(false);
    };
  }, [role]);

  useEffect(() => {
    async function setResults() {
      if (
        Array.isArray(stockInCart) &&
        Array.isArray(productionInCart) &&
        Object.keys(data).includes('coupon') &&
        Array.isArray(currencies) &&
        currencies.length > 0 &&
        currency
      ) {
        const results = await priceCalculator.getResults({
          data,
          currencies,
          currency,
          stockInCart,
          productionInCart,
          onDemand,
          misc,
        });
        setPriceResults(results);
      }
    }
    setResults();
  }, [stockInCart, productionInCart, onDemand, data?.coupon, currencies, currency]);

  useEffect(() => {
    if (data.clientOrderNumber) {
      setClientOrderNumber(data.clientOrderNumber);
    }
  }, [data?.clientOrderNumber]);

  useEffect(() => {
    if (data?.currency && Array.isArray(currencies) && currencies.length > 0) {
      const c = currencies.find((c) => c.currency == data.currency);
      if (c?.currency) {
        dispatch(
          setCurrency({
            currency: c.currency,
          }),
        );
      }
    }
  }, [currencies, data?.currency]);

  useEffect(() => {
    if (data.status == 'Opened') {
      setIsEdit(true);
    }
    return () => {
      setIsEdit(false);
    };
  }, [data?.status]);

  useEffect(() => {
    if (data?.coupon) {
      setHasCoupon(true);
      setCouponCode(data.coupon.code);
      if (data.coupon.discount_amount) {
        setCouponAmount(data.coupon.discount_amount);
      } else {
        setCouponPercent(data.coupon.discount_percent);
      }
      setCouponCurrency(data.coupon.currency);
    }
  }, [data?.coupon]);

  useEffect(() => {
    if (role) {
      setIsClient(role !== 'ADMIN' && role === 'STAFF');
    }
  }, [role]);

  useEffect(() => {
    setIsMobile(windowSize.width <= 575.98);
  }, [windowSize]);

  useEffect(() => {
    console.log('Fetching details....');
    const controller = new AbortController();

    if (params.id && !isNaN(reFetchCounter)) {
      setIsLoading(true);
      api
        .get(`/cpo/details/${params.id}`)
        .then(({ data }) => {
          if (data && data.id) {
            setData(data);
            dispatch(setStockInCart(data.stockInCart));
            dispatch(setProductionInCart(data.productionInCart));
            dispatch(setOnDemand(data.onDemand || []));
            // setFirstStockInCart(data.stockInCart);
          }
        })
        .catch((e) => {
          if (e?.response?.status == 404) {
            setWasFound(false);
          } else {
            dispatch(setErrors([{ message: e }]));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return () => controller.abort();
  }, [params.id, reFetchCounter, currency]);

  const reFetchFromLot = useCallback(
    (lotId, cpoId) => {
      if (Array.isArray(data.products)) {
        api
          .get(`/cpo/bottle-quanties/${cpoId}`, { params: { lotId: lotId } })
          .then(({ data: responseData }) => {
            let productIndex = -1;
            let hasDifferentAmounts = false;

            data.products.some((product, idx) => {
              let found = false;
              const lots = product?.lots;
              if (Array.isArray(lots)) {
                const lot = lots.find((lot) => lot.lot_id == lotId);
                if (lot != undefined) {
                  found = true;
                  productIndex = idx;
                  hasDifferentAmounts =
                    parseFloat(lot.qtyStockAv) != parseFloat(responseData.qtyStockAv) ||
                    parseFloat(lot.qtyProductionAv) != parseFloat(responseData.qtyProductionAv);
                  return true;
                } else {
                  return false;
                }
              }
              return found;
            });

            if (productIndex != -1 && hasDifferentAmounts) {
              setData((prev) => {
                const productToUpdate = prev.products[productIndex];

                productToUpdate.lots = productToUpdate.lots.map((lot) => {
                  if (lot.lot_id == lotId) {
                    lot.qtyStockAv = responseData.qtyStockAv;
                    lot.qtyProductionAv = responseData.qtyProductionAv;
                  }
                  return lot;
                });

                prev.products[productIndex] = productToUpdate;
                return {
                  ...prev,
                };
              });
            }
          })
          .catch((err) => {
            toast.error(`The lot ${lotId} changed in other cart. We could not update the amount, please refresh the page.`);
          });
      } else {
        toast.error('data.products is not an array');
      }
    },
    [data.products],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSocket(socket);
      socket.connect();
      if (socket.connected) {
        setIsConnected(true);
      }
      socket.on('connect', () => {
        setIsConnected(true);
        socket.emit('join', 'orders');
        socket.on('qty-lot-updated', (socketData) => {
          if (socketData?.lotId && socketData?.cpoId != data.id) {
            reFetchFromLot(socketData.lotId, data.id);
          }
        });
      });
      socket.on('disconnect', (reason) => {
        setIsConnected(false);
        if (reason === 'io server disconnect') {
          /**
           * the disconnection was initiated by the server,
           * you need to reconnect manually
           */
          socket.connect();
        }
      });
    }, 600);

    return () => {
      clearTimeout(timeout);
      socket.offAny();
      socket.disconnect();
      setIsConnected(false);
    };
  }, [reFetchFromLot, data.id, socket]);

  useEffect(() => {
    const controller = new AbortController();
    const controllerMisc = new AbortController();

    api.get('/rates/today', { signal: controller.signal }).then(({ data }) => {
      setCurrencies(data);
    });

    if (data.id) {
      api.get(`/cpo-miscellaneous/${data.id}`, { signal: controllerMisc.signal, params: { currency } }).then(({ data }) => {
        dispatch(setMiscellaneous(data));
      });
    }
    return () => {
      controller.abort();
      controllerMisc.abort();
    };
  }, [data?.id, currency]);

  useEffect(() => {
    const controller = new AbortController();

    const to = setTimeout(() => {
      if (data.id && clientOrderNumber != data.clientOrderNumber) {
        api
          .patch(
            `/cpo/client-order-number/${data.id}`,
            {
              order_number: clientOrderNumber,
            },
            { signal: controller.signal },
          )
          .then(() => {
            toast.success(`Automatically saved your order number: ${clientOrderNumber}`, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          })
          .catch((err) => {
            dispatch(setErrors([{ message: err }]));
          });
      }
    }, 800);

    return () => {
      clearTimeout(to);
      controller.abort();
    };
  }, [clientOrderNumber]);

  const applyCoupon = () => {
    if (couponCode.length > 0) {
      setIsApplying(true);
      api
        .post(`/cpo/apply-coupon/${data.id}`, {
          code: couponCode,
        })
        .then(({ data }) => {
          if (data.valid) {
            toast.success(`Your coupon code has been applied to your order.`, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });

            setHasCoupon(true);
            delete data.valid;
            setData((prev) => {
              prev['coupon'] = {
                code: couponCode,
                ...data,
              };
              return prev;
            });
          } else {
            dispatch(setErrors([{ message: data.reason || 'Unknown error.' }]));
          }
        })
        .catch((err) => {
          dispatch(setErrors([{ message: err }]));
          setIsApplying(false);
        })
        .finally(() => {
          setIsApplying(false);
        });
    } else {
      setIsApplying(false);

      dispatch(
        setErrors([
          {
            message: 'Please enter a coupon code.',
          },
        ]),
      );
    }
  };

  if (isLoading) {
    return (
      <ContentLayout title={'Loading...'}>
        <Loading />
      </ContentLayout>
    );
  }

  if (!wasFound) {
    return (
      <ContentLayout title={'Order Not Found'}>
        <CAlert color="warning">The order #{params.id} was not found.</CAlert>
        <CButton
          onClick={() => {
            navigate('/dashboard/cpos/cpos');
          }}
        >
          {isClient ? 'Go to all my orders' : 'Go to all orders'}
        </CButton>
      </ContentLayout>
    );
  }

  const removeCoupon = () => {
    try {
      setRemovingCoupon(true);
      api
        .delete(`/cpo/coupon/${data.id}`)
        .then(() => {
          dispatch(setSuccessMessages([{ message: 'The coupon has been removed.' }]));
          setCouponCode('');
          setCouponAmount(0);
          setCouponPercent(0);
          setCouponCurrency('');
          setShowRemoveCoupon(false);
          setHasCoupon(false);
          setData((prev) => {
            prev.coupon = null;
            return prev;
          });
        })
        .catch((e) => {
          dispatch(setErrors([{ message: e }]));
        })
        .finally(() => {
          setRemovingCoupon(false);
        });
    } catch (e) {
      dispatch(setErrors([{ message: e.message }]));
      setRemovingCoupon(false);
    }
  };

  const confirmOrder = () => {
    if (!data.id) {
      return toast.error('The order was undefined. Please refresh and try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    const keys = Object.keys(data);

    if (keys.includes('stockInCart') && keys.includes('productionInCart') && Array.isArray(stockInCart) && Array.isArray(productionInCart)) {
      const prods = [...stockInCart, ...productionInCart];

      const hasDepleted = prods.some((item) => parseFloat(item.qty) > 0);

      if (hasDepleted) {
        setVisible(true);
      } else {
        return toast.error('Your cart is empty. Add some products first.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } else {
      dispatch(
        setErrors([
          {
            message: 'The cart object is invalid. Please refresh and try again.',
          },
        ]),
      );
    }
  };

  const postOrder = async () => {
    setIsLoadingPost(true);
    api
      .patch('/cpo/order-status/' + data.id, {
        order_status: 'In Preparation', // Test empty for now
      })
      .then(({ data }) => {
        if (data) {
          if (data.placeNewOrder) {
            toast.success('Your order has been posted. You have entered an amount above inventory on-hand, this will send bottles to production which will add time to your estimated ship date.');
          } else {
            toast.success('Your order has been posted.');
          }
          // askDownload(data.id)
        }
        setRefetchCounter((v) => v + 1);
      })
      .finally(() => {
        setVisible(false);
        setIsLoadingPost(false);
      });
  };

  return (
    <ContentLayout title={`Order ${data.id ? `#${data.id}` : ''}`}>
      <CRow>
        <CCol>
          <div className="">
            <BreadCrumbs
              items={[
                {
                  to: '/dashboard/cpos/cpos',
                  text: 'CPOs',
                  disabled: false,
                },
                {
                  to: `/dashboard/cpos/${data.id}`,
                  text: `Order #${data.id}`,
                  disabled: true,
                },
              ]}
            />
          </div>
        </CCol>
      </CRow>
      <div className="cpo-details">
        <CRow>
          <CCol>
            <h3 className="fs-6 fw-bold">Info</h3>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs="12" md="3" lg="2">
            <div className="status-flex">
              <div>
                <CFormLabel>Status:</CFormLabel>
              </div>
              <CAlert color="info" className={'d-flex align-items-center justify-items-center mb-0 w-100'} style={{ maxHeight: '38px', marginTop: 'auto' }}>
                <TheIcon name="info_black" />
                {data.status}
              </CAlert>
            </div>
          </CCol>
          <CCol xs="12" md="3" lg="2">
            <CFormLabel>Currency</CFormLabel>
            {currencyUpdated && (
              <select
                key={`currency-${currency}`}
                className="form-select"
                aria-label="example"
                defaultValue={currency}
                onChange={(e) => {
                  const newCurrency = e.target.value;
                  if (newCurrency && !!data.id) {
                    api
                      .patch(`/cpo/currency/${data.id}`, {
                        currency: newCurrency,
                      })
                      .then(() => {
                        currencies.forEach((c) => {
                          if (c?.currency === newCurrency) {
                            dispatch(
                              setCurrency({
                                currency: c.currency,
                              }),
                            );
                            toast.success(`The new currency for this order is now: ` + newCurrency, {
                              position: 'top-right',
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: 'colored',
                            });
                          }
                        });
                      })
                      .catch((e) => {
                        dispatch(
                          setErrors([
                            {
                              message: e,
                            },
                          ]),
                        );
                      });
                  }
                }}
              >
                {currencies &&
                  currencies.map((currency, i) => {
                    return (
                      <option value={currency.currency} key={`option-currency-${currency.currency}-${i}`}>
                        {currency.currency}
                      </option>
                    );
                  })}
              </select>
            )}
          </CCol>
          <CCol xs="12" md="6" lg="3">
            <CFormLabel htmlFor="clientOrderNumberInput">Client Order #:</CFormLabel>
            <CFormInput
              id="clientOrderNumberInput"
              value={clientOrderNumber}
              onChange={(e) => {
                setClientOrderNumber(e.target.value);
              }}
            ></CFormInput>
          </CCol>
          <CCol xs="12" md="6" lg="5">
            {hasCoupon ? (
              <>
                <div>
                  <CFormLabel>&nbsp;</CFormLabel>
                </div>
                <CButton color="primary">
                  <span>{typeof couponCode == 'string' ? couponCode.toUpperCase() : ''}</span> &nbsp;
                  <CBadge color="secondary">{couponAmount ? money(couponAmount, couponCurrency) : `${couponPercent}%`} OFF</CBadge>
                </CButton>
                <LButton
                  isLoading={removingCoupon}
                  color="danger"
                  className="ms-1"
                  onClick={() => {
                    setShowRemoveCoupon(true);
                  }}
                >
                  Remove
                </LButton>
              </>
            ) : (
              <>
                <CRow>
                  <CCol xs={8}>
                    <CFormLabel>Coupon Code</CFormLabel>
                    <CFormInput
                      value={couponCode}
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                      }}
                      placeholder="Coupon Code"
                      style={{ textTransform: 'uppercase' }}
                      disabled={data?.coupon?.code?.length > 0 || false}
                    ></CFormInput>
                  </CCol>
                  <CCol xs={4}>
                    <div>
                      <CFormLabel>&nbsp;</CFormLabel>
                    </div>
                    <LButton isLoading={isApplying} onClick={applyCoupon} disabled={data?.coupon?.code?.length > 0 || false}>
                      APPLY
                    </LButton>
                  </CCol>
                </CRow>
              </>
            )}
          </CCol>
        </CRow>
      </div>
      <CRow>
        <CCol>
          {isConnected && (
            <CAlert color="success">The amounts of bottles will change if someone else adds or removes bottles to their cart in real-time.</CAlert>
          )}
        </CCol>
      </CRow>
      <CRow>
        {/*
        <CCol>
          <CFormLabel>Auto-Save</CFormLabel>
          <CFormSwitch
            checked={isAutoSave}
            onChange={() => {
              setIsAutoSave((v) => !v);
            }}
            label="Save the changes in my order automatically."
          />
        </CCol>
          */}
      </CRow>
      <CRow>
        <CCol>
          <div className="my-3">
            <h3 className="fs-6 fw-bold">Products</h3>
          </div>
          <div className="table-container">
            <CTable className="orders-table">
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell width={`${isMobile ? '30' : '40'}%`}>Product</CTableHeaderCell>
                  <CTableHeaderCell>In Cart</CTableHeaderCell>
                  <CTableHeaderCell>Price</CTableHeaderCell>
                  <CTableHeaderCell>Total</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {Array.isArray(data?.products) &&
                  data.products.map((product) => <OrderRow key={`product-row-${product.product_id}`} product={product} isMobile={isMobile} />)}

                {Array.isArray(misc) &&
                  misc.map((item, idx) => {
                    return <MiscellaneousCartRow item={item} key={`misc-it-r-${idx}`} isEdit={isEdit} />;
                  })}

                <CTableRow style={{ background: '#ADD8E6', marginTop: '0.3rem' }}>
                  <CTableDataCell colSpan={3}>
                    <b>Subtotal</b>
                  </CTableDataCell>
                  <CTableDataCell colSpan={1}>
                    <b>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: currency || 'USD',
                      }).format(priceResults.subtotal)}
                    </b>
                  </CTableDataCell>
                </CTableRow>
                {data && (data?.coupon?.discount_percent || data?.coupon?.discount_amount) ? (
                  <>
                    <CTableRow style={{ background: '#ADD8E6' }}>
                      <CTableDataCell colSpan={3}>
                        <b>Coupon</b>
                      </CTableDataCell>
                      <CTableDataCell colSpan={1}>
                        <b>{priceResults.discountString}</b>
                      </CTableDataCell>
                    </CTableRow>
                  </>
                ) : (
                  <></>
                )}

                <CTableRow style={{ background: '#ADD8E6' }}>
                  <CTableDataCell colSpan={3}>
                    <b>Total</b>
                  </CTableDataCell>
                  <CTableDataCell colSpan={1}>
                    <b>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: currency || 'USD',
                      }).format(priceResults.total)}
                    </b>
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <div className="flex-columns">
            {data && data.status === 'Opened' ? (
              <LButton
                isLoading={isLoadingPost}
                onClick={() => {
                  confirmOrder();
                }}
              >
                Post Order
              </LButton>
            ) : (
              <DownloadPdfFiles cpoId={data.id} status={data.status} isAdminOrStaff={isAdminOrStaff} />
            )}
            {data && data.status === 'Opened' && (
              <CButton color="danger" onClick={() => setVisibleDelete(true)}>
                Delete Order.
              </CButton>
            )}
            {data && data.status === 'In Preparation' && (
              <CButton color="info" variant="outline" onClick={() => setVisibleChange(true)}>
                Change my order
              </CButton>
            )}
            {isAdminOrStaff && isEdit && <AddMiscCart cpoId={parseInt(data.id)} />}
            <PalletSituation />
            {isAdminOrStaff && data?.status != 'Opened' && <DownloadExcel cpoId={data.id} />}
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <YesNoModal
            visible={showRemoveCoupon}
            setVisible={setShowRemoveCoupon}
            onNo={() => {
              setShowRemoveCoupon(false);
            }}
            title="Remove Coupon"
            description={'This will remove the coupon from your order. You will be able to add it again if it hasn\'t expired.'}
            onYes={removeCoupon}
            isLoading={removingCoupon}
          />

          <YesNoModal
            visible={visibleDelete}
            setVisible={setVisibleDelete}
            title="Do you want to delete cancel the order?"
            description={'This will delete the order and you won\'t be able to restore it.'}
            onYes={() => {
              api
                .delete('/cpo/' + data.id)
                .then(() => {
                  setVisibleDelete(false);
                  navigate('/dashboard/cpos/cpos');
                })
                .catch((err) => {
                  dispatch(setErrors([{ message: err }]));
                });
            }}
            onNo={() => {
              setVisibleDelete(false);
            }}
          ></YesNoModal>
          <YesNoModal
            visible={visible}
            setVisible={setVisible}
            title="Do you want to place the order?"
            description={
              <>
                <p>Your order will be processed.</p>
              </>
            }
            onYes={postOrder}
            onNo={() => {
              setVisible(false);
            }}
            isLoading={isLoadingPost}
            yesText="Post Order"
            noText="Cancel"
          ></YesNoModal>
          <YesNoModal
            visible={visibleChange}
            setVisible={setVisibleChange}
            title="Change order?"
            isLoading={isPatchingStatus}
            description={
              'This will update the order status from \'In Preparation\' to \'Opened\' and you will be able to change the items and amounts in your order.'
            }
            onYes={() => {
              setIsPatchingStatus(true);
              api
                .patch('/cpo/order-status/' + data.id, {
                  order_status: 'Opened', // Test empty for now
                })
                .then(() => {
                  setVisibleChange(false);
                  setRefetchCounter((v) => v + 1);
                })
                .finally(() => {
                  setIsPatchingStatus(false);
                });
            }}
            onNo={() => {
              setVisibleChange(false);
            }}
          ></YesNoModal>
        </CCol>
      </CRow>
    </ContentLayout>
  );
}
