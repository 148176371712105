import { CButton, CSpinner } from '@coreui/react';
import PropTypes from 'prop-types';

const LButton = (props) => {
  const propsCopy = { ...props };
  delete propsCopy.isLoading;
  return (
    <CButton {...propsCopy} disabled={props.disabled || props.isLoading || false}>
      {props.isLoading && <CSpinner color="white" size="sm" style={{ marginRight: '0.6rem' }} />}
      {props.children}
    </CButton>
  );
};

LButton.defaultProps = {
  isLoading: false,
};

LButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default LButton;
