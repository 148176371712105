import styles from './tasting-menu-item.module.css';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react';
import { cilPlus } from '@coreui/icons';
import { CModal, CModalBody, CModalHeader, CModalFooter, CModalTitle, CRow, CFormSelect, CButton } from '@coreui/react';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';

const TastingItem = ({ fixedSize, expression, products, onItemUpdated, isDisabled }) => {
  const [brand, setBrand] = useState('');
  const [type, setType] = useState('');
  const [age, setAge] = useState('');
  const [visible, setVisible] = useState(false);
  const [productId, setProductId] = useState(-1);
  const [chosenProduct, setChosenProduct] = useState({});

  const [editBrand, setEditBrand] = useState(false);
  const [editType, setEditType] = useState(false);
  const [hasData, setHasData] = useState(false);

  const dispatch = useDispatch();

  const choose = () => {
    let chosenProductLocal = {};

    const productExists = products.some((p) => {
      const isProduct = p.id == productId;
      if (isProduct) {
        chosenProductLocal = p;
        setChosenProduct(p);
      }
      return isProduct;
    });

    if (productExists) {
      setBrand(chosenProductLocal.computed_expression);
      setType(chosenProductLocal.agave_scientific_name);
      setAge(chosenProductLocal.edad_a_madurez);
      setVisible(false);
    } else {
      dispatch(
        setErrors([
          {
            message: 'Please choose a product.',
          },
        ]),
      );
    }
  };

  const clear = () => {
    setBrand('');
    setType('');
    setAge('');
    setChosenProduct({});
    setHasData(false);
  };

  useEffect(() => {
    onItemUpdated({
      id: expression.id,
      brand,
      type,
      age,
    });
  }, [brand, type, age]);

  useEffect(() => {
    if (expression && (expression?.brand?.length > 0 || expression?.type?.length > 0 || expression?.age?.length > 0)) {
      setBrand(expression?.brand);
      setType(expression?.type);
      setAge(expression?.age);
      setHasData(true);
    }
  }, [expression]);

  return (
    <>
      <section className={styles['tasting-menu-item']}>
        <div>
          <div className={fixedSize ? styles['circle-fixed'] : styles['circle']}>
            {chosenProduct?.id > 0 || hasData ? (
              <>
                <div>
                  <h1>{expression.id}</h1>
                  {editBrand ? (
                    <>
                      <input
                        type="text"
                        placeholder="Brand"
                        value={brand}
                        onChange={(e) => {
                          setBrand(e.target.value);
                        }}
                        className={styles.editInput}
                        onBlur={() => setEditBrand(false)}
                      />
                    </>
                  ) : (
                    <p
                      onDoubleClick={() => {
                        if (!isDisabled) {
                          setEditBrand(true);
                        }
                      }}
                    >
                      {brand}
                    </p>
                  )}

                  {editType ? (
                    <>
                      <input
                        type="text"
                        placeholder="Type"
                        value={type}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                        className={styles.editInput}
                        onBlur={() => setEditType(false)}
                      />
                    </>
                  ) : (
                    <p
                      onDoubleClick={() => {
                        if (!isDisabled) {
                          setEditType(true);
                        }
                      }}
                    >
                      {type}
                    </p>
                  )}

                  {fixedSize && (
                    <div className="mt-3">
                      <CButton size="sm" color="danger" onClick={clear}>
                        Remove
                      </CButton>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  className={styles['tasting-add-btn']}
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  <CIcon icon={cilPlus} size="xl" />
                </div>
              </>
            )}
          </div>
          {!fixedSize && (
            <div>
              <div className={styles['line-item']}>
                <p>Appearance:</p>
                <span className={styles.line}></span>
              </div>
              <div className={styles['line-item']}>
                <p>Nose:</p>
                <span className={styles.line}></span>
              </div>
              <div className={styles['line-item']}>
                <p>Body:</p>
                <span className={styles.line}></span>
              </div>
              <div className={styles['line-item']}>
                <p>Finish:</p>
                <span className={styles.line}></span>
              </div>
              <div className={styles['line-ratings']}>
                <p>Notes:</p>
                <div className={styles.ratings}>
                  <b>1</b> <b>2</b> <b>3</b>
                  <b>4</b>
                  <b>5</b>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <CModal visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Choose Expressions</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CRow>
                <CFormSelect
                  value={productId}
                  onChange={(e) => {
                    setProductId(e.target.value);
                  }}
                >
                  <option value="-1">Choose an expression.</option>
                  {products.map((product, index) => {
                    return (
                      <option value={product.id} key={`product-opt-modal-${index}`}>
                        {product.computed_expression}
                      </option>
                    );
                  })}
                </CFormSelect>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="danger"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </CButton>
              <CButton color="primary" onClick={choose}>
                Next
              </CButton>
            </CModalFooter>
          </CModal>
        </div>
      </section>
    </>
  );
};

TastingItem.defaultProps = {
  fixedSize: false,
  expression: {},
  products: [],
  onItemUpdated: () => {
    console.warn('Function Not defined!');
  },
  isDisabled: false,
};

TastingItem.propTypes = {
  fixedSize: PropTypes.bool,
  expression: PropTypes.object,
  products: PropTypes.array,
  onItemUpdated: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default TastingItem;
