const successMessagesReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET_SUCCESS_MESSAGES':
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default successMessagesReducer;
