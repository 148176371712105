import { useCallback, useEffect, useState } from 'react';
import api from 'src/api';
import ContentLayout from 'src/layout/ContentLayout';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CRow,
} from '@coreui/react';
import BreadCrumbs from 'src/components/BreadCrumbs';
import { useNavigate } from 'react-router';
import Loading from 'src/components/Loading';
import { Helmet } from 'react-helmet';

export default function LotsProcess() {
  const [lots, setLots] = useState([]);
  const [results, setResults] = useState([]);
  const [search, setSearch] = useState('');
  const [hideC, setHideC] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(false);
    api
      .get('/lotes/status', { signal: controller.signal })
      .then(({ data }) => {
        setLots(data);
      })
      .finally(() => setLoading(false));
    return () => controller.abort();
  }, []);

  const getResults = useCallback(() => {
    return lots.filter((lot) => {
      if (hideC) {
        return lot.statusSimple != 'Completed';
      } else {
        return true;
      }
    });
  }, [lots, hideC]);

  useEffect(() => {
    setLoading(true);

    const interval = setTimeout(() => {
      if (search.length > 0) {
        setResults(
          lots.filter((lot) => {
            if (hideC && lot.statusSimple == 'Completed') {
              return false;
            } else {
              return lot.lot_number
                .toLowerCase()
                .includes(search.toLowerCase());
            }
          }),
        );
      } else {
        setResults(getResults());
      }
      setLoading(false);
    }, 500);

    return () => {
      clearInterval(interval);
      setResults(getResults());
      setLoading(false);
    };
  }, [lots, search, hideC]);

  const navigate = useNavigate();

  return (
    <ContentLayout title={'Production Process'}>
      <Helmet>
        <title>Process - Batch Production</title>
      </Helmet>
      <div>
        <BreadCrumbs
          items={[
            {
              to: '/dashboard/process/lots',
              text: 'Production Process',
              disabled: true,
            },
          ]}
        />
        <div className="my-4">
          <CRow>
            <CCol xs={12} md={4} xl={2}>
              <CFormLabel>Hide Completed</CFormLabel>
              <CFormSwitch
                checked={hideC}
                onChange={() => setHideC((c) => !c)}
              />
            </CCol>
            <CCol xs={12} md={8} lg={10}>
              <CFormLabel>Filter By Lot Number</CFormLabel>
              <CFormInput
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </CCol>
          </CRow>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            {results.length < 1 ? (
              <>
                <h3 className="text-center text-danger">No results found.</h3>
              </>
            ) : (
              <div className="table-container">
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Lot Number</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {results.map((lot, idx) => {
                      return (
                        <Tr key={`lot-${idx}`}>
                          <Td>{lot.lot_number}</Td>
                          <Td>
                            <span
                              className={`${
                                lot.statusSimple == 'Completed' ?
                                  'text-success' :
                                  ''
                              }`}
                            >
                              {lot.status}
                            </span>
                          </Td>
                          <Td>
                            <CButton
                              onClick={() => {
                                navigate(`/dashboard/process/lot/${lot.id}`);
                              }}
                            >
                              Details
                            </CButton>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </div>
            )}
          </>
        )}
      </div>
    </ContentLayout>
  );
}
