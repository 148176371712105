import { CContainer, CRow, CCol, CCard, CCardBody, CCardHeader, CCardTitle, CCardFooter, CButton } from '@coreui/react';

import api from 'src/api';
import { useState, useEffect } from 'react';
import { ShelfTalkerProvider } from '../../context/ShelfTalkerContext';
import ShelfTalker from 'src/components/ShelfTalker/ShelfTalker';
import { useNavigate } from 'react-router-dom';
import YesNoModal from 'src/components/YesNoModal';
import { useDispatch } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';

const MyShelfTalkers = () => {
  const [talkers, setTalkers] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isDeleting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    api.get('/shelf-talker', { signal: controller.signal }).then(({ data }) => {
      setTalkers(data);
    });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <CContainer>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <CCardTitle>My Shelf Talkers</CCardTitle>
            </CCardHeader>
            <CCardBody>
              <ShelfTalkerProvider>
                <CRow>
                  <CCol>
                    <CButton
                      color="dark"
                      className="mb-3"
                      onClick={() => {
                        navigate('/dashboard/tools/shelf-talker');
                      }}
                    >
                      New Shelf Talker
                    </CButton>
                  </CCol>
                </CRow>
                <CRow>
                  {talkers.map((talker, idx) => (
                    <CCol xs={12} xl={6} key={`shelf-talker-${idx}`}>
                      <CCard style={{ margin: '1rem' }}>
                        <CCardHeader>
                          <CCardTitle>{talker.title ? talker.title : 'Untitled'}</CCardTitle>
                        </CCardHeader>
                        <CCardBody>
                          <div className="d-flex justify-content-center">
                            <ShelfTalker showControls={false} withProps={true} {...talker} />
                          </div>
                        </CCardBody>
                        <CCardFooter>
                          <CButton
                            className="me-2"
                            onClick={() => {
                              navigate({
                                pathname: '/tools/shelf-talker',
                                search: `?id=${talker.id}`,
                              });
                            }}
                          >
                            Edit
                          </CButton>
                          <CButton
                            color="danger"
                            onClick={() => {
                              setDeleteId(talker.id);
                              setVisible(true);
                            }}
                          >
                            Delete
                          </CButton>
                        </CCardFooter>
                      </CCard>
                    </CCol>
                  ))}
                </CRow>
                <CRow>
                  <CCol>
                    <YesNoModal
                      visible={visible}
                      setVisible={setVisible}
                      title="Do you want to delete this shelf talker?"
                      description="You can't undo this."
                      isLoading={isDeleting}
                      onNo={() => {
                        setVisible(false);
                      }}
                      onYes={() => {
                        api
                          .delete(`/shelf-talker/${deleteId}`)
                          .then(() => {
                            setTalkers((prevTalkers) => {
                              return prevTalkers.filter((pt) => pt.id !== deleteId);
                            });
                            dispatch(
                              setSuccessMessages([
                                {
                                  message: 'The shelf talker has' + 'been deleted successfully',
                                },
                              ]),
                            );
                          })
                          .catch(() => {
                            setErrors([
                              {
                                message: 'We were unable to delete the shelf talker.' + ' Please Try again later.',
                              },
                            ]);
                          })
                          .finally(() => {
                            setVisible(false);
                            setDeleteId(null);
                          });
                      }}
                    />
                  </CCol>
                </CRow>
              </ShelfTalkerProvider>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default MyShelfTalkers;
