import React from 'react';
import PropTypes from 'prop-types';
import {
  CCol,
  CRow,
  CModalBody,
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CFormTextarea,
} from '@coreui/react';
import LButton from 'src/components/LButton/LButton';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';
import { useEffect } from 'react';
import api from 'src/api';

const ModalPurchaseDetails = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [lotNumber, setLotNumber] = useState('');
  const [classification, setClassification] = useState('');
  const [abv, setAbv] = useState('');
  const [liters, setLiters] = useState('');
  const [pricePerLiter, setPricePerLiter] = useState('');
  const [ext, setExt] = useState('');
  const [notes, setNotes] = useState('');

  const saveChanges = () => {
    let valid = true;
    let message = 'Unknown error';

    let lotExtraField = null;

    lotExtraField = props.purchase.lot_extra_field || {};

    if (typeof lotExtraField != 'object') {
      lotExtraField = {};
    }

    lotExtraField.GRADOS = `${abv}`;

    const gradosN = parseFloat(abv);
    if (isNaN(gradosN)) {
      valid = false;
      message = 'ABV (Grados) must be a number.';
    } else if (gradosN < 0) {
      valid = false;
      message = 'ABV (Grados) must be a positive number.';
    } else if (gradosN > 100) {
      valid = false;
      message = `ABV (Grados) can't be greater than 100`;
    }

    if (typeof lotNumber != 'string') {
      valid = false;
      message = 'The value for the lot number is invalid';
    } else if (lotNumber.length < 1) {
      valid = false;
      message = 'Please enter the lot number';
    }

    const priceN = parseFloat(pricePerLiter);
    if (isNaN(priceN)) {
      valid = false;
      message = 'The Price per liter must be a number.';
    } else if (priceN <= 0) {
      valid = false;
      message = 'The Price per liter must be a positive number.';
    }

    const litersN = parseFloat(liters);
    if (isNaN(litersN)) {
      setLiters(0);
    } else if (litersN <= 0) {
      setLiters(0);
    }

    if (valid) {
      const data = {
        classification,
        liters,
        pricePerLiter,
        notes,
        lotNumber,
        lot_extra_field: JSON.stringify(lotExtraField),
      };

      setIsLoading(true);

      api
        .put(`/lotes/purchases/${props.purchase.lot_id}`, data)
        .then(() => {
          dispatch(setSuccessMessages([{ message: 'Updated successfully!' }]));
          props.onFinish();
        })
        .catch((e) => {
          dispatch(setErrors([{ message: e }]));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      dispatch(setErrors([{ message }]));
    }
  };

  useEffect(() => {
    const purchase = props.purchase;

    setPricePerLiter(purchase.price_per_liter);
    setLiters(purchase.liters_purchased);
    setNotes(purchase.purchase_note || '');
    setLotNumber(purchase.lot_number);
    setClassification(purchase.classification || '');

    if (typeof purchase?.lot_extra_field?.GRADOS == 'string') {
      setAbv(purchase.lot_extra_field.GRADOS);
    }

    return () => {
      setLotNumber('');
      setClassification('');
      setAbv('');
      setLiters('');
      setPricePerLiter('');
      setNotes('');
    };
  }, [props.purchase]);

  useEffect(() => {
    const ppl = parseFloat(pricePerLiter);
    const lts = parseFloat(liters);
    if (!isNaN(ppl) && !isNaN(lts)) {
      setExt(`${ppl * lts}`);
    } else {
      setExt('');
    }
  }, [pricePerLiter, liters]);

  return (
    <CModalBody>
      <CForm style={{ margin: '10px' }}>
        <CRow className="mb-2">
          <CCol>
            <CFormLabel className="mb-1" htmlFor="expressionInput">
              Expression
            </CFormLabel>
            <CFormInput
              type="text"
              id="expressionInput"
              defaultValue={props.purchase.expression}
              size="sm"
              readOnly
            />
          </CCol>
        </CRow>
        <CRow className="mb-2">
          <CCol>
            <CFormLabel className="mb-1" htmlFor="lotInput">
              Lot
            </CFormLabel>
            <CFormInput
              type="text"
              id="lotInput"
              value={lotNumber}
              onChange={(e) => {
                setLotNumber(e.target.value);
              }}
              size="sm"
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="classInput">
              Classification
            </CFormLabel>
            <CFormInput
              type="text"
              id="classInput"
              value={classification}
              readOnly
              onChange={(e) => {
                setClassification(e.target.value);
              }}
              size="sm"
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="abvInput">
              ABV
            </CFormLabel>
            <CFormInput
              type="text"
              id="abvInput"
              value={abv}
              onChange={(e) => {
                setAbv(e.target.value);
              }}
              size="sm"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="litersInput">
              Liters
            </CFormLabel>
            <CFormInput
              type="text"
              id="litersInput"
              value={liters}
              onChange={(e) => {
                setLiters(e.target.value);
              }}
              size="sm"
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="mxpLtrInput">
              MXP$/LTR
            </CFormLabel>
            <CFormInput
              type="text"
              id="mxpLtrInput"
              value={pricePerLiter}
              onChange={(e) => {
                setPricePerLiter(e.target.value);
              }}
              size="sm"
            />
          </CCol>
          <CCol>
            <CFormLabel className="mb-1" htmlFor="extInput">
              EXT
            </CFormLabel>
            <CFormInput
              type="text"
              id="extInput"
              value={ext}
              onChange={(e) => {
                setExt(e.target.value);
              }}
              size="sm"
              readOnly
            />
          </CCol>
        </CRow>
        <div className="mb-3">
          <CFormLabel className="mb-1" htmlFor="notesInput">
            Notes
          </CFormLabel>
          <CFormTextarea
            id="notesInput"
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            size="sm"
          />
        </div>
        <LButton color="primary" isLoading={isLoading} onClick={saveChanges}>
          Save Changes
        </LButton>
        <CButton
          color="danger"
          onClick={() => {
            props.setVisible(false);
          }}
          className="ms-1"
        >
          Close
        </CButton>
      </CForm>
    </CModalBody>
  );
};

ModalPurchaseDetails.propTypes = {
  purchase: PropTypes.any,
  setVisible: PropTypes.any,
  detailPurchase: PropTypes.any,
  onFinish: PropTypes.func,
};

export default ModalPurchaseDetails;
