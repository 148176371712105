import React from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CContainer,
  CModalTitle,
  CFormTextarea,
} from '@coreui/react';
import { useState, useEffect } from 'react';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';

import api from '../../api';
import { Helmet } from 'react-helmet';

const Maguey = () => {
  const [editVisible, setEditVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [magueyList, setMagueyList] = useState([]);
  const [modalMaguey, setModalMaguey] = useState([]);

  function fetchMaguey() {
    api.get('/catalog/items/maguey-name').then(({ data }) => {
      setMagueyList(data);
    });
  }

  useEffect(() => {
    fetchMaguey();
  }, []);

  function handleNewSubmit(e) {
    e.preventDefault();
    if (!e.target.elements.commonInput.value) {
      alert('Common Name must be entered');
      return 0;
    }
    if (!e.target.elements.agaInput.value) {
      alert('Agave must be entered');
      return 0;
    }

    const tempMagueyList = magueyList.map((element) => {
      return element.common_name;
    });

    if (
      tempMagueyList.includes(e.target.elements.commonInput.value.toUpperCase())
    ) {
      alert('Common Name already exists');
      return 0;
    }

    api
      .post('/catalog/items/maguey-name/new', {
        common_name: e.target.elements.commonInput.value,
        agave_scientific_name: e.target.elements.agaInput.value,
        edad_a_madurez: e.target.elements.edadInput.value,
        note: e.target.elements.notesInput.value,
      })
      .then(() => {
        fetchMaguey();
      })
      .finally(() => {
        setNewVisible(false);
      });
  }

  function handleSaveSubmit(e) {
    e.preventDefault();
    api
      .post('/catalog/items/maguey-name/update', {
        common_name: e.target.elements.commonInput.value,
        agave_scientific_name: e.target.elements.agaInput.value,
        edad_a_madurez: e.target.elements.edadInput.value,
        note: e.target.elements.notesInput.value,
      })
      .then(() => {
        fetchMaguey();
      })
      .finally(() => {
        setEditVisible(false);
      });
  }

  function deleteMaguey(commonName) {
    api
      .post('/catalog/items/maguey-name/delete', {
        common_name: commonName,
      })
      .then(() => {
        fetchMaguey();
      })
      .finally(() => {
        setEditVisible(false);
      });
  }

  function MagueyNewModal() {
    return (
      <CModalBody key="modal2">
        <CForm style={{ margin: '10px' }} onSubmit={handleNewSubmit}>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="commonInput">
                Nombre Comun
              </CFormLabel>
              <CFormInput type="text" id="commonInput" size="sm" />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="agaInput">
                Agave Nombre Científico
              </CFormLabel>
              <CFormInput type="text" id="agaInput" size="sm" />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="edadInput">
                Edad a Madurez
              </CFormLabel>
              <CFormInput type="text" id="edadInput" size="sm" />
            </CCol>
            <CCol>
              <CFormLabel className="mb-2" htmlFor="notesInput">
                Notes
              </CFormLabel>
              <CFormTextarea id="notesInput" rows="2" />
            </CCol>
          </CRow>
          <div style={{ marginTop: '5px' }}>
            <CButton type="submit" color="primary">
              Add New
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                setNewVisible(false);
              }}
              style={{ marginLeft: '10px', color: 'white' }}
            >
              Close
            </CButton>
          </div>
        </CForm>
      </CModalBody>
    );
  }

  function MagueyModal(props) {
    return (
      <CModalBody key="modal1">
        <CModal key="modalConfirmDelete" size="sm" visible={deleteModalVisible}>
          <CModalHeader>
            <CModalTitle>Confirm Delete</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CButton
              color="primary"
              onClick={() => {
                deleteMaguey(props.maguey.common_name);
                setDeleteModalVisible(false);
              }}
            >
              Delete
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                setDeleteModalVisible(false);
              }}
              style={{ marginLeft: '10px', color: 'white' }}
            >
              Close
            </CButton>
          </CModalBody>
        </CModal>
        <CForm style={{ margin: '10px' }} onSubmit={handleSaveSubmit}>
          <CRow className="mb-2">
            <CCol>
              <CFormLabel className="mb-1" htmlFor="commonInput">
                Nombre Comun
              </CFormLabel>
              <CFormInput
                type="text"
                id="commonInput"
                defaultValue={props.maguey.common_name}
                size="sm"
                readOnly
              />
            </CCol>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="agaInput">
                Agave Nombre Científico
              </CFormLabel>
              <CFormInput
                type="text"
                id="agaInput"
                defaultValue={props.maguey.agave_scientific_name}
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CFormLabel className="mb-1" htmlFor="edadInput">
                Edad a Madurez
              </CFormLabel>
              <CFormInput
                type="text"
                id="edadInput"
                defaultValue={props.maguey.edad_a_madurez}
                size="sm"
              />
            </CCol>
            <CCol>
              <CFormLabel className="mb-2" htmlFor="notesInput">
                Notes
              </CFormLabel>
              <CFormTextarea
                id="notesInput"
                defaultValue={props.maguey.note}
                rows="2"
              />
            </CCol>
          </CRow>
          <div style={{ marginTop: '10px' }}>
            <CButton
              color="danger"
              className="float-end"
              onClick={() => setDeleteModalVisible(true)}
            >
              <CIcon icon={cilTrash} style={{ color: 'white' }} />
            </CButton>
            <CButton type="submit" color="primary">
              Save
            </CButton>
            <CButton
              color="danger"
              onClick={() => {
                setEditVisible(false);
              }}
              style={{ marginLeft: '10px', color: 'white' }}
            >
              Close
            </CButton>
          </div>
        </CForm>
      </CModalBody>
    );
  }

  function adjustModal(maguey) {
    setModalMaguey(maguey);
    setEditVisible(!editVisible);
  }

  function MagueyRow(props) {
    return (
      <Tr
        style={{ cursor: 'pointer', fontSize: '0.85em' }}
        onClick={(e) => {
          adjustModal(props.maguey);
        }}
        key={'mr' + props.m}
      >
        <Td>{props.maguey.common_name}</Td>
        <Td>{props.maguey.agave_scientific_name}</Td>
        <Td>{props.maguey.edad_a_madurez}</Td>
        <Td>{props.maguey.note}</Td>
      </Tr>
    );
  }

  function MagueysList(props) {
    const mgs = props.magueys;
    let m = -1;
    const list = mgs.map((maguey) => {
      m++;
      return <MagueyRow maguey={maguey} m={m} key={`mez-row-${m}`} />;
    });
    return list;
  }

  return (
    <CContainer>
      <Helmet>
        <title>Magueys</title>
      </Helmet>
      <CModal
        key="modalNew"
        visible={newVisible}
        onDismiss={() => setNewVisible(false)}
        size="lg"
      >
        <CModalHeader>
          <CCol sm={5}>
            <strong>MAGUEY NAME</strong> <small>New</small>
          </CCol>
        </CModalHeader>
        <MagueyNewModal />
      </CModal>
      <CModal
        key="modalEdit"
        visible={editVisible}
        onDismiss={() => setEditVisible(false)}
        size="lg"
      >
        <CModalHeader>
          <CCol sm={5}>
            <strong>MAGUEY NAME</strong> <small>Details</small>
          </CCol>
        </CModalHeader>
        <MagueyModal maguey={modalMaguey} />
      </CModal>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <CCol sm={5}>
                <strong>MAGUEY NAME</strong>
              </CCol>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CButton
                    style={{ marginBottom: '15px' }}
                    onClick={() => setNewVisible(true)}
                  >
                    New Maguey Name
                  </CButton>
                </CCol>
              </CRow>
              <div className="table-container">
                <Table>
                  <Thead>
                    <Tr>
                      <Th scope="col">Nombre Comun</Th>
                      <Th scope="col">Agave Nombre Científico</Th>
                      <Th scope="col">Edad a Madurez</Th>
                      <Th scope="col">Notes</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <MagueysList magueys={magueyList} />
                  </Tbody>
                </Table>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

Maguey.defaultProps = {
  magueys: [],
};

Maguey.propTypes = {
  magueys: PropTypes.any,
  products: PropTypes.any,
  magueyList: PropTypes.any,
  maguey: PropTypes.string,
  m: PropTypes.number,
};

export default Maguey;
