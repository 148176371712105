import '../../scss/steps.scss';
import TheIcon from '../TheIcon/TheIcon';
import PropTypes from 'prop-types';

function Steps({ steps }) {
  const getActiveSize = (progress) => {
    const sizeInPx = 64; // 64px
    return (progress * sizeInPx) / 100;
  };

  return (
    <div className="cc-steps">
      {steps.map((step, idx) => {
        return (
          <div key={`step-${idx}`} className="cc-step-wrapper">
            <div className="cc-step-content">
              <div className={`cc-step-circle progress-${step.progress}`}>
                {step.progress == 100 ? (
                  <TheIcon className="m-0 p-0" size={32} name={`${step?.overrideIcon?.length > 0 ? step.overrideIcon : 'check_white'}`} />
                ) : (
                  step.step
                )}
              </div>
              <div className="cc-step-text">{step.text}</div>
            </div>
            {idx < steps.length - 1 && (
              <div className="indicator-wrapper">
                <div className="indicator-bg vertical"></div>
                <div className="indicator-active vertical" style={{ height: getActiveSize(step.nextProgress || 0) }}></div>
                <div className="indicator-bg horizontal"></div>
                <div className="indicator-active horizontal" style={{ width: getActiveSize(step.nextProgress || 0) }}></div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

Steps.propTypes = {
  steps: PropTypes.array,
};

export default Steps;
