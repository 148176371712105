import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ImageFromFile = ({ file, className }) => {
  const [src, setSrc] = useState();
  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      function() {
        setSrc(reader.result);
      },
      false,
    );
    reader.readAsDataURL(file);
  }, [file]);
  return <img src={src} alt="image preview" className={className || {}} />;
};

ImageFromFile.propTypes = {
  file: PropTypes.any,
  className: PropTypes.string,
};

export default ImageFromFile;
