import axios from 'axios';
import { logoutRoute, newTokenInfo, setErrors } from 'src/actions/index';
import Bowser from 'bowser';
let store;
import { Buffer } from 'buffer';

export const injectStore = (_store) => {
  store = _store;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const objectsEqual = (o1, o2) =>
  typeof o1 === 'object' && Object.keys(o1).length > 0 ?
    Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p])) :
    o1 === o2;

const arraysEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    let returnValue = null;
    try {
      const refreshToken = store?.getState()?.auth?.refresh_token;
      if (error.response.status === 401 && store?.getState()?.auth?.token && refreshToken && !originalRequest._retry) {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const browserName = browser.getBrowserName() || '';
        const browserVersion = browser.getBrowserVersion() || '';
        const osName = browser.getOSName() || '';
        const osVersion = browser.getOSVersion() || '';
        const platformType = browser.getPlatformType() || '';

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/user/refresh-token`, {
          refresh_token: refreshToken,
          bs: Buffer.from(JSON.stringify({ browserName, browserVersion, osName, osVersion, platformType })).toString('base64'),
        });

        if (data && data.token) {
          store.dispatch(newTokenInfo(data));
          originalRequest._retry = true;
          axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
          returnValue = api(originalRequest);
        }
      }
    } catch (e) {
      if (e?.response?.status == 422) {
        // The refresh token has expired, was deleted (invalidated by the user or system)
        store.dispatch(logoutRoute());
      }
    }

    if (returnValue != null) {
      return returnValue;
    }

    if (error && error.response) {
      const r = error.response;
      if (r.data && r.data.errors && r.data.errors.length) {
        if (!arraysEqual(r.data.errors, store?.getState()?.errorsReducer)) {
          store.dispatch(setErrors(r.data.errors));
        }
      }
    }
    return Promise.reject(error);
  },
);

api.interceptors.request.use((config) => {
  try {
    const token = store?.getState()?.auth?.token;
    config.headers.authorization = `Bearer ${token}`;
  } catch {
    console.error('No token provided.');
  }
  return config;
});

export default api;
