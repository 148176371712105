import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CButton, CPopover } from '@coreui/react';
import YesNoModal from './YesNoModal';

import { useSelector } from 'react-redux';
import api from 'src/api';

import CIcon from '@coreui/icons-react';
import { cilInfo } from '@coreui/icons';

import { Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import { setErrors } from 'src/actions';
import { useDispatch } from 'react-redux';
import moment from 'moment';

function ActiveCPORow(props) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const role = useSelector((state) => state.auth.role);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Tr style={{ cursor: 'pointer', fontSize: '0.85em' }} key={props.ac}>
      <Th
        scope="row"
        onClick={(e) => {
          props.adjustCPOViewModal(props.cpo);
        }}
      >
        {props.cpo.id}
      </Th>
      <Td
        onClick={(e) => {
          props.adjustCPOViewModal(props.cpo);
        }}
      >
        {props.cpo.client_name}
      </Td>
      <Td
        onClick={(e) => {
          props.adjustCPOViewModal(props.cpo);
        }}
      >
        {props.cpo.order_status}
      </Td>

      <Td
        onClick={(e) => {
          props.adjustCPOViewModal(props.cpo);
        }}
      >
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: props.cpo.cpo_currency || 'USD',
        }).format(props.cpo.total)}
      </Td>
      <Td
        onClick={(e) => {
          props.adjustCPOViewModal(props.cpo);
        }}
      >
        {moment(props.cpo.date_created).format('YYYY-MM-DD')}
      </Td>
      <Td>
        {props.cpo.order_status === 'In Preparation' ? (
          ''
        ) : (
          <>
            {props.cpo.daysLeft == 1 ? (
              <>This order expires today.</>
            ) : props.cpo.daysLeft <= 0 ? (
              <>This order has expired. It will be automatically deleted by the system.</>
            ) : (
              <>{props.cpo.daysLeft} business days left. &nbsp;</>
            )}
            &nbsp;
            <CPopover
              title="What is this?"
              content={
                'To ensure that our inventory is available to all buyers, any shopping carts that are inactive (Not Posted) for 10 business days' +
                ' will be automatically deleted, and the items in those carts will be released back into our inventory.'
              }
            >
              <CButton size="sm" shape="rounded-pill">
                <CIcon icon={cilInfo} />
              </CButton>
            </CPopover>
          </>
        )}
      </Td>

      {(role === 'ADMIN' || role === 'STAFF') && (
        <Td>
          <CButton
            disabled={props.cpo.order_status !== 'In Preparation'}
            color="primary"
            size="sm"
            onClick={() => {
              setVisible(true);
            }}
          >
            Ship Order
          </CButton>
          <YesNoModal
            visible={visible}
            title="Ship Order"
            description="Do you really want to ship this order?"
            setVisible={setVisible}
            onNo={() => setVisible(false)}
            isLoading={isLoading}
            onYes={() => {
              setIsLoading(true);
              api
                .post(`/cpo/ship/${props.cpo.id}`)
                .then(() => {
                  setVisible(false);
                  props.onFinish();
                })
                .catch((err) => {
                  dispatch(setErrors([{ message: err }]));
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          />
        </Td>
      )}
    </Tr>
  );
}

ActiveCPORow.propTypes = {
  cpo: PropTypes.object.isRequired,
  adjustCPOViewModal: PropTypes.func.isRequired,
  ac: PropTypes.any.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default ActiveCPORow;
