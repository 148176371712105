import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CContainer,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CForm,
  CFormInput,
} from '@coreui/react';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';

import CIcon from '@coreui/icons-react';
import { cilFile } from '@coreui/icons';

import { useState, useEffect } from 'react';
import api from 'src/api';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { Helmet } from 'react-helmet';

const PhysicalCount = () => {
  const [productsList, setProductsList] = useState([]);
  const [visible, setVisible] = useState();
  const [show, setShow] = useState(false);
  const [allLots, setAllLots] = useState();
  const [submitting, setSubmitting] = useState(false);
  const formRef = useRef();

  const downloadForm = () => {
    const el = formRef.current;

    html2canvas(el).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new JsPDF('p', 'mm');
      let position = 0;

      doc.setFontSize(12);
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('count-form.pdf');
    });
  };

  const onSubmitCount = (e) => {
    e.preventDefault();
    setSubmitting(true);
    api
      .put('/situation/physical-count', {
        lots: allLots,
      })
      .then(() => {
        fetchInventory(true);
        setVisible(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  function fetchInventory(showTable) {
    api.get('/situation/physical-count').then(({ data }) => {
      setProductsList(data);

      let allLots = [];

      if (data && data.length) {
        data.forEach((l) => {
          const productLots = l.lots;
          allLots.push(...JSON.parse(JSON.stringify(productLots)));
        });

        allLots = allLots.map((l) => {
          delete l.available_liters;
          delete l.bottles_in_stock;
          delete l.expression;
          delete l.lot_number;
          delete l.old_available_liters;
          delete l.old_bottles_in_stock;
          delete l.ltr_count;
          return l;
        });

        setAllLots(allLots);
      }

      if (showTable) {
        setShow(true);
      }
    });
  }

  useEffect(() => {
    fetchInventory();
  }, []);

  function ProductsRows(props) {
    const prods = props.products;
    const isForm = props.isForm || false;
    const lots = props.lots;

    const list = [];

    function isPositiveInteger(n) {
      const floatN = parseFloat(n);
      return !isNaN(floatN) && isFinite(n) && floatN >= 0 && floatN % 1 === 0;
    }

    const bottleCountUpdated = (id, target) => {
      const v = target.value;

      if (isPositiveInteger(v)) {
        lots.map((l) => {
          if (l.lot_id === id) {
            l.bottles = target.value;
          }
          return l;
        });
        props.updateData(lots);
      } else if (v !== '') {
        target.value = '';
        alert('Please enter a valid number.');
      }
    };

    const granelCountUpdated = (id, target) => {
      const v = target.value;

      if (isPositiveInteger(v)) {
        lots.map((l) => {
          if (l.lot_id === id) {
            l.liters = target.value;
          }
          return l;
        });
        props.updateData(lots);
      } else if (v !== '') {
        target.value = '';
        alert('Please enter a valid number.');
      }
    };

    const noteUpdated = (id, target) => {
      lots.map((l) => {
        if (l.lot_id === id) {
          l.notes = target.value;
        }
        return l;
      });
      props.updateData(lots);
    };

    prods.forEach((product) =>
      product.lots.forEach((lot) => {
        const bottleDiff = lot.lc_bottles_count - lot.old_bottles_in_stock;
        const granelDiff = lot.lc_liters_count - lot.old_available_liters;

        if (isForm) {
          list.push(
            <Tr
              style={{ fontSize: '0.85em' }}
              key={
                `lot-` +
                `${lot.lot_number}-${lot.bottles_in_stock}-${lot.lot_id}`
              }
            >
              <Td scope="row">{product.expression}</Td>
              <Td>{lot.lot_number}</Td>
              <Td>
                <CFormInput
                  size="sm"
                  style={{ width: '90px' }}
                  onChange={(e) => {
                    bottleCountUpdated(lot.lot_id, e.target);
                  }}
                  type="number"
                  required
                  min="0"
                ></CFormInput>
              </Td>
              <Td>
                <CFormInput
                  size="sm"
                  style={{ width: '90px' }}
                  type="number"
                  onChange={(e) => {
                    granelCountUpdated(lot.lot_id, e.target);
                  }}
                  required
                  min="0"
                ></CFormInput>
              </Td>
              <Td>
                <CFormInput
                  size="sm"
                  type="text"
                  onChange={(e) => {
                    noteUpdated(lot.lot_id, e.target);
                  }}
                ></CFormInput>
              </Td>
            </Tr>,
          );
        } else {
          list.push(
            <Tr
              style={{ fontSize: '0.85em' }}
              key={`lot-r-${lot.lot_number}-${lot.lot_id}-${
                lot.available_liters
              }-${Math.random()}`}
            >
              <Td scope="row">{product.expression}</Td>
              <Td>{lot.lot_number}</Td>
              <Td>{lot.old_bottles_in_stock}</Td>
              <Td>{lot.lc_bottles_count}</Td>
              <Td color={bottleDiff >= 0 ? 'info' : 'danger'}>{bottleDiff}</Td>
              <Td>{lot.old_available_liters}</Td>
              <Td>{lot.lc_liters_count}</Td>
              <Td color={granelDiff >= 0 ? 'info' : 'danger'}>{granelDiff}</Td>
              <Td>{lot.notes}</Td>
            </Tr>,
          );
        }
      }),
    );

    return list;
  }

  return (
    <>
      <Helmet>
        <title>Physical Count</title>
      </Helmet>
      <CContainer>
        <CModal visible={visible} onDismiss={() => setVisible(false)} size="xl">
          <CModalHeader>
            <CCol sm={5}>
              <strong>Inventory Count</strong>
            </CCol>
          </CModalHeader>
          <CModalBody>
            <CForm onSubmit={onSubmitCount}>
              {submitting && <p>Loading...</p>}
              {!submitting && (
                <div className="table-container with-margin">
                  <Table ref={formRef}>
                    <Thead>
                      <Tr>
                        <Th scope="col">Product</Th>
                        <Th scope="col">Lot Number</Th>
                        <Th scope="col">Physical Bottle</Th>
                        <Th scope="col">A Granel Ltr</Th>
                        <Th scope="col">Note</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <ProductsRows
                        products={productsList}
                        lots={allLots}
                        isForm={true}
                        updateData={(data) => {
                          setAllLots(data);
                        }}
                      />
                    </Tbody>
                  </Table>
                </div>
              )}

              <CButton type="submit" color="primary" disabled={submitting}>
                Submit
              </CButton>

              <CButton
                disabled={submitting}
                type="button"
                color="dark"
                style={{ marginLeft: '10px' }}
                onClick={downloadForm}
              >
                PDF <CIcon icon={cilFile}></CIcon>
              </CButton>
              <CButton
                disabled={submitting}
                color="danger"
                onClick={() => {
                  setVisible(false);
                }}
                style={{ marginLeft: '10px', color: 'white' }}
              >
                Close
              </CButton>
            </CForm>
          </CModalBody>
        </CModal>
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>INVENTORY</strong> <small>Physical Count</small>
              </CCardHeader>
              <CCardBody>
                <CButton
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  Insert Physical Count
                </CButton>
                {show && (
                  <div className="table-container with-margin">
                    <Table>
                      <Thead>
                        <Tr>
                          <Th scope="col">Expression</Th>
                          <Th scope="col">Lot</Th>
                          <Th scope="col">Btl Inventory</Th>
                          <Th scope="col">Btl Count</Th>
                          <Th scope="col">Btl Variance</Th>
                          <Th scope="col">Ltr Inventory</Th>
                          <Th scope="col">Ltr Count</Th>
                          <Th scope="col">Ltr Variance</Th>
                          <Th scope="col">Notes</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <ProductsRows products={productsList} />
                      </Tbody>
                    </Table>
                  </div>
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

PhysicalCount.defaultProps = {
  products: [],
  isForm: false,
};

PhysicalCount.propTypes = {
  isForm: PropTypes.bool,
  products: PropTypes.any,
  p: PropTypes.number,
  compromised_bottles: PropTypes.number,
  bottles_in_production: PropTypes.number,
  expression: PropTypes.string,
  l: PropTypes.number,
  lot_number: PropTypes.string,
  bottles_in_stock: PropTypes.number,
  lots: PropTypes.any,
  allLots: PropTypes.array,
  updateData: PropTypes.func,
};

export default PhysicalCount;
