import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react';
import './costs.css';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import api from 'src/api';
import useMoneyCurrency from '../../hooks/useMoneyCurrency';
import { useDispatch } from 'react-redux';
import { setErrors } from '../../actions';
import LButton from '../../components/LButton/LButton';
import useDownload from '../../hooks/useDownload';
import { Helmet } from 'react-helmet';

import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loading from 'src/components/Loading';
import { toast } from 'react-toastify';

const CostsTable = ({ title, data, bottled, totalCost }) => {
  const [visible, setVisible] = useState(false);
  const money = useMoneyCurrency();
  const [item, setItem] = useState({});

  const showDetails = (item) => {
    setItem(item);
    setVisible(true);
  };

  return (
    <>
      <CRow>
        <CCol>
          <CModal
            visible={visible}
            backdrop={`static`}
            onClose={() => {
              setVisible(false);
            }}
          >
            <CModalHeader>
              Cost Details - {item.expression} - {item.lot_number}
            </CModalHeader>
            <CModalBody>
              <Table>
                {bottled && (
                  <Thead>
                    <Tr>
                      <Th>Item</Th>
                      <Th>Cost per unit</Th>
                      <Th>Total Units</Th>
                      <Th>Total Price</Th>
                    </Tr>
                  </Thead>
                )}
                <Tbody>
                  {item && typeof item.costs === 'object' ? (
                    <>
                      {item.costs.map((cost, idx) => {
                        return (
                          <Tr key={`cost-${idx}`}>
                            <Td>{cost.name}</Td>
                            {cost.unit !== undefined && (
                              <>
                                <Td>{money(cost.unit, 'USD', 3)}</Td>
                              </>
                            )}
                            {cost.total !== undefined && (
                              <>
                                <Td>{cost.total}</Td>
                              </>
                            )}
                            <Td>{money(cost.value, 'USD')}</Td>
                          </Tr>
                        );
                      })}
                      {bottled && item?.cost_of_goods && (
                        <Tr>
                          <Td>
                            <b className={`text-right`}>Total</b>
                          </Td>
                          <Td colSpan={2}>{money(item.totalCostsPerUnit, 'USD', 4)}</Td>
                          <Td>
                            <b>{money(item.cost_of_goods, 'USD', 2)}</b>
                          </Td>
                        </Tr>
                      )}
                    </>
                  ) : (
                    <>
                      <Tr>
                        <Td>No costs available.</Td>
                      </Tr>
                    </>
                  )}
                </Tbody>
              </Table>
            </CModalBody>
            <CModalFooter>
              <CButton
                onClick={() => {
                  setVisible(false);
                }}
              >
                Close
              </CButton>
            </CModalFooter>
          </CModal>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <div className="mb-3">
            <h2>{title}</h2>
          </div>
        </CCol>
      </CRow>
      <Table>
        <Thead>
          <Tr>
            <Td>Lot</Td>
            <Td>Expression</Td>
            <Td>SKU</Td>
            <Td>Bottle Size</Td>
            <Td>$/Ltr</Td>
            <Td>{bottled ? 'Bottles' : 'Ltr'}</Td>
            <Td>Cost of Goods</Td>
            <Td>Details</Td>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, idx) => {
            return (
              <Tr key={`tr-${idx}`}>
                <Td>{item.lot_number}</Td>
                <Td>{item.expression}</Td>
                <Td>{item.sku}</Td>
                <Td>{item.ml_btl === 1000 ? '1L' : `${item.ml_btl}ml`}</Td>
                <Td>{money(item.price_per_liter, 'USD')}</Td>
                <Td>{bottled ? item.bottles : item.liters}</Td>
                <Td>{money(item.cost_of_goods, 'USD', 2)}</Td>
                <Td>
                  <CButton
                    size="sm"
                    onClick={() => {
                      showDetails(item);
                    }}
                  >
                    Details
                  </CButton>
                </Td>
              </Tr>
            );
          })}
          <Tr>
            <Td colSpan={6} style={{ textAlign: 'right' }}>
              <b>Total</b>
            </Td>
            <Td>
              <span>{money(totalCost, 'USD')}</span>
            </Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

CostsTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  bottled: PropTypes.bool,
  totalCost: PropTypes.number,
};

const Costs = () => {
  const [granel, setGranel] = useState([]);
  const [bottled, setBottled] = useState([]);
  const [granelTotalCost, setGranelTotalCost] = useState(99999999);
  const [bottledTotalCost, setBottledTotalCost] = useState(99999999);
  const money = useMoneyCurrency();
  const reportRef = useRef(null);
  const dispatch = useDispatch();
  const downloader = useDownload();
  const [isDownloading, setIsDownloading] = useState(false);

  const [loading, setLoading] = useState(false);
  const [validDate, setValidDate] = useState();
  const [notFound, setNotFound] = useState(false);

  const onDateChange = (date) => {
    setValidDate(date);
  };

  useEffect(() => {
    setLoading(true);
    api
      .get('/product/cost-reports-data', {
        params: {
          date: moment(validDate).format('YYYY-MM-DD'),
          today: moment(new Date()).format('YYYY-MM-DD'),
        },
      })
      .then(({ data }) => {
        setNotFound(false);
        if (data?.granel?.length > 0) {
          setGranel(data.granel);
        }
        if (data?.bottled?.length > 0) {
          setBottled(data.bottled);
        }
        if (data?.granelTotalCost) {
          setGranelTotalCost(data.granelTotalCost);
        }
        if (data?.bottledTotalCost) {
          setBottledTotalCost(data.bottledTotalCost);
        }
      })
      .catch((e) => {
        if (e?.response?.status == 404) {
          toast.error(`We could not find any data from this date.`);
          setNotFound(true);
        } else {
          dispatch(setErrors([{ message: e }]));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [validDate]);

  const downloadReport = async () => {
    setIsDownloading(true);
    api
      .get('/reports/costs', {
        params: {
          date: moment(validDate).format('YYYY-MM-DD'),
          today: moment(new Date()).format('YYYY-MM-DD'),
        },
      })
      .then(({ data }) => {
        setNotFound(false);
        if (data.pdf) {
          downloader.download(`${process.env.REACT_APP_API_URL}/${data.pdf}`, `costs-report-${moment().format('YYYY-MM-DD')}.pdf`);
        } else {
          dispatch(
            setErrors([
              {
                message: 'Could not get the pdf path.',
              },
            ]),
          );
        }
      })
      .catch((e) => {
        if (e?.response?.status == 404) {
          toast.error(`We could not find any data from this date.`);
          setNotFound(true);
        } else {
          dispatch(setErrors([{ message: e }]));
        }
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Costs</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CCardTitle>Costs Reports</CCardTitle>
              </CCardHeader>
              {loading ? (
                <CCardBody>
                  <Loading />
                </CCardBody>
              ) : (
                <>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CFormLabel>Pick data from this date:</CFormLabel>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs={12}>
                        <DatePicker placeholderText="Pick a date (Empty for today)" selected={validDate} onChange={onDateChange} />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <div className="my-3">
                          <LButton isLoading={isDownloading} onClick={downloadReport}>
                            Download Report
                          </LButton>
                        </div>
                      </CCol>
                    </CRow>
                    {notFound ? (
                      <>
                        <CRow>
                          <CCol>
                            <h1>No records found</h1>
                            <CAlert>We could not find data for this date: ({moment(validDate).format('YYYY-MM-DD')})</CAlert>
                          </CCol>
                        </CRow>
                      </>
                    ) : (
                      <>
                        <div ref={reportRef}>
                          <CRow>
                            <CCol>
                              <h1>Cost of Goods</h1>
                            </CCol>
                          </CRow>

                          <CRow>
                            <CCol>
                              <b>Date:</b>
                              <br />
                              {moment(validDate).format('YYYY-MM-DD')}
                            </CCol>
                            <CCol>
                              <b>Rate:</b>
                              <br />
                              Mex$20.00
                            </CCol>
                          </CRow>
                          <hr />
                          <CostsTable title={`Bottled`} data={bottled} bottled={true} totalCost={bottledTotalCost} />
                          <hr />
                          <CostsTable title={`A Granel`} data={granel} totalCost={granelTotalCost} />
                          <hr />
                          <h1>Grand Total</h1>
                          <Table>
                            <Thead>
                              <Tr>
                                <Th>Item</Th>
                                <Th style={{ textAlign: 'right' }}>Subtotal</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td>Bottled</Td>
                                <Td style={{ textAlign: 'right' }}>{money(bottledTotalCost, 'USD', 3)}</Td>
                              </Tr>
                              <Tr>
                                <Td>A Granel</Td>
                                <Td style={{ textAlign: 'right' }}>{money(granelTotalCost, 'USD', 3)}</Td>
                              </Tr>
                              <Tr>
                                <Td>
                                  <h4>Total</h4>
                                </Td>
                                <Td style={{ textAlign: 'right' }}>
                                  <h4>{money(granelTotalCost + bottledTotalCost, 'USD', 3)}</h4>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      </>
                    )}
                  </CCardBody>
                </>
              )}
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default Costs;
