import React from 'react';
import PropTypes from 'prop-types';
import { CCol, CRow, CForm, CFormLabel, CFormInput, CFormSelect, CButton, CFormTextarea } from '@coreui/react';
import api from 'src/api';
import LButton from 'src/components/LButton/LButton';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setErrors } from '../../actions';

const NewExpressionFormDiv = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [expressionName, setExpressionName] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();

    api
      .post(
        '/product/compute-expression-name',
        {
          maguey_id: props.chosenMaguey,
          maker_id: props.chosenMaker,
        },
        {
          signal: controller.signal,
        },
      )
      .then(({ data }) => {
        if (data?.expressionName?.length > 0) {
          setExpressionName(data.expressionName);
        } else {
          dispatch(
            setErrors([
              { message: 'Unable to get the computed expression name. You can continue and check the name of the expression after it has been created.' },
            ]),
          );
        }
      })
      .catch((e) => {
        dispatch(setErrors([{ message: e }]));
      });

    return () => {
      controller.abort();
    };
  }, []);

  function handleNewSubmit(e) {
    e.preventDefault();

    const elms = e.target.elements;

    if (!elms.mlBtlInput.value) {
      alert('ml/btl must be inserted');
      return;
    }

    if (!elms.caseInput.value) {
      alert('Case Pack must be inserted');
      return;
    }
    setIsLoading(true);
    const extraFields = {
      GRADOS: elms.abvInput.value,
      FINCA: elms.fincaInput.value,
      ELEVACIÓN: elms.eleInput.value,
      COSECHA: elms.cosInput.value,
      TIERRA: elms.tierraInput.value,
      PUEBLO: elms.pueInput.value,
      HORNO: elms.hornoInput.value,
      LEÑA: elms.lenaInput.value,
      DURACIÓN: elms.durInput.value,
      MOLINO: elms.molInput.value,
      FERMENTACIÓN: elms.ferInput.value,
      TIPO: elms.tinaInput.value,
      FUENTE: elms.aguaInput.value,
      DESTILADOR: elms.desInput.value,
      NUMERO: elms.numInput.value,
      CLASSE: elms.classeInput.value,
      AJUSTES: elms.ajuInput.value,
      NOTAS: elms.notasInput.value,
    };

    api
      .post('/product', {
        maker_id: props.chosenMaker,
        maguey_id: props.chosenMaguey,
        product_type: elms.typeSelect.value,
        ml_btl: elms.mlBtlInput.value,
        case_pack: elms.caseInput.value,
        billing_center_name: 'Colegas',
        standard_extra_fields: JSON.stringify(extraFields),
        expression_name: expressionName,
      })
      .then(() => {
        props.fetchExpressions();
      })
      .finally(() => {
        props.setNewVisible(false);
        setIsLoading(false);
      });
  }

  return (
    <CForm style={{ margin: '10px' }} onSubmit={handleNewSubmit}>
      <CRow className="mb-2">
        <CCol xs={12}>
          <CFormLabel className="mb-1" htmlFor="expressionInput">
            Expression Name
          </CFormLabel>
          <CFormInput
            type="text"
            id="expressionInput"
            value={expressionName}
            size="sm"
            onChange={(e) => {
              setExpressionName(e.target.value);
            }}
          />
        </CCol>
      </CRow>
      <CRow className="mb-2">
        <CCol>
          <CFormLabel className="mb-1" htmlFor="abvInput">
            Type
          </CFormLabel>
          <CFormSelect id="typeSelect" size="sm">
            <option value="mezcal">mezcal</option>
            <option value="rum">rum</option>
          </CFormSelect>
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="abvInput">
            ABV
          </CFormLabel>
          <CFormInput type="text" id="abvInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="mlBtlInput">
            ml/btl
          </CFormLabel>
          <CFormSelect id="mlBtlInput" size="sm">
            <option value="750">750</option>
            <option value="1000">1000</option>
            <option value="500">500</option>
          </CFormSelect>
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="abvInput">
            Case Pack
          </CFormLabel>
          <CFormSelect id="caseInput" size="sm">
            <option value="6">6</option>
          </CFormSelect>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="fincaInput">
            Finca
          </CFormLabel>
          <CFormInput type="text" id="fincaInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-2" htmlFor="eleInput">
            Elevación
          </CFormLabel>
          <CFormInput type="text" id="eleInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="cosInput">
            Cosecha
          </CFormLabel>
          <CFormInput type="text" id="cosInput" size="sm" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="tierraInput">
            Tierra
          </CFormLabel>
          <CFormInput type="text" id="tierraInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-2" htmlFor="pueInput">
            Pueblo
          </CFormLabel>
          <CFormInput type="text" id="pueInput" size="sm" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="hornoInput">
            Horno
          </CFormLabel>
          <CFormInput type="text" id="hornoInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-2" htmlFor="lenaInput">
            Leña
          </CFormLabel>
          <CFormInput type="text" id="lenaInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="durInput">
            Duración de Horneado
          </CFormLabel>
          <CFormInput type="text" id="durInput" size="sm" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="molInput">
            Molino
          </CFormLabel>
          <CFormInput type="text" id="molInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="ferInput">
            Fermentación
          </CFormLabel>
          <CFormInput type="text" id="ferInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-2" htmlFor="tinaInput">
            Tipo de Tina
          </CFormLabel>
          <CFormInput type="text" id="tinaInput" size="sm" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="aguaInput">
            Fuente de Agua
          </CFormLabel>
          <CFormInput type="text" id="aguaInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="desInput">
            Tipo de Destilador
          </CFormLabel>
          <CFormInput type="text" id="desInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-2" htmlFor="numInput">
            Numero de Destilaciones
          </CFormLabel>
          <CFormInput type="text" id="numInput" size="sm" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="classeInput">
            Classe
          </CFormLabel>
          <CFormInput type="text" id="classeInput" size="sm" />
        </CCol>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="ajuInput">
            Ajustes
          </CFormLabel>
          <CFormInput type="text" id="ajuInput" size="sm" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormLabel className="mb-1" htmlFor="notasInput">
            Notas
          </CFormLabel>
          <CFormTextarea id="notasInput" rows="2" />
        </CCol>
      </CRow>
      <div style={{ marginTop: '5px' }}>
        <LButton isLoading={isLoading} type="submit" color="primary" disabled={isLoading}>
          Add New
        </LButton>
        <CButton
          color="danger"
          onClick={() => {
            props.setNewVisible(false);
          }}
          style={{ marginLeft: '10px', color: 'white' }}
          disabled={isLoading}
        >
          Close
        </CButton>
      </div>
    </CForm>
  );
};

NewExpressionFormDiv.propTypes = {
  chosenMaker: PropTypes.any,
  chosenMaguey: PropTypes.any,
  fetchExpressions: PropTypes.any,
  setNewVisible: PropTypes.any,
  setNewExpressionFormVisible: PropTypes.any,
};

export default NewExpressionFormDiv;
