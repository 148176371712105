import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CContainer,
  CModal,
  CModalBody,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { useSelector } from 'react-redux';
import api from '../../../api';

import LotDetailModal from 'src/views/lotes/lot-details/lotDetails-edit-modal';
import LButton from 'src/components/LButton/LButton';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';
import { Helmet } from 'react-helmet';

const InventoryByExpression = () => {
  const [productsList, setProductsList] = useState([]);
  const role = useSelector((state) => state.auth.role);

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [lotDetails, setLotDetails] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useDispatch();

  const notClient = role !== 'CLIENT';

  useEffect(() => {
    const controller = new AbortController();
    api('/situation/inventory-by-products', {signal: controller.abort()}).then(({ data }) => {
      setProductsList(data);
    });
    return () => {
      controller.abort();
    };
  }, []);

  function LotRow(props) {
    return (
      /* SubRows*/
      <React.Fragment key={'ibelr' + props.l}>
        <Helmet>
          <title>Inventory By Expression</title>
        </Helmet>
        <CTableRow
          style={{ fontSize: '0.85em' }}
          onClick={() => {
            api.get(`/lotes/lot-details/${props.lot_number}`).then(({ data }) => {
              setLotDetails(data);
              setIsDetailsVisible(true);
            });
          }}
        >
          {role === 'CLIENT' ? (
            <>
              <CTableDataCell style={{ color: 'gray' }} width="30%">
                {props.lot_number}
              </CTableDataCell>
              <CTableDataCell style={{ color: 'gray' }} width="20%">
                {
                  props.bottles_in_stock - props.inSomeonesCart
                  /** In Someone's cart */
                }
              </CTableDataCell>
              <CTableDataCell style={{ color: 'gray' }} width="20%">
                {props.bottles_in_production}
              </CTableDataCell>
              <CTableDataCell style={{ color: 'gray' }} width="20%">
                {props.bottles_in_stock}
              </CTableDataCell>
            </>
          ) : (
            <>
              <CTableDataCell style={{ color: 'gray' }} width="30%">
                {props.lot_number}
              </CTableDataCell>
              <CTableDataCell width="10%">{props.availableLiters /** A granel */}</CTableDataCell>
              <CTableDataCell style={{ color: 'gray' }} width="10%">
                {props.bottles_in_production}
              </CTableDataCell>
              <CTableDataCell style={{ color: 'gray' }} width="10%">
                {props.bottles_in_stock - props.inSomeonesCart /** In Someone's cart */}
              </CTableDataCell>

              <CTableDataCell style={{ color: 'gray' }} width="20%">
                {props.bottles_in_stock}
              </CTableDataCell>
              <CTableDataCell style={{ color: 'gray' }} width="20%">
                {parseInt(props.inSomeonesCart != null ? props.inSomeonesCart : 0)}
              </CTableDataCell>
            </>
          )}
        </CTableRow>
      </React.Fragment>
    );
  }

  LotRow.propTypes = {
    inSomeonesCart: PropTypes.number,
    availableLiters: PropTypes.number,
  };

  function LotsList(props) {
    const lots = props.lots;
    let l = -1;
    return lots.map((lot) => {
      l++;
      return <LotRow l={l} {...lot} key={`lot-r-${l}`} />;
    });
  }

  function ProductRow(props) {
    const [collapseVisible, setCollapseVisible] = useState(false);

    const getTotalAvailableLiters = (lots) => {
      let total = 0;
      if (lots) {
        lots.forEach((lot) => {
          total += parseInt(lot.available_liters);
        });
      }
      return total;
    };

    const getAvailableNow = (lots) => {
      let totalProd = 0;
      let totalInCart = 0;

      if (lots) {
        lots.forEach((lot) => {
          totalProd += parseInt(lot.bottles_in_stock);
          totalInCart += parseInt(lot.in_someones_cart);
        });
      }
      return totalProd - totalInCart;
    };

    const getInSomeonesCart = (lots) => {
      let total = 0;
      if (lots) {
        lots.forEach((lot) => {
          total += parseInt(lot.in_someones_cart);
        });
      }
      return total;
    };

    const getBottlesInStock = (lots) => {
      let total = 0;
      if (lots) {
        lots.forEach((lot) => {
          total += parseInt(lot.bottles_in_stock);
        });
      }
      return total;
    };

    return (
      <React.Fragment key={'iber' + props.p}>
        <CTableRow
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setCollapseVisible(!collapseVisible);
          }}
        >
          {/* Main Rows */}

          {role === 'CLIENT' ? (
            <>
              <CTableDataCell width="30%">{props.expression}</CTableDataCell>
              <CTableDataCell width="20%">
                {/* Label: Available Now*/}
                {getAvailableNow(props.lots)}
              </CTableDataCell>

              <CTableDataCell width="20%">
                {/* Bottles in production */}
                {parseInt(props.bottles_in_production || '0')}
              </CTableDataCell>
              <CTableDataCell width="20%">{getBottlesInStock(props.lots)}</CTableDataCell>
            </>
          ) : (
            <>
              <CTableDataCell width="30%">{props.expression}</CTableDataCell>
              <CTableDataCell style={{ fontWeight: 'bold' }} width="10%">
                {/* Label: A granel */}
                {getTotalAvailableLiters(props.lots)}
              </CTableDataCell>
              <CTableDataCell width="10%">
                {/* Bottles in production */}
                {parseInt(props.bottles_in_production || '0')}
              </CTableDataCell>
              <CTableDataCell width="10%">
                {/* Label: Available Now*/}
                {getAvailableNow(props.lots)}
              </CTableDataCell>

              <CTableDataCell width="20%">{getBottlesInStock(props.lots) /** Physical **/}</CTableDataCell>
              <CTableDataCell width="20%">{getInSomeonesCart(props.lots)}</CTableDataCell>
            </>
          )}
        </CTableRow>
        <CTableRow>
          <CTableDataCell colSpan="6" style={{ padding: 0, margin: 0, border: 0 }}>
            <CCollapse visible={collapseVisible}>
              <div className="table-container">
                <CTable hover small>
                  <CTableBody>
                    <LotsList lots={props.lots} />
                  </CTableBody>
                </CTable>
              </div>
            </CCollapse>
          </CTableDataCell>
        </CTableRow>
      </React.Fragment>
    );
  }

  function ProductsList(props) {
    const prods = props.products;
    let p = -1;
    return prods.map((product) => {
      p++;
      return (
        <ProductRow
          p={p}
          bottles_in_production={product.bottles_in_production}
          bottles_in_stock={product.bottles_in_stock}
          expression={product.expression}
          lots={product.lots}
          inSomeonesCart={product.in_someones_cart}
          key={`prod-row-${p}`}
        />
      );
    });
  }

  function downloadPdf() {
    setIsDownloading(true);
    api
      .get(
        '/product/pdf/inventory-simple',
        {
          responseType: 'blob',
        },
        {
          encoding: null,
        },
      )
      .then(({ data }) => {
        const pdfBlob = new Blob([data], { type: 'application/pdf' });
        const blobUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `inventory-cpo-` + `${new Date().getDay()}` + `-${new Date().getMonth()}-${new Date().getFullYear()}.pdf`);
        link.click();
        link.remove();
        URL.revokeObjectURL(blobUrl);
      })
      .catch((e) => {
        dispatch(
          setErrors([
            {
              message: e,
            },
          ]),
        );
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }

  return (
    <CContainer>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <CCol sm={5}>
                <strong>INVENTORY</strong> <small>By Expression</small>
              </CCol>
            </CCardHeader>
            <CCardBody>
              <div className="table-container">
                {(role === 'ADMIN' || role === 'STAFF') && (
                  <CRow>
                    <CCol>
                      <LButton isLoading={isDownloading} onClick={downloadPdf} className="mb-3">
                        Download PDF
                      </LButton>
                    </CCol>
                  </CRow>
                )}
                <CTable hover small>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Expression</CTableHeaderCell>

                      {role === 'CLIENT' ? (
                        <>
                          <CTableHeaderCell scope="col">Total Available</CTableHeaderCell>
                          <CTableHeaderCell scope="col">In Production</CTableHeaderCell>
                          <CTableHeaderCell scope="col">In Stock</CTableHeaderCell>
                        </>
                      ) : (
                        <>
                          {notClient && (
                            <CTableHeaderCell color="info" scope="col" style={{ fontWeight: 'bold' }}>
                              A Granel
                            </CTableHeaderCell>
                          )}
                          <CTableHeaderCell scope="col">In Production</CTableHeaderCell>
                          <CTableHeaderCell scope="col">Available Now</CTableHeaderCell>

                          <CTableHeaderCell scope="col">Physical</CTableHeaderCell>
                          <CTableHeaderCell scope="col">In someone&apos;s cart</CTableHeaderCell>
                        </>
                      )}
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <ProductsList products={productsList} />
                  </CTableBody>
                </CTable>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CModal visible={isDetailsVisible} size="lg">
          <CModalBody>
            <LotDetailModal isEditable={false} lotDetail={lotDetails} setVisible={setIsDetailsVisible}></LotDetailModal>
          </CModalBody>
        </CModal>
      </CRow>
    </CContainer>
  );
};

InventoryByExpression.defaultProps = {
  products: [],
};

InventoryByExpression.propTypes = {
  products: PropTypes.any,
  p: PropTypes.number,
  bottles_in_production: PropTypes.number,
  expression: PropTypes.string,
  l: PropTypes.number,
  lot_number: PropTypes.string,
  bottles_in_stock: PropTypes.number,
  lots: PropTypes.any,
};

export default InventoryByExpression;
