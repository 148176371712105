import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import CIcon from '@coreui/icons-react';
import {cilWarning, cilCheckCircle} from '@coreui/icons';
import {CToast, CToastHeader, CToastBody, CToaster} from '@coreui/react';

import {useSelector, useDispatch} from 'react-redux';

import {setErrors, setSuccessMessages} from 'src/actions/index';

const RequestError = () => {
  const errors = useSelector((state) => state.errorsReducer);
  const successMessages = useSelector((state) => state.successMessagesReducer);
  const dispatch = useDispatch();

  const [toast, addToast] = useState(0);
  const toaster = useRef();

  const generateToast = (item, isError) => {
    return (
      <CToast title="Message" delay={5000} className="align-items-center"
        style={{background: '#FFFFFF', opacity: 1}}>
        <CToastHeader className={isError ? 'bg-danger' : 'bg-success'} closeButton style={{color: 'white'}}>
          <CIcon icon={isError ? cilWarning : cilCheckCircle} style={{marginRight: '3px'}}></CIcon>
          <b className="me-auto" style={{color: 'white'}}>
            {isError ? 'Error' : 'Success!'}
          </b>
        </CToastHeader>
        <CToastBody>{item.message}</CToastBody>
      </CToast>
    );
  };

  useEffect(() => {
    if (errors?.length > 0) {
      console.log(errors);
    }
    const genericMessage = 'Unable to retrieve the error message';
    if (errors && errors.length > 0 && typeof errors.forEach == 'function') {
      errors.forEach((error, i) => {
        switch (typeof error) {
          case 'string':
            error = {message: error};
            break;
          case 'object':
            let finalMessage = genericMessage;
            if (error?.message?.response?.data) {
              // Request Error.

              const errors = error.message.response.data?.errors;
              if (errors?.length > 0) {
                finalMessage = errors[0]?.message;
              } else if (errors?.response?.data?.message) {
                finalMessage = errors.response.data.message;
              } else if (typeof error?.message?.response === 'object') {
                finalMessage = `The request failed: ${error.message.response.status} -  ${error.message.response.statusText}`;
              }
            } else if (typeof error.message == 'string') {
              finalMessage = error.message;
            }

            if (typeof finalMessage == 'string') {
              error = {message: finalMessage};
            } else {
              error = {message: genericMessage};
            }
            break;
          default:
            error = {message: genericMessage};
        }

        setTimeout(() => {
          addToast(generateToast(error, true));
        }, 5);
      });
      dispatch(setErrors([]));
    }
    return () => {
      if (errors.length > 0) {
        dispatch(setErrors([]));
      }
    };
  }, [errors]);

  useEffect(() => {
    successMessages.forEach((item, i) => {
      setTimeout(() => {
        addToast(generateToast(item, false));
      }, 10);
    });

    return () => {
      if (successMessages?.length > 0) {
        setSuccessMessages([]);
      }
    };
  }, [successMessages]);

  return (
    <>
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </>
  );
};

RequestError.propTypes = {
  errors: PropTypes.array,
  success: PropTypes.array,
};

export default RequestError;
