import React from 'react';

/* Start of: Financial Items */
import Rates from './views/financial/Rates';
import VariableCosts from './views/financial/VariableCosts';
import PricingSchemes from './views/financial/PricingSchemes';
import InventoryByExpression from './views/inventory/by-expression/InventoryByExpression';
import InventoryByLot from './views/inventory/by-lot/InventoryByLot';
import InventoryAGranel from './views/inventory/a-granel/InventoryAGranel';
import PhysicalCount from './views/inventory/physical-count/PhysicalCount';
import ByDate from './views/inventory/physical-count/ByDate';
import Purchases from './views/lotes/purchases/purchases';
import Production from './views/lotes/production/production';
import LotDetails from './views/lotes/lot-details/lotDetails';
import Prices from './views/prices/prices';
import PriceCalculator from './views/prices/PriceCalculator';
import CPOs from './views/cpos/cpos';
import Discounts from './views/discounts/Discounts';
import Coupons from './views/coupons/Coupons';
import ExternalSales from './views/sales/ExternalSales';
import Expressions from './views/expressions/expressions';
import Maguey from './views/maguey/maguey';
import Barcodes from './views/barcodes/barcodes';
import Mezcaleros from './views/mezcaleros/mezcaleros';
import Dashboard from './views/dashboard/Dashboard';
import HomeClients from './views/clients/index';
import Users from './views/users/index';
import Settings from './views/users/Settings';
import SchemaInfo from './views/pdf/SchemaInfo';
import ArtSubmissions from './views/art/ArtSubmissions';
import ArtReview from './views/art/ArtReview';
import Importers from './views/importers/Importers';
import Costs from './views/reports/Costs';
import ExWorksBuyer from './views/reports/ExWorksBuyer';
import QuickbooksHome from './views/quickbooks/QuickbooksHome';
import LotsProcess from './views/processes/LotsProcess';
import ProcessByLot from './views/processes/ProcessByLot';

import DefaultLayout from './layout/DefaultLayout';
import DefaultPage from './views/pages/default/DefaultPage';
import Login from './views/pages/login/Login';
import Page404 from './views/pages/page404/Page404';
import Page500 from './views/pages/page500/Page500';

import ShelfTalkerPage from './views/tools/ShelfTalkerPage';
import TastingMenuPage from './views/tools/TastingMenuPage';
import MyShelfTalkers from './views/tools/MyShelfTalkers';
import MyTastingMenus from './views/tools/MyTastingMenus';
import Logout from './views/logout/logout';
import NotAllowed from './views/pages/not-allowed/NotAllowed';
import SignUp from './views/sign-up/SignUp';
import CpoDetail from './views/cpos/CpoDetail';

import { OrderProvider } from './context/OrderContext';
import SessionsReview from './views/users/SessionsReview';
import LoginHistory from './views/users/LoginHistory';
import Miscellaneous from './views/miscellaneous/Miscellaneous';

const routes = [
  {
    path: '/dashboard',
    element: <DefaultLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'quickbooks/home',
        name: 'Quickbooks',
        element: <QuickbooksHome />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'inventory/by-expression',
        name: 'Inventory by expression',
        element: <InventoryByExpression />,
        allowed: ['ADMIN', 'STAFF', 'CLIENT'],
      },
      {
        path: 'inventory/by-lot',
        name: 'Inventory By Lot',
        element: <InventoryByLot />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'importers',
        name: 'Importers',
        element: <Importers />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'inventory/a-granel',
        name: 'Inventory a Granel',
        element: <InventoryAGranel />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'reports/costs',
        name: 'Costs of Goods',
        element: <Costs />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'reports/ex-works',
        name: 'Ex Works',
        element: <ExWorksBuyer />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'financial/rates',
        name: 'Rates',
        element: <Rates />,
        allowed: ['ADMIN'],
      },
      {
        path: 'financial/variable-costs',
        name: 'Variable Costs',
        element: <VariableCosts />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'financial/pricing-schemes',
        name: 'Pricing Schemes',
        element: <PricingSchemes />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'sales/external-sales',
        name: 'External Sales',
        element: <ExternalSales />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'inventory/physical-count',
        name: 'Physical Count',
        element: <PhysicalCount />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'inventory/by-date',
        name: 'By Date',
        element: <ByDate />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'lotes/purchases',
        name: 'Purchases',
        element: <Purchases />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'lotes/production',
        name: 'Production',
        element: <Production />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'lotes/lot-details',
        name: 'LotDetails',
        element: <LotDetails />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'prices/price-calculator',
        name: 'Price Calculator',
        exact: true,
        element: <PriceCalculator />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'prices/prices',
        name: 'Prices',
        element: <Prices />,
        exact: true,
        allowed: ['ADMIN', 'STAFF', 'CLIENT'],
      },
      {
        path: 'discounts',
        name: 'Discounts',
        element: <Discounts />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'coupons',
        name: 'Coupons',
        element: <Coupons />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'cpos/cpo/:id',
        name: 'CPO',
        element: (
          <OrderProvider>
            <CpoDetail />
          </OrderProvider>
        ),
        allowed: ['ADMIN', 'STAFF', 'CLIENT'],
      },
      {
        path: 'cpos/cpos',
        name: 'CPOs',
        element: <CPOs />,
        allowed: ['ADMIN', 'STAFF', 'CLIENT'],
      },
      {
        path: 'expressions',
        name: 'Expressions',
        element: <Expressions />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'miscellaneous',
        name: 'Miscellaneous',
        element: <Miscellaneous />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'maguey',
        name: 'Maguey',
        element: <Maguey />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'barcodes',
        name: 'Barcodes',
        element: <Barcodes />,
        allowed: ['ADMIN', 'STAFF', 'ARTIST'],
      },
      {
        path: 'mezcaleros',
        name: 'Mezcaleros',
        element: <Mezcaleros />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'clients',
        name: 'Clients Home',
        element: <HomeClients />,
        allowed: ['ADMIN', 'STAFF'],
      },
      { path: '', exact: true, name: 'Home', allowed: ['ADMIN', 'STAFF'] },
      {
        path: 'dashboard',
        name: 'Dashboard',
        element: <Dashboard />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'tools/my-tasting-menus',
        name: 'Tasting Menus',
        element: <MyTastingMenus />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'users',
        name: 'Users',
        element: <Users />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'art-submissions',
        name: 'Art Submissions',
        element: <ArtSubmissions />,
        allowed: ['ARTIST'],
      },
      {
        path: 'schema-info',
        name: 'Schema Info (PDF)',
        element: <SchemaInfo />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'art-review',
        name: 'Art Review',
        element: <ArtReview />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'process/lots',
        element: <LotsProcess />,
        allowed: ['ADMIN', 'STAFF'],
      },
      {
        path: 'process/lot/:id',
        element: <ProcessByLot />,
        allowed: ['ADMIN', 'STAFF'],
      },
    ],
  },
  {
    path: '/tools',
    element: <DefaultLayout />,
    children: [
      {
        path: 'shelf-talker',
        name: 'Shelf Talker',
        element: <ShelfTalkerPage />,
      },
      {
        path: 'tasting-menu',
        name: 'Tasting Menu',
        element: <TastingMenuPage />,
      },
      {
        path: 'my-shelf-talkers',
        name: 'My Shelf Talkers',
        element: <MyShelfTalkers />,
      },
      {
        path: 'my-tasting-menus',
        name: 'My Tasting Menus',
        element: <MyTastingMenus />,
      },
    ],
  },
  {
    path: '/login/:session_id',
    element: <Login />,
  },
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      {
        path: 'sign-up',
        name: 'Sign Up',
        element: <SignUp />,
      },
      {
        path: 'logout',
        name: 'Log out',
        element: <Logout />,
      },
      {
        path: '',
        element: <DefaultPage />,
      },
      {
        path: 'not-allowed',
        element: <NotAllowed />,
      },

      {
        path: '500',
        element: <Page500 />,
      },
      {
        path: 'users',
        children: [
          {
            path: 'settings',
            element: <Settings />,
          },
          {
            path: 'sessions-review',
            element: <SessionsReview />,
          },
          {
            path: 'login-history',
            element: <LoginHistory />,
          },
        ],
      },
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
