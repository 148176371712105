import React from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CModalHeader,
  CNav,
  CNavItem,
  CNavLink,
  CButton,
  CContainer,
  CModalBody,
  CModalFooter,
  CForm,
  CFormSelect,
  CAlert,
  CTableRow,
  CTableDataCell,
  CSpinner,
} from '@coreui/react';
import { useState, useEffect, useCallback } from 'react';
// import CPOViewModal from './cpo-view-modal';
import FinishedCPORow from 'src/components/FinishedCPORow';
import ActiveCPORow from 'src/components/ActiveCPORow';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';

import { useSelector } from 'react-redux';

import api from '../../api';

import CIcon from '@coreui/icons-react';
import { cilActionUndo } from '@coreui/icons';

import LButton from 'src/components/LButton/LButton';
import YesNoModal from 'src/components/YesNoModal';
import { useDispatch } from 'react-redux';
import { setErrors, setSuccessMessages } from 'src/actions';
import { Helmet } from 'react-helmet';
import BreadCrumbs from 'src/components/BreadCrumbs';
import { useNavigate } from 'react-router';

const DeletedCpoRow = ({ cpo, fetchCPOs, setDeletedCPos, setActiveKey }) => {
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleting, setShowDeleting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoadingRestore, setIsLoadingRestore] = useState(false);
  const [restoreId, setRestoreId] = useState(null);
  const [showRestoreModal, setShowRestoreModal] = useState(false);

  const dispatch = useDispatch();

  const getTotalAfterCoupon = (order) => {
    let amount = 0;
    if (order.discount_amount > 0) {
      amount = order.discount_amount;
    } else if (order.discount_percent > 0) {
      amount = order.total * (order.discount_percent / 100);
    }
    return order.total - amount;
  };

  const deletePermanently = () => {
    if (deleteId === null) {
      dispatch(
        setErrors({
          message: 'The item to delete was not set.',
        }),
      );
    } else {
      setIsDeleting(true);
      api
        .delete(`/deleted-cpo/${deleteId}`)
        .then(() => {
          setDeletedCPos((prevVal) => {
            return prevVal.filter((it) => {
              return it.cpo_id !== deleteId;
            });
          });

          setDeleteId(null);
          setShowDeleting(false);
          dispatch(
            setSuccessMessages([
              {
                message: 'The order has been permanently deleted',
              },
            ]),
          );
          fetchCPOs();
        })
        .catch((e) => {
          dispatch(
            setErrors([
              {
                message: e?.data?.errors || 'Sorry we are unable to delete.',
              },
            ]),
          );
        })
        .finally(() => {
          setIsDeleting(false);
        });
    }
  };

  return (
    <Tr
      style={{
        cursor: 'pointer',
        fontSize: '0.85em',
      }}
    >
      <Td>{cpo.cpo_id}</Td>
      <Td>{cpo.username}</Td>
      <Td>
        <b style={{ color: 'red' }}>DELETED</b>
      </Td>
      <Td>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: cpo.cpo_currency || 'USD',
        }).format(getTotalAfterCoupon(cpo))}
      </Td>
      <Td>
        <LButton
          size="sm"
          isLoading={isLoadingRestore}
          onClick={() => {
            setRestoreId(cpo.cpo_id);
            setShowRestoreModal(true);
          }}
          disabled={isDeleting}
          style={{ marginRight: '0.3rem' }}
        >
          Restore <CIcon style={{ marginLeft: '0.3rem' }} icon={cilActionUndo} />
        </LButton>
        <LButton
          color="danger"
          style={{
            color: 'white',
          }}
          size="sm"
          isLoading={isDeleting}
          disabled={isLoadingRestore}
          onClick={() => {
            setDeleteId(cpo.cpo_id);
            setShowDeleting(true);
          }}
        >
          Delete Permanently
        </LButton>

        <YesNoModal
          visible={showDeleting}
          setVisible={setShowDeleting}
          onYes={() => {
            deletePermanently();
          }}
          onNo={() => {
            setShowDeleting(false);
            setDeleteId(null);
          }}
          isLoading={isDeleting}
          title="Permanently delete order"
          description="You won't be able to restore this order anymore."
        />
        <YesNoModal
          visible={showRestoreModal}
          setVisible={setShowRestoreModal}
          isLoading={isLoadingRestore}
          onYes={() => {
            setIsLoadingRestore(true);
            api
              .post(`/deleted-cpo/restore/${restoreId}`)
              .then(() => {
                dispatch(
                  setSuccessMessages([
                    {
                      message: 'The order has been restored successfully',
                    },
                  ]),
                );
                fetchCPOs();
                setRestoreId(null);
                setActiveKey(1);
              })
              .catch((e) => {
                console.log(e);
                dispatch(
                  setErrors([
                    {
                      message: e?.data?.errors || 'Sorry we were unable to restore the order.',
                    },
                  ]),
                );
              })
              .finally(() => {
                setIsLoadingRestore(false);
                setShowRestoreModal(false);
              });
          }}
          onNo={() => {
            setShowRestoreModal(false);
            setRestoreId(null);
          }}
          title="Restore Order"
          description={
            <>
              <p>
                <b>WARNING:</b>
              </p>
              <p>The order will be restored, and any items that are no longer available will be removed from your order.</p>
            </>
          }
        />
      </Td>
    </Tr>
  );
};

DeletedCpoRow.propTypes = {
  cpo: PropTypes.object,
  fetchCPOs: PropTypes.func,
  setDeletedCPos: PropTypes.func.isRequired,
  setActiveKey: PropTypes.func.isRequired,
};

const CPOs = () => {
  const [activeKey, setActiveKey] = useState(1);
  const [cpoViewVisible, setCPOViewVisible] = useState(false);
  const [activeCPOsList, setActiveCPOsList] = useState([]);
  const [finishedCPOsList, setFinishedCPOsList] = useState([]);
  const [newVisible, setNewVisible] = useState(false);

  const [creating, setCreating] = useState(false);

  const navigate = useNavigate();

  const [buyer, setBuyer] = useState([]);
  const [buyers, setBuyers] = useState([]);
  // const [cpoId, setCPOId] = useState();
  // const [products, setProducts] = useState([]);
  const [deletedCPOs, setDeletedCPos] = useState([]);

  // const [currencies, setCurrencies] = useState([]);

  const [loadingOrders, setLoadingOrders] = useState(true);

  const role = useSelector((state) => state.auth.role);

  const fetchCPOs = useCallback(() => {
    let activeCPOs = [];
    let finishedCPOs = [];
    setLoadingOrders(true);

    const controller = new AbortController();

    api
      .get('/cpo', { signal: controller.signal })
      .then(({ data }) => {
        activeCPOs = data.filter((item) => {
          return item.order_status !== 'Shipped';
        });
        createCPO;
        finishedCPOs = data.filter((item) => {
          return item.order_status === 'Shipped';
        });
        setActiveCPOsList(activeCPOs);
        setFinishedCPOsList(finishedCPOs);
      })
      .finally(() => {
        setLoadingOrders(false);
      });

    api.get('/deleted-cpo', { signal: controller.signal }).then(({ data }) => {
      setDeletedCPos(data);
    });

    return () => {
      controller.abort();
    };
  }, []);

  /*
  const deleteCPO = (cpoId) => {
    setCPOViewVisible(false);
  };
  */

  const createCPO = () => {
    setCreating(true);
    api
      .post('/cpo/new', {
        user_id: buyer,
      })
      .then(({ data }) => {
        if (data && data.id) {
          navigate(`/dashboard/cpos/cpo/${data.id}`);
        }
      })
      .finally(() => {
        setCreating(false);
      });
  };

  const buyersOptions = buyers.map((b) => {
    return (
      <option value={b.user_id} key={b.user_id}>
        {b.username}
      </option>
    );
  });

  useEffect(() => {
    const controller = new AbortController();

    const to = setTimeout(() => {
      fetchCPOs();
      api.get('/user/buyers', { signal: controller.signal }).then(({ data }) => {
        setBuyers(data);
      });
    }, 500);

    return () => {
      controller.abort();
      clearTimeout(to);
    };
  }, [fetchCPOs]);

  useEffect(() => {
    if (!cpoViewVisible) {
      // setCPOId(null);
      fetchCPOs();
    }
  }, [cpoViewVisible]);

  function adjustCPOViewModal(cpo) {
    setCPOViewVisible(true);
    if (cpo?.id) {
      // setCPOId(cpo.id);
      navigate(`/dashboard/cpos/cpo/${cpo.id}`);
    }
  }

  function FinishedCPOsList(props) {
    const cpos = props.finishedCPOs;
    const list = cpos.map((cpo, fc) => {
      return <FinishedCPORow adjustCPOViewModal={adjustCPOViewModal} cpo={cpo} fc={fc} key={`fc-${fc}`} />;
    });
    return list;
  }

  function ActiveCPOsList(props) {
    const cpos = props.activeCPOs;

    return (
      <>
        {Array.isArray(cpos) && cpos.length <= 0 && (
          <>
            <CTableRow>
              <CTableDataCell colSpan={7}>
                <CAlert color="info" className="d-flex w-100">
                  No active orders found.
                </CAlert>
              </CTableDataCell>
            </CTableRow>
          </>
        )}
        {cpos.map((cpo, idx) => {
          return (
            <ActiveCPORow
              adjustCPOViewModal={adjustCPOViewModal}
              cpo={cpo}
              ac={idx}
              key={`ac-${idx}`}
              onFinish={() => {
                fetchCPOs();
              }}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{role === 'ADMIN' || role === 'STAFF' ? 'Client Purchase Orders' : 'Purchase Orders'}</title>
      </Helmet>
      <CContainer>
        {/*
          <CPOViewModal
            cpoId={cpoId}
            cpoViewVisible={cpoViewVisible}
            setCPOViewVisible={setCPOViewVisible}
            products={products}
            deleteCPO={deleteCPO}
            currencies={currencies}
            setCPOId={setCPOId}
          />
  */}
        <CModal key="newModal" visible={newVisible} onClose={() => setNewVisible(false)} backdrop={`static`} size="lg">
          <CModalHeader>
            <CCol sm={5}>
              <strong>PURCHASE</strong> <small>New</small>
            </CCol>
          </CModalHeader>
          <CModalBody>
            <CForm onSubmit={(e) => e.preventDefault()}>
              <CFormSelect
                onChange={(e) => {
                  setBuyer(e.target.value);
                }}
              >
                <option value="0">Select a buyer</option>
                {buyersOptions}
              </CFormSelect>
            </CForm>
          </CModalBody>
          <CModalFooter>
            <CButton color="danger" style={{ color: 'white' }} onClick={() => setNewVisible(false)}>
              Cancel
            </CButton>
            <CButton
              color="primary"
              onClick={() => {
                createCPO();
              }}
            >
              Create
            </CButton>
          </CModalFooter>
        </CModal>

        <CCard className="mb-4">
          <CCardHeader>
            <CCol sm={5}>
              <strong>SALES</strong> <small>{role === 'CLIENT' ? 'POs' : 'CPOs'}</small>
            </CCol>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <div className="">
                  <BreadCrumbs
                    items={[
                      {
                        to: '/dashboard/cpos/cpos',
                        text: 'CPOs',
                        disabled: true,
                      },
                    ]}
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <LButton
                  isLoading={creating}
                  style={{ marginBottom: '15px' }}
                  onClick={() => {
                    if (role === 'ADMIN') {
                      setNewVisible(true);
                    } else {
                      createCPO();
                    }
                  }}
                >
                  New CPO
                </LButton>
              </CCol>
            </CRow>
            <CNav variant="tabs" role="tablist">
              <CNavItem>
                <CNavLink
                  href=""
                  active={activeKey === 1}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveKey(1);
                  }}
                >
                  Active
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href=""
                  active={activeKey === 2}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveKey(2);
                  }}
                >
                  Finished
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href=""
                  active={activeKey === 3}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveKey(3);
                  }}
                >
                  Deleted
                </CNavLink>
              </CNavItem>
            </CNav>
            <CTabContent>
              <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
                <CRow>
                  <CCol xs={12}>
                    <CCard className="mb-4">
                      <CCardBody>
                        <div className="table-container">
                          <Table>
                            <Thead>
                              <Tr>
                                <Th scope="col">Order</Th>
                                <Th scope="col">Client</Th>
                                <Th scope="col">Status</Th>
                                <Th scope="col">Order Total</Th>
                                <Th scope="col">Date Created</Th>
                                <Th scope="col">Order Expiration</Th>

                                {(role === 'ADMIN' || role === 'STAFF') && <Th scope="col">Ship Order</Th>}
                              </Tr>
                            </Thead>
                            <Tbody>
                              {loadingOrders ? (
                                <>
                                  <Tr>
                                    <Td colSpan={6}>
                                      <CSpinner />
                                    </Td>
                                  </Tr>
                                </>
                              ) : (
                                <ActiveCPOsList activeCPOs={activeCPOsList} />
                              )}
                            </Tbody>
                          </Table>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </CTabPane>
              <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 2}>
                <CRow>
                  <CCol xs={12}>
                    <CCard className="mb-4">
                      <CCardBody>
                        <div className="table-container">
                          <Table>
                            <Thead>
                              <Tr>
                                <Th scope="col">Order</Th>
                                <Th scope="col">Client</Th>
                                <Th scope="col">Status</Th>
                                <Th scope="col">Order Total</Th>
                                <Th scope="col">Date Created</Th>
                                <Th scope="col">Date Shipped</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {loadingOrders ? (
                                <Tr>
                                  <Td>
                                    <CSpinner />
                                  </Td>
                                </Tr>
                              ) : (
                                <FinishedCPOsList finishedCPOs={finishedCPOsList} />
                              )}
                            </Tbody>
                          </Table>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </CTabPane>
              <CTabPane role="tabpanel" aria-labelledby="deleted-cpos-tab" visible={activeKey == 3}>
                <CRow>
                  <CCol xs={12}>
                    <CCard className="mb-4">
                      <CCardBody>
                        <div className="table-container">
                          <Table>
                            <Thead>
                              <Tr>
                                <Td scope="col">Order</Td>
                                <Td scope="col">Client</Td>
                                <Td scope="col">Status</Td>
                                <Td scope="col">Order Total</Td>
                                <Td scope="col">Actions</Td>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {deletedCPOs.map((cpo, idx) => {
                                return (
                                  <DeletedCpoRow
                                    setActiveKey={setActiveKey}
                                    setDeletedCPos={setDeletedCPos}
                                    key={`del-cpo-row-${idx}`}
                                    cpo={cpo}
                                    fetchCPOs={fetchCPOs}
                                  />
                                );
                              })}
                            </Tbody>
                          </Table>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </CTabPane>
            </CTabContent>
          </CCardBody>
        </CCard>
      </CContainer>
    </>
  );
};

CPOs.defaultProps = {};

CPOs.propTypes = {
  fc: PropTypes.number,
  ac: PropTypes.number,
  finishedCPOs: PropTypes.any,
  activeCPOs: PropTypes.any,
};

export default CPOs;
