import './shelf.css';

import { CButton, CFormSelect } from '@coreui/react';

import { SketchPicker } from 'react-color';
import CIcon from '@coreui/icons-react';
import { cilImage, cilText, cilColorPalette, cilPaperclip } from '@coreui/icons';

import { useContext } from 'react';
import { useState, useEffect } from 'react';
import logo from 'src/img/CC_LOGO.png';
import tsookLogo from 'src/img/tsooklogo.png';
import api from 'src/api';
import { ShelfTalkerContext } from '../../context/ShelfTalkerContext';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const ShelfTalker = (props) => {
  const [showPicker, setShowPicker] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const {
    setDescriptions,
    descriptions,
    shelfTakerRef,
    background,
    isImage,
    src,
    expressionType,
    types,
    subtitle1,
    setSubtitle1,
    subtitle2,
    setSubtitle2,
    subtitle3,
    setSubtitle3,
    titleLine1,
    setTitleLine1,
    titleLine2,
    setTitleLine2,
    description,
    setDescription,
    setBackground,
    setIsImage,
    setIsMultiLine,
    isMultiline,
    titleSingleLine1,
    setSingleLine1,
    textSize,
    setExpressionType,
    setSrc,
    setTextSize,
    setFiles,
    color,
    setColor,
  } = useContext(ShelfTalkerContext);
  useEffect(() => {
    const controller = new AbortController();
    api.get('/product/descriptions', { signal: controller.signal }).then(({ data }) => {
      setDescriptions(data);
    });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Shelf Talker</title>
      </Helmet>
      <div style={{ marginBottom: '3rem', padding: '0.3rem' }}>
        {props.showControls && (
          <div style={{ paddingLeft: '1.3rem', paddingRight: '1.3rem' }}>
            <CFormSelect
              placeholder="Template"
              onChange={(e) => {
                const val = e.target.value;

                descriptions.some((item) => {
                  const isItem = item.id == val;

                  if (isItem) {
                    setDescription(item.description || '');
                    setSubtitle1(item.common_name);
                    setSubtitle2(item.maker_name);

                    if (item.computed_expression.toLowerCase().includes('tso\'ok')) {
                      setExpressionType(types.TSOOK);
                    } else {
                      setExpressionType(types.CUENTACUENTOS);
                    }
                    setSrc(null);
                  } else {
                    setDescription('');
                    setSubtitle1('');
                    setSubtitle2('');
                  }
                  return isItem;
                });
              }}
            >
              <option value="-1">Choose a template</option>
              {descriptions.map((desc, idx) => {
                return (
                  <option value={desc.id} key={`opt-m-${idx}`}>
                    {desc.computed_expression}
                  </option>
                );
              })}
            </CFormSelect>
          </div>
        )}
        <div className="shelf-wrapper">
          <div className="shelf-talker-container" ref={shelfTakerRef}>
            {props.withProps ? (
              <>
                <div
                  className="shelf-talker-header"
                  style={{
                    backgroundColor: props.background,
                    color: props.text_color,
                  }}
                >
                  <div className="shelf-divider"></div>

                  {props.has_image ? (
                    <div className="img-container">
                      {props.custom_img === null ? (
                        <>
                          <img src={props.image === 'tsok' ? tsookLogo : logo} />
                        </>
                      ) : (
                        <>
                          <img src={`${process.env.REACT_APP_API_URL}/shelf-talker/img/${props.custom_img}`} />
                        </>
                      )}
                    </div>
                  ) : props.is_multiline ? (
                    <>
                      <input
                        type="text"
                        className="shelf-text-1 multi-line"
                        defaultValue={props.title_line_1}
                        readOnly
                        placeholder="Line #1"
                        style={{
                          backgroundColor: props.background,
                          color: props.text_color,
                        }}
                      />
                      <input
                        type="text"
                        className="shelf-text-1 multi-line"
                        defaultValue={props.title_line_2}
                        placeholder="Line #2"
                        style={{
                          backgroundColor: props.background,
                          color: props.text_color,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        type="text"
                        className="shelf-text-1"
                        defaultValue={props.title_single_line_1}
                        readOnly
                        placeholder="Brand / Headline"
                        style={{
                          fontSize: `${props.title_single_font_size}px`,
                          backgroundColor: props.background,
                          color: props.text_color,
                        }}
                      />
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="shelf-talker-header" style={{ backgroundColor: background, color: color }}>
                <div className="shelf-divider"></div>

                {isImage ? (
                  <div className="img-container">
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      id="imagePicker"
                      accept="image/*"
                      onChange={(e) => {
                        if (e?.target?.files?.length > 0) {
                          setFiles(e.target.files);
                          const reader = new FileReader();
                          reader.addEventListener(
                            'load',
                            function() {
                              setSrc(reader.result);
                            },
                            false,
                          );
                          reader.readAsDataURL(e.target.files[0]);
                        }
                      }}
                    />
                    {src === null ? (
                      <>
                        <img src={expressionType === types.TSOOK ? tsookLogo : logo} />
                      </>
                    ) : (
                      <>
                        <img src={src} />
                      </>
                    )}
                  </div>
                ) : isMultiline ? (
                  <>
                    <input
                      type="text"
                      className="shelf-text-1 multi-line"
                      value={titleLine1}
                      onChange={(e) => {
                        setTitleLine1(e.target.value);
                      }}
                      placeholder="Line #1"
                      style={{ backgroundColor: background, color: color }}
                    />
                    <input
                      type="text"
                      className="shelf-text-1 multi-line"
                      value={titleLine2}
                      onChange={(e) => {
                        setTitleLine2(e.target.value);
                      }}
                      placeholder="Line #2"
                      style={{ backgroundColor: background, color: color }}
                    />
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      className="shelf-text-1"
                      value={titleSingleLine1}
                      onChange={(e) => {
                        setSingleLine1(e.target.value);
                      }}
                      placeholder="Brand / Headline"
                      style={{
                        fontSize: `${textSize}px`,
                        backgroundColor: background,
                        color: color,
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {props.withProps ? (
              <>
                <div className="sht-body">
                  <input type="text" className="text-inp" placeholder="Agave" defaultValue={props.sub_line1 /* subtitle1 */} readOnly />
                  <input type="text" className="text-inp" placeholder="Maker" defaultValue={props.sub_line2 /* subtitle2*/} readOnly />
                  <input
                    type="text"
                    className="text-inp text-inp-special"
                    placeholder="Country, Region, Vintage Or Destination"
                    defaultValue={props.sub_line3 /* subtitle3*/}
                    readOnly
                  />

                  <textarea style={{ border: 'none', outline: 'none' }} defaultValue={props.description /** description*/} readOnly></textarea>
                </div>
              </>
            ) : (
              <div className="sht-body">
                <input
                  type="text"
                  className="text-inp"
                  placeholder="Agave"
                  value={subtitle1}
                  onChange={(e) => {
                    setSubtitle1(e.target.value);
                  }}
                />
                <input
                  type="text"
                  className="text-inp"
                  placeholder="Maker"
                  value={subtitle2}
                  onChange={(e) => {
                    setSubtitle2(e.target.value);
                  }}
                />
                <input
                  type="text"
                  className="text-inp text-inp-special"
                  placeholder="Country, Region, Vintage Or Destination"
                  value={subtitle3}
                  onChange={(e) => {
                    setSubtitle3(e.target.value);
                  }}
                />

                <textarea
                  style={{ border: 'none', outline: 'none' }}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                ></textarea>
              </div>
            )}
          </div>
          {props.showControls && (
            <>
              <div className="shelf-floating-controls">
                <div
                  className="shelf-control"
                  onClick={() => {
                    setShowPicker(!showPicker);
                  }}
                >
                  <CIcon icon={cilColorPalette} />
                </div>
                <div
                  className="shelf-control"
                  onClick={() => {
                    setShowColorPicker(!showColorPicker);
                  }}
                >
                  T<CIcon icon={cilColorPalette} />
                </div>
                {isImage ? (
                  <>
                    <div
                      className="shelf-control"
                      onClick={() => {
                        setIsImage(!isImage);
                      }}
                    >
                      <CIcon icon={cilText} />
                    </div>

                    <label
                      htmlFor="imagePicker"
                      className="shelf-control"
                      onClick={() => {
                        console.log('Choose another image');
                      }}
                    >
                      <CIcon icon={cilPaperclip} />
                    </label>
                  </>
                ) : (
                  <>
                    <div
                      className="shelf-control"
                      onClick={() => {
                        setIsMultiLine(!isMultiline);
                      }}
                    >
                      <small>
                        {isMultiline ? (
                          <>
                            One <br />
                            Line
                          </>
                        ) : (
                          <>
                            Two
                            <br />
                            Lines
                          </>
                        )}
                      </small>
                    </div>
                    <div
                      className="shelf-control"
                      onClick={() => {
                        setIsImage(!isImage);
                      }}
                    >
                      <CIcon icon={cilImage} />
                    </div>
                    {!isMultiline && (
                      <>
                        <div
                          className="shelf-control text"
                          onClick={() => {
                            setTextSize(parseInt(textSize) + 3);
                          }}
                        >
                          <span>T</span>
                          <span>+</span>
                        </div>
                        <div
                          className="shelf-control text"
                          onClick={() => {
                            setTextSize(parseInt(textSize) - 3);
                          }}
                        >
                          <span>T</span>
                          <span>-</span>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {props.showControls && showPicker && (
            <div className="shelf-color-picker">
              <div className="color-wrapper">
                <SketchPicker
                  color={background}
                  onChangeComplete={(color) => {
                    setBackground(color.hex);
                  }}
                />
                <div
                  className="flex-center"
                  style={{
                    padding: '0.3rem',
                  }}
                >
                  <CButton
                    size="sm"
                    color="danger"
                    style={{ color: '#fff' }}
                    onClick={() => {
                      setShowPicker(false);
                    }}
                  >
                    Close Color Picker
                  </CButton>
                </div>
              </div>
            </div>
          )}
          {props.showControls && showColorPicker && (
            <div className="shelf-color-picker">
              <div className="color-wrapper">
                <SketchPicker
                  color={color}
                  onChangeComplete={(color) => {
                    setColor(color.hex);
                  }}
                />
                <div
                  className="flex-center"
                  style={{
                    padding: '0.3rem',
                  }}
                >
                  <CButton
                    size="sm"
                    color="danger"
                    onClick={() => {
                      setShowColorPicker(false);
                    }}
                  >
                    Close Color Picker
                  </CButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ShelfTalker.defaultProps = {
  showControls: true,
  withProps: false,
};
ShelfTalker.propTypes = {
  showControls: PropTypes.bool,
  withProps: PropTypes.bool,
  sub_line1: PropTypes.string,
  sub_line2: PropTypes.string,
  sub_line3: PropTypes.string,
  text_color: PropTypes.string,
  background: PropTypes.string,
  title_single_font_size: PropTypes.string,
  has_image: PropTypes.bool,
  custom_img: PropTypes.string,
  image: PropTypes.string,
  is_multiline: PropTypes.bool,
  title_line_1: PropTypes.string,
  title_line_2: PropTypes.string,
  title_single_line_1: PropTypes.string,
  description: PropTypes.string,
};

export default ShelfTalker;
