import '../scss/avatar.scss';
import PropTypes from 'prop-types';

function CCAvatar({ src, size = 'md' }) {
  return <div data-testid="avatar-container" className={`cc-avatar ${size}`} style={{ backgroundImage: `url(${src})` }}></div>;
}

CCAvatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.string,
};

export default CCAvatar;
