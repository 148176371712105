import {
  CFormInput,
  CTable,
  CTableHead,
  CTableHeaderCell,
  CTooltip,
  CButton,
  CFormLabel,
  CRow,
  CCol,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CAlert,
  CFormSwitch,
} from '@coreui/react';
import PropTypes from 'prop-types';
import TheIcon from '../TheIcon/TheIcon';
import { useCallback, useEffect, useState } from 'react';
import LButton from '../LButton/LButton';

import { useContext } from 'react';
import { OrderContext } from 'src/context/OrderContext';
import { toast } from 'react-toastify';
import useCasePackCalculator from 'src/hooks/useCasePackCalculator';
import api from 'src/api';
import SubTableRow from '../SubTableRow/SubTableRow';
import { setErrors } from 'src/actions';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import usePrevious from 'src/hooks/usePrevious';
import { setStockInCart, setProductionInCart } from 'src/actions';

function OrderSubTable({ lots, isStock, product }) {
  const [casePacks, setCasePacks] = useState('');
  const { data, setData, isAutoSave, isConnected, socket } = useContext(OrderContext);
  const [isManual, setIsManual] = useState(false);
  const cpCalculator = useCasePackCalculator();
  const [isSavingCp, setIsSavingCp] = useState(false);
  const [wasLimited, setWasLimited] = useState(false);
  const prevCasePacks = usePrevious(casePacks);
  const stockInCart = useSelector((state) => state.order.stockInCart);
  const productionInCart = useSelector((state) => state.order.productionInCart);

  const [lastCpSet, setLastCpSet] = useState(-1);
  const dispatch = useDispatch();
  const [canUpdate, setCanUpdate] = useState(false);

  useEffect(() => {
    if (product) {
      setLastCpSet(isStock ? product.current_case_pack : product.current_case_pack_production);
    }
    return () => {
      setLastCpSet(-1);
    };
  }, [product, isStock]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Array.isArray(data.products) && data.products.length > 0 && product.product_id) {
        data.products.some((p) => {
          const isProduct = p.product_id == product.product_id;
          if (isProduct) {
            let cp = isStock ? p.current_case_pack : p.current_case_pack_production;
            cp = parseFloat(cp);

            if (!isNaN(cp) && cp > 0) {
              setCasePacks(cp);
            }
          }
          return isProduct;
        });
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [data.products, product]);

  const getAvailable = () => {
    const bottlesAvailable = product.lots.reduce((prev, current) => {
      if (isStock) {
        return prev + parseInt(current.qtyStockAv);
      } else {
        return prev + parseInt(current.qtyProductionAv);
      }
    }, 0);

    const cpAvailable = cpCalculator.howManyCasePacks(bottlesAvailable);

    return {
      bottlesAvailable,
      cpAvailable,
    };
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const { cpAvailable } = getAvailable();
      if (Array.isArray(product.lots) && cpAvailable >= parseFloat(casePacks) && !isNaN(casePacks)) {
        // const isStockValid = isStock && parseFloat(casePacks) != parseFloat(product.current_case_pack);
        // const isProductionValid = !isStock && parseFloat(casePacks) != parseFloat(product.current_case_pack_production);

        if (parseFloat(casePacks) != parseFloat(lastCpSet) || (!isNaN(lastCpSet) && isNaN(parseFloat(casePacks)))) {
          // only re-pick the bottles when the case packs amount changes.

          let bottlesNeeded = cpCalculator.howManyBottles(parseFloat(casePacks));
          let bottlesDepleted = 0;
          const newStockItems = [];
          const newProductionItems = [];

          product.lots.forEach((lot) => {
            const available = isStock ? parseInt(lot.qtyStockAv) : parseInt(lot.qtyProductionAv);

            if (bottlesNeeded > 0 && available > 0) {
              if (bottlesNeeded <= available) {
                // 1. There are an amount equal or greater to the amount required
                bottlesDepleted = bottlesNeeded;
                bottlesNeeded = 0;
              } else if (available > 0) {
                // There are bottles available but not all wee need.
                bottlesNeeded -= available;
                bottlesDepleted = available;
              }
              if (bottlesDepleted > 0) {
                const newItem = {
                  lot_id: lot.lot_id,
                  product_id: lot.product_id,
                  lot_number: lot.lot_number,
                  qty: bottlesDepleted,
                  price: product.unit_price,
                  ml_btl: product.ml_btl,
                };

                if (isStock) {
                  newStockItems.push(newItem);
                } else {
                  newProductionItems.push(newItem);
                }
              }
            }
          });

          if (newStockItems.length > 0) {
            const currentStock = stockInCart.filter((item) => {
              return item.product_id != product.product_id;
            });
            dispatch(setStockInCart([...currentStock, ...newStockItems]));
          } else if (newProductionItems.length > 0) {
            const currentProduction = productionInCart.filter((item) => {
              return item.product_id != product.product_id;
            });
            dispatch(setProductionInCart([...currentProduction, ...newProductionItems]));
          }
          setLastCpSet(casePacks);
          setCanUpdate(true);
        }
      }
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [casePacks, lastCpSet, isStock]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (product && Array.isArray(stockInCart) && Array.isArray(productionInCart)) {
        const foundInStock = stockInCart.some((p) => p.product_id == product.product_id && p.qty > 0);
        const foundInProduction = productionInCart.some((p) => p.product_id == product.product_id && p.qty > 0);
        if ((isStock && foundInStock) || (!isStock && foundInProduction)) {
          if (isNaN(parseFloat(prevCasePacks))) {
            setIsManual(isNaN(parseFloat(casePacks)));
          } else {
            // The previous casePacks value was a number. Which means the user was typing and deleted the number from the field.
            setIsManual(false);
          }
        }
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [product, stockInCart, productionInCart, casePacks, prevCasePacks]);

  const notifyUpdate = useCallback(() => {
    if (isConnected) {
      product.lots.forEach((lot) => {
        socket.emit('qty-lot-updated', {
          lotId: lot.lot_id,
          cpoId: data.id,
        });
      });
    }
  }, [isConnected, socket, product]);

  const saveCasePacks = () => {
    if (Array.isArray(product?.lots)) {
      if (isNaN(parseFloat(casePacks)) || parseFloat(casePacks) == 0 || casePacks == '') {
        const currentCP = isStock ? product.current_case_pack : product.current_case_pack_production;
        if (currentCP > 0) {
          setIsSavingCp(true);
          api
            .post(`/cpo/remove-case-packs/${data.id}`, {
              productId: product.product_id,
              isStock: isStock,
            })
            .then(() => {
              setData((prev) => {
                const products = prev.products.map((p) => {
                  if (p.product_id == product.product_id) {
                    if (isStock) {
                      p.current_case_pack = 0;
                    } else {
                      p.current_case_pack_production = 0;
                    }
                  }
                  return p;
                });
                return {
                  ...prev,
                  products: products,
                };
              });
              notifyUpdate();
              toast.success('You have removed the case packs and all bottles of this product from your order.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
            })
            .catch((err) => {
              dispatch(
                setErrors([
                  {
                    message: err,
                  },
                ]),
              );
            })
            .finally(() => {
              setIsSavingCp(false);
            });
          return false;
        }

        return toast.error(`Enter a valid number in the case packs field.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
      const { cpAvailable } = getAvailable();

      if (parseFloat(casePacks) > cpAvailable) {
        return toast.error('The amount of case packs cannot be greater than the amount available.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }

      setIsSavingCp(true);

      const itemsToSave = isStock ?
        stockInCart.filter((item) => item.product_id == product.product_id) :
        productionInCart.filter((item) => item.product_id == product.product_id);

      api
        .patch(`/cpo/case-packs/${data.id}`, {
          casePacks: casePacks,
          items: JSON.stringify(itemsToSave),
          isStock,
          productId: product.product_id,
        })
        .then(() => {
          setData((prev) => {
            const products = prev.products.map((p) => {
              if (p.product_id == product.product_id) {
                if (isStock) {
                  p.current_case_pack = casePacks;
                } else {
                  p.current_case_pack_production = casePacks;
                }
              }
              return p;
            });
            return {
              ...prev,
              products: products,
            };
          });

          toast.success(`${isAutoSave ? 'Automatically' : 'Successfully'} saved ${casePacks} CP in your order.`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          notifyUpdate();
        })
        .finally(() => {
          setIsSavingCp(false);
        });
    } else {
      toast.error('This product does not have any lots available.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const validateCp = (cp) => {
    if (cp == '' || cp == 0) {
      setWasLimited(false);
      // Remove current items in "cart"

      setData((data) => {
        if (isStock) {
          const currentStock = stockInCart;
          return {
            ...data,
            stockInCart: currentStock.filter((item) => {
              return item.product_id != product.product_id;
            }),
          };
        } else {
          const currentProduction = productionInCart;
          return {
            ...data,
            productionInCart: currentProduction.filter((item) => {
              return item.product_id != product.product_id;
            }),
          };
        }
      });
    }

    const wantsCp = parseFloat(cp);
    const { cpAvailable } = getAvailable();

    if (!isNaN(wantsCp) && wantsCp > cpAvailable && cpAvailable > 0) {
      setCasePacks(cpAvailable);
      setWasLimited(true);
    }
  };

  const validateCpHandler = useCallback(debounce(validateCp, 1000), []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (canUpdate && isAutoSave && !isManual) {
        setCanUpdate(false);
        saveCasePacks();
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [canUpdate, stockInCart, casePacks, isAutoSave, saveCasePacks]);

  return (
    <div className="py-3">
      <div>
        {isStock ? (
          <></>
        ) : (
          <CRow>
            <CCol>
              <CAlert color="warning" data-testid="preOrderWarning">
                <b>Warning: </b> These bottles are in production. This could delay the time of delivery of your order.
              </CAlert>
            </CCol>
          </CRow>
        )}
        <CRow>
          <CFormLabel htmlFor="case_packs" data-testid="casepacks-label">
            <b className="me-2 fs-5">Case Packs</b>
          </CFormLabel>
        </CRow>
        <CRow>
          <CCol xs={12} sm={isAutoSave ? 12 : 8} md={isAutoSave ? 12 : 9} xl={isAutoSave ? 12 : 10}>
            <div className="m-1">
              <CFormInput
                disabled={isManual}
                id="case_packs"
                data-testid="casePacksInput"
                placeholder="Case packs"
                value={casePacks}
                onChange={(e) => {
                  const cp = e.target.value;
                  setCasePacks(cp);
                  validateCpHandler(cp);
                  if (cp == '') {
                    if (isStock) {
                      dispatch(setStockInCart([]));
                      setCanUpdate(true);
                    } else {
                      dispatch(setProductionInCart([]));
                      setCanUpdate(true);
                    }
                  }
                }}
                type="number"
              />
            </div>
          </CCol>
          {!isAutoSave && (
            <CCol xs={12} sm={4} md={3} xl={2}>
              <div className="m-1">
                <LButton isLoading={isSavingCp} disabled={isManual} data-testid="casePacksSaveBtn" className="w-100" onClick={saveCasePacks}>
                  {isSavingCp ? 'Saving...' : 'Save'}
                </LButton>
              </div>
            </CCol>
          )}
        </CRow>
        <CRow>
          <CCol>
            <div className="my-3">
              <CAlert color="warning" dismissible visible={wasLimited} onClose={() => setWasLimited(false)}>
                You entered an amount of case packs greater than the amount available. The system automatically set the amount to the highest possible. (
                {casePacks} case packs)
              </CAlert>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <div className="flex-columns align-items-center p-1">
              <CFormSwitch data-testid="manualSelection" checked={isManual} onChange={() => setIsManual((v) => !v)} size="xl" label="Manual selection" />
              <CTooltip
                content={
                  'When you enter the amount of case packs you want to buy, the system will automatically choose lots for you. ' +
                  'If you want to enter a specific amount of bottles of a lot you can enable this switch.'
                }
                placement="top"
              >
                <CButton style={{ background: 'transparent', border: 'none' }}>
                  <TheIcon name="info_blue" size={20} className={`m-0`} />
                </CButton>
              </CTooltip>
            </div>
          </CCol>
        </CRow>
      </div>
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell width={'20%'}>Lot Number</CTableHeaderCell>
            <CTableHeaderCell data-testid="bottlesAvailableCell">{isStock ? 'Bottles Available' : 'Bottles In Production'}</CTableHeaderCell>
            <CTableHeaderCell>
              {isStock ? 'Bottles In Your Order' : 'Bottles in production in your order'}
              <CTooltip
                content={
                  'When you are buying case packs the system will auto-assign bottles from lots available. ' +
                  'If you are interested in a specific lot or specific amount of bottles you can manually set ' +
                  'the amount in the fields below. (You have to enable manual selection)'
                }
                placement="top"
              >
                <CButton style={{ background: 'transparent', border: 'none' }}>
                  <TheIcon name="info_blue" size={20} className={`m-0`} />
                </CButton>
              </CTooltip>
            </CTableHeaderCell>
            {isManual && !isAutoSave && <CTableHeaderCell>Save</CTableHeaderCell>}
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {Array.isArray(lots) && lots.length > 0 ? (
            lots.map((lot, idx) => {
              return <SubTableRow product={product} isManual={isManual} isStock={isStock} key={`row-t-${idx}`} lot={lot} />;
            })
          ) : (
            <CTableRow>
              <CTableDataCell colSpan={3}>
                <b>No Lots Found</b>
              </CTableDataCell>
            </CTableRow>
          )}
        </CTableBody>
      </CTable>
    </div>
  );
}

OrderSubTable.propTypes = {
  isStock: PropTypes.bool.isRequired,
  lots: PropTypes.array,
  product: PropTypes.object,
};

export default OrderSubTable;
