import { CTableRow, CTableDataCell, CBadge } from '@coreui/react';
import useMoneyCurrency from 'src/hooks/useMoneyCurrency';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import anime from 'animejs';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import TheIcon from '../TheIcon/TheIcon';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react';
import OrderSubTable from '../OrderSubTable/OrderSubTable';

import useCasePackCalculator from 'src/hooks/useCasePackCalculator';
import { useSelector } from 'react-redux';
import OrderOnDemand from '../OrderOnDemand/OrderOnDemand';
import useProductCategoryChecker from 'src/hooks/userProductCategoryChecker';

function OrderRow({ product, isMobile }) {
  const money = useMoneyCurrency();

  const [productionDepleted, setProductionDepleted] = useState(0);
  const [depleted, setDepleted] = useState(0);
  const [available, setAvailable] = useState({});
  const [hasMoreThanAvailable, setHasMoreThanAvailable] = useState(false);
  const subRowRef = useRef(null);
  const contentRef = useRef(null);
  const [initialHeight, setInitialHeight] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeKey, setActiveKey] = useState(1);
  const cpCalculator = useCasePackCalculator();
  const currency = useSelector((state) => state.currency);
  const [backgroundColor, setBackgroundColor] = useState('');
  const { stockInCart, productionInCart, onDemand } = useSelector((state) => state.order);
  const [onDemandQty, setOnDemandQty] = useState(0);

  const [showRow, setShowRow] = useState(false);

  const categoryChecker = useProductCategoryChecker();

  useEffect(() => {
    const timeout = setTimeout(() => {
      let av = {
        stock: 0,
        production: 0,
      };
      if (Array.isArray(product.lots)) {
        av = product.lots.reduce(
          (prev, currItem) => {
            // We use two different ifs because a single object could contain both stock and production.
            if (currItem.qtyStockAv > 0) {
              prev['stock'] = (prev?.stock || 0) + parseFloat(currItem.qtyStockAv);
            }
            if (currItem.qtyProductionAv > 0) {
              prev['production'] = (prev?.production || 0) + parseFloat(currItem.qtyProductionAv);
            }
            return prev;
          },
          { stock: 0, production: 0 }, // default state
        );
        setAvailable(av);
      }
      if (Array.isArray(stockInCart) && Array.isArray(productionInCart) && Array.isArray(onDemand)) {
        let areProductsFound = false;

        const totalStock = stockInCart.reduce((prev, curr) => {
          if (curr.product_id == product.product_id) {
            return prev + curr.qty;
          }
          return prev;
        }, 0);

        const totalProd = productionInCart.reduce((prev, curr) => {
          if (curr.product_id == product.product_id) {
            return prev + curr.qty;
          }
          return prev;
        }, 0);

        const totalOnDemand = onDemand.reduce((prev, curr) => {
          if (curr.product_id == product.product_id) {
            return prev + parseInt(curr.bottle_qty);
          }
          return prev;
        }, 0);

        if (totalStock > 0 || totalProd > 0 || totalOnDemand > 0) {
          areProductsFound = true;
        }

        if (totalStock > av?.stock) {
          setHasMoreThanAvailable(true);
        }

        setProductionDepleted(totalProd);
        setDepleted(totalStock + totalProd + totalOnDemand);
        setOnDemandQty(totalOnDemand);

        const totalLotsAv = product.lots.reduce((previousValue, currentValue) => {
          return previousValue + parseFloat(currentValue.qtyStock);
        }, 0);
        setShowRow(areProductsFound || totalLotsAv > 0 || categoryChecker.isElBarroOrTsook(product?.product_name));
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [stockInCart, productionInCart, onDemand, product.lots]);

  useEffect(() => {
    if (contentRef?.current && initialHeight == null) {
      setInitialHeight(contentRef.current.offsetHeight);
      contentRef.current.style.height = 0;
    }
  }, [contentRef, initialHeight]);

  const toggleSubRow = (e) => {
    e.preventDefault();
    if (subRowRef?.current && contentRef?.current) {
      const contentSettings = {
        targets: contentRef.current,
        duration: 150,
        easing: 'linear',
      };
      const rowSettings = {
        targets: subRowRef.current,
        duration: 400,
        easing: 'easeInOutQuad',
      };
      const isOpen = subRowRef.current.style.opacity != 0;
      if (isOpen) {
        contentSettings.height = '0px';
        rowSettings.opacity = 0;
        rowSettings.update = (anim) => {
          const p = Math.round(anim.progress);
          if (p > 40) {
            subRowRef.current.style.visibility = 'collapse';
          }
        };
      } else {
        contentSettings.height = `${initialHeight}px`;
        contentSettings.complete = () => {
          contentRef.current.style.height = '';
        };
        rowSettings.opacity = 1;
        subRowRef.current.style.visibility = 'visible';
      }
      setOpen(!isOpen);
      anime(contentSettings);
      anime(rowSettings);
    }
  };

  const debouncedChangeHandler = useCallback(debounce(toggleSubRow, 100), [toggleSubRow]);

  const displayPrice = useCallback(() => {
    return money(product.unit_price, currency.currency);
  }, [currency.currency]);

  const displayTotalPrice = useCallback(() => {
    return money(product.unit_price * depleted, currency.currency);
  }, [product.unit_price, currency.currency, depleted]);

  useEffect(() => {
    let bg = '';

    if (hasMoreThanAvailable) {
      bg = 'red';
    } else if (productionDepleted > 0) {
      bg = 'yellow';
    } else if (depleted > 0) {
      bg = 'mediumaquamarine';
    }

    setBackgroundColor(bg);
  }, [depleted, productionDepleted, hasMoreThanAvailable]);

  return (
    <>
      <CTableRow
        style={{ background: backgroundColor, display: showRow ? '' : 'none', opacity: showRow ? '1' : '0' }}
        data-testid="row-open"
        className={`cursor-pointer  ${open ? 'order-row-selected' : ''}`}
        onClick={debouncedChangeHandler}
      >
        <CTableDataCell width={`${isMobile ? '30' : '40'}%`} data-testid="responsive-cell">
          {product.product_name}
        </CTableDataCell>
        <CTableDataCell>
          <span data-testid="depletedQty">{depleted}</span> bottles
          {depleted > 0 ?
            ` or 
                    ${cpCalculator.howManyCasePacks(depleted).toFixed(1)} CP` :
            ''}
        </CTableDataCell>
        <CTableDataCell>
          <span data-testid="display-price-cell">{displayPrice()}</span>
        </CTableDataCell>
        <CTableDataCell>
          <span data-testid="display-total-price">{displayTotalPrice()}</span>
        </CTableDataCell>
      </CTableRow>
      <CTableRow
        data-testid="row-collapsable"
        className={`${open ? 'order-row-selected' : ''}`}
        ref={subRowRef}
        style={{ opacity: '0', visibility: 'collapse' }}
      >
        <CTableDataCell colSpan={4} style={{ width: '100%' }}>
          <div ref={contentRef} style={{ overflow: 'hidden', boxSizing: 'border-box' }}>
            <div className="flex-columns">
              <div>
                <TheIcon name="barcode" size={24} />
                SKU: {product.sku}
              </div>
              <div>
                <TheIcon name="cp_size" size={24} />
                Case Pack Size: {product.case_pack}
              </div>
              <div>
                <TheIcon name="bottle" size={24} />
                Bottle Size: {product.ml_btl}ml
              </div>
            </div>
            <div className="my-4 bg-white p-2" style={{ borderRadius: '9px' }}>
              <CNav variant="tabs" role="tablist">
                <CNavItem>
                  <CNavLink className="cursor-pointer" active={activeKey === 1} onClick={() => setActiveKey(1)}>
                    Stock
                  </CNavLink>
                </CNavItem>

                <CNavItem>
                  {(available.production > 0 || productionDepleted > 0) && (
                    <CNavLink className="cursor-pointer" active={activeKey === 2} onClick={() => setActiveKey(2)}>
                      Production &nbsp;
                      {productionDepleted > 0 && (
                        <>
                          <CBadge color="warning">{cpCalculator.howManyCasePacks(productionDepleted).toFixed(2)} CP in Cart</CBadge>
                        </>
                      )}
                    </CNavLink>
                  )}
                </CNavItem>
                {categoryChecker.isElBarroOrTsook(product?.product_name) && (
                  <CNavItem>
                    <CNavLink className="cursor-pointer" active={activeKey === 3} onClick={() => setActiveKey(3)}>
                      <div className="flex-center">
                        <span>Order On Demand</span>
                        {onDemandQty > 0 && (
                          <CBadge className="ms-1" color="info">
                            {onDemandQty / 6} CP
                          </CBadge>
                        )}
                      </div>
                    </CNavLink>
                  </CNavItem>
                )}
              </CNav>
              <CTabContent>
                <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
                  <OrderSubTable product={product} lots={product.lots} isStock={true} />
                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 2}>
                  <OrderSubTable product={product} lots={product.lots} isStock={false} />
                </CTabPane>
                {categoryChecker.isElBarroOrTsook(product?.product_name) && (
                  <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 3}>
                    <OrderOnDemand product={product} />
                  </CTabPane>
                )}
              </CTabContent>
            </div>
          </div>
        </CTableDataCell>
      </CTableRow>
    </>
  );
}

OrderRow.propTypes = {
  product: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default OrderRow;
