import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';

import { useEffect, useState } from 'react';
import ContentLayout from 'src/layout/ContentLayout';
import Loading from 'src/components/Loading';
import api from 'src/api';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';
import { CAlert, CButton, CCol, CRow } from '@coreui/react';
import NewMiscelaneous from 'src/components/Miscellaneous/NewMiscelaneous';
import PropTypes from 'prop-types';
import moment from 'moment';
import useMoneyCurrency from 'src/hooks/useMoneyCurrency';
import YesNoModal from 'src/components/YesNoModal';
import { toast } from 'react-toastify';

const Miscellaneous = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [item, setItem] = useState({});
  const dispatch = useDispatch();

  const [counter, setCounter] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [showDelete, setShowDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    api
      .get('/miscellaneous', { signal: controller.signal })
      .then(({ data }) => {
        setItems(data);
      })
      .catch((e) => {
        dispatch(setErrors(e));
      })
      .finally(() => {
        setLoading(false);
      });
    return () => controller.abort();
  }, [counter]);

  return (
    <ContentLayout title="Miscellaneous">
      {loading ? (
        <Loading />
      ) : (
        <>
          <CRow>
            <CCol>
              <CButton
                className="mb-3"
                onClick={() => {
                  setIsEdit(false);
                  setShowNew(true);
                }}
              >
                New Item
              </CButton>
              <NewMiscelaneous
                item={item}
                show={showNew}
                setShow={setShowNew}
                isEdit={isEdit}
                onComplete={() => {
                  setCounter((c) => c + 1);
                  setShowNew(false);
                }}
                editId={editId}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              {items.length > 0 ? (
                <>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Price</Th>
                        <Th>Type</Th>
                        <Th>Created At</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {items.map((item, key) => {
                        return (
                          <MiscellaneousRow
                            item={item}
                            setItem={setItem}
                            key={`miscellaneous-item-${key}`}
                            setIsEdit={setIsEdit}
                            setEditId={setEditId}
                            setShowNew={setShowNew}
                            deleteRequest={(id) => {
                              setDeleteId(id);
                              setShowDelete(true);
                            }}
                          />
                        );
                      })}
                    </Tbody>
                  </Table>
                  <YesNoModal
                    title="Delete Item"
                    description="This action can't be undone. If the item is in use you won't be able to delete it."
                    visible={showDelete}
                    setVisible={setShowDelete}
                    onNo={() => {
                      setShowDelete(false);
                      setDeleteId(null);
                    }}
                    onYes={() => {
                      if (deleteId == null) {
                        toast.error('Error, the id is null.');
                      } else {
                        setDeleting(true);
                        api
                          .delete(`/miscellaneous/${deleteId}`)
                          .then(() => {
                            setCounter((c) => c + 1);
                          })
                          .catch((e) => {
                            dispatch(setErrors([{ message: e }]));
                          })
                          .finally(() => {
                            setShowDelete(false);
                            setDeleting(false);
                          });
                      }
                    }}
                    isLoading={deleting}
                    noText="Cancel"
                    yesText="Delete"
                  />
                </>
              ) : (
                <>
                  <CAlert color="warning">No miscellaneous items were found.</CAlert>
                </>
              )}
            </CCol>
          </CRow>
        </>
      )}
    </ContentLayout>
  );
};

const MiscellaneousRow = ({ item, setShowNew, setIsEdit, setEditId, setItem, deleteRequest }) => {
  const money = useMoneyCurrency();
  return (
    <Tr>
      <Td>{item.name}</Td>
      <Td>{money(item.price, 'USD')}</Td>
      <Td>{item.type}</Td>
      <Td>{moment(item.createAt).format('YYYY-MM-DD')}</Td>
      <Td>
        <CButton
          className="m-1"
          onClick={() => {
            setIsEdit(true);
            setEditId(item.id);
            setShowNew(true);
            setItem(item);
          }}
        >
          Update
        </CButton>
        <CButton
          color="danger"
          className="m-1"
          onClick={() => {
            deleteRequest(item.id);
          }}
        >
          Delete
        </CButton>
      </Td>
    </Tr>
  );
};

MiscellaneousRow.propTypes = {
  item: PropTypes.object,
  setIsEdit: PropTypes.bool,
  setEditId: PropTypes.func,
  setShowNew: PropTypes.func,
  setItem: PropTypes.func,
  deleteRequest: PropTypes.func,
};

export default Miscellaneous;
