import React, { useState, useEffect } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CContainer,
  CCardTitle,
  CAlert,
  CFormInput,
} from '@coreui/react';
import LButton from 'src/components/LButton/LButton';
import api from 'src/api';
import { setErrors, setSuccessMessages } from 'src/actions';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import './variableCosts.css';

const mergeWithDefaults = (data, defaults) => {
  return Object.keys(defaults).reduce((acc, key) => {
    acc[key] =
      data && data[key] !== null && data[key] !== undefined
        ? data[key]
        : defaults[key];
    return acc;
  }, {});
};

const VariableCosts = () => {
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [freight, setFreight] = useState('');
  const [ttb, setTtb] = useState('');
  const [coLiq, setCoLiq] = useState('');
  const [bottles, setBottles] = useState('');
  const [cork, setCork] = useState('');
  const [testing, setTesting] = useState('');
  const [labelPrintingBtl, setLabelPrintingBtl] = useState('');
  const [labelPrintingHt, setLabelPrintingHt] = useState('');
  const [bottling, setBottling] = useState('');
  const [cardboard, setCardboard] = useState('');
  const [palletPkg, setPalletPkg] = useState('');
  const [pesoToUsd, setPesoToUsd] = useState('');
  const [usdToPeso, setUsdToPeso] = useState('');

  const defaultRows = {
    freightRow: { first: 0, second: null, third: null, fourth: null },
    warehousingRow: { first: 0, second: -1, third: 0, fourth: null },
    ttbRow: { first: 0, second: -1, third: null, fourth: null },
    abvRow: { first: 0, second: -1, third: -1, fourth: -1 },
    coRow: { first: 0, second: 0, third: null, fourth: null },
    bottlesRow: { first: -1, second: 0, third: null, fourth: -1 },
    corkRow: { first: -1, second: 0, third: null, fourth: -1 },
    testingRow: { first: -1, second: 0, third: null, fourth: -1 },
    labelBtlRow: { first: -1, second: 0, third: null, fourth: -1 },
    labelTagRow: { first: -1, second: 0, third: null, fourth: -1 },
    bottlingRow: { first: 0, second: 0, third: null, fourth: -1 },
    cardboardRow: { first: -1, second: 0, third: null, fourth: -1 },
    palletRow: { first: -1, second: 0, third: null, fourth: -1 },
    taxRow: { first: 0, second: -1, third: -1, fourth: -1 },
  };

  const [freightRow, setFreightRow] = useState(defaultRows.freightRow);
  const [warehousingRow, setWarehousingRow] = useState(
    defaultRows.warehousingRow
  );
  const [ttbRow, setTtbRow] = useState(defaultRows.ttbRow);
  const [abvRow, setAbvRow] = useState(defaultRows.abvRow);
  const [coRow, setCoRow] = useState(defaultRows.coRow);
  const [bottlesRow, setBottlesRow] = useState(defaultRows.bottlesRow);
  const [corkRow, setCorkRow] = useState(defaultRows.corkRow);
  const [testingRow, setTestingRow] = useState(defaultRows.testingRow);
  const [labelBtlRow, setLabelBtlRow] = useState(defaultRows.labelBtlRow);
  const [labelTagRow, setLabelTagRow] = useState(defaultRows.labelTagRow);
  const [bottlingRow, setBottlingRow] = useState(defaultRows.bottlingRow);
  const [cardboardRow, setCardboardRow] = useState(defaultRows.cardboardRow);
  const [palletRow, setPalletRow] = useState(defaultRows.palletRow);
  const [taxRow, setTaxRow] = useState(defaultRows.taxRow);

  useEffect(() => {
    const controller = new AbortController();

    api
      .get('/variable-costs', { signal: controller.signal })
      .then(({ data }) => {
        if (data?.id) {
          setFreight(data.freight);
          setTtb(data.ttb_import_tax);
          setCoLiq(data.co_liquor_import_tax);
          setBottles(data.bottles);
          setCork(data.cork);
          setTesting(data.testing);
          setLabelPrintingBtl(data.label_printing_btl);
          setLabelPrintingHt(data.label_printing_hang_tag);
          setBottling(data.bottling);
          setCardboard(data.cardboard_cartoons);
          setPalletPkg(data.pallet_and_pkg);
          setPesoToUsd(data.peso_to_usd);
          setUsdToPeso(data.usd_to_peso);

          setFreightRow(
            mergeWithDefaults(data.freight_row, defaultRows.freightRow)
          );
          setWarehousingRow(
            mergeWithDefaults(data.warehousing_row, defaultRows.warehousingRow)
          );
          setTtbRow(mergeWithDefaults(data.ttb_row, defaultRows.ttbRow));
          setAbvRow(mergeWithDefaults(data.abv_row, defaultRows.abvRow));
          setCoRow(mergeWithDefaults(data.co_row, defaultRows.coRow));
          setBottlesRow(
            mergeWithDefaults(data.bottles_row, defaultRows.bottlesRow)
          );
          setCorkRow(mergeWithDefaults(data.cork_row, defaultRows.corkRow));
          setTestingRow(
            mergeWithDefaults(data.testing_row, defaultRows.testingRow)
          );
          setLabelBtlRow(
            mergeWithDefaults(data.label_btl_row, defaultRows.labelBtlRow)
          );
          setLabelTagRow(
            mergeWithDefaults(data.label_tag_row, defaultRows.labelTagRow)
          );
          setBottlingRow(
            mergeWithDefaults(data.bottling_row, defaultRows.bottlingRow)
          );
          setCardboardRow(
            mergeWithDefaults(data.cardboard_row, defaultRows.cardboardRow)
          );
          setPalletRow(
            mergeWithDefaults(data.pallet_row, defaultRows.palletRow)
          );
          setTaxRow(mergeWithDefaults(data.tax_row, defaultRows.taxRow));
        }
      });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const updatedFreightRow = {
      ...freightRow,
      second: (coRow.second === 1 ? 600 : 720)?.toFixed(2),
      third: (freightRow.first / freightRow.second)?.toFixed(2),
    };
    const updatedTtbRow = {
      ...ttbRow,
      third: (
        ttbRow.first *
        (abvRow.first / 100) *
        (0.26417 * coRow.second)
      )?.toFixed(2),
    };
    const updatedCoRow = {
      ...coRow,
      third: (coRow.first * coRow.second)?.toFixed(2),
    };
    const updatedBottlesRow = {
      ...bottlesRow,
      third: (bottlesRow.second / pesoToUsd)?.toFixed(2),
    };
    const updatedCorkRow = {
      ...corkRow,
      third: (corkRow.second / pesoToUsd)?.toFixed(2),
    };
    const updatedTestingRow = {
      ...testingRow,
      third: (testingRow.second / pesoToUsd / freightRow.second)?.toFixed(2),
    };
    const updatedLableBtlRow = {
      ...labelBtlRow,
      third: (labelBtlRow.second / pesoToUsd)?.toFixed(2),
    };
    const updatedLabelTagRow = {
      ...labelTagRow,
      third: (labelTagRow.second / pesoToUsd)?.toFixed(2),
    };
    const updatedBottlingRow = {
      ...bottlingRow,
      third: (bottlingRow.second / pesoToUsd)?.toFixed(2),
    };
    const updatedCardboardRow = {
      ...cardboardRow,
      third: (cardboardRow.second / pesoToUsd / 12)?.toFixed(2),
    };
    const updatedPalletRow = {
      ...palletRow,
      third: (coRow.second === 0.75
        ? palletRow.second / 672
        : palletRow.second / 600
      )?.toFixed(2),
    };

    setFreightRow(updatedFreightRow);
    setTtbRow(updatedTtbRow);
    setCoRow(updatedCoRow);
    setBottlesRow(updatedBottlesRow);
    setCorkRow(updatedCorkRow);
    setTestingRow(updatedTestingRow);
    setLabelBtlRow(updatedLableBtlRow);
    setLabelTagRow(updatedLabelTagRow);
    setBottlingRow(updatedBottlingRow);
    setCardboardRow(updatedCardboardRow);
    setPalletRow(updatedPalletRow);
  }, [
    coRow.second,
    abvRow.first,
    ttbRow.first,
    coRow.first,
    bottlesRow.second,
    corkRow.second,
    testingRow.second,
    labelBtlRow.second,
    labelTagRow.second,
    bottlingRow.second,
    cardboardRow.second,
    palletRow.second,
    pesoToUsd,
    freightRow.first,
    freightRow.second,
  ]);

  const save = () => {
    let valid = true;

    const validateNumberField = (value, fieldName) => {
      if (isNaN(parseFloat(value))) {
        valid = false;
        dispatch(
          setErrors([
            { message: `Please fill the ${fieldName} field with a number.` },
          ])
        );
      }
    };

    validateNumberField(freight, 'Freight');
    validateNumberField(ttb, 'TTB Import Tax CB Rate');
    validateNumberField(coLiq, 'CO Liquor Import Tax');
    validateNumberField(bottles, 'Bottles');
    validateNumberField(cork, 'Cork');
    validateNumberField(testing, 'Testing');
    validateNumberField(labelPrintingBtl, 'Label Printing - Btl');
    validateNumberField(labelPrintingHt, 'Label Printing - Hang Tag');
    validateNumberField(bottling, 'Bottling');
    validateNumberField(cardboard, 'Cardboard cartons');
    validateNumberField(palletPkg, 'Pallet and Pkg');
    validateNumberField(pesoToUsd, 'Peso to USD');
    validateNumberField(usdToPeso, 'USD to Peso');

    if (valid) {
      setIsLoading(true);
      api
        .post('/variable-costs/save', {
          freight,
          ttb_import_tax: ttb,
          co_liquor_import_tax: coLiq,
          bottles,
          cork,
          testing,
          label_printing_btl: labelPrintingBtl,
          label_printing_hang_tag: labelPrintingHt,
          bottling,
          cardboard_cartoons: cardboard,
          pallet_and_pkg: palletPkg,
          peso_to_usd: pesoToUsd,
          usd_to_peso: usdToPeso,
          freight_row: freightRow,
          warehousing_row: warehousingRow,
          ttb_row: ttbRow,
          abv_row: abvRow,
          co_row: coRow,
          bottles_row: bottlesRow,
          cork_row: corkRow,
          testing_row: testingRow,
          label_btl_row: labelBtlRow,
          label_tag_row: labelTagRow,
          bottling_row: bottlingRow,
          cardboard_row: cardboardRow,
          pallet_row: palletRow,
          tax_row: taxRow,
        })
        .then(() => {
          dispatch(
            setSuccessMessages([
              { message: 'The data has been saved successfully!' },
            ])
          );
        })
        .catch((e) => {
          dispatch(setErrors([{ message: e }]));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleChange = (setter) => (e, field) => {
    setter((prev) => ({ ...prev, [field]: e.target.value }));
  };

  return (
    <>
      <CContainer>
        <Helmet>
          <title>Variable Costs</title>
        </Helmet>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CCardTitle>Variable Costs</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CAlert
                      color='warning'
                      visible={showInfo}
                      dismissible
                      onClose={() => setShowInfo(false)}
                    >
                      Please enter the amounts in the specified currency. All
                      the values must be saved in that currency in order to work
                      well with other features.
                    </CAlert>
                  </CCol>
                </CRow>
                <table className='styled-table'>
                  <thead>
                    <tr>
                      <th>(BUYER)</th>
                      <th></th>
                      <th>Btl/Pallet</th>
                      <th>Per Bottle</th>
                      <th>Per 6 CP</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Freight (PAID BY DIST) Discount</td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        US$
                        <CFormInput
                          type='number'
                          value={freightRow.first}
                          onChange={(e) => {
                            setFreight(e.target.value);
                            handleChange(setFreightRow)(e, 'first');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td>{freightRow.second}</td>
                      <td>US${freightRow.third}</td>
                      <td>
                        US$
                        {((freightRow.first / freightRow.second) * 6)?.toFixed(
                          2
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Warehousing</td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        US$
                        <CFormInput
                          type='number'
                          value={warehousingRow.first}
                          onChange={(e) =>
                            handleChange(setWarehousingRow)(e, 'first')
                          }
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td></td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        US$
                        <CFormInput
                          type='number'
                          value={warehousingRow.third}
                          onChange={(e) =>
                            handleChange(setWarehousingRow)(e, 'third')
                          }
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td>US${(warehousingRow.third * 6)?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>TTB Import Tax CB Rate</td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        US$
                        <CFormInput
                          type='number'
                          value={ttbRow.first}
                          onChange={(e) => {
                            setTtb(e.target.value);
                            handleChange(setTtbRow)(e, 'first');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td></td>
                      <td>US${ttbRow.third}</td>
                      <td>US${(ttbRow.third * 6)?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Proof Gallon Cost at ABV Shown Below</td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        US$
                        {(ttbRow.first * (abvRow.first / 100) * 2)?.toFixed(2)}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>ABV</td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CFormInput
                          type='number'
                          value={abvRow.first}
                          onChange={(e) => handleChange(setAbvRow)(e, 'first')}
                          style={{ marginRight: '5px' }}
                        />
                        %
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td className='section-header'>Btl in L</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Pesos per Dollar</td>
                      <td></td>
                      <td style={{ backgroundColor: '#f4d35e' }}>
                        <CFormInput
                          type='number'
                          value={pesoToUsd}
                          onChange={(e) => {
                            setPesoToUsd(e.target.value);
                            setUsdToPeso(
                              (1 / parseInt(e.target.value))?.toFixed(2)
                            );
                          }}
                        />
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>CO Liquor Import Tax 60.26¢ per (PAID BY DIST)</td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        US$
                        <CFormInput
                          type='number'
                          value={coRow.first}
                          onChange={(e) => {
                            setCoLiq(e.target.value);
                            handleChange(setCoRow)(e, 'first');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td style={{ backgroundColor: '#a0e7e5' }}>
                        <CFormInput
                          type='number'
                          value={coRow.second}
                          onChange={(e) => handleChange(setCoRow)(e, 'second')}
                        />
                      </td>
                      <td>US${coRow.third}</td>
                      <td>US${(coRow.third * 6)?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td className='section-subtotal'>SUBTOTAL</td>
                      <td></td>
                      <td></td>
                      <td className='section-subtotal'>
                        US$
                        {(
                          parseFloat(freightRow.third) +
                          parseFloat(warehousingRow.third) +
                          parseFloat(ttbRow.third) +
                          parseFloat(coRow.third)
                        )?.toFixed(2)}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className='section-header'>(SUPPLIER)</td>
                      <td></td>
                      <td className='section-header'>Pesos</td>
                      <td className='section-header'>Dollars</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Bottles</td>
                      <td></td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        US${' '}
                        <CFormInput
                          type='number'
                          value={bottlesRow.second}
                          onChange={(e) => {
                            setBottles(e.target.value);
                            handleChange(setBottlesRow)(e, 'second');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td>US${bottlesRow.third}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Cork</td>
                      <td></td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        US${' '}
                        <CFormInput
                          type='number'
                          value={corkRow.second}
                          onChange={(e) => {
                            setCork(e.target.value);
                            handleChange(setCorkRow)(e, 'second');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td>US${corkRow.third}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Testing</td>
                      <td></td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        US${' '}
                        <CFormInput
                          type='number'
                          value={testingRow.second}
                          onChange={(e) => {
                            setTesting(e.target.value);
                            handleChange(setTestingRow)(e, 'second');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td>US${testingRow.third}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Label Printing - Btl</td>
                      <td></td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        US${' '}
                        <CFormInput
                          type='number'
                          value={labelBtlRow.second}
                          onChange={(e) => {
                            setLabelPrintingBtl(e.target.value);
                            handleChange(setLabelBtlRow)(e, 'second');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td>US${labelBtlRow.third}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Label Printing - Hang Tag</td>
                      <td></td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        US${' '}
                        <CFormInput
                          type='number'
                          value={labelTagRow.second}
                          onChange={(e) => {
                            setLabelPrintingHt(e.target.value);
                            handleChange(setLabelTagRow)(e, 'second');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td>US${labelTagRow.third}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Bottling</td>
                      <td
                        style={{
                          whiteSpace: 'nowrap',
                          verticalAlign: 'middle',
                        }}
                      >
                        <span
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}
                        >
                          US$
                        </span>
                        <CFormInput
                          type='number'
                          value={bottlingRow.first}
                          onChange={(e) =>
                            handleChange(setBottlingRow)(e, 'first')
                          }
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: '5px',
                            width: 'auto',
                          }}
                        />
                      </td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        US$
                        <CFormInput
                          type='number'
                          value={bottlingRow.second}
                          onChange={(e) => {
                            setBottling(e.target.value);
                            handleChange(setBottlingRow)(e, 'second');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td>US${bottlingRow.third}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Cardboard cartons</td>
                      <td>Included w btl to start</td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        US$
                        <CFormInput
                          type='number'
                          value={cardboardRow.second}
                          onChange={(e) => {
                            setCardboard(e.target.value);
                            handleChange(setCardboardRow)(e, 'second');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td>US${cardboardRow.third}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Pallet and Pkg</td>
                      <td></td>
                      <td
                        style={{
                          backgroundColor: '#a0e7e5',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        US$
                        <CFormInput
                          type='number'
                          value={palletRow.second}
                          onChange={(e) => {
                            setPalletPkg(e.target.value);
                            handleChange(setPalletRow)(e, 'second');
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                      <td>
                        US$
                        {palletRow.third}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className='section-subtotal'>SUBTOTAL</td>
                      <td></td>
                      <td></td>
                      <td className='section-subtotal'>
                        US$
                        {(
                          parseFloat(bottlesRow.third) +
                          parseFloat(corkRow.third) +
                          parseFloat(testingRow.third) +
                          parseFloat(labelBtlRow.third) +
                          parseFloat(labelTagRow.third) +
                          parseFloat(bottlingRow.third) +
                          parseFloat(cardboardRow.third)
                        ).toFixed(2)}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className='section-subtotal'>GRAND TOTAL</td>
                      <td></td>
                      <td></td>
                      <td>
                        US$
                        {(
                          parseFloat(freightRow.third) +
                          parseFloat(warehousingRow.third) +
                          parseFloat(ttbRow.third) +
                          parseFloat(coRow.third) +
                          (parseFloat(bottlesRow.third) +
                            parseFloat(corkRow.third) +
                            parseFloat(testingRow.third) +
                            parseFloat(labelBtlRow.third) +
                            parseFloat(labelTagRow.third) +
                            parseFloat(bottlingRow.third) +
                            parseFloat(cardboardRow.third))
                        ).toFixed(2)}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className='section-subtotal'>TAX RATE</td>
                      <td>
                        <CFormInput
                          type='number'
                          value={taxRow.first}
                          onChange={(e) => handleChange(setTaxRow)(e, 'first')}
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <CRow>
                  <CCol>
                    <div className='my-3'>
                      <LButton isLoading={isLoading} onClick={save}>
                        Save
                      </LButton>
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default VariableCosts;
