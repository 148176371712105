import { CAlert } from '@coreui/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import TheIcon from 'src/components/TheIcon/TheIcon';
import ContentLayout from 'src/layout/ContentLayout';
const NotAllowed = () => {
  return (
    <>
      <Helmet>
        <title>Not Authorized</title>
      </Helmet>
      <ContentLayout title="Not Authorized">
        <h1>Not Authorized</h1>
        <CAlert color="warning" className="d-flex align-items-center ">
          <TheIcon name="warning" />
          You are not authorized to view the content you were trying to access.
          Please make sure you have an account with authorization to view it.
        </CAlert>
      </ContentLayout>
    </>
  );
};

export default NotAllowed;
