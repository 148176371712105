import { CFormSelect, CFormInput } from '@coreui/react';
import PropTypes from 'prop-types';
import './filterableselect.css';
import { useState, useCallback, useEffect } from 'react';
import { debounce } from 'debounce';

const FilterableSelect = ({ defaultValue, selectProps = {}, placeholder, options, text, value, onChange: onChangeFn, size, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [displayResults, setDisplayResults] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handler = useCallback(
    debounce((v) => {
      if (typeof v === 'string') {
        setResults(
          options.filter((opt) => {
            return opt[text]?.toLowerCase()?.includes(v.toLowerCase()) || false;
          }),
        );
      }
    }, 100),
    [options],
  );

  useEffect(() => {
    if (options && typeof options === 'object') {
      setResults([...options]);
    }
    setSearchTerm('');
    setSelectedOption('');
  }, [options]);

  useEffect(() => {
    onChangeFn(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (searchTerm === '') {
      setSelectedOption('');
    }
  }, [searchTerm]);

  useEffect(() => {
    options.some((opt) => {
      const isValue = opt[value] == defaultValue;
      if (isValue) {
        setSearchTerm(opt[text]);
        setSelectedOption(opt[value]);
      }
      return isValue;
    });
  }, [options, defaultValue]);

  const chooseOption = (opt) => {
    setSearchTerm(opt[text]);
    setSelectedOption(opt[value]);
  };
  return (
    <>
      <div className="search-container-filterable">
        <CFormInput
          {...rest}
          size={size}
          value={searchTerm}
          type="search"
          placeholder={placeholder}
          onChange={(e) => {
            const v = e.target.value;
            setSearchTerm(v);
            handler(v);
          }}
          onBlur={() => {
            setTimeout(() => {
              setDisplayResults(false);
            }, 250);
          }}
          onFocus={() => {
            setDisplayResults(true);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (results.length > 0) {
                chooseOption(results[0]);
              } else {
                setSearchTerm('');
                setSelectedOption();
              }
              setDisplayResults(false);
            }
          }}
        ></CFormInput>
        {displayResults && (
          <div className="search-results">
            <ul>
              {results.map((opt, idx) => {
                return (
                  <li
                    onClick={() => {
                      chooseOption(opt);
                    }}
                    key={`opt-li-s-${idx}`}
                  >
                    {opt[text]}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <div style={{ display: 'none' }}>
        <CFormSelect
          {...selectProps}
          value={selectedOption}
          onChange={(e) => {
            setSelectedOption(e.target.value);
          }}
        >
          {options.map((opt, idx) => {
            return (
              <option value={opt[value]} key={`opt-sel-search-${idx}`}>
                {opt[text]}
              </option>
            );
          })}
        </CFormSelect>
      </div>
    </>
  );
};

FilterableSelect.defaultProps = {
  placeholder: 'Type something here..',
  value: 'value',
  text: 'text',
  options: [],
  defaultValue: null,
  size: 'sm',
};

FilterableSelect.propTypes = {
  placeholder: PropTypes.string,
  text: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.array,
  defaultValue: PropTypes.any,
  size: PropTypes.string,
  selectProps: PropTypes.object,
  onChange: PropTypes.func,
};

export default FilterableSelect;
