import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import React from 'react';
import PropTypes from 'prop-types';

import LButton from './LButton/LButton';

const YesNoModal = (props) => {
  return (
    <CModal backdrop={`static`} visible={props.visible} onClose={() => {
      props.setVisible(false);
    }}>
      <CModalHeader>
        <CModalTitle>{props.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>{props.description}</CModalBody>
      <CModalFooter>
        <LButton
          type="button"
          color="danger"
          style={{color: 'white'}}
          onClick={(e) => {
            e.preventDefault();
            props.setVisible(false);
          }}
          disabled={props.isLoading}
        >
          {props.noText}
        </LButton>
        <LButton
          type="button"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onYes();
          }}
          isLoading={props.isLoading}
        >
          {props.yesText}
        </LButton>
      </CModalFooter>
    </CModal>
  );
};

YesNoModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.any,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  isLoading: PropTypes.bool,
  yesText: PropTypes.string,
  noText: PropTypes.string,
};

YesNoModal.defaultProps = {
  title: 'Confirm action',
  description: '',
  onYes: () => {
    alert('Not yet implemented.');
  },
  onNo: () => {
    alert('Not yet implemented.');
  },
  isLoading: false,
  yesText: 'Yes',
  noText: 'No',
};

export default YesNoModal;
