import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  CAlert,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CRow,
} from '@coreui/react';
import api from 'src/api';
import { debounce } from 'debounce';
import { useSelector } from 'react-redux';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import Loading from 'src/components/Loading';
import useMoneyCurrency from 'src/hooks/useMoneyCurrency';
import { AgGridReact } from 'ag-grid-react';
import styles from './gridStyles.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const Prices = () => {
  const [pricesList, setPricesList] = useState([]);
  const [usernameFilter, setUsernameFilter] = useState('');
  const [showHiddenUsers, setShowHiddenUsers] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState('');
  const [loadingPrices, setLoadingPrices] = useState(true);
  const [transformedData, setTransformedData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  // Just a value that makes useEffect get the results from api.
  const [hiddenNumber] = useState(1);

  const role = useSelector((state) => state.auth.role);
  // const isClient = role === 'CLIENT';

  const money = useMoneyCurrency();

  useEffect(() => {
    const controller = new AbortController();
    const currencyController = new AbortController();

    api.get('/rates/today', { signal: controller.signal }).then(({ data }) => {
      setCurrencies(data);
    });

    api
      .get('/user/currency', { signal: currencyController.signal })
      .then(({ data }) => {
        if (data.currency) {
          setCurrency(data.currency);
        } else {
          setCurrency('NotFound');
          toast.error('Failed to load your currency.');
        }
      });

    return () => {
      controller.abort();
      currencyController.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const to = setTimeout(() => {
      setLoadingPrices(true);
      api
        .get('/sales/prices', {
          signal: controller.signal,
          params: {
            username: usernameFilter,
            showHidden: showHiddenUsers ? 1 : 0,
            currency: currency,
          },
        })
        .then(({ data }) => {
          const formattedData = processData(data);
          setTransformedData(formattedData);
          createColumnDefinitions(formattedData);
        })
        .finally(() => {
          setLoadingPrices(false);
        });
    }, 250);
    return () => {
      controller.abort();
      clearTimeout(to);
    };
  }, [usernameFilter, hiddenNumber, showHiddenUsers, currency]);

  const processData = (data) => {
    const groupedByExpression = data.reduce((acc, cur) => {
      (acc[cur.expression] = acc[cur.expression] || []).push(cur);
      return acc;
    }, {});

    const gridData = Object.entries(groupedByExpression).map(
      ([expression, details]) => {
        const rowData = { expression };
        details.forEach((detail) => {
          rowData[detail.buyer_name + '_id'] = detail.user_id;
          rowData[detail.buyer_name + '_productId'] = detail.product_id;
          rowData[detail.buyer_name + '_price'] = detail.price;
          rowData[detail.buyer_name + '_costPrice'] = detail.cost_price === 0 ? '-' : detail.cost_price.toFixed(2);
          rowData[detail.buyer_name + '_markup'] = detail.markup === 0 ? '-' : detail.markup.toFixed(2);
          rowData[detail.buyer_name + '_margin'] = detail.margin === 0 ? '-' : detail.margin.toFixed(2);
        });
        return rowData;
      }
    );

    return gridData;
  };

  const createColumnDefinitions = (data) => {
    const buyerNames = new Set();
    data.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key.includes('_price')) {
          buyerNames.add(key.split('_')[0]);
        }
      });
    });

    const initialColumnDefs = [
      {
        headerName: 'Expression',
        field: 'expression',
        pinned: 'center',
        width: 300,
      },
      ...Array.from(buyerNames).map((buyerName) => ({
        headerName: buyerName,
        field: buyerName,
        children: [
          {
            headerName: 'Sls Price',
            field: `${buyerName}_price`,
            width: 120,
            editable: role === 'ADMIN',
          },
          { headerName: 'Cost Price', field: `${buyerName}_costPrice`, width: 120 },
          { headerName: 'Markup (%)', field: `${buyerName}_markup`, width: 120 },
          { headerName: 'Margin (%)', field: `${buyerName}_margin`, width: 120 },
        ],
        hide: false,
      })),
    ];

    setColumnDefs(initialColumnDefs);
  };

  const toggleColumnVisibility = useCallback((groupName) => {
    setColumnDefs((currentDefs) =>
      currentDefs.map((colDef) => {
        if (colDef.headerName === groupName && colDef.children) {
          const newChildren = colDef.children.map((child) => ({
            ...child,
            hide: !child.hide,
          }));
          return { ...colDef, children: newChildren };
        }
        return colDef;
      })
    );
  }, []);

  const PricesTable = () => {
    return (
      <div>
        <div
          style={{ marginBottom: '10px', display: 'flex', flexWrap: 'wrap' }}
        >
          {columnDefs
            .filter((colDef) => colDef.children)
            .map((groupColDef) => (
              <label
                key={groupColDef.headerName}
                style={{ marginRight: '20px' }}
              >
                <input
                  type='checkbox'
                  checked={!groupColDef.children.some((child) => child.hide)}
                  onChange={() =>
                    toggleColumnVisibility(groupColDef.headerName)
                  }
                />{' '}
                {groupColDef.headerName}
              </label>
            ))}
        </div>
        <div
          className='ag-theme-alpine table-container with-margin'
          style={{ height: 600, width: '100%' }}
        >
          <AgGridReact
            rowData={transformedData}
            columnDefs={columnDefs}
            defaultColDef={{ resizable: true, sortable: true }}
            onCellValueChanged={handleCellValueChanged}
          />
        </div>
      </div>
    );
  };

  const handleCellValueChanged = useCallback((event) => {
    const { data, colDef, newValue } = event;

    const fieldParts = colDef.field.split('_');
    if (fieldParts[1] === 'price') {
      const buyer_name = fieldParts[0];
      updateValue(
        newValue,
        data[buyer_name + '_id'],
        data[buyer_name + '_productId']
      );
    }
  }, []);

  const updateValue = debounce((newValue, buyerId, productId) => {
    if (role === 'ADMIN') {
      api
        .post('/sales/prices/update', {
          product_id: productId,
          buyer: buyerId,
          price: newValue,
        })
        .then((response) => {
          toast.success('Price updated successfully', {
            autoClose: 1500,
          });
        })
        .catch((error) => {
          toast.error('Failed to update price', {
            autoClose: 1500,
          });
        });
    }
  }, 1000);

  const currencyChanged = (e) => {
    e.preventDefault();
    setCurrency(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Expression Prices</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol xs={12}>
            <CCard className='mb-4'>
              <CCardHeader>
                <CCol sm={5}>
                  <strong>PRICE TABLE</strong> <small>By Client</small>
                </CCol>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CAlert color='info'>
                      The prices are shown in the currency below.
                    </CAlert>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CFormLabel>Currency</CFormLabel>
                    <CFormSelect value={currency} onChange={currencyChanged}>
                      {Array.isArray(currencies) && currencies.length > 0 ? (
                        currencies.map((currency) => {
                          return (
                            <option
                              value={currency.currency}
                              key={`option-currency-${currency.currency}`}
                            >
                              {currency.currency}
                            </option>
                          );
                          currencyChanged;
                        })
                      ) : currency === 'NotFound' ? (
                        <option value={-1}>Failed to load currencies.</option>
                      ) : (
                        <option value={-1}>Loading...</option>
                      )}
                    </CFormSelect>
                  </CCol>
                </CRow>
                {role === 'ADMIN' || role === 'STAFF' ? (
                  <>
                    <React.Fragment>
                      <CRow className='mb-5'>
                        <CCol xs={12} md={8}>
                          <CForm
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <CFormLabel>Filter by username</CFormLabel>
                            <CFormInput
                              name='search-username'
                              type='search'
                              size={'sm'}
                              placeholder={'Type a username'}
                              value={usernameFilter}
                              onChange={(e) => {
                                setUsernameFilter(e.target.value);
                              }}
                            />
                          </CForm>
                        </CCol>
                        <CCol xs={12} md={4}>
                          <CFormLabel>&nbsp;</CFormLabel>
                          <div>
                            <CFormSwitch
                              label={'Show Hidden Users'}
                              checked={showHiddenUsers}
                              onChange={() => {
                                setShowHiddenUsers((v) => !v);
                              }}
                            />
                          </div>
                        </CCol>
                      </CRow>
                      <hr />
                    </React.Fragment>
                  </>
                ) : null}
                {loadingPrices ? (
                  <>
                    <Loading />
                  </>
                ) : (
                  <PricesTable items={pricesList} />
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

Prices.defaultProps = {
  prices: [],
};

Prices.propTypes = {
  prices: PropTypes.any,
  columns: PropTypes.any,
  items: PropTypes.any,
  item: PropTypes.any,
  header: PropTypes.any,
  price: PropTypes.any,
  expression: PropTypes.any,
  h: PropTypes.number,
  r: PropTypes.number,
  c: PropTypes.number,
};

export default Prices;
