import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CContainer,
  CFormSelect,
} from '@coreui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';
import api from 'src/api';
import Loading from 'src/components/Loading';
import LButton from 'src/components/LButton/LButton';

const ByDate = () => {
  const role = useSelector((state) => state.auth.role);
  const [startDate, setStartDate] = useState();
  const [selectionMode, setSelectionMode] = useState('date');
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useDispatch();

  const onDateChange = (date) => {
    setStartDate(date);

    setLoading(true);
    let params;

    if (selectionMode === 'month') {
      const year = date.getFullYear();
      const month = date.getMonth();
      const lastDayOfMonth = new Date(year, month + 1, 0);

      params = {
        date: lastDayOfMonth.toISOString().slice(0, 10),
      };
    } else {
      params = {
        date: new Date(date).toISOString().slice(0, 10),
      };
    }

    api
      .get('/situation/by-date', { params })
      .then(({ data }) => {
        setInventory(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleModeChange = (e) => {
    setSelectionMode(e.target.value);
    setStartDate(null);
    setInventory([]);
  };

  function downloadPdf() {
    setIsDownloading(true);

    let params;

    if (selectionMode === 'month') {
      const year = startDate.getFullYear();
      const month = startDate.getMonth();
      const lastDayOfMonth = new Date(year, month + 1, 0);

      params = {
        date: lastDayOfMonth.toISOString().slice(0, 10),
      };
    } else {
      params = {
        date: new Date(startDate).toISOString().slice(0, 10),
      };
    }

    api
      .get(
        '/situation/pdf/inventory-history',
        {
          params: params,
          responseType: 'blob',
        },
        {
          encoding: null,
        }
      )
      .then(({ data }) => {
        const pdfBlob = new Blob([data], { type: 'application/pdf' });
        const blobUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute(
          'download',
          `inventory-history-` +
            `${new Date().getDay()}` +
            `-${new Date().getMonth()}-${new Date().getFullYear()}.pdf`
        );
        link.click();
        link.remove();
        URL.revokeObjectURL(blobUrl);
      })
      .catch((e) => {
        dispatch(
          setErrors([
            {
              message: e,
            },
          ])
        );
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }

  return (
    <>
      <Helmet>
        <title>Inventory By Date</title>
      </Helmet>
      <CContainer>
        <CRow>
          <CCol xs={12}>
            <CCard className='mb-4'>
              <CCardHeader>
                <strong>INVENTORY</strong> <small>By Date or Month</small>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol xs='6' md='3' lg='2'>
                    <CFormSelect
                      className='mb-2'
                      onChange={handleModeChange}
                      value={selectionMode}
                    >
                      <option value='date'>By Date</option>
                      <option value='month'>By Month</option>
                    </CFormSelect>
                  </CCol>
                  <CCol xs='6' md='3' lg='2'>
                    <DatePicker
                      selected={startDate}
                      onChange={onDateChange}
                      showMonthYearPicker={selectionMode === 'month'}
                      placeholderText={
                        selectionMode === 'month' ? 'mm/yyyy' : 'dd/mm/yyyy'
                      }
                      dateFormat={
                        selectionMode === 'month' ? 'MM/yyyy' : 'dd/MM/yyyy'
                      }
                    />
                  </CCol>
                  {(role === 'ADMIN' || role === 'STAFF') && (
                    <CRow>
                      <CCol>
                        <LButton
                          isLoading={isDownloading}
                          onClick={downloadPdf}
                          className='mb-3'
                        >
                          Download PDF
                        </LButton>
                      </CCol>
                    </CRow>
                  )}
                </CRow>
                <hr />
                {startDate && (
                  <Table>
                    <Thead>
                      <Tr>
                        <Th scope='col'>Product</Th>
                        <Th scope='col'>Lot</Th>
                        <Th scope='col'>Btls</Th>
                        <Th scope='col'>Ltr</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {inventory.map((item) => {
                        return (
                          <Tr key={`tr-${item.lot_number}`}>
                            <Td>{item.expression}</Td>
                            <Td>{item.lot_number}</Td>
                            <Td>{item.quantity_bottles}</Td>
                            <Td>{item.quantity_liters}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                )}
                {loading ? (
                  <>
                    <Loading />
                  </>
                ) : (
                  <>
                    {startDate && !inventory.length && (
                      <p>No data available for the selected date or month.</p>
                    )}
                  </>
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default ByDate;
