import { cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import PropTypes from 'prop-types';
import './choose-product.css';

import { CModal, CModalBody, CModalHeader, CModalFooter, CModalTitle, CButton, CForm, CFormLabel, CFormInput, CRow, CCol, CFormSwitch } from '@coreui/react';

import { useState } from 'react';
import FilterableSelect from '../FilterableSelect/FilterableSelect';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';
import api from 'src/api';
import { v4 as uuidv4 } from 'uuid';

const ChooseProduct = ({ choose, expressions, onContinue, item, removeProduct }) => {
  const [visible, setVisible] = useState(false);
  const [chosenExpression, setChosenExpression] = useState(null);
  const [price, setPrice] = useState('');
  const [qty, setQty] = useState('');
  const [isGift, setIsGift] = useState(false);
  const [lots, setLots] = useState([]);
  const [chosenLot, setChosenLot] = useState(null);
  const [id, setId] = useState();

  const dispatch = useDispatch();

  const cancel = () => {
    setPrice('');
    setQty('');
    setChosenExpression(null);
    setVisible(false);
    setIsGift(false);
  };

  useEffect(() => {
    if (chosenExpression) {
      api
        .get(`/product/lots/${chosenExpression}`)
        .then(({ data }) => {
          if (data.length <= 0) {
            dispatch(setErrors([{ message: 'No Lots found for chosen expression.' }]));
          }
          setLots(data);
        })
        .catch((err) => {
          dispatch(setErrors([{ message: err }]));
        });
    } else {
      setLots([]);
    }
  }, [chosenExpression]);

  useEffect(() => {
    if (item?.lot_id) {
      setChosenLot(item.lot_id);
    }
  }, [lots, item]);

  useEffect(() => {
    if (item) {
      setChosenExpression(item.product_id);
      setIsGift(item.isGift);
      setQty(item.qty);
      if (item.id) {
        setId(item.id);
      }
    }
  }, [item]);

  useEffect(() => {
    if (isGift) {
      setPrice(0);
    } else if (item?.price) {
      setPrice(item.price);
    }
  }, [isGift, item]);

  const getExpressionName = (id) => {
    let expresssionName = '';

    expressions.some((ex) => {
      const isItem = ex.id == id;
      if (isItem) {
        expresssionName = ex.expression;
      }
      return isItem;
    });

    return expresssionName;
  };

  const addExpression = () => {
    let valid = true;

    if (isNaN(parseFloat(price))) {
      // not a valid number
      valid = false;
      dispatch(
        setErrors([
          {
            message: 'Please enter a valid number in the price field.',
          },
        ]),
      );
    }
    if (parseFloat(price) <= 0 && !isGift) {
      // Price is zero or less and is not a gift.
      dispatch(
        setErrors([
          {
            message: 'This was not a gift, you must set the price to a value greater than zero.',
          },
        ]),
      );
      valid = false;
    }

    if (isNaN(parseInt(qty))) {
      dispatch(
        setErrors([
          {
            message: 'Please enter a valid number in the quantity field.',
          },
        ]),
      );
      valid = false;
    } else if (parseInt(qty) <= 0) {
      dispatch(
        setErrors([
          {
            message: 'Please enter a number greater than zero in the quantity field.',
          },
        ]),
      );
      valid = false;
    }

    if (isNaN(parseInt(chosenLot)) || parseInt(chosenLot) <= 0) {
      dispatch(setErrors([{ message: 'Please choose a lot' }]));
      valid = false;
    }

    if (valid) {
      if (typeof onContinue == 'function') {
        onContinue({
          product_id: chosenExpression,
          price: parseFloat(price),
          qty: parseInt(qty),
          isGift,
          lot_id: chosenLot,
          id: id || uuidv4(),
        });
        cancel();
        setVisible(false);
      } else {
        dispatch(
          setErrors([
            {
              message: 'Invalid. onContinue is not a function',
            },
          ]),
        );
      }
    }
  };

  return (
    <>
      <div
        className="choose-wrapper"
        onClick={() => {
          setVisible(true);
        }}
        data-testid="choose-wrapper"
      >
        {choose ? (
          <CIcon icon={cilPlus} size="3xl" />
        ) : (
          <div className="flex-center">
            <p className="text-center mb-0 pb-0">
              <b>x{item.qty}</b> <span>{getExpressionName(item.product_id)}</span>
            </p>
            {item.isGift ? (
              <p className="text-success text-center">
                <b>Gift</b>
              </p>
            ) : (
              <p className="text-center">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(item.price)}{' '}
                per bottle
              </p>
            )}
          </div>
        )}
      </div>
      <CModal backdrop="static" fullscreen visible={visible} onClose={() => setVisible(false)} size="lg">
        <CModalHeader>
          <CModalTitle>Choose Expression</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <CRow>
              <CCol xs={12} md={6}>
                <div className="mb-3">
                  <CFormLabel>Choose an expression</CFormLabel>
                  <FilterableSelect
                    selectProps={{ 'data-testid': 'expressions-select' }}
                    data-testid="choose-exp"
                    defaultValue={chosenExpression}
                    options={expressions}
                    value="id"
                    text="expression"
                    onChange={(v) => {
                      setChosenExpression(v);
                    }}
                  />
                </div>
              </CCol>
              <CCol xs={12} md={6}>
                <div className="mb-3">
                  <CFormLabel>Choose a Lot</CFormLabel>
                  <FilterableSelect
                    selectProps={{ 'data-testid': 'lots-select' }}
                    data-testid="choose-lot"
                    defaultValue={chosenLot}
                    options={lots}
                    value="id"
                    text="lot_number"
                    onChange={(v) => {
                      setChosenLot(v);
                    }}
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs={12}>
                <div className="mb-3">
                  <CFormSwitch
                    data-testid="was_gift"
                    defaultChecked={isGift}
                    label="Was this a gift?"
                    size="xl"
                    value={isGift}
                    onChange={() => {
                      setIsGift((prev) => !prev);
                    }}
                  />
                </div>
              </CCol>
              <CCol xs={12} md={5}>
                <div className="mb-3">
                  <CFormLabel>Price</CFormLabel>
                  <CFormInput
                    data-testid="priceInput"
                    disabled={isGift}
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                    placeholder="Price"
                    type="number"
                  />
                </div>
              </CCol>
              <CCol xs={12} md={5}>
                <div className="mb-3">
                  <CFormLabel>Quantity (of bottles)</CFormLabel>
                  <CFormInput
                    data-testid="qtyInput"
                    value={qty}
                    onChange={(e) => {
                      setQty(e.target.value);
                    }}
                    placeholder="Qty"
                    type="number"
                  />
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CModalBody>
        <CModalFooter>
          {item && (
            <CButton
              color="danger"
              onClick={() => {
                removeProduct(item);
                setVisible(false);
              }}
              className="float-start"
            >
              <CIcon icon={cilTrash} className="mr-1" />
              Remove Product
            </CButton>
          )}
          <CButton color="primary" onClick={addExpression}>
            {' '}
            {item?.id ? 'Update' : 'Add Expression'}
          </CButton>
          <CButton color="danger" onClick={cancel}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

ChooseProduct.defaultProps = {
  choose: false,
  expressions: [],
};

ChooseProduct.propTypes = {
  choose: PropTypes.bool,
  expressions: PropTypes.array,
  onContinue: PropTypes.func,
  item: PropTypes.object,
  removeProduct: PropTypes.func,
};

export default ChooseProduct;
