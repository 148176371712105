import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'src/api';

import { useDispatch } from 'react-redux';
import { logoutRoute } from 'src/actions';

export default function OnLogout() {
  const navigate = useNavigate();
  const state = useSelector((s) => s);
  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();

    const verify = () => {
      if (state.auth.loggedIn) {
        api
          .get('/sessions/validate/' + state.auth.sessionId)
          .then(() => {})
          .catch((data) => {
            if (data?.response?.status == 401) {
              dispatch(logoutRoute());
            }
          });
      }
    };
    verify();

    const interval = setInterval(() => {
      verify();
    }, 1000 * 60 * 60 * 24); // Check every 24 hours

    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, [state.auth.sessionId, state.auth.loggedIn]);

  useEffect(() => {
    const to = setTimeout(() => {
      if (state.auth.loggedIn && state.loggedOut) {
        navigate('/logout');
      }
    }, 3000);
    if (state.auth.loggedIn && state.loggedOut) {
      toast.error('Your session has expired or it was closed. Logging out', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    return () => {
      clearTimeout(to);
    };
  }, [state.auth.loggedIn, state.loggedOut]);

  return <></>;
}
