import React from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CButton,
  CContainer,
  CModal,
  CModalHeader,
  CModalBody,
  CFormCheck,
} from '@coreui/react';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'src/components/custom-responsive-table.css';
import {useState, useEffect} from 'react';
import api from 'src/api';
import {Helmet} from 'react-helmet';
const InventoryAGranel = () => {
  const [visible, setVisible] = useState(false);
  const [productsList, setProductsList] = useState([]);

  function fetchInventory() {
    api.get('/situation/a-granel').then(({data}) => {
      setProductsList(data);
    });
  }

  useEffect(() => {
    fetchInventory();
  }, []);

  function sendToProduction(e) {
    e.preventDefault();
    const toProduction = [];
    for (let i = 0; i < e.target.elements.length - 2; i += 4) {
      if (e.target.elements[i].checked) {
        if (
          parseInt(e.target.elements[i + 3].value) >
          parseInt(e.target.elements[i + 2].value)
        ) {
          alert(
              'More than maximum ammount selected for lot ' +
            e.target.elements[i + 1].value +
            '. Permited maximum ' +
            e.target.elements[i + 2].value,
          );
        } else {
          toProduction.push({
            lot_number: e.target.elements[i + 1].value,
            liters: parseInt(e.target.elements[i + 3].value),
          });
        }
      }
    }
    toProduction.forEach((lot) => {
      api.post('/lotes/production/new', lot).then(() => {
        fetchInventory();
        setVisible(false);
      });
    });
  }

  function ProductsList(props) {
    const prods = props.lots;
    let p = -1;
    const list = prods.map((lot) => {
      p++;
      return (
        <Tr style={{fontSize: '0.85em'}} key={'iagr' + p}>
          <Td scope="row">{lot.expression}</Td>
          <Td>{lot.lot_number}</Td>
          <Td color="info" style={{fontWeight: 'bold'}}>
            {lot.available_liters}
          </Td>
          <Td>
            {Math.trunc(
                ((lot.available_liters - 1) * 0.995) / (lot.ml_btl / 1000),
            )}
          </Td>
        </Tr>
      );
    });

    return list;
  }

  const SendToProductionRow = (props) => {
    const [colorChecked, setColorChecked] = useState('transparent');
    const [inputDisabled, setInputDisabled] = useState(true);
    const amountInput = React.createRef();

    return (
      <Tr color={colorChecked}>
        <Td scope="row">
          <CFormCheck
            onClick={() => {
              if (colorChecked === 'transparent') {
                setColorChecked('secondary');
                setInputDisabled(false);
              } else {
                setColorChecked('transparent');
                setInputDisabled(true);
                amountInput.current.value = '';
              }
            }}
          ></CFormCheck>
        </Td>
        <Td>{props.expression}</Td>
        <Td>
          <CFormInput readOnly plainText defaultValue={props.lot}></CFormInput>
        </Td>
        <Td style={{textAlign: 'center'}}>
          <CFormInput
            readOnly
            plainText
            defaultValue={props.available}
          ></CFormInput>
        </Td>
        <Td>
          <CFormInput
            type="text"
            id="amountInput"
            size="sm"
            disabled={inputDisabled}
            ref={amountInput}
          ></CFormInput>
        </Td>
      </Tr>
    );
  };

  const SendToProduction = (props) => {
    const prods = props.lots;
    let p = -1;
    const list = prods.map((product) => {
      p++;
      return (
        <SendToProductionRow
          key={`stp-${p}`}
          expression={product.expression}
          lot={product.lot_number}
          available={product.available_liters}
        />
      );
    });
    return list;
  };

  return (
    <>
      <Helmet>
        <title>
          Inventory A Granel
        </title>
      </Helmet>
      <CContainer>
        <CModal visible={visible} onDismiss={() => setVisible(false)} size="lg">
          <CModalHeader>
            <CCol sm={5}>
              <strong>SEND TO PRODUCTION</strong>
            </CCol>
          </CModalHeader>
          <CModalBody>
            <CForm style={{margin: '10px'}} onSubmit={sendToProduction}>
              <div className="table-container with-margin">
                <Table>
                  <Thead>
                    <Tr>
                      <Th scope="col"></Th>
                      <Th scope="col">Expression</Th>
                      <Th scope="col">Lot</Th>
                      <Th scope="col">Available</Th>
                      <Th scope="col">Amount</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <SendToProduction lots={productsList} />
                  </Tbody>
                </Table>
              </div>

              <CButton type="submit" color="primary">
                Send to Production
              </CButton>
              <CButton
                color="danger"
                onClick={() => {
                  setVisible(false);
                }}
                style={{marginLeft: '10px'}}
              >
                Close
              </CButton>
            </CForm>
          </CModalBody>
        </CModal>
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>INVENTORY</strong> <small>a Granel</small>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CButton
                      style={{marginBottom: '15px'}}
                      onClick={() => {
                        setVisible(!visible);
                      }}
                    >
                      Send to Production
                    </CButton>
                  </CCol>
                </CRow>
                <div className="table-container with-margin">
                  <Table>
                    <Thead>
                      <Tr>
                        <Th scope="col">Expression</Th>
                        <Th scope="col">Lot</Th>
                        <Th color="info" scope="col">
                          Liters
                        </Th>
                        <Th scope="col">Projected Btls</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <ProductsList lots={productsList} />
                    </Tbody>
                  </Table>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

InventoryAGranel.defaultProps = {
  products: [],
};

InventoryAGranel.propTypes = {
  products: PropTypes.any,
  p: PropTypes.number,
  compromised_bottles: PropTypes.number,
  bottles_in_production: PropTypes.number,
  expression: PropTypes.string,
  l: PropTypes.number,
  lot_number: PropTypes.string,
  bottles_in_stock: PropTypes.number,
  lots: PropTypes.any,
  available: PropTypes.string,
  lot: PropTypes.string,
};

export default InventoryAGranel;
