import {
  CContainer,
  CCol,
  CCard,
  CCardBody,
  CRow,
  CFormInput,
  CFormLabel,
  CButton,
} from '@coreui/react';
import LButton from 'src/components/LButton/LButton';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  login,
  setErrors,
  setSuccessMessages,
  hideSidebarShow,
} from 'src/actions';
import api from 'src/api';

import {useEffect} from 'react';

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideSidebarShow());
  }, []);

  const submitForm = () => {
    let valid = true;
    if (emailAddress?.length < 1) {
      dispatch(
          setErrors([
            {
              message: 'Please enter your email address',
            },
          ]),
      );
      valid = false;
    } else if (password?.length < 1) {
      dispatch(
          setErrors([
            {
              message: 'Please enter your password',
            },
          ]),
      );
      valid = false;
    } else if (repeatPassword?.length < 1) {
      dispatch(
          setErrors([
            {
              message: 'Please repeat your password',
            },
          ]),
      );
      valid = false;
    } else if (repeatPassword !== password) {
      dispatch(
          setErrors([
            {
              message: 'Both passwords should be equal',
            },
          ]),
      );
      valid = false;
    }
    if (valid) {
      setIsLoading(true);
      api
          .post('/user/sign-up', {
            username,
            email: emailAddress,
            password,
          })
          .then(({data}) => {
            api
                .post('/user/sign-in', {
                  username: username,
                  password: password,
                })
                .then(({data}) => {
                  dispatch(
                      login({
                        token: data.token,
                        role: data.role,
                        refresh_token: data.refresh_token,
                        avatar: data.avatar,
                        username: data.username,
                      }),
                  );
                })
                .catch((e) => {
                  dispatch(
                      setErrors([
                        {
                          message: 'We could not sign you in.',
                        },
                      ]),
                  );
                });

            dispatch(
                setSuccessMessages([
                  {
                    message: 'Your account has been created',
                  },
                ]),
            );
          })
          .catch((e) => {
            dispatch(
                setErrors([
                  {
                    message:
                  e?.response?.data?.errors > 0 ?
                    e.response.data.errors[0]?.message :
                    e,
                  },
                ]),
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
    }
  };

  return (
    <>
      {loggedIn && navigate('/')}
      <CContainer>
        <CRow>
          <CCol xs={12} md={{span: 6, offset: 3}} xl={{span: 4, offset: 4}}>
            <CCard>
              <CCardBody>
                <div className="d-flex justify-content-center">
                  <img src="/logo.png" alt="Cuentacuentos logo" width="200px" />
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <CRow>
                    <CCol>
                      <div className="mb-3">
                        <CFormLabel>Username</CFormLabel>
                        <CFormInput
                          placeholder="Username"
                          value={username}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                        />
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div className="mb-3">
                        <CFormLabel>Email Address</CFormLabel>
                        <CFormInput
                          placeholder="Email Address"
                          type="email"
                          value={emailAddress}
                          onChange={(e) => {
                            setEmailAddress(e.target.value);
                          }}
                        />
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs={12}>
                      <div className="mb-3">
                        <CFormLabel>Password</CFormLabel>
                        <CFormInput
                          placeholder="Password"
                          type="password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                    </CCol>
                    <CCol xs={12}>
                      <div className="mb-3">
                        <CFormLabel>Repeat Password</CFormLabel>
                        <CFormInput
                          placeholder="Password"
                          type="password"
                          value={repeatPassword}
                          onChange={(e) => {
                            setRepeatPassword(e.target.value);
                          }}
                        />
                      </div>
                    </CCol>
                  </CRow>
                </form>
                <CRow>
                  <CCol>
                    <div className="d-grid">
                      <LButton
                        color="dark"
                        isLoading={isLoading}
                        type="button"
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        Sign Up
                      </LButton>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <p className="text-center text-muted my-1">- or -</p>
                    <div className="d-grid">
                      <CButton
                        color="dark"
                        variant="outline"
                        onClick={() => {
                          window.location.assign(
                              `${process.env.REACT_APP_LOGIN_URL_SSO}`,
                          );
                        }}
                      >
                        Sign In
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default SignUp;
