import { CFormInput, CTableRow, CTableDataCell, CAlert } from '@coreui/react';
import TheIcon from '../TheIcon/TheIcon';
import { useEffect, useState } from 'react';
import LButton from '../LButton/LButton';

import { useContext } from 'react';
import { OrderContext } from 'src/context/OrderContext';
import { toast } from 'react-toastify';
import api from 'src/api';
import { useDispatch, useSelector } from 'react-redux';
import { setErrors } from 'src/actions';

import PropTypes from 'prop-types';
import { setStockInCart, setProductionInCart } from 'src/actions';

function SubTableRow({ lot, isStock, isManual, product }) {
  const [depleted, setDepleted] = useState(-1);
  const [inCart, setInCart] = useState(-1);
  const { data, isAutoSave, isConnected, socket } = useContext(OrderContext);
  const [isSaving, setIsSaving] = useState(false);
  const [available, setAvailable] = useState(0);
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState(-1);

  const { stockInCart, productionInCart } = useSelector((state) => state.order);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const searchIn = isStock ? stockInCart : productionInCart;
      if (Array.isArray(searchIn) && lot?.lot_number) {
        const total = searchIn.reduce((prev, curr) => {
          if (lot.lot_id == curr.lot_id) {
            return prev + curr.qty;
          } else {
            return prev;
          }
        }, 0);
        setDepleted(total);
        setInCart(total);
        setInitialValue(total);
      }
    }, 600);

    return () => {
      clearTimeout(timeout);
    };
  }, [data, stockInCart, lot, isStock]);

  useEffect(() => {
    const v = isStock ? parseInt(lot.qtyStockAv) : parseInt(lot.qtyProductionAv);
    if (!isNaN(v)) {
      setAvailable(v);
    }

    return () => {
      setAvailable(0);
    };
  }, [lot.qtyStockAv, lot.qtyProductionAv, isStock]);

  function saveQty() {
    // setIsSaving(true)
    let value = parseFloat(depleted);
    let error = null;

    if (isNaN(value) || !Number.isInteger(value)) {
      error = `Please enter a valid value (Must be a positive number without any decimals.)`;
    } else if (value > available) {
      error = `Please choose a number less or equal to the amount of bottles available.`;
    } else if (value < 0) {
      error = `Enter a positive value only.`;
    }

    if (!data?.id) {
      error = 'Unexpected error: The order is was not defined. Please refresh the page and try again.';
    }

    if (!lot?.lot_id) {
      error = 'Unexpected error: The lot id is was not defined. Please refresh the page and try again.';
    }

    if (isAutoSave && isManual && depleted == '') {
      error = null;
      value = 0;
    }

    if (error == null) {
      setIsSaving(true);

      api
        .patch(`/cpo/manual-update/${data.id}`, {
          qty: value,
          lot_id: lot.lot_id,
          isStock: isStock,
        })
        .then(() => {
          const filter = (item) => item.lot_number != lot.lot_number;
          const mapper = (item) => {
            if (item.lot_number == lot.lot_number) {
              item.qty = value;
            }
            return item;
          };

          if (isStock) {
            let newStockInCart = [];

            if (value <= 0) {
              newStockInCart = stockInCart.filter(filter);
            } else {
              newStockInCart = stockInCart.map(mapper);
            }
            // When setting the value to zero (0) the item is deleted. We need to add it again.
            const existsInNewStock = newStockInCart.some((item) => item.lot_number == lot.lot_number);
            if (!existsInNewStock) {
              newStockInCart.push({
                lot_id: lot.lot_id,
                lot_number: lot.lot_number,
                ml_btl: product.ml_btl,
                price: lot.price,
                product_id: lot.product_id,
                qty: value,
              });
            }
            dispatch(setStockInCart(newStockInCart));
          } else {
            let newProdInCart = [];

            if (value <= 0) {
              newProdInCart = productionInCart.filter(filter);
            } else {
              newProdInCart = productionInCart.map(mapper);
            }
            // When setting the value to zero (0) the item is deleted. We need to add it again.
            const existsInNewStock = newProdInCart.some((item) => item.lot_number == lot.lot_number);
            if (!existsInNewStock) {
              newProdInCart.push({
                lot_id: lot.lot_id,
                lot_number: lot.lot_number,
                ml_btl: product.ml_btl,
                price: lot.price,
                product_id: lot.product_id,
                qty: value,
              });
            }
            dispatch(setProductionInCart(newProdInCart));
          }

          if (isManual && isConnected) {
            socket.emit('qty-lot-updated', {
              cpoId: data.id,
              lotId: lot.lot_id,
            });
          }

          toast.success(`${isAutoSave ? 'Automatically' : 'Successfully'} saved ${value} bottles of ${lot.lot_number} in your "cart"`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        })
        .catch((err) => {
          dispatch(setErrors([{ message: err }]));
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isAutoSave && isManual && depleted != initialValue) {
        saveQty();
      }
    }, 1200);

    return () => {
      clearTimeout(timeout);
    };
  }, [depleted, isAutoSave, isManual, initialValue]);

  return (
    <CTableRow>
      <CTableDataCell width={'20%'}>{lot.lot_number}</CTableDataCell>
      <CTableDataCell>{isStock ? lot.qtyStockAv : lot.qtyProductionAv}</CTableDataCell>
      <CTableDataCell>
        {inCart > (isStock ? lot.qtyStockAv : lot.qtyProductionAv) && (
          <CAlert color="danger">
            Some bottles from Lot {lot.lot_number} are <b>no longer available or you entered a greater amount</b> and you need to remove them.
          </CAlert>
        )}
        <CFormInput
          disabled={!isManual}
          placeholder="Amount of bottles"
          value={depleted}
          onChange={(e) => {
            setDepleted(e.target.value);
          }}
        />
      </CTableDataCell>
      {isManual && !isAutoSave && (
        <CTableDataCell>
          <LButton isLoading={isSaving} onClick={saveQty}>
            <TheIcon name="save" className="m-0" />
          </LButton>
        </CTableDataCell>
      )}
    </CTableRow>
  );
}

SubTableRow.propTypes = {
  lot: PropTypes.object,
  isStock: PropTypes.bool,
  isManual: PropTypes.bool,
  product: PropTypes.object,
};

export default SubTableRow;
